import { Typography } from "@mui/material";
import React from "react";

const PortalScreenTitle = ({ children, sx }) => {
  const titleStyles = {
    color: "#ffffff",
    // fontWeight: 800,
    fontSize: "1.2rem !important",
    // lineHeight: "2.4rem",
    // textTransform: "uppercase",
    textTransform: "capitalize",
    // fontFamily: "var(--mont) !important",
  };
  return (
    <Typography variant="h1" sx={{ ...titleStyles, ...sx }}>
      {children}
    </Typography>
  );
};

export default PortalScreenTitle;
