import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { scenarioStatusOptions } from "../../forms/_formQuestions/selectOptions";
import { useBorrowerHook } from "../../hooks/useBorrowerHook";
import { useBrokerDrilldownHook } from "../../hooks/useBrokerDrilldownHook";
import { setFormData } from "../../redux/actions/formDataActions";
import FinalFormSelectField from "../form/Fields/FinalFormSelectField";
import ReactFinalForm from "../form/ReactFinalForm";
import MuiModal from "../shared/MuiModal";
import MuiGrid from "../shared/MuiGrid";
import GridItem from "../shared/MuiGrid/GridItem";
import FinalFormTextField from "../form/Fields/FinalFormTextField";
import { EmployeeSelectFieldComponent } from "../form/components/inputs/finalFormComponents/EmployeeSelectFieldComponent";
import { Field, useForm, useFormState } from "react-final-form";
import FinalFormFileUpload from "../form/Fields/FinalFormFileUpload";

import {
  Autocomplete,
  Button,
  Card,
  FormHelperText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { AddRounded, DeleteOutline, DownloadRounded } from "@mui/icons-material";
import { FieldArray } from "react-final-form-arrays";
import FlexBox from "../shared/FlexBox";
import { useIronfundUsersHook } from "../../hooks/useIronfundUsersHook";
import { arrayProtector } from "../../helpers/arrayProtector";
import SectionWithTitle from "../shared/SectionWithTitle";
import { downloadObjectAsExcel } from "../../helpers/downloadObjectAsExcel";
import { getTodaysDate } from "../../helpers/getTodaysDate";

const BulkInviteUsersToOrganizationModal = ({ show, onCancel, scenario }) => {
  const { postIsLoading, updateBrokerScenario } = useBorrowerHook();

  const dispatch = useDispatch();

  const handleOnScenarioUpdate = (data) => {
    const onRequestSuccess = (passedData) => {
      console.log("onrequest success", passedData);
      dispatch(setFormData(passedData));
      onCancel();
      // setShow(false);
    };

    const params = {
      payload: data,
      id: scenario?._id,
      onSuccessFn: onRequestSuccess,
    };

    //MAKE SURE FORM DATA IN REQUEST
    // updateBrokerScenario(params);
  };

  return (
    <MuiModal
      // description={"Example"}
      title={"Bulk Invite Users to Organization"}
      size={"sm"}
      show={show}
      onClose={onCancel}
      disableBackgroundCancel // optional
      // success={isSuccess} // optional
    >
      <ReactFinalForm
        // hideButton
        isLoading={postIsLoading}
        onSubmit={handleOnScenarioUpdate}
        initialValues={{ associatedEmailDomains: [""] }}
        submitButtonText={"Update Status"}
        //
        // formSpy
      >
        <FormContent />
      </ReactFinalForm>
    </MuiModal>
  );
};

const FormContent = () => {
  const [showNewCompanyName, setShowNewCompanyName] = useState(false);
  const { ironFundUserCompanyNamesList, users } = useIronfundUsersHook();
  const { values } = useFormState();
  const { change } = useForm();
  const filter = createFilterOptions();

  const handleOnAutocompleteChange = (val) => {
    change("company", val);
  };

  const handleDownloadTemplate = () => {
    const numOfUsers = Number(values?.numberOfUsers);
    console.log(numOfUsers);
    const template = Array(numOfUsers).fill({
      name: "",
      emailAddress: "",
      phoneNumber: "",
      assignedSalesperson: values?.loanAssignedTo?.fullName,
      company: values?.company,
      companyRole: "",
      companyUrl: values?.companyUrl,
      associatedEmailDomains: values?.associatedEmailDomains,
    });
    console.log(template);
    downloadObjectAsExcel(template, `User Bulk Upload Template`);
  };

  return (
    <>
      <SectionWithTitle title={"Company Information"} noMargin>
        <MuiGrid>
          <GridItem size={4}>
            <FinalFormTextField name={"numberOfUsers"} label={`Number of Users`} number />
          </GridItem>

          <GridItem size={8}>
            <Field label="Assigned To" name={`loanAssignedTo`} component={EmployeeSelectFieldComponent} />
          </GridItem>
        </MuiGrid>
      </SectionWithTitle>
      <SectionWithTitle title={"Company Information"}>
        <MuiGrid>
          <GridItem size={12}>
            <Autocomplete
              disablePortal
              freeSolo
              disableClearable
              onChange={(event, newValue) => {
                handleOnAutocompleteChange(newValue);
              }}
              filterOptions={(options, params) => {
                console.log({ options, params });
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option);
                console.log({ filtered, inputValue, isExisting });
                if (arrayProtector(filtered).length === 0) {
                  filtered.push(`${inputValue}`);
                }

                return filtered;
              }}
              options={ironFundUserCompanyNamesList}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderOption={(props, option) => <li {...props}>{option}</li>}
              renderInput={(params) => <TextField {...params} label="Company" variant="filled" size="small" />}
            />
            {showNewCompanyName ? <FinalFormTextField name={"company"} label={`New Company Name`} /> : null}
          </GridItem>
          <GridItem size={12}>
            <FinalFormTextField name={"companyRole"} label={`Company Role`} />
          </GridItem>
          <GridItem size={12}>
            <FinalFormTextField name={"companyUrl"} label={`Company Website URL`} />
          </GridItem>
        </MuiGrid>
      </SectionWithTitle>

      <AdditionalFormFieldsSection />
      <SectionWithTitle
        title={"Upload Excel of Users"}
        headerContent={
          <Button
            sx={{ borderRadius: "0", padding: "2px 6px", fontSize: ".7rem", fontWeight: 600 }}
            onClick={handleDownloadTemplate}
            size="small"
            endIcon={<DownloadRounded sx={{ color: "var(--primary)", fontSize: "1rem !important" }} />}
          >
            Download Template
          </Button>
        }
      >
        <MuiGrid>
          <GridItem size={12}>
            <FinalFormFileUpload
              name={"excel"}
              // title={"Upload Sizer"}
              // docGroup={fileDocumentSchema.termsAndSizer.name}
              // docType={value}
              // hideSelectedFiles

              noSidePadding
              // docType={fileDocumentSchema.termsAndSizer.types.sizer}
              maxFiles={1}
            />
          </GridItem>
        </MuiGrid>
      </SectionWithTitle>
    </>
  );
};

const AdditionalFormFieldsSection = ({
  primaryName,
  additionalName,
  additionalItems,
  additionalLabel,
  label,
  phoneNumber,
}) => {
  const { mutators } = useForm();
  const { values } = useFormState();

  const formDataArrayName = `associatedEmailDomains`;
  // const formDataArrayName = `additionalPhoneNumbers`;

  const handleAddNew = () => {
    mutators.push(formDataArrayName, "");
  };

  const handleDeleteRow = (index) => {
    mutators.remove(formDataArrayName, index);
  };

  return (
    <SectionWithTitle
      title={"Associated Email Domains"}
      headerContent={
        <IconButton sx={{ borderRadius: "0" }} onClick={handleAddNew} size="small">
          <AddRounded sx={{ color: "var(--primary)" }} />
        </IconButton>
      }
    >
      {/* <Typography sx={{ fontSize: ".7rem" }}>
     
        Example: if the email address assoicated with the company is john@example.com, the value to enter would be
        <b>example.com</b> only.
      </Typography> */}
      <Card variant="outlined" sx={{ boxShadow: "none" }}>
        <Table
          size="small"
          sx={{
            ".MuiInputBase-root": {
              borderRadius: 0,
              border: "none",
              input: {
                padding: "6px 10px",
              },
              "&:before": {
                borderBottom: 0,
              },
            },
            "tr:last-child": {
              td: {
                borderBottom: "none !important",
              },
            },
            // ...tableSx,
          }}
        >
          <TableBody>
            <FieldArray name={formDataArrayName}>
              {({ fields }) =>
                fields.map((arrayName, index) => (
                  <TableRow>
                    <TableCell sx={{ padding: 0, width: "30px", textAlign: "center" }}>@</TableCell>
                    <TableCell sx={{ padding: 0, borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>
                      <FinalFormTextField
                        variant={"standard"}
                        // label={`${label} ${index + 1} `}
                        placeholder={"example.com"}
                        name={arrayName}
                      />
                    </TableCell>

                    <TableCell sx={{ padding: 0, width: "30px" }}>
                      <IconButton onClick={handleDeleteRow} size="small" sx={{ borderRadius: 0 }}>
                        <DeleteOutline sx={{ fontSize: "1.2rem !important" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </FieldArray>
          </TableBody>
        </Table>
      </Card>
      <FormHelperText>
        If the email address associated with the company looks like this <b>john@mycompany.com</b>, then you should
        enter the value <b>mycompany.com</b> as the value.
      </FormHelperText>
    </SectionWithTitle>
  );
  // return (
  //   <Card
  //     variant="outlined"
  //     sx={{
  //       overflow: "auto",
  //       boxShadow: "none",
  //       maxWidth: "unset",
  //     }}
  //   >
  //     <FlexBox
  //       justifyBetween
  //       alignCenter
  //       sx={{
  //         overflow: "hidden",
  //         background: "#e8f0fe",
  //         borderBottom: "2px solid #23568524",
  //         // borderBottom: "1px solid #e2e2e2",
  //       }}
  //     >
  //       <Typography
  //         sx={{
  //           // color: "var(--primary)",
  //           fontWeight: 600,
  //           paddingLeft: "6px",
  //           fontSize: ".75rem",
  //           lineHeight: ".7rem",
  //         }}
  //         variant="body1"
  //       >
  //         Associated Email Domains
  //       </Typography>
  //       <IconButton sx={{ borderRadius: "0" }} onClick={handleAddNew} size="small">
  //         <AddRounded sx={{ color: "var(--primary)" }} />
  //       </IconButton>
  //     </FlexBox>
  //     <Table
  //       size="small"
  //       sx={{
  //         ".MuiInputBase-root": {
  //           borderRadius: 0,
  //           border: "none",
  //           input: {
  //             padding: "6px 10px",
  //           },
  //           "&:before": {
  //             borderBottom: 0,
  //           },
  //         },
  //         "tr:last-child": {
  //           td: {
  //             borderBottom: "none !important",
  //           },
  //         },
  //         // ...tableSx,
  //       }}
  //     >
  //       <TableBody>
  //         <FieldArray name={formDataArrayName}>
  //           {({ fields }) =>
  //             fields.map((arrayName, index) => (
  //               <TableRow>
  //                 <TableCell sx={{ padding: 0, width: "30px", textAlign: "center" }}>@</TableCell>
  //                 <TableCell sx={{ padding: 0, borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>
  //                   <FinalFormTextField
  //                     variant={"standard"}
  //                     // label={`${label} ${index + 1} `}
  //                     placeholder={"example.com"}
  //                     name={arrayName}
  //                   />
  //                 </TableCell>

  //                 <TableCell sx={{ padding: 0, width: "30px" }}>
  //                   <IconButton onClick={handleDeleteRow} size="small" sx={{ borderRadius: 0 }}>
  //                     <DeleteOutline sx={{ fontSize: "1.2rem !important" }} />
  //                   </IconButton>
  //                 </TableCell>
  //               </TableRow>
  //             ))
  //           }
  //         </FieldArray>
  //       </TableBody>
  //     </Table>
  //   </Card>
  // );
};

export default BulkInviteUsersToOrganizationModal;
