import React from "react";
import TextOverflow from "../../../../../components/shared/TextOverflow";
import Signature from "../../../../../components/form/components/inputs/generic/Signature/Signature";
import { Field, useFormState } from "react-final-form";
import MuiGrid from "../../../../../components/shared/MuiGrid";
import GridItem from "../../../../../components/shared/MuiGrid/GridItem";
import { PasswordFieldComponent } from "../../../../../components/form/components/inputs/finalFormComponents/PasswordFieldComponent";
import { VALIDATOR_REQUIRE } from "../../../../../helpers/validators/inputValidators";
import { Box } from "@mui/material";
import FormGroup from "../../../../../components/form/components/shared/FormGroup/FormGroup";

const AuctionConfirmation = (props) => {
  const { values } = useFormState();

  const pStyles = { fontSize: ".7rem", lineHeight: ".9rem" };
  return (
    <Box sx={{ p: { fontSize: ".7rem" } }} className="credit-auth-confirmation">
      <MuiGrid>
        <GridItem size={12}>
          <TextOverflow title={"Authorization Agreement"}>
            <p style={pStyles}>
              I hereby authorize and instruct Flatiron Realty Capital LLC (the Lender) and its vendors/affiliates to
              obtain and review my personal credit report, background checks, and all other reports and documents and
              involving due diligence in connection with my real estate loan. I understand and agree that the Lender and
              its vendors/affiliates intends to use the credit report for the purpose of evaluating my financial
              readiness to personally guarantee the real estate loan that I have applied for. My signature below
              authorizes the release to credit report and screening agencies of financial or other information that I
              have supplied to the Lender and its vendors/affiliates in connection with such evaluation. Authorization
              is further granted to the credit report and screening agencies to use a copy of this form to obtain any
              information the agencies may deem necessary to complete my credit report and/or background screenings.
            </p>
            <p style={{ marginTop: "14px", ...pStyles }}>
              The actual terms and conditions upon which the Lender might extend credit are subject to satisfactory
              completion of due diligence, internal approval, satisfactory review of documentation and such other or
              different items and conditions as may be determined by the Lender following the completion of its
              underwriting (the “Underwriting and Approval Process”). As a result, notwithstanding any agreement of the
              parties to the contrary, (i) Lender’s approval of the loan documents shall not constitute a final approval
              of the loan and (ii) the loan documents shall not be considered final until the Underwriting and Approval
              Process is completed (as determined in Lender’s sole discretion).
            </p>
            <p style={{ marginTop: "14px", ...pStyles }}>
              The borrower hereby agrees that all aforementioned information is true and in no means is false and
              intended to influence the credit underwriting of the Lender in regard to origination of the loan
              associated with the subject property or properties. Additionally, the borrower understands that this loan
              application does not constitute a commitment from the Lender to originate the loan associated with the
              subject property or properties. Lastly, the borrower acknowledges that all fees collected in relation to
              loan due diligence are non-refundable, in any circumstance.
            </p>
          </TextOverflow>
        </GridItem>

        <GridItem size={12}>
          <Signature isRequired name={"signature"} label="Borrower Signature" />
        </GridItem>

        {values.citizenshipStatus === "Foreign National with SSN" || values.citizenshipStatus === "US Citizen" ? (
          <GridItem size={12}>
            <FormGroup noMargin label="Social Security Number">
              <Field
                validate={VALIDATOR_REQUIRE}
                name={"ssn"}
                label="Social Security Number"
                component={PasswordFieldComponent}
              />
            </FormGroup>
          </GridItem>
        ) : null}
      </MuiGrid>
    </Box>
  );
};

export default AuctionConfirmation;
