import { LoadingButton } from "@mui/lab";
import { CircularProgress, IconButton } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useFilesHook } from "../../hooks/useFilesHook";
import FileButton from "./FileButton";
import { DownloadRounded, VisibilityRounded } from "@mui/icons-material";

const ScenarioFileButton = ({ fileStorageName, label, scenarioId, downloadName, icon }) => {
  return (
    <FileButton
      icon={icon}
      // startIcon={<DownloadRounded sx={{ fontSize: "1rem !important", marginRight: "-3px" }} />}
      // filename={fileDisplayName}
      // downloadName={downloadName}
      label={label}
      path={`portal_get_file_by_name?file_storage_name=${fileStorageName}&scenarioType=IronLincBrokerScenario&scenarioId=${scenarioId}`}
    />
  );
};

export default ScenarioFileButton;
