import { isNil } from "lodash";

export const getFileExtensionFromString = (name) => {
  if (isNil(name)) {
    return "";
  }
  let ext = String(name).split(".").pop();

  return ext;
};
