import * as React from "react";

import { ThemeProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { inputTheme } from "./assets/styles/MuiStyles";
import "./assets/styles/global.css";

import { useStytchSession, useStytchUser } from "@stytch/react";
import { useSelector } from "react-redux";
import SnackbarAlert from "./components/shared/SnackbarAlert/SnackbarAlert";

import { globalAlertClose } from "./redux/actions/globalAlertActions";
import AuthenticatedView, { authenticatedRoutes } from "./routes/authenticatedRoutes";
import UnauthenticatedView from "./views/Unauthenticated/UnauthenticatedView";

const App = () => {
  const globalAlert = useSelector((state) => state.globalAlert);
  const dispatch = useDispatch();

  const { user } = useStytchUser();
  const { session } = useStytchSession();

  const stytchIsActive = session || user;

  const handleCloseSnackbar = () => {
    dispatch(globalAlertClose());
  };

  return (
    <ThemeProvider theme={inputTheme}>
      {stytchIsActive ? <AuthenticatedView /> : <UnauthenticatedView />}

      <SnackbarAlert
        open={globalAlert.show}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message={globalAlert.message}
        sx={{ bottom: "40px !important", zIndex: 11111111111 }}
        anchorPosition="left"
        variant="filled"
        isGlobal
        type={globalAlert.type}
      />
    </ThemeProvider>
  );
};

export default App;
