import { CloseRounded, KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import VerticalNavLeftColumn from "../../../components/layout/VerticalNavLayout/VerticalNavLeftColumn";
import IronFundLogo from "../../../components/shared/IronFundLogo";
import BrokerVerticalNavigationLinks from "./BrokerVerticalNavigationLinks";

const BrokerVerticalNavigation = ({ collapsed, isMobile, isOpen, setIsOpen }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed && !isMobile ? collapsed : false);

  const styles = {
    default: {
      right: "10px",
    },
    collapsedStyles: {
      width: "24px",
      height: "40px",
      borderRadius: "4px",
      right: "2px",
    },
  };

  const buttonStyles = isCollapsed && !isMobile ? styles.collapsedStyles : styles.default;
  const drawerWidth_mobile = "100%";
  // const drawerWidth_mobile = "calc(100% - 40px)";
  const drawerWidth_desktop = isCollapsed ? 75 : 215;
  const drawerWidth = isMobile ? drawerWidth_mobile : drawerWidth_desktop;

  const collapseIconButton = (
    <Tooltip arrow placement="right" title={collapsed ? "Expand" : "Minimize"}>
      <IconButton
        onClick={() => setIsCollapsed((s) => !s)}
        sx={{ position: "absolute", padding: "5px", ...buttonStyles }}
      >
        {isCollapsed ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
      </IconButton>
    </Tooltip>
  );
  const closeMobileIconButton = (
    <IconButton onClick={() => setIsOpen(false)} sx={{ position: "absolute", padding: "5px", ...buttonStyles }}>
      <CloseRounded sx={{ fontSize: "2rem" }} />
    </IconButton>
  );

  return (
    <VerticalNavLeftColumn
      width={isOpen ? drawerWidth : 0}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      variant={isMobile ? undefined : "permanent"}
      // variant={isMobile ? "persistent" : "permanent"}
    >
      {/* <VerticalNavLeftColumn width={isCollapsed ? 65 : 195}> */}
      <Box
        sx={{
          // margin: "20px 14px",
          display: "flex",
          alignItems: "center",
          // justifyContent: "center",
          paddingLeft: isMobile ? "20px" : "0",
          justifyContent: isMobile ? "flex-start" : "center",
          height: "84px",
          // gap: "3px",
        }}
      >
        <Link to={"/"}>
          {/* <FlatIronLogo building={isCollapsed} width={isCollapsed ? "32px" : "195px"} color={"#1C456A"} /> */}
          <IronFundLogo width={"185px"} color={"#1C456A"} sx={{ marginLeft: "-5px" }} />
          {/* <FlatIronLogo building={isCollapsed} width={isCollapsed ? "25px" : "135px"} color={"#1C456A"} /> */}
        </Link>

        {isMobile ? closeMobileIconButton : null}
        {/* {isMobile ? closeMobileIconButton : collapseIconButton} */}
      </Box>
      <BrokerVerticalNavigationLinks isCollapsed={isCollapsed} />
    </VerticalNavLeftColumn>
  );
};

export default BrokerVerticalNavigation;
