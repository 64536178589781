import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from "@mui/icons-material";
import { Button, IconButton, Menu } from "@mui/material";
import React from "react";

/**
 * 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (passedData) => {
    setAnchorEl(null);
  };

  <MuiButtonDropdown
      icon={<MoreVertRounded sx={tableCellIconButtonStyles.icon} />}
      id={id}
      sx={tableCellIconButtonStyles.styles}
      open={open}
      anchorEl={anchorEl}
      handleClick={handleDropdownClick}
      handleClose={handleDropdownClose}
    >

    </MuiButtonDropdown>
 */

const MuiButtonDropdown = ({ id, open, anchorEl, handleClick, handleClose, children, icon, sx }) => {
  const buttonType = icon ? (
    <IconButton onClick={handleClick} sx={sx}>
      {icon}
    </IconButton>
  ) : (
    <Button
      onClick={handleClick}
      variant="contained"
      endIcon={open ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
      sx={{
        padding: "1px 8px",
        fontWeight: 500,
        fontSize: ".7rem",
      }}
    >
      Actions
    </Button>
  );
  return (
    <>
      {buttonType}
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            marginTop: "5px",
            border: "1px solid #cbd6e2",
            // border: "var(--border)",
          },
          ".MuiListItemIcon-root": {
            minWidth: "26px",
            svg: {
              fontSize: "1.1rem",
            },
          },
          ".MuiListItemText-root span": {
            fontSize: ".8rem",
          },
          ".MuiButtonBase-root": {
            paddingLeft: "12px !important",
            // paddingRight: "27px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {children}
      </Menu>
    </>
  );
};

export default MuiButtonDropdown;
