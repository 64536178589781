import { CloseRounded } from "@mui/icons-material";
import { Box, Dialog, DialogContent, IconButton, Stack, Typography } from "@mui/material";
import { useStytchUser } from "@stytch/react";
import React, { useMemo, useState } from "react";
import Form from "../../../../../components/form/Form/Form";
import FlexBox from "../../../../../components/shared/FlexBox";
import TabSwitcher from "../../../../../components/shared/TabSwitcher";
import TabPanel from "../../../../../components/shared/TabSwitcher/TabPanel";
import { useAxiosHook } from "../../../../../hooks/useAxiosHook";
import CopyToClipboardContent from "./CopyToClipboardContent";
import InviteUserEmailContent from "./InviteUserEmailContent";
import InviteUserPreviewCard from "./InviteUserPreviewCard";
import { isArray, isNil } from "lodash";
import { useSelector } from "react-redux";

const InviteUserModal = ({ open, onClose }) => {
  const [value, setValue] = React.useState(0);
  const employees = useSelector((state) => state.globalData.employees);

  const { user } = useStytchUser();

  const currentUserEmail = user?.trusted_metadata?.emailAddress;
  const currentUserSalesObject = useMemo(
    () => (!isArray(employees) ? null : employees?.find((e) => e?.emailAddress === currentUserEmail)),
    [employees, currentUserEmail]
  );

  const url = `https://ironlinc.ironfund.com/sign-up?inviteId=${user?.trusted_metadata?.mongoid}`;
  const handleOnClose = () => {
    setValue(0);
    onClose();
  };

  const templates = [
    `
    <div>Hello, </div><br />
    <div>We would like to invite you to join Iron Fund. Please click the link below and complete the form to get your account set up.</div><br />
    <div>
    <a href="${url}" target="_blank" color="#235685" style="text-decoration: underline; color: rgb(0, 0, 0); font-size: 12px;">
    ${url}</a></div>
    <br />
    `,
  ];

  return (
    <Dialog
      fullWidth={true}
      maxWidth={value === 0 ? "lg" : "sm"}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{
        zIndex: 111111111,
        ".MuiPaper-root": {
          background: "none",
          boxShadow: "none",
          overflow: "unset",
        },
        ".MuiDialogContent-root": {
          flexGrow: 0,
          flexShrink: 0,
        },
      }}
    >
      <Form
        initialValues={{
          subject: "Welcome To Iron Fund | By IronLinc",
          loanSalesperson: currentUserSalesObject,
          body: templates[0],
        }}
        hideButton
        // formSpy
        submitButtonText={"Send Email Invitation"}

        //
      >
        <FlexBox sx={{ flexGrow: 0, gap: "20px", justifyContent: "center" }}>
          <DialogContent
            sx={{
              background: "#ffffff !important",
              flexGrow: 1,
              borderRadius: "4px",
              boxShadow: "var(--bsMui)",
              position: "relative",
            }}
          >
            <Box id="customized-dialog-title" onClose={handleOnClose}>
              <Stack spacing={1} padding="20px 0 10px" width={"100%"} sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ fontWeight: 600 }} variant="h1">
                  Invite Broker To Iron Fund
                </Typography>
                <Typography sx={{ opacity: 0.8 }} gutterBottom>
                  When a Broker signs up with this link they will be associated to your account
                </Typography>
              </Stack>
              <IconButton
                aria-label="close"
                onClick={handleOnClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseRounded />
              </IconButton>
            </Box>
            <Stack
              sx={{
                "button.MuiButtonBase-root": {
                  flexGrow: 1,
                },
              }}
            >
              <TabSwitcher sub setValue={setValue} tabs={["Send Via Email", "Copy Invite Link"]} />

              <TabPanel value={value} index={0}>
                <InviteUserEmailContent url={url} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <CopyToClipboardContent url={url} />
              </TabPanel>
            </Stack>
          </DialogContent>
          {value === 0 ? <InviteUserPreviewCard url={url} /> : null}
        </FlexBox>
      </Form>
    </Dialog>
  );
};

export default InviteUserModal;
