import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import React from "react";
import "./FormSubmitButton.css";

const FormSubmitButton = ({
  onClick,
  style,
  id,
  disabled,
  className,
  isLoading,
  buttonText,
  children,
  normalState,
  startIcon,
  endIcon,
  sx,
}) => {
  // <FormField noMargin={props.noMargin}>
  // </FormField>

  const loadingState = (
    <>
      {/* <span>
        {props.loadingButtonText ? props.loadingButtonText : "Submitting"}
      </span> */}
      <CircularProgress size={".8rem"} sx={{ color: "#ffffff" }} />
      {/* <SpinnerCircular size={20} thickness={50} speed={100} color="#ffffff" /> */}
    </>
  );
  const defaultNormalState = <>{normalState}</>;

  return (
    <LoadingButton
      style={style}
      onClick={onClick}
      id={id}
      disabled={disabled}
      type="submit"
      endIcon={endIcon}
      startIcon={startIcon}
      variant="contained"
      value="Submit"
      sx={{ marginTop: "15px", height: "52px ", ...sx }}
      className={`form-submit-button ${className} `}
    >
      {isLoading ? loadingState : defaultNormalState}
      {!isLoading ? buttonText : null}
      {children}
    </LoadingButton>
  );
};

export default FormSubmitButton;
