import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormCard from "../../../../components/shared/FormCard";
import BrokerSignUpForm from "../../../../forms/BrokerSignUpForm";
import EmailUnsubscribeForm from "../../../../forms/EmailUnsubscribeForm";

const UnsubscribeScreen = (props) => {
  const [isSuccess, setIsSuccess] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const handleOnSuccess = (email) => {
    setIsSuccess(true);
    setUserEmail(email);
  };

  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: "var(--lightBlue)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
      }}
    >
      <FormCard
        title={isSuccess ? "Unsubscribe Successfull" : "Email Unsubscribe"}
        description={
          isSuccess
            ? ` You will no longer receive any emails from us. Please be aware this may take up to 72 hours to take effect.`
            : "Enter your email address below."
        }
        isSuccess={isSuccess}
      >
        <EmailUnsubscribeForm onSuccess={handleOnSuccess} />
      </FormCard>
    </Box>
  );
};

export default UnsubscribeScreen;
