import { Alert, Box, Fade, Snackbar } from "@mui/material";
import React from "react";
import "./SnackbarAlert.css";

const SnackbarAlert = ({
  open,
  autoHideDuration,
  onClose,
  transition,
  message,
  sx,
  absolute,
  anchorPosition,
  type,
  isGlobal,
}) => {
  const styles = {
    position: absolute ? "absolute" : "fixed",
    ...sx,
  };

  return (
    <Snackbar
      id="snackbar-alert"
      open={open}
      autoHideDuration={autoHideDuration || 2400}
      TransitionComponent={transition || Fade}
      onClose={onClose}
      className={type}
      sx={styles}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: anchorPosition ? anchorPosition : "center",
      }}
    >
      <Box>
        <AlertItem onClose={onClose} isGlobal={isGlobal} message={message} type={type} />
      </Box>
    </Snackbar>
  );
};

const AlertItem = React.forwardRef(function MyComponent({ onClose, isGlobal, message, type }, ref) {
  //  Spread the props to the underlying DOM element.
  const defaultType = type ? type : "success";
  return (
    <Alert onClose={onClose} severity={isGlobal ? type : defaultType} sx={{ width: "100%" }}>
      {message}
    </Alert>
  );
});

export default SnackbarAlert;
