import { isArray, isNil } from "lodash";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAxiosHook } from "./useAxiosHook";
import { updateGlobalData } from "../redux/actions/globalDataActions";
import { removeDuplicates } from "../helpers/removeDuplicates";
import { useEffect } from "react";
import { arrayProtector } from "../helpers/arrayProtector";
import { toKebabCase } from "../helpers/toKebabCase";
import { toNormalCase } from "../helpers/toNormalCase";
import { setFormData } from "../redux/actions/formDataActions";

export const useIronfundUsersHook = () => {
  const globalUsers = useSelector((state) => state.globalData?.users);
  const loanScenarios = useSelector((state) => state.loanScenarios);
  const users = useMemo(() => globalUsers, [globalUsers]);
  const { axiosPostIsLoading, axiosGetIsLoading, axiosGetRequest, axiosPostRequest } = useAxiosHook();
  const isLoading = axiosGetIsLoading || axiosPostIsLoading;
  const dispatch = useDispatch();

  const fetchIronFundUsers = async ({ onSuccessFn, onFailFn }) => {
    /**
     * Fetches users -> returns all users -> filters out employees -> reverses list -> sets list of all users in globalData.users
     * Return:  array of all user accounts
     */
    const endpoint = `get_all_ironfund_users`;

    const onRequestSuccess = (passedData) => {
      let users = passedData?.users;
      let reversedUsers;
      if (isArray(users)) {
        let filteredUsers = users.filter(
          (u) => !u?.emailAddress.includes("@ironfund.com") && !u?.emailAddress.includes("@flatironrealtycapital.com")
        );
        reversedUsers = filteredUsers.reverse();
        dispatch(updateGlobalData({ key: "users", value: reversedUsers }));
      }
      if (onSuccessFn) {
        onSuccessFn(reversedUsers);
      }
    };

    const params = {
      name: "Get All Users",
      path: endpoint,
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not get scenarios",
      onFailFn,
    };
    await axiosGetRequest(params);
  };

  const usersHookGuardClause = () => {
    console.log("GUARD CLUSEEEEE");
    if (isNil(users)) {
      // fetchIronFundUsers({});
      return true;
    }
    return false;
  };

  const getIronFundUserCompanies = () => {
    if (isNil(users)) {
      return;
    }
    const uniqueCompanyTableData = removeDuplicates(users, "company");
    let companyNameList = uniqueCompanyTableData.map((obj) => obj.company);
    const result = companyNameList.filter((str) => !isNil(str) && str !== "" && str !== " ").sort();
    return result;
  };

  const filterUsersByKeyName = (value, keyName) => {
    usersHookGuardClause();

    const filteredList = users.filter((c) => c[keyName] === value);
    return filteredList;
  };

  const ironFundUserCompanyNamesList = useMemo(() => arrayProtector(getIronFundUserCompanies()), [users]);

  return {
    isLoading,
    users,
    ironFundUserCompanyNamesList,
    fetchIronFundUsers,
    filterUsersByKeyName,

    getIronFundUserCompanies,
  };
};
