import { Box, Button, Divider, Stack } from "@mui/material";
import React from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useSelector } from "react-redux";
import DrilldownMultiItemCard from "../../../../../components/Drilldown/DrilldownMultiItemCard";
import FinalFormSelectField from "../../../../../components/form/Fields/FinalFormSelectField";
import FinalFormTextField from "../../../../../components/form/Fields/FinalFormTextField";
import { TextFieldComponent } from "../../../../../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import MuiGrid from "../../../../../components/shared/MuiGrid";
import GridItem from "../../../../../components/shared/MuiGrid/GridItem";
import { citizenshipOptions, yesNo } from "../../../../../forms/_formQuestions/selectOptions";
import { fieldNames } from "../../../../../global/forms/fieldNames";
import { getObjectValueFromStringPath } from "../../../../../helpers/getObjectValueFromStringPath";
import { parsePhoneNumber } from "../../../../../helpers/parse/parsePhoneNumber";
import EditableDrilldownTabCard from "../components/EditableDrilldownTabCard";
import DrilldownCardTabSection from "../../../../../components/Drilldown/DrilldownCardTabSection";

import { AddRounded, PersonAddRounded, PersonRounded } from "@mui/icons-material";
import { isEqual } from "lodash";

const ScenarioDrilldownTabBorrowers = (props) => {
  const { values } = useFormState();

  const { mutators } = useForm();
  const handleAddNewBorrower = () => {
    mutators.push("borrowers", {});
  };
  return (
    <EditableDrilldownTabCard
      cardHeader
      title={"Borrowers & Guarantors"}
      onClickText={"Add New Borrower"}
      onClickIcon={<PersonAddRounded />}
      onClick={handleAddNewBorrower}
    >
      <Stack spacing={2} sx={{ paddingBottom: "50px" }}>
        <FieldArray name={"borrowers"}>
          {({ fields }) =>
            fields.map((name, index) => (
              <DrilldownMultiItemCard
                key={index}
                title={
                  <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                    <PersonRounded sx={{ fontSize: "1.1rem" }} />{" "}
                    {`${getObjectValueFromStringPath(
                      values,
                      `${name}.firstName`,
                      "Unknown"
                    )} ${getObjectValueFromStringPath(values, `${name}.lastName`, "Unknown")}`}
                  </Box>
                }
              >
                <MuiGrid spacing={4}>
                  <GridItem mobile size={3}>
                    <DrilldownCardTabSection noMargin title={"Contact Information"}>
                      <MuiGrid>
                        <GridItem mobile size={4}>
                          <FinalFormTextField label="First Name" name={`${name}.${fieldNames.firstName}`} />
                        </GridItem>
                        <GridItem mobile size={4}>
                          <FinalFormTextField
                            label="Middle Name"
                            placeholder="Middle"
                            name={`${name}.${fieldNames.middleName}`}
                          />
                        </GridItem>
                        <GridItem mobile size={4}>
                          <FinalFormTextField label="Last Name" name={`${name}.${fieldNames.lastName}`} />
                        </GridItem>
                        <GridItem mobile size={12}>
                          <FinalFormTextField
                            label="Email Address"
                            // validate={VALIDATOR_EMAIL}
                            name={`${name}.${fieldNames.emailAddress}`}
                          />
                        </GridItem>
                        <GridItem mobile size={12}>
                          <Field
                            name={`${name}.${fieldNames.phoneNumber}`}
                            parse={parsePhoneNumber}
                            label={`Phone Number`}
                            component={TextFieldComponent}
                          />
                        </GridItem>
                      </MuiGrid>
                    </DrilldownCardTabSection>
                  </GridItem>
                  <GridItem mobile size={3}>
                    <DrilldownCardTabSection noMargin title={"Background"}>
                      <MuiGrid>
                        <GridItem mobile size={12}>
                          <FinalFormSelectField
                            label="Citizenship Status"
                            name={`${name}.citizenshipStatus`}
                            items={citizenshipOptions}
                          />
                        </GridItem>

                        <GridItem mobile size={12}>
                          <FinalFormTextField dollar name={`${name}.cashLiquidity`} label="Cash Liquidity" />
                        </GridItem>
                        <GridItem mobile size={12}>
                          <FinalFormTextField
                            number
                            name={`${name}.${fieldNames.ficoMidScore}`}
                            label="FICO Mid Score"
                          />
                        </GridItem>
                      </MuiGrid>
                    </DrilldownCardTabSection>
                  </GridItem>
                  <GridItem mobile size={6}>
                    <DrilldownCardTabSection noMargin title={"Experience"}>
                      <MuiGrid>
                        <GridItem mobile size={values.hasExperienceWithIncomeProducingProperties === "Yes" ? 6 : 12}>
                          <FinalFormSelectField
                            noMargin
                            name={"hasExperienceWithIncomeProducingProperties"}
                            label="Experience w/ Income Producing Properties?"
                            items={yesNo}
                          />
                        </GridItem>
                        {values.hasExperienceWithIncomeProducingProperties === "Yes" && (
                          <>
                            <GridItem mobile size={6}>
                              <FinalFormTextField
                                name={"numOfIncomeProducingPropertiesWithinTwoYears"}
                                label="How many in the last two years?"
                                number
                              />
                            </GridItem>
                          </>
                        )}
                        <GridItem mobile size={values.hasExperienceWithFixFlips === "Yes" ? 6 : 12}>
                          <FinalFormSelectField
                            name={"hasExperienceWithFixFlips"}
                            label="Experience w/ Fix & Flips?"
                            items={yesNo}
                          />
                        </GridItem>
                        {values.hasExperienceWithFixFlips === "Yes" && (
                          <>
                            <GridItem mobile size={6}>
                              <FinalFormTextField
                                name={"numOfFixFlipsWithinTwoYears"}
                                label="How many in the last two years?"
                                number
                              />
                            </GridItem>
                          </>
                        )}
                        <GridItem mobile size={values.hasExperienceWithGroundUpConstruction === "Yes" ? 6 : 12}>
                          <FinalFormSelectField
                            name={"hasExperienceWithGroundUpConstruction"}
                            label="Experience w/ Ground Up Construction?"
                            items={yesNo}
                          />
                        </GridItem>

                        {values.hasExperienceWithGroundUpConstruction === "Yes" && (
                          <>
                            <GridItem mobile size={6}>
                              <FinalFormTextField
                                name={"numOfGroundUpConstructionProjectsWithinTwoYears"}
                                label="How many in the last two years?"
                                number
                              />
                            </GridItem>
                          </>
                        )}
                      </MuiGrid>
                    </DrilldownCardTabSection>
                    {/* <DrilldownCardTabSection noMargin title={"Experience"}>
                      <MuiGrid>
                        <GridItem mobile size={6}>
                          <FinalFormSelectField
                            label="Is Experienced?"
                            name={`${name}.${fieldNames.isExperienced}`}
                            items={yesNo}
                          />
                        </GridItem>
                        <GridItem mobile size={6}>
                          <FinalFormTextField
                            label="Total Years Experience"
                            number
                            name={`${name}.${fieldNames.totalYearsExperience}`}
                          />
                        </GridItem>
                        <GridItem mobile size={6}>
                          <FinalFormTextField
                            label="Properties Flipped within 12 months"
                            number
                            name={`${name}.flipsWithin12months`}
                          />
                        </GridItem>
                        <GridItem mobile size={6}>
                          <FinalFormTextField
                            label="Properties Flipped within 24 months"
                            number
                            name={`${name}.flipsWithin24months`}
                          />
                        </GridItem>
                        <GridItem mobile size={4}>
                          <FinalFormTextField
                            label="Properties Owned within 24 months"
                            number
                            name={`${name}.propertiesOwnedWithin24months`}
                          />
                        </GridItem>

                        <GridItem mobile size={4}>
                          <FinalFormTextField
                            label="Income Producing Properties"
                            number
                            name={`${name}.${fieldNames.incomeProducingProperties}`}
                          />
                        </GridItem>

                        <GridItem mobile size={4}>
                          <FinalFormTextField
                            label="Number of Ground Up Projects"
                            name={`${name}.${fieldNames.numberOfGroundUpProjects}`}
                          />
                        </GridItem>
                      </MuiGrid>
                    </DrilldownCardTabSection> */}
                  </GridItem>
                </MuiGrid>
              </DrilldownMultiItemCard>
            ))
          }
        </FieldArray>
      </Stack>
    </EditableDrilldownTabCard>
  );
};

export default ScenarioDrilldownTabBorrowers;
