import { DeleteOutlineRounded, HorizontalRuleRounded } from "@mui/icons-material";
import { IconButton, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { isNil } from "lodash";
import React, { useMemo } from "react";
import { createSelectItemsDocGroups } from "../../../../../../../../helpers/createSelectItems/createSelectItemsDocGroups";
import { createSelectItemsDocType } from "../../../../../../../../helpers/createSelectItems/createSelectItemsDocType";
import { getDocumentGroupName } from "../../../../../../../../helpers/getDocumentGroupName";
import { stringProtector } from "../../../../../../../../helpers/stringProtector";
import FileRowEditableInput from "../shared/LoanFilesTableInput";
import FilesTableCell from "../shared/LoanFilesTableCell";
import LoanFilesTableCell from "../shared/LoanFilesTableCell";

const LoanFileUploadTableRow = ({ onChange, onRemove, index, row }) => {
  const fileData = {
    file_display_name: isNil(row?.file_display_name) ? row.file.path : row?.file_display_name,
    docGroup: row?.docGroup,
    docType: row?.docType,
  };

  const handleFileDataChange = (key, value) => {
    let fileData_spread = { ...fileData };
    fileData_spread[key] = value;
    if (key === "docGroup") {
      fileData_spread["docType"] = undefined;
    }

    const params = {
      index: index,
      row: row,
      updateKey: key,
      updateValue: value,
    };
    onChange(params);
  };

  return (
    <LoanFileUploadTableRowWrapper>
      <FileRowFilenameCell value={fileData?.file_display_name} onChange={handleFileDataChange} />
      <FileRowActionsTableCell onClick={() => onRemove(row?.id)} fileData={fileData} />
    </LoanFileUploadTableRowWrapper>
  );
};

const LoanFileUploadTableRowWrapper = ({ children }) => {
  const filesRowStyles = {
    position: "relative",
    cursor: "pointer",
    "&:nth-child(even)": {
      background: "#e0e0e03d",
    },
    td: {
      borderBottom: "none",
    },
    ".MuiTableCell-root .MuiInput-root": {
      "&:before": {
        borderBottom: "none",
      },
    },
    "&:last-child td": {
      borderBottom: "none",
    },
    "&:hover": {
      ".edit-button": {
        visibility: "visible",
      },
    },
  };
  return (
    <TableRow sx={filesRowStyles} className="files-row">
      {children}
    </TableRow>
  );
};

//FILE NAME
const FileRowFilenameCell = ({ value, onChange }) => {
  const value_memo = useMemo(() => stringProtector(value), [value]);

  const handleChangeFileName = (e) => {
    let val = e.target.value;
    onChange("file_display_name", val);
  };

  return (
    <FilesTableCell
      sx={{
        maxWidth: "400px",
        padding: "0 !important",
        ".MuiFormControl-root": {
          border: "none",
        },
      }}
    >
      <FileRowEditableInput onChange={handleChangeFileName} value={value_memo} />
    </FilesTableCell>
  );
};

/** DELETE BUTTON
 * If editing show ->  Download || More
 * If not editing -> Save
 */
const FileRowActionsTableCell = ({ onClick }) => {
  const iconButtonStyles = {
    styles: {
      background: "#4986be1a",
      border: "1px solid #4986be29",
      padding: "4px",
      borderRadius: "0",
      width: "100%",
      height: "100%",
    },
    icon: {
      color: "var(--blue)",
      fontSize: "1.1rem",
    },
  };

  return (
    <>
      <LoanFilesTableCell>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
          }}
        >
          <IconButton onClick={onClick} sx={iconButtonStyles.styles}>
            <DeleteOutlineRounded sx={iconButtonStyles.icon} />
          </IconButton>
        </Box>
      </LoanFilesTableCell>
    </>
  );
};

export default LoanFileUploadTableRow;
