import React from "react";

import HeaderImage from "../../../../assets/images/auction-home.png";
import LandingPage from "../../../../components/LandingPage/LandingPage";
// import "./BorrowerCreditAuthWorkspace.css";

import { Box, Stack, Typography } from "@mui/material";
import FormWizard from "../../../../components/form/FormWizard/FormWizard";
import SuccessContent from "../../../../components/shared/SuccessContent";
import { useBorrowerHook } from "../../../../hooks/useBorrowerHook";
import AuctionBorrowerExperience from "./formSteps/AuctionBorrowerExperience";
import AuctionBorrowerUpcomingAuctions from "./formSteps/AuctionBorrowerUpcomingAuctions";
import AuctionConfirmation from "./formSteps/AuctionConfirmation";
import AuctionContactInfo from "./formSteps/AuctionContactInfo";
import AuctionLiquidity from "./formSteps/AuctionLiquidity";

const AuctionComScreen = (props) => {
  const [showError, setShowError] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const { postIsLoading, submitAuctionForm } = useBorrowerHook();

  const handleFormSubmit = async (formState) => {
    console.log("SUBMITTING CREATE LOAN DEAL", formState);

    const params = {
      payload: formState,
      name: "Auction form Deal",
      onSuccessFn: () => setIsSuccess(true),
      // onFailFn: () => setIsSuccess(true),
      onFailMsg: "Could not create auction form",
    };

    await submitAuctionForm(params);
  };
  const formSteps = [
    {
      stepName: "Background",
      stepComponent: <AuctionContactInfo />,
    },
    {
      stepName: "Upcoming Auctions",
      stepComponent: <AuctionBorrowerUpcomingAuctions />,
    },
    {
      stepName: "Experience",
      stepComponent: <AuctionBorrowerExperience />,
    },
    {
      stepName: "Liquidity",
      stepComponent: <AuctionLiquidity />,
    },

    {
      stepName: "Confirmation",
      stepComponent: <AuctionConfirmation />,
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "44vh",
          img: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
          },
        }}
        component={"section"}
        id="auction-landing-page"
      >
        <img src={HeaderImage} alt="" srcSet="" />
      </Box>
      <Box
        component={"section"}
        className="landing-page"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LandingPage
          card
          auction
          title={isSuccess ? "Your Submission Was Submitted!" : "Auction Investor Financing Preapproval"}
          description={
            isSuccess ? null : "Please fill out the following fields to get preapproved Auction property financing."
          }
          // description={
          //   isSuccess
          //     ? ` A member of our team will reach out you via phone. Pre-approvals within 24 hours*`
          //     : "Please fill out the following fields to get preapproved Auction property financing."
          // }
        >
          {isSuccess ? (
            <>
              <SuccessContent />
              <Stack>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#707070",
                    fontSize: "0.9rem",
                    lineHeight: " 24px",
                    marginTop: " 5px",
                  }}
                >
                  A member of our team will reach out you via phone.
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#707070",
                    fontSize: "0.9rem",
                    lineHeight: " 24px",
                    marginTop: " 5px",
                  }}
                >
                  Pre-approvals within 24 hours*
                </Typography>
              </Stack>
            </>
          ) : (
            <FormWizard
              formSteps={formSteps}
              id="auction-preapproval-form"
              initialValues={{
                allowPhoneNumberContact: "Yes",
              }}
              isLoading={postIsLoading}
              onSubmit={handleFormSubmit}
              readOnly={props.readOnly}
              clickable
              muiStepper
              alt
              formType={"flatironSiteCreditAuth"}
              submitButtonText={"Submit Preapproval Application and Credit Authorization"}
              // formSpy
            />
          )}
        </LandingPage>
      </Box>
    </Box>
  );
};

export default AuctionComScreen;
