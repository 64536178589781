import React from "react";
import AnimatedCheckmark from "./AnimatedCheckmark/AnimatedCheckMark";
import SvgWrapper from "./SvgComponents/SvgWrapper/SvgWrapper";
import { Box } from "@mui/material";
import { isNil } from "lodash";

const SuccessContent = ({ message, sx, top }) => {
  const successContentStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px",
    flexDirection: "column",
    textAlign: "center",
  };
  return (
    <Box sx={sx} style={successContentStyles} className="success-content">
      <div className="header-success-content">
        {!isNil(top) && message ? (
          <p style={{ marginBottom: "15px" }} className="success-message">
            {message}
          </p>
        ) : null}
        <SvgWrapper width={"72px"} center className="checkmark">
          <AnimatedCheckmark />
        </SvgWrapper>
        {isNil(top) && message ? (
          <p style={{ marginTop: "15px" }} className="success-message">
            {message}
          </p>
        ) : null}
      </div>
    </Box>
  );
};

export default SuccessContent;
