import { useDispatch, useSelector } from "react-redux";

import { useStytchUser } from "@stytch/react";
import { isArray, isNil, isObject, isString } from "lodash";
import { arrayProtector } from "../helpers/arrayProtector";
import { setFormData } from "../redux/actions/formDataActions";
import { useBorrowerHook } from "./useBorrowerHook";

export const useBrokerDrilldownHook = () => {
  const { user } = useStytchUser();
  const userEmail = user?.trusted_metadata?.emailAddress;
  const { getIsLoading, postIsLoading, getUserScenarios } = useBorrowerHook();
  // const isInternalEmployee = userEmail.includes("@ironfund.com") || userEmail.includes("@gmail.com");

  const checkIfUserTypeIsInternal = () => {
    if (isNil(userEmail) || !isString(userEmail)) {
      return false;
    }
    return userEmail.includes("@ironfund.com") || userEmail.includes("@flatironrealtycapital.com");
  };
  const isInternalEmployee = checkIfUserTypeIsInternal();

  const loanScenarios = useSelector((state) => state.loanScenarios);
  const dispatch = useDispatch();
  const loanScenariosAreEmpty = arrayProtector(loanScenarios).length === 0 ? true : false;

  /** Fetches scenarios > sets loanScenarios redux > (optional) has   */
  const handleGetAndSetBrokerScenarios = (id, callback) => {
    if (loanScenariosAreEmpty) {
      console.log("GGOO");
      getUserScenarios({ onSuccessFn: (scenarios) => handleFilterScenario(id, callback, scenarios) });
    }
  };

  /**  takes passed id > filters loanScenarios > sets formData  */
  const handleFilterScenario = (id, callback, scenarios) => {
    console.log("onsuccess", id, callback);
    const scenariosList = isNil(scenarios) ? loanScenarios : scenarios;
    const filteredScenario = scenariosList?.filter((sc) => sc?._id === id);
    console.log("filteredScenario", filteredScenario);
    if (filteredScenario.length === 0) {
      if (!isNil(callback)) {
        callback();
      }
      return;
    }
    if (isArray(filteredScenario) && filteredScenario.length >= 1) {
      dispatch(setFormData(filteredScenario[0]));
    }
    return;
  };

  /**
   * If scenarios are empty: fetch all scenarios and set to redux > pass FN to filter and set scenario drilldown
   * If scenarios are populated:  filter and set scenario drilldown
   */
  const handleSetScenarioDrilldownData = (id, callback) => {
    if (loanScenariosAreEmpty) {
      console.log("options 1");

      getUserScenarios({});
      console.log("options 1");
    } else {
      handleFilterScenario(id, callback);
    }

    return;
  };

  const moveScenarioToUnderwriting = ({ id, onSuccessFn }) => {};

  return {
    isInternalEmployee,
    getIsLoading,
    postIsLoading,
    handleFilterScenario,
    handleGetAndSetBrokerScenarios,
    handleSetScenarioDrilldownData,
    moveScenarioToUnderwriting,
  };
};
