import React, { useState } from "react";

import EmailOutlined from "@mui/icons-material/EmailOutlined";
import { Field, useFormState } from "react-final-form";

import { PhoneOutlined } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { Stack } from "@mui/system";
import Form from "../components/form/Form/Form";
import { TextFieldComponent } from "../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import FormGroup from "../components/form/components/shared/FormGroup/FormGroup";
import FormSubmitButton from "../components/form/components/shared/FormSubmitButton/FormSubmitButton";
import ErrorMessage from "../components/shared/ErrorMessage";
import MuiGrid from "../components/shared/MuiGrid";
import GridItem from "../components/shared/MuiGrid/GridItem";
import { parsePhoneNumber } from "../helpers/parse/parsePhoneNumber";
import { VALIDATOR_EMAIL, VALIDATOR_REQUIRE } from "../helpers/validators/inputValidators";
import { useAxiosHook } from "../hooks/useAxiosHook";
import { isEmpty } from "lodash";
import { returnQueryStringParametersInObject } from "../helpers/returnQueryStringParametersInObject";
import { formStateWithQueryParams } from "../helpers/formStateWithQueryParams";
import FinalFormSelectField from "../components/form/Fields/FinalFormSelectField";
import { usaStateInitials } from "./_formQuestions/selectOptions";
import { MultiSelectFieldComponent } from "../components/form/components/inputs/finalFormComponents/MultiSelectFieldComponent";
import FinalFormTextField from "../components/form/Fields/FinalFormTextField";
import { getObjectValueFromStringPath } from "../helpers/getObjectValueFromStringPath";

const BrokerSignUpForm = ({ onSuccess }) => {
  const [error, setError] = useState(null);
  const [errorSuggestions, setErrorSuggestios] = useState([]);
  const { axiosPostIsLoading, axiosPostRequest } = useAxiosHook();

  const signUp = async (state) => {
    const params = {
      name: "Create Borrower",
      path: "create_external_user",
      payload: formStateWithQueryParams(state),
      onSuccessFn: () => onSuccess(state.emailAddress),
    };

    await axiosPostRequest(params);
  };

  return (
    <Form
      id="create"
      hideButton
      customFormTypes={{ userCategory: "broker" }}
      // customFormTypes={{ userCategory: "borrower" }}
      submitButtonText={"props.submitButtonText"}
      onClick={signUp}
      // formSpy
      // onClick={handleCreateUser}
    >
      <FormGroup noMargin>
        <MuiGrid spacing={1}>
          <GridItem size={6}>
            <Field
              validate={VALIDATOR_REQUIRE}
              name={"firstName"}
              label={`First Name`}
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={6}>
            <Field validate={VALIDATOR_REQUIRE} name={"lastName"} label={`Last Name`} component={TextFieldComponent} />
          </GridItem>
          <GridItem size={12}>
            <Field
              validate={VALIDATOR_EMAIL}
              name={"emailAddress"}
              label={`Email Address`}
              icon={<EmailOutlined />}
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={12}>
            <Field
              validate={VALIDATOR_REQUIRE}
              name={"phoneNumber"}
              icon={<PhoneOutlined />}
              parse={parsePhoneNumber}
              label={`Phone Number`}
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={6}>
            <Field
              validate={VALIDATOR_REQUIRE}
              name={"company"}
              label={`Company Name`}
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={6}>
            <Field
              validate={VALIDATOR_REQUIRE}
              name={"companyRole"}
              label={`Company Role`}
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={12}>
            <Field
              // validate={VALIDATOR_REQUIRE}
              name={"companyUrl"}
              label={`Company Website URL`}
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={12}>
            <Field
              name={"activeStates"}
              verticalOptions
              multiple
              label="What states are you currently active in?"
              items={usaStateInitials}
              component={MultiSelectFieldComponent}
            />
          </GridItem>

          <ReferralSourceQuestion label={"Broker name"} name={"referralSourceBrokerName"} />
        </MuiGrid>
      </FormGroup>
      <FormSubmitButton isLoading={axiosPostIsLoading} buttonText={"Create Account"} />
      <ErrorMessage show={error} message={error} />
      {errorSuggestions.length >= 1 ? <ErrorSuggestions errors={errorSuggestions} /> : null}
    </Form>
  );
};

const ReferralSourceQuestion = ({ label, name, condition }) => {
  const { values } = useFormState();
  const isBrokerReferral = values?.referralSource === "Broker referral";
  const isOther = values?.referralSource === "Other";

  return (
    <>
      <GridItem size={isBrokerReferral || isOther ? 6 : 12}>
        <FinalFormSelectField
          required
          label="How did you hear about us?"
          name={`referralSource`}
          items={["Advertising", "Broker referral", "Direct outreach", "Through my company", "Other"]}
        />
      </GridItem>
      {isBrokerReferral ? (
        <GridItem size={6}>
          <FinalFormTextField label={"Broker name"} required name={"referralSourceBrokerName"} />
        </GridItem>
      ) : null}

      {isOther ? (
        <GridItem size={6}>
          <FinalFormTextField label={"Enter source name"} required name={"referralSourceOtherName"} />
        </GridItem>
      ) : null}
    </>
  );
};

const ErrorSuggestions = (props) => {
  return (
    <>
      <ErrorMessage
        bold
        style={{ marginTop: "15px" }}
        show={true}
        message={"Password not strong enough, here's what we suggest."}
      />
      <Stack direction={"column"} color="error">
        {props.errors.map((err) => (
          // <ErrorMessage style={{ background: "red" }} show={true} message={err} />
          <Chip
            sx={{
              color: "var(--red)",
              // display: 'flex',
              width: "min-content",
              margin: "5px auto 0",
              background: "#d32f2f24",
            }}
            label={err}
          />
        ))}
      </Stack>
    </>
  );
};

export default BrokerSignUpForm;
