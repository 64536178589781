import { Box } from "@mui/material";
import { Editor, EditorState, RichUtils, convertFromHTML, convertFromRaw, convertToRaw } from "draft-js";
import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { DraftJsStyleMap } from "../../shared/DraftJsTextEditor/DraftJsStyleMap";
import { useSelector } from "react-redux";
import { isEmpty, isNil, isString } from "lodash";
import draftToHtml from "draftjs-to-html";
/**
 * Example:
 *  <FinalFormDraftJsField
        label="Requested Loan amount"
        name={`${fieldNames.requestedLoanAmount}`}
        dollar
    />
 */

const FinalFormDraftJsField = ({
  name,
  html,

  disabled,
}) => {
  return <Field name={name} component={TextEditor} readOnly={disabled} html={html} />;
};

const TextEditor = ({ input, html, meta, readOnly }) => {
  const formData = useSelector((state) => state.formData);
  const val = input.value;

  const initState = EditorState.createEmpty();
  const [editorState, setEditorState] = useState(initState);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const rawMarkup = convertToRaw(editorState.getCurrentContent());
    const htmlMarkup = draftToHtml(rawMarkup);
    // console.log("rawMarkup", rawMarkup);
    // console.log("markup", markup);
    return input.onChange(html ? htmlMarkup : rawMarkup);
    // return input.onChange(convertedToRaw);
  };
  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(val);
      return "handled";
    }
  };

  useEffect(() => {
    console.log("init");
    if (isNil(val) || isEmpty(val) || isString(val)) return;
    console.log("PASS");
    const state = EditorState.createWithContent(convertFromRaw(val));
    setEditorState(state);
  }, []);
  // useEffect(() => {
  //   if (isNil(val) || isEmpty(val)) return;
  //   const state = EditorState.createWithContent(convertFromRaw(val));
  //   setEditorState(state);
  // }, [formData]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        border: "1px solid #cccccc",
        borderRadius: "4px",
        maxHeight: "300px",
        minHeight: "100px",
        overflowY: "auto",
        opacity: readOnly ? 0.75 : 1,
      }}
      className="draft-js-root-container"
    >
      <Editor
        readOnly={readOnly}
        customStyleMap={DraftJsStyleMap}
        editorState={editorState}
        handleKeyCommand={handleKeyCommand}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onChange={onEditorStateChange}
      />
    </Box>
  );
};

export default React.memo(FinalFormDraftJsField);
