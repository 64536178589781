import { isNil } from "lodash";
import { formatAsDollarString } from "./formatAsDollarString";

export const getFinalFormTotalLoanAmount = (values, makeDollarString) => {
  const includesRehab =
    values?.loanProductType === "Fix and Flip" || values?.loanProductType === "Ground Up Construction" ? true : false;

  const initialAmt = isNil(values?.initialLoanAmount) ? 0 : values?.initialLoanAmount;
  const rehabAmt = isNil(values?.constructionRenovationBudget) ? 0 : values?.constructionRenovationBudget;
  let totalLoanAmount = initialAmt + rehabAmt;
  const returnValue = includesRehab ? totalLoanAmount : initialAmt;

  return makeDollarString ? formatAsDollarString(returnValue) : returnValue;
};
