import { set } from "lodash";

const profileReducer = (state = null, action) => {
  switch (action.type) {
    case "SET_PROFILE_STATE":
      return action.payload;

    case "UPDATE_PROFILE_STATE":
      let newState = { ...state };
      let updatedState = set(newState, action.payload.key, action.payload.value);
      return updatedState;

    case "CLEAR_PROFILE_STATE":
      return null;

    default:
      return state;
  }
};

export default profileReducer;
