import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import Authenticate from "../../Authenticate";
import UserView from "../../components/layout/UserView";
import BackgroundShape from "../../components/shared/BackgroundShape";
import AuctionComScreen from "./screens/AuctionComScreen";
import BorrowerCreditAuthScreen from "./screens/BorrowerCreditAuthScreen";
import BorrowerLoanApplicationScreen from "./screens/BorrowerLoanApplicationScreen";
import BorrowerLoginScreen from "./screens/BorrowerLoginScreen/BorrowerLoginScreen";
import BrokerLoginScreen from "./screens/BrokerLoginScreen/BrokerLoginScreen";
import BrokerSignUpScreen from "./screens/BrokerSignUpScreen/BrokerSignUpScreen";
import UnsubscribeScreen from "./screens/UnsubscribeScreen/UnsubscribeScreen";
import CalendarInviteScreen from "./screens/CalendarInviteScreen/CalendarInviteScreen";

const UnauthenticatedView = (props) => {
  return (
    <UserView id="borrower" sx={{ overflowY: "auto" }} component={"main"}>
      <Routes>
        <Route path={"/"} element={<BorrowerLoginScreen />} />
        <Route path={"/login"} element={<BrokerLoginScreen />} />
        <Route path={"/sign-up"} element={<BrokerSignUpScreen />} />
        <Route path={"/unsubscribe"} element={<UnsubscribeScreen />} />

        <Route path="/auction-preapproval" element={<AuctionComScreen />} />
        <Route path="/loan-application" element={<BorrowerLoanApplicationScreen />} />
        <Route path="/credit-authorization" element={<BorrowerCreditAuthScreen />} />

        <Route path="/authenticate" element={<Authenticate />} />
        <Route path="/meeting/*" element={<CalendarInviteScreen />} />
        <Route path="*" element={<Navigate to={`/`} replace />} />
      </Routes>
      <BackgroundShape />
    </UserView>
  );
};

export default UnauthenticatedView;
