import { SearchRounded } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";

export const styles = {
  card: {
    overflow: "auto",
    boxShadow: "none",
    flexDirection: "column",
    padding: "0 !important",
    flexGrow: 1,
    width: "100%",
  },
  cardHeaderContent: {
    minHeight: "42px",
    padding: "6px 10px",
    background: "#18406308",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  table: {
    tableHeadCell: {
      transition: "100ms",
      cursor: "pointer",
      "&:hover": {
        background: "#f9f9f9",
      },
    },
    tableHeadLinkCell: {
      position: "relative",
      fontWeight: "600",
      fontSize: ".7rem",
      color: "rgba(0, 0, 0, 0.87)",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      flexGrow: 0,
      ".MuiSvgIcon-root": {
        fontSize: ".9rem !important",
      },
    },
    rowOnClick: { transform: "rotate(-90deg)", color: "#242526" },
  },
  pagination: {
    root: {
      ".MuiPaginationItem-root": {
        fontSize: ".7rem",
        height: "24px",
        minWidth: "24px",
      },
    },
    countSelect: {
      ".MuiTypography-root": {
        fontSize: ".7rem",
        color: "#747171",
        whiteSpace: "nowrap",
      },
      ".MuiSelect-select": {
        fontSize: ".7rem",
        padding: "0 3px",
      },
    },
    textFieldInputProps: {
      sx: {
        padding: "6px 6px",
        "&:before": {
          borderBottom: "transparent",
        },
      },
      startAdornment: (
        <InputAdornment position="start">
          <SearchRounded />
        </InputAdornment>
      ),
    },
    clearSearchButton: { textDecoration: "underline", fontWeight: 500, color: "var(--gray)", fontSize: ".7rem" },
  },
};
