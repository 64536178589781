import React from "react";
import MuiDialogModal from "../../components/shared/MuiDialogModal";
import SnackbarAlert from "../../components/shared/SnackbarAlert/SnackbarAlert";
import BrokerCreateScenarioFormWizard from "./BrokerCreateScenarioFormWizard";
import { useDispatch } from "react-redux";
import { addScenario } from "../../redux/actions/loanScenariosActions";

const BrokerCreateScenarioFormModal = (props) => {
  const [isSuccess, setIsSuccess] = React.useState(null);
  const [formInitValues, setFormInitValues] = React.useState({
    scenarioStatus: "New",
  });
  const dispatch = useDispatch();
  //If deal is succesful
  const onComplete = async (passedData) => {
    console.log("Deal created successfully");
    setIsSuccess(true);
    // props.fetchAllDeals();
    setFormInitValues({});
    dispatch(addScenario(passedData));
  };

  //If deal fails
  const handleOnFail = async (state) => {
    console.log("Fail", state);
    setFormInitValues(state);
    setIsSuccess(false);
  };

  //When modal closed
  const handleOnCancel = () => {
    console.log("Closing Deal Modal", isSuccess);
    if (isSuccess === true) {
      console.log("Fetch");
      // props.fetchAllDeals();
      setIsSuccess(null);
    }
    setFormInitValues({});
    props.onCancel();
  };

  return (
    <MuiDialogModal
      id="create-loan-form-modal"
      title={"Create Loan Scenario"}
      successMessage={"Loan Scenario Successfully Created!"}
      show={props.show}
      centerTitle
      modal
      maxWidth={"lg"}
      isSuccess={isSuccess}
      // paperWidth={"60%"}
      onClose={handleOnCancel}
    >
      <BrokerCreateScenarioFormWizard
        onComplete={onComplete}
        onFail={handleOnFail}
        // initialValues={props.initialValues ? props.initialValues : formInitValues}
      />
      <SnackbarAlert
        open={isSuccess === false}
        onClose={() => setIsSuccess(null)}
        message={"Could not create deal"}
        sx={{ bottom: "15px !important" }}
        anchorPosition="center"
        type={"error"}
        // anchorPosition="right"
        // absolute
      />
    </MuiDialogModal>
  );
  // return (
  //   <Modal
  //     id="create-loan-form-modal"
  //     noBackdropClick
  //     onCancel={handleOnCancel}
  //     show={props.show}
  //     headerContent={
  //       <h2>
  //         {isSuccess === true
  //           ? "Loan Created Successfully!"
  //           : "Create New Loan"}
  //       </h2>
  //     }
  //   >
  //     <div className="inner-wrapper">
  //       <section id="main-content-area">
  //         <div className="modal__body">
  //           {isSuccess === true ? (
  //             successContent
  //           ) : (
  //             <CreateLoanFormWizard
  //               onComplete={onComplete}
  //               onFail={handleOnFail}
  //               initialValues={formInitValues}
  //             />
  //           )}
  //         </div>
  //       </section>
  //     </div>
  //     <SnackbarAlert
  //       open={isSuccess === false}
  //       onClose={() => setIsSuccess(null)}
  //       message={"Could not create deal"}
  //       sx={{ bottom: "15px !important" }}
  //       anchorPosition="center"
  //       type={"error"}
  //       // anchorPosition="right"
  //       // absolute
  //     />
  //   </Modal>
  // );
};

export default BrokerCreateScenarioFormModal;
