import React from "react";
import { useState } from "react";
import { convertStringToTime } from "../helpers/convertStringToTime";
import { convertStringToDate } from "../helpers/convertStringToDate";
import { isArray, isNil, isString } from "lodash";
import { Popover, Typography } from "@mui/material";
import { arrayProtector } from "../helpers/arrayProtector";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import FlexBox from "../components/shared/FlexBox";

const IronFundUserLastSignInPopover = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const convertSignIn = (signInTime) => {
    return `${convertStringToDate(signInTime)} at ${convertStringToTime(signInTime)}`;
  };

  const open = Boolean(anchorEl);
  if (isNil(user?.lastSignIn)) {
    return "-";
  }

  if (isString(user?.lastSignIn)) {
    return convertSignIn(user?.lastSignIn);
  }

  if (isArray(user?.lastSignIn)) {
    return (
      <div>
        <FlexBox alignCenter>
          <Typography
            sx={{ fontSize: ".7rem" }}
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            {convertSignIn(user?.lastSignIn[0])}
          </Typography>
          {open ? (
            <KeyboardArrowUp sx={{ fontSize: ".9rem !important" }} />
          ) : (
            <KeyboardArrowDown sx={{ fontSize: ".9rem !important" }} />
          )}
        </FlexBox>
        <Popover
          id="mouse-over-popover"
          sx={{
            fontSize: ".7rem",
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <FlexBox column>
            <Typography
              sx={{
                padding: "8px",
                fontWeight: 600,
                fontSize: ".7rem",
              }}
            >
              Previous Sign Ins
            </Typography>
            {user?.lastSignIn.map((signIn, index) => (
              <Typography
                key={signIn}
                sx={{
                  padding: "8px",
                  fontSize: ".7rem",
                  borderBottom: arrayProtector(user?.lastSignIn).length === index + 1 ? "none" : "var(--border)",
                }}
              >
                {convertSignIn(signIn)}
              </Typography>
            ))}
          </FlexBox>
        </Popover>
      </div>
    );
  }
};

export default IronFundUserLastSignInPopover;
