import { isNil } from "lodash";
import { useCommentsHook } from "../../../../../../../hooks/useCommentsHook";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../../../../../../redux/actions/formDataActions";
import { Box, Card, Divider, IconButton, Typography } from "@mui/material";
import { CloseRounded, ReplyRounded } from "@mui/icons-material";
import DraftJsTextEditor from "../../../../../../../components/shared/DraftJsTextEditor";
import { useBorrowerHook } from "../../../../../../../hooks/useBorrowerHook";

const DealCommentCardItem = ({ name, body, date, onReplyClick, onCancelClick, parentId, isLast, isEditing }) => {
  // console.log("parentId", parentId);
  const isReply = isNil(parentId) ? false : true;
  const formData = useSelector((state) => state.formData);
  const { commentsPostIsLoading, postDealComment } = useCommentsHook();
  const { postIsLoading, postLoanScenarioComment } = useBorrowerHook();
  const dispatch = useDispatch();

  const handleOnReplySubmit = async (data) => {
    console.log("submitting", data);
    const handleOnSuccess = (returnedCommentList) => {
      const newCommentList = returnedCommentList?.dealComments;

      dispatch(updateFormData({ key: "dealComments", value: newCommentList }));
      // setInitData({});
    };
    const params = {
      id: formData?._id,
      // payload: data,
      // payload: { data },
      payload: { data: data, replyTo: parentId },
      onSuccessFn: handleOnSuccess,
      // onFailFn: () => setInitData(data),
    };
    // handleOnSuccess({ ...DUMMY_COMMENT_SUCCESS, data });
    console.log(";params", params);
    postLoanScenarioComment(params);
  };

  return (
    <Box className="deal-comment-card-item" position={"relative"}>
      <CardItemArrow isReply={isReply} isLast={isLast} />
      <Card
        sx={{
          position: "relative",
          zIndex: 11111,
          margin: "0px 5px 0px 5px",
          marginBottom: 0,
          marginLeft: isReply ? "9px" : "5px",
          boxShadow: "none",
          border: isReply && isEditing ? "1px solid #1c456a45" : "var(--border)",
        }}
      >
        <Box sx={{ p: "4px 8px 4px", display: "flex", alignItems: "center" }}>
          <CardItemHeaderName isReply={isReply} name={name} isEditing={isEditing} date={date} />
          <CardItemIconButton
            isReply={isReply}
            isEditing={isEditing}
            onCancelClick={onCancelClick}
            onReplyClick={onReplyClick}
          />
        </Box>
        {/* <Divider /> */}
        <Box
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexGrow={1}
          width="100%"
          padding="0 5px 5px 0"
        >
          <DraftJsTextEditor
            data={body}
            readOnly={isReply && isEditing ? false : true}
            hideOptions
            buttonText={"Post Reply"}
            isLoading={commentsPostIsLoading}
            onSubmit={handleOnReplySubmit}
          />
        </Box>
      </Card>
    </Box>
  );
};

const CardItemIconButton = ({ isReply, onCancelClick, isEditing, onReplyClick }) => {
  const header__button = (
    <IconButton
      disabled={!isReply && isEditing}
      onClick={isEditing ? onCancelClick : onReplyClick}
      sx={{ padding: "2px" }}
    >
      {isReply && isEditing ? <CloseRounded sx={{ fontSize: 18 }} /> : <ReplyRounded sx={{ fontSize: 18 }} />}
    </IconButton>
  );

  return <Box sx={{ display: "flex", alignItems: "center" }}>{isReply && !isEditing ? null : header__button}</Box>;
};

const CardItemHeaderName = ({ isReply, name, isEditing, date }) => {
  const headerName = (
    <Typography fontSize={".7rem"} fontWeight={600}>
      {name}
    </Typography>
  );
  const headerName__reply = (
    <Box sx={{ display: "flex", gap: "4px" }}>
      <Typography fontStyle={"italic"} fontSize={".7rem"} fontWeight={500}>
        Replying to
      </Typography>
      {headerName}
    </Box>
  );

  return (
    <Box
      className="deal-comment-card-item__header"
      sx={{
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        gap: "6px",
      }}
      spacing={0.5}
    >
      {isReply && isEditing ? headerName__reply : headerName}
      <Typography fontSize={".7rem"} variant="body2" color="text.secondary">
        {date}
      </Typography>
    </Box>
  );
};

const CardItemArrow = ({ isReply, isLast }) => {
  const isLastStyles = {
    position: "absolute",
    borderTop: "2px solid var(--blue)",
    top: "11px",
    width: "14px",
    height: "100%",
    left: "0px",
    // left: "-9px",
  };

  const arrowStyles = {
    isLast: {
      ...isLastStyles,
      // // top: "0px",
      // left: "-9px",
      // // width: "14px",
      // height: "100%",
      background: "#ffffff",
      // background: "#fafafa",
      // background: "#f1f1f1",
    },
    isNotLast: {
      ...isLastStyles,

      // top: "14px",
      // width: "8px",
      // height: "90%",
    },
  };
  return isReply ? (
    <Box
      className="deal-comment-card-item__arrow--horizontal"
      sx={isLast ? arrowStyles.isLast : arrowStyles.isNotLast}
    />
  ) : null;
};

export default DealCommentCardItem;
