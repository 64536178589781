import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import { useMemo } from "react";
import { arrayProtector } from "../../helpers/arrayProtector";
import { isArray } from "lodash";

const MuiSelectField = ({ value, items, onChange, variant, size, error, label, placeholder, disabled, sx }) => {
  let itemsList = useMemo(() => arrayProtector(items), [items]);
  const inputVariant = variant ? variant : "filled";
  const inputSize = size ? size : "small";
  return (
    <FormControl variant={inputVariant} size={inputSize} fullWidth>
      <InputLabel variant={inputVariant} size={inputSize}>
        {label}
      </InputLabel>
      <Select
        error={error}
        size={inputSize}
        label={label}
        fullWidth
        sx={variant === "standard" ? { marginTop: "0 !important", ...sx } : { ...sx }}
        // multiple={multiple ? true : false}
        disabled={disabled}
        variant={inputVariant}
        value={value}
        onChange={onChange}
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {!isArray(items)
          ? null
          : itemsList.map((i) => {
              return typeof i === "string" ? (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ) : (
                <MenuItem key={i.name} value={i.name}>
                  {i.label}
                </MenuItem>
              );
            })}
      </Select>
    </FormControl>
  );
};

export default MuiSelectField;
