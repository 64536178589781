import { Typography } from "@mui/material";
import React from "react";

const ScreenSubTitle = ({ children, sx, variant, h3, color }) => {
  const colorType = color ? color : "var(--primary)";
  const variantType = h3 ? "h3" : "h2";
  const styles = {
    // fontWeight: 500,
    color: colorType,
    // fontSize: ".95rem",
  };
  return (
    <Typography variant={variant ? variant : variantType} sx={{ ...styles, ...sx }}>
      {children}
    </Typography>
  );
};

export default ScreenSubTitle;
