import React, { useEffect } from "react";

import FileUploadField from "../../../components/form/components/inputs/generic/FileUploadField/FileUploadField";

import { useForm, useFormState } from "react-final-form";
import FormGroup from "../../../components/form/components/shared/FormGroup/FormGroup";

import { isNil } from "lodash";
import MuiGrid from "../../../components/shared/MuiGrid";
import GridItem from "../../../components/shared/MuiGrid/GridItem";
import { fileDocumentSchema } from "../../_formQuestions/_formSchema/fileDocumentSchema";
// import Form from "../../../../UserAdmission/components/SignInForm/SignInForm";
// import "./CreditAuthFormStepOne.css";

const CreateScenarioStepFiles = ({ display }) => {
  const { values } = useFormState();
  const { mutators } = useForm();

  // const additionalName = `${props.name}Additional`;

  const addAdditionalFieldHandler = (name) => {
    mutators.push(name, {});
  };

  useEffect(() => {
    if (isNil(values.subjectProperties)) {
      addAdditionalFieldHandler("subjectProperties");
    }
  }, []);

  return (
    <FormGroup bold fieldLabel={"Documents & Files"}>
      <MuiGrid spacing={3}>
        <GridItem mobile size={6}>
          <FileUploadField
            noMargin
            name={fileDocumentSchema.appraisal.name}
            uploadTitle={"Appraisal"}
            docGroup={fileDocumentSchema.appraisal.name}
            // docType={fileDocumentSchema.entityDocs.types.ein.name}
            // maxFiles={1}
          />
        </GridItem>
        <GridItem mobile size={6}>
          <FileUploadField
            noMargin
            name={fileDocumentSchema.borrowerDocs.name}
            uploadTitle={"Borrower Documents"}
            docGroup={fileDocumentSchema.borrowerDocs.name}
            // docType={fileDocumentSchema.entityDocs.types.articlesOfOrganization.name}
            // maxFiles={1}
          />
        </GridItem>
        <GridItem mobile size={6}>
          <FileUploadField
            noMargin
            name={fileDocumentSchema.constructionDocuments.name}
            uploadTitle="Construction Documents"
            docGroup={fileDocumentSchema.constructionDocuments.name}
            // docType={fileDocumentSchema.entityDocs.types.cogs.name} // ??? Is this correct?
            // maxFiles={1}
          />
        </GridItem>

        <GridItem mobile size={6}>
          <FileUploadField
            noMargin
            name={fileDocumentSchema.entityDocs.name}
            uploadTitle="Entity Documents"
            docGroup={fileDocumentSchema.entityDocs.name}
            // docType={fileDocumentSchema.entityDocs.types.foreignEntityDocs.name}
            // maxFiles={1}
          />
        </GridItem>
        <GridItem mobile size={6}>
          <FileUploadField
            noMargin
            name={fileDocumentSchema.liquidity.name}
            uploadTitle="Liquidity"
            docGroup={fileDocumentSchema.liquidity.name}
            // docType={fileDocumentSchema.entityDocs.types.foreignEntityDocs.name}
            // maxFiles={1}
          />
        </GridItem>
        <GridItem mobile size={6}>
          <FileUploadField
            noMargin
            name={fileDocumentSchema.propertyDocuments.name}
            uploadTitle="Property Documents"
            docGroup={fileDocumentSchema.propertyDocuments.name}
            // docType={fileDocumentSchema.entityDocs.types.foreignEntityDocs.name}
            // maxFiles={1}
          />
        </GridItem>
        <GridItem mobile size={12}>
          <FileUploadField
            noMargin
            name={fileDocumentSchema.noCategory.name}
            uploadTitle="No Category"
            docGroup={fileDocumentSchema.noCategory.name}
            // docType={fileDocumentSchema.entityDocs.types.foreignEntityDocs.name}
            // maxFiles={1}
          />
        </GridItem>
      </MuiGrid>
    </FormGroup>
  );
};

export default CreateScenarioStepFiles;
