import { useStytch } from "@stytch/react";
import React, { useState } from "react";

import EmailOutlined from "@mui/icons-material/EmailOutlined";
import { Field, useForm, useFormState } from "react-final-form";

import { isNil } from "lodash";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { DeleteOutlineRounded } from "@mui/icons-material";
import Form from "../../../../components/form/Form/Form";
import FormGroup from "../../../../components/form/components/shared/FormGroup/FormGroup";
import SuccessContent from "../../../../components/shared/SuccessContent";
import ErrorMessage from "../../../../components/shared/ErrorMessage";
import FormSubmitButton from "../../../../components/form/components/shared/FormSubmitButton/FormSubmitButton";
import { TextFieldComponent } from "../../../../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import { VALIDATOR_EMAIL } from "../../../../helpers/validators/inputValidators";

const BrokerLoginForm = ({ onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null);

  const stytch = useStytch();
  const loginEmailCookie = Cookies.get("loginEmail");
  //Sends email to user who submitted form
  const handleSendMagicLink = async (state) => {
    let email = state.emailAddress.toLowerCase();

    if (isNil(loginEmailCookie)) {
      Cookies.set("loginEmail", email);
    }

    const emailPromise = stytch.magicLinks.email.send(email, {
      login_magic_link_url: `${window.location.origin}/authenticate`,
      // signup_magic_link_url: `${window.location.origin}/borrower-authenticate`,
    });
    setIsLoading(true);

    try {
      await emailPromise;
      setIsSuccess(true);
      setIsLoading(false);
      setError(false);
      onSuccess(state.emailAddress);
    } catch (e) {
      console.log("🚀 handleSendMagicLink ", e.status_code);
      if (e.status_code === 404) {
        setError("Email address not found. Please make an account first.");
      }

      if (e.status_code !== 404) {
        setError(e.error_message);
      }

      // setError(e);
      setIsLoading(false);
      // setError(true);
    }
  };

  const sentMagicLinkContent = <SuccessContent />;
  const initialValues = isNil(loginEmailCookie) ? {} : { emailAddress: loginEmailCookie };
  const magicLinkForm = (
    <Form id="login" hideButton onClick={handleSendMagicLink} initialValues={initialValues}>
      <FormGroup noMargin>
        <FormQuestions loginEmailCookie={loginEmailCookie} isLoading={isLoading} />
      </FormGroup>

      <ErrorMessage show={error} message={error} />
    </Form>
  );

  return isSuccess ? sentMagicLinkContent : magicLinkForm;
};

const FormQuestions = ({ isLoading }) => {
  const [show, setShow] = useState(false);

  const { values } = useFormState();
  const { change } = useForm();

  const clearCookie = () => {
    Cookies.remove("loginEmail");
    change("emailAddress", undefined);
    setShow(false);
  };

  useEffect(() => {
    if (!isNil(Cookies.get("loginEmail")) && !isNil(values?.emailAddress)) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);
  return (
    <FormGroup noMargin>
      <Box position={"relative"}>
        <Field
          validate={VALIDATOR_EMAIL}
          name={"emailAddress"}
          label={`Email Address`}
          component={TextFieldComponent}
        />
        {show ? (
          <Tooltip
            sx={{
              position: "absolute",
              top: "6px",
              right: "10px",
              zIndex: 1111,
            }}
            title="Remove saved email"
          >
            <IconButton endIcon={<DeleteOutlineRounded />} onClick={clearCookie}>
              <DeleteOutlineRounded />
            </IconButton>
          </Tooltip>
        ) : null}
      </Box>
      <FormSubmitButton isLoading={isLoading} buttonText="Send Magic Login Link" />
    </FormGroup>
  );
};

export default BrokerLoginForm;
