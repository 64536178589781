import React, { useState } from "react";

import { Box, Button, IconButton, Stack, Tooltip } from "@mui/material";
import { isArray, isNil, toLower } from "lodash";

import {
  ChevronRight,
  DownloadRounded,
  HouseRounded,
  InsertDriveFileOutlined,
  PersonRounded,
} from "@mui/icons-material";
import { useMemo } from "react";
import ScreenSubTitle from "../../../../../../components/layout/Screen/ScreenSubTitle";
import FlexBox from "../../../../../../components/shared/FlexBox";
import MuiGrid from "../../../../../../components/shared/MuiGrid";
import GridItem from "../../../../../../components/shared/MuiGrid/GridItem";
import MuiReactTable from "../../../../../../components/shared/MuiReactTable";
import TabPanel from "../../../../../../components/shared/TabSwitcher/TabPanel";
import { downloadObjectAsExcel } from "../../../../../../helpers/downloadObjectAsExcel";
import { getTodaysDate } from "../../../../../../helpers/getTodaysDate";
import { removeDuplicates } from "../../../../../../helpers/removeDuplicates";
import UserDetailsDrawer from "../../UserDetailsDrawer";

const UserAnalyticsTab = ({ data, onClick, tabValue }) => {
  //State
  const [userData, setUserData] = useState(false);
  const tableData = React.useMemo(() => data, [data]);

  return (
    <TabPanel value={tabValue} index={4}>
      <Stack spacing={5}>
        <IronfundSalespeopleSection data={tableData} setUserData={setUserData} />
        {/* <AnalyticSection title={"Broker Companies"} icon={<HouseRounded />}>
          <CompaniesSection data={data} />
        </AnalyticSection> */}
      </Stack>
      <UserDetailsDrawer
        show={userData}
        user={userData}
        setUserData={setUserData}
        // onSuccess={fetchAndSetUsers}
        onCancel={() => setUserData(undefined)}
      />
    </TabPanel>
  );
};

const IronfundSalespeopleSection = ({ data, setUserData }) => {
  const [tabValue, setTabValue] = useState(0);
  const tableData = removeDuplicates(data, "assignedSalesperson.emailAddress");

  const getAllAssociatedProperties = (property) => {
    const allProperties = data.filter((d) => d.assignedSalesperson.emailAddress === property);

    if (!isArray(allProperties)) return [];
    return allProperties;
  };

  const getExcelTableData = () => {
    const newArray = tableData.map((person) => {
      return {
        Name: person?.assignedSalesperson?.fullName,
        "Email Address": person?.assignedSalesperson?.emailAddress,
        "Total Assigned Brokers": getAllAssociatedProperties(person?.assignedSalesperson?.emailAddress).length,
        "Scenarios Submitted From Brokers": getAllAssociatedProperties(person?.assignedSalesperson?.emailAddress)
          .map((salesPerson) => (isNil(salesPerson?.scenarioCount) ? 0 : salesPerson?.scenarioCount))
          .reduce((prev, next) => prev + next),
      };
    });

    return newArray;
  };

  const handleSelectUser = (user) => {
    console.log("user", user);
    // const user = data?.filter((p) => p?.emailAddress === user?.emailAddress)
    setUserData(user);
  };

  const salesperson_associted_users_columns = [
    {
      Header: "Name",
      // accessor: "emailAddress",
      accessor: (d) => (
        <Button
          sx={{
            width: "100%",
            justifyContent: "flex-start",
            padding: 0,
            fontSize: ".7rem",
            fontWeight: 400,
            // padding: "6px 10px",
          }}
          onClick={() => handleSelectUser(d)}
        >
          {d?.fullName}
        </Button>
      ),
    },

    {
      Header: "Email Address",
      // accessor: "emailAddress",
      accessor: (d) => (
        <Button
          sx={{
            width: "100%",
            justifyContent: "flex-start",
            padding: 0,
            fontSize: ".7rem",
            fontWeight: 400,
            // padding: "6px 10px",
          }}
          onClick={() => handleSelectUser(d)}
        >
          {d?.emailAddress}
        </Button>
      ),
    },
    {
      Header: "Company",
      accessor: "company",
    },
    {
      Header: "Company Role",
      accessor: "companyRole",
    },
    {
      Header: "Scenarios",
      accessor: (d) => (isNil(d?.scenarios) ? 0 : d?.scenarios?.length),
    },
    {
      Header: "Details",
      accessor: (d) => (
        <Button
          size="small"
          variant="contained"
          endIcon={<ChevronRight sx={{ marginLeft: "-4px" }} />}
          sx={{
            // width: "100%",
            // justifyContent: "flex-start",
            padding: "1px 6px",
            minWidth: "unset",
            fontSize: ".7rem",
            fontWeight: 400,
            // padding: "6px 10px",
          }}
          onClick={() => handleSelectUser(d)}
        >
          View
        </Button>
      ),
    },
  ];

  const all_salespeople_columns = [
    {
      Header: "Salesperson",
      accessor: (d) => d.assignedSalesperson?.fullName,
    },

    {
      Header: "Total Assigned Brokers",
      accessor: (d) => getAllAssociatedProperties(d.assignedSalesperson?.emailAddress).length,
    },
    {
      Header: "Scenarios Submitted From Brokers",
      accessor: (d) =>
        getAllAssociatedProperties(d.assignedSalesperson?.emailAddress)
          .map((person) => (isNil(person?.scenarioCount) ? 0 : person?.scenarioCount))
          .reduce((prev, next) => prev + next),
    },
  ];

  const salespeopleTabNames = useMemo(() => tableData?.map((s) => s?.assignedSalesperson?.fullName), []);
  return (
    <>
      <AnalyticSection mt={0} title={"Assigned Broker Summary"} icon={<PersonRounded />}>
        <MuiGrid spacing={2}>
          <GridItem size={12}>
            <MuiReactTable
              title={"Salesperson Broker Summary"}
              emptyTitle={"There are currently no users."}
              noBorder
              headerContent={
                <Tooltip arrow title="Download Excel">
                  <IconButton
                    onClick={() =>
                      downloadObjectAsExcel(getExcelTableData(), `Assigned Brokers Report ${getTodaysDate()}`)
                    }
                  >
                    <FlexBox center sx={{ position: "relative", width: "1rem", height: "1rem" }}>
                      <InsertDriveFileOutlined
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          fontSize: "1.4rem !important",
                          color: "#1f7244",
                        }}
                      />
                      <DownloadRounded
                        sx={{
                          position: "absolute",
                          top: "90%",
                          left: "80%",
                          borderRadius: "50px",
                          transform: "translate(-50%, -50%)",
                          fontSize: ".8rem !important",
                          color: "#1f7244",
                          background: "#f8f9fa",
                        }}
                      />
                    </FlexBox>
                  </IconButton>
                </Tooltip>
              }
              columns={all_salespeople_columns}
              data={tableData}
            />
          </GridItem>
        </MuiGrid>
      </AnalyticSection>
      {/* <AnalyticSection mt={3} title={"Assigned Brokers Per Salesperson"} icon={<GroupsRounded />}>
        <Box
          sx={{
            background: "#f8f9fa",
            border: "1px solid rgba(224, 224, 224, 1)",
            borderTopLeftRadius: "2px",
            borderTopRightRadius: "2px",
            marginBottom: "-2px",
          }}
        >
          <TabSwitcher noBorder sub setValue={setTabValue} tabs={salespeopleTabNames} />
        </Box>
        {tableData.map((salesperson, index) => (
          <TabPanel value={tabValue} index={index}>
            <MuiReactTable
              // title={salesperson?.assignedSalesperson?.fullName}
              // hideSubHeader

              // secondaryRow={<TabSwitcher noBorder sub setValue={setTabValue} tabs={salespeopleTabNames} />}
              emptyTitle={"There are currently no users associated with you."}
              noBorder
              cardSx={{
                h2: {
                  fontSize: ".8rem",
                },
                td: {
                  // padding: 0,
                },
                ".table-primary-header": {
                  minHeight: "34px !important",
                },
                ".table-outer-wrapper": {
                  // maxHeight: "180px",
                  // height: "180px",
                  // overflowY: "auto",
                },
              }}
              columns={salesperson_associted_users_columns}
              data={data?.filter(
                (d) => d?.assignedSalesperson?.emailAddress === salesperson?.assignedSalesperson?.emailAddress
              )}
            />
          </TabPanel>
        ))}
      </AnalyticSection> */}
    </>
  );
};

const CompaniesSection = ({ data, setUserData }) => {
  const uniqueCompanyTableData = removeDuplicates(data, "company");

  const cols_company_total_users = [
    {
      Header: "Name",
      // accessor: "emailAddress",
      accessor: (d) => d?.fullName,
      CellWidth: "15%",
    },

    {
      Header: "Email Address",
      // accessor: "emailAddress",
      accessor: "emailAddress",
      CellWidth: "20%",
    },
    {
      Header: "Company",
      accessor: "company",
      CellWidth: "15%",
    },
    {
      Header: "Assigned To",
      accessor: (d) => d?.assignedSalesperson?.fullName,
      CellWidth: "15%",
    },
    {
      Header: "Company Role",
      accessor: "companyRole",
      CellWidth: "15%",
    },
    {
      Header: "Scenarios",
      accessor: (d) => (isNil(d?.scenarios) ? 0 : d?.scenarios?.length),
      CellWidth: "10%",
    },
    {
      Header: "Details",
      accessor: (d) => (
        <Button
          size="small"
          variant="contained"
          endIcon={<ChevronRight sx={{ marginLeft: "-4px" }} />}
          sx={{
            // width: "100%",
            // justifyContent: "flex-start",
            padding: "1px 6px",
            minWidth: "unset",
            fontSize: ".7rem",
            fontWeight: 400,
            // padding: "6px 10px",
          }}
          onClick={() => setUserData(d)}
        >
          View
        </Button>
      ),
      CellWidth: "10%",
      // CellWidth: "100px",
    },
  ];
  return (
    <MuiGrid spacing={2}>
      {uniqueCompanyTableData.map((company) => (
        <GridItem size={12}>
          <MuiReactTable
            title={company?.company}
            // hideSubHeader
            emptyTitle={"There are currently no users associated with you."}
            noBorder
            cardSx={{
              h2: {
                fontSize: ".8rem",
              },
              ".table-primary-header": {
                minHeight: "34px !important",
              },
            }}
            columns={cols_company_total_users}
            data={data?.filter((d) => toLower(d?.company) === toLower(company?.company))}
          />
        </GridItem>
      ))}
    </MuiGrid>
  );
};

const AnalyticSection = ({ title, icon, children, mt }) => {
  return (
    <Box>
      <FlexBox alignCenter sx={{ paddingLeft: "3px" }} gap={"4px"}>
        {icon ? (
          <Box
            sx={{
              ".MuiSvgIcon-root": {
                width: "1.1rem",
                height: "auto",
                color: "var(--primary)",
              },
            }}
          >
            {icon}
          </Box>
        ) : null}
        <ScreenSubTitle sx={{ marginBottom: "5px" }} variant="h4">
          {title}
        </ScreenSubTitle>
      </FlexBox>
      {children}
    </Box>
  );
};
export default UserAnalyticsTab;
