import { Box, Card, CircularProgress, Table, TableBody, TableHead } from "@mui/material";
import React from "react";
import FlatironTableHeader from "../FlatironTable/FlatironTableHeader";

import MuiEditableTableCell from "./MuiEditableTableCell";
import MuiEditableTableRow from "./MuiEditableTableRow";
import { Children } from "react";
import { arrayProtector } from "../../../helpers/arrayProtector";
import FlexBox from "../FlexBox";

const MuiEditableTable = ({
  title,
  tableHeaderContent,
  columnsContent,
  columns,
  maxWidth,
  children,
  cardSx,
  isLoading,
  tableSx,
  headerColor,
}) => {
  // const columnsContent = (
  //   <>
  //     <MuiEditableTableCell th>#</MuiEditableTableCell>
  //     <MuiEditableTableCell th>Unit Occupied</MuiEditableTableCell>
  //     <MuiEditableTableCell th>Unit Current Lease Term</MuiEditableTableCell>
  //     <MuiEditableTableCell th>Unit Monthly In Place Rent</MuiEditableTableCell>
  //     <MuiEditableTableCell th>Unit Monthly Market Rent</MuiEditableTableCell>
  //     <MuiEditableTableCell th>Unit Expected Post Renovation Rent</MuiEditableTableCell>
  //     <MuiEditableTableCell th>Unit Square Footage</MuiEditableTableCell>
  //   </>
  // );

  return (
    <Card
      style={{
        overflow: "auto",
        boxShadow: "none",
        maxWidth: maxWidth ? maxWidth : "unset",
        ...cardSx,
      }}
      variant="outlined"
      // title="Files"
    >
      {title ? (
        <FlatironTableHeader
          title={title}
          tableHeaderStyle={{ width: "100%" }}
          headerContent={tableHeaderContent}
          headerColor={headerColor}
        />
      ) : null}
      <Box className="table-inner-wrapper" sx={{ overflowX: "auto" }}>
        <Table size="small" sx={{ ...tableSx }}>
          <TableHead>
            <MuiEditableTableRow>
              {columnsContent
                ? columnsContent
                : arrayProtector(columns).map((col) => (
                    <MuiEditableTableCell th key={col}>
                      {col}
                    </MuiEditableTableCell>
                  ))}
            </MuiEditableTableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <td colSpan={"100%"}>
                <FlexBox center sx={{ flexGrow: 1, padding: "20px", width: "100%" }}>
                  <CircularProgress size={24} />
                </FlexBox>
              </td>
            ) : (
              children
            )}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};
// const PropertyUnitsTable = ({ name, units, isDisabled }) => {
//   // const { mutators } = useForm();

//   const unitsArrayPathName = `${name}.units`;

//   // const handleAddNewUnit = () => {
//   //   mutators.push(unitsArrayPathName, {});
//   // };

//   return (
//     <Card
//       style={{
//         overflow: "auto",
//         boxShadow: "none",
//       }}
//       variant="outlined"
//       noPadding
//       title="Files"
//     >
//       <FlatironTableHeader
//         title={"Property Units"}
//         tableHeaderStyle={{ width: "100%" }}
//         // headerContent={
//         //   isDisabled ? null : (
//         //     <Tooltip onClick={handleAddNewUnit} arrow title={"Add New Unit"}>
//         //       <IconButton
//         //         sx={{
//         //           margin: "0px",
//         //           padding: "0px",
//         //           color: "var(--blue)",
//         //         }}
//         //       >
//         //         <AddRounded sx={{ fontSize: "1rem" }} />
//         //       </IconButton>
//         //     </Tooltip>
//         //   )
//         // }
//       />

//       <Table size="small">
//         <TableHeader>
//           <TableRow>
//             <TableCellItem component="th">#</TableCellItem>
//             <TableCellItem component="th">Unit Occupied</TableCellItem>
//             <TableCellItem component="th">Unit Current Lease Term</TableCellItem>
//             <TableCellItem component="th">Unit Monthly In Place Rent</TableCellItem>
//             <TableCellItem component="th">Unit Monthly Market Rent</TableCellItem>
//             <TableCellItem component="th">Unit Expected Post Renovation Rent</TableCellItem>
//             <TableCellItem component="th">Unit Square footage</TableCellItem>
//           </TableRow>
//         </TableHeader>
//         {/* <TableBody>
//           <FieldArray name={unitsArrayPathName}>
//             {({ fields }) =>
//               fields.map((name, unitIndex) => (
//                 <TableRowItem
//                   key={unitIndex}
//                   // row={units[unitIndex]}
//                   index={unitIndex}
//                   isDisabled={isDisabled}
//                   propertyName={name}
//                 />
//               ))
//             }
//           </FieldArray>
//         </TableBody> */}
//       </Table>
//     </Card>
//   );
// };

// const TableRowItem = ({ row, isDisabled, propertyName, index }) => {
//   return (
//     <TableRow className="files-row">
//       <TableCellItem component="th">{index + 1}</TableCellItem>

//       <TableCellItem
//         isDisabled={isDisabled}
//         index={index}
//         propertyName={propertyName}
//         name="unitOccupied"
//         row={row}
//         type="select"
//         // items={yesNo}
//       />
//       <TableCellItem
//         isDisabled={isDisabled}
//         index={index}
//         propertyName={propertyName}
//         name="leaseType"
//         row={row}
//         items={[
//           "Long-term (original term >= 12 months)",
//           "Month-to-Month",
//           "Short-term (vacation rental, AirBnB or VRBO)",
//           "No Lease in Place",
//         ]}
//         type="select"
//       />
//       <TableCellItem
//         isDisabled={isDisabled}
//         index={index}
//         propertyName={propertyName}
//         name="unitMonthlyInPlaceRent"
//         row={row}
//         type="dollar"
//       />
//       <TableCellItem
//         isDisabled={isDisabled}
//         index={index}
//         propertyName={propertyName}
//         name="unitMonthlyMarketRent"
//         row={row}
//         type="dollar"
//       />
//       <TableCellItem
//         isDisabled={isDisabled}
//         index={index}
//         propertyName={propertyName}
//         name="expectedPostRenovationRent"
//         row={row}
//         type="dollar"
//       />
//       <TableCellItem
//         isDisabled={isDisabled}
//         index={index}
//         propertyName={propertyName}
//         name="unitSquareFootage"
//         row={row}
//         type="number"
//       />
//     </TableRow>
//   );
// };

// const TableCellItem = ({ isDisabled, type, label, name, index, row, component, children, propertyName, items }) => {
//   const styles = {
//     position: "relative",
//     padding: !isDisabled ? "10px" : "10px",
//     fontSize: ".7rem",
//     "& .MuiInput-input": {
//       padding: "6px",
//     },
//   };
//   console.log("propertyName", propertyName);
//   console.log("name", name);
//   console.log("row", row);
//   // const { values } = useFormState();
//   // const pathName = `example`;
//   const pathName = `${propertyName}.${name}`;
//   // console.log("pathName", pathName);
//   const thStyles = { fontWeight: "600", fontSize: ".7rem", padding: "10px !important" };
//   if (component === "th") {
//     return (
//       <TableCell component={"td"} sx={{ ...styles, ...thStyles }}>
//         {children}
//       </TableCell>
//     );
//   }
//   if (!isDisabled) {
//     if (type === "select") {
//       return (
//         <TableCell component={"td"} sx={styles}>
//           <FinalFormSelectField name={pathName} items={items} dollar variant="standard" />
//         </TableCell>
//       );
//     }
//     return (
//       <TableCell component={"td"} sx={styles}>
//         <FinalFormTextField
//           // name={name}
//           variant="standard"
//           name={pathName}
//           dollar={type === "dollar" ? true : false}
//           number={type === "number" ? true : false}
//         />
//       </TableCell>
//     );
//   }

//   return (
//     <TableCell component={"td"} sx={styles}>
//       {/* {getObjectValueFromStringPath(values, pathName)} */}
//       {/* {isNil(row?.[name]) ? "-" : row?.[name]} */}
//     </TableCell>
//   );
// };
export default MuiEditableTable;
