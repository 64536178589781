export const generateDealCommentHtml = ({ postedByName, loanId, loanName, postedByDate, commentHtml }) => {
  return `<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

  <head>
    <title>
    </title>
    <!--[if !mso]><!-->
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <!--<![endif]-->
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <style type="text/css">
      #outlook a {
        padding: 0;
      }
  
      body {
        margin: 0;
        padding: 0;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
      }
  
      table,
      td {
        border-collapse: collapse;
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
      }
  
      img {
        border: 0;
        height: auto;
        line-height: 100%;
        outline: none;
        text-decoration: none;
        -ms-interpolation-mode: bicubic;
      }
  
      p {
        display: block;
        margin: 13px 0;
      }
    </style>
    <!--[if mso]>
              <noscript>
              <xml>
              <o:OfficeDocumentSettings>
                <o:AllowPNG/>
                <o:PixelsPerInch>96</o:PixelsPerInch>
              </o:OfficeDocumentSettings>
              </xml>
              </noscript>
              <![endif]-->
    <!--[if lte mso 11]>
              <style type="text/css">
                .mj-outlook-group-fix { width:100% !important; }
              </style>
              <![endif]-->
    <style type="text/css">
    </style>
  </head>
  
  <body style="word-spacing:normal" class="template-body">
    <table cellPadding="0" cellSpacing="0" border="0"   style="padding:0 0;margin:0px;width:100%">
      <tr>
        <td colSpan="1" style="padding:0px;margin:0px"> </td>
        <td colSpan="1" style="padding:0px;margin:0px" width="620">
          <table cellPadding="0" cellSpacing="0" border="0" style="padding:0 0;margin:0px;width:100%;border:1px solid #dddddd">
            <tr>
              <td colSpan="1" style="padding:0px;margin:0px">
                <table cellPadding="0" cellSpacing="0" border="0" style="padding:0 0;margin:0px;width:100%;background:#ffffff">
                  <tr>
                    <td colSpan="1" style="padding:0px;margin:0px" width="30"></td>
                    <td colSpan="1" style="padding:0px;margin:0px">
                      <table cellPadding="0" cellSpacing="0" border="0" height="100" style="padding:0 0;margin:0px;width:100%">
                        <tr>
                          <td colSpan="1" style="padding:0px;margin:0px"><img width="120" style="width:100%;max-width:120px" src="https://ironfund.com/wp-content/uploads/2020/08/rsz_iron_fund_logo_no_shadow.png" /></td>
                        </tr>
                      </table>
                      <table cellPadding="0" cellSpacing="0" border="0" height="100" style="padding:0 0;margin:0px;width:100%">
                        <tr>
                          <td colSpan="1" style="padding:0px;margin:0px">
                          
                          <p>Comment Posted By: <b>${postedByName}</b></p>
                          <p>Scenario Name : <b>${loanName}</b></p>
                          <p>Date of Comment: <b>${postedByDate}</b></p>
                     
                          </td>
                        </tr>
                      </table>
                      <table cellPadding="0" cellSpacing="0" border="0" style="padding:0 0;margin:0px;width:100%;background:#f6f8fa">
                        <tr>
                          <td colSpan="1" style="padding:0px;margin:0px" width="30"></td>
                          <td colSpan="1" style="padding:0px;margin:0px">
                            <table cellPadding="0" cellSpacing="0" border="0" height="20px" style="padding:0 0;margin:0px;width:100%">
                              <tr>
                                <td colSpan="1" style="padding:0px;margin:0px"></td>
                              </tr>
                            </table>
                            <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;line-height:25px;color:#525252;text-align:left;font-weight:normal">${commentHtml} 
                            ${generateHtmlButton(`https://ironlinc.ironfund.com/scenarios/${loanId}/comments`)}
                            </div>
                            <table cellPadding="0" cellSpacing="0" border="0" height="20px" style="padding:0 0;margin:0px;width:100%">
                              <tr>
                                <td colSpan="1" style="padding:0px;margin:0px"></td>
                              </tr>
                            </table>
                          </td>
                          <td colSpan="1" style="padding:0px;margin:0px" width="30"></td>
                        </tr>
                        
                      </table>
                    </td>
                    <td colSpan="1" style="padding:0px;margin:0px" width="30"></td>
                  </tr>
          
                </table>
                <table cellPadding="0" cellSpacing="0" border="0" height="30px" style="padding:0 0;margin:0px;width:100%">
                  <tr>
                    <td colSpan="1" style="padding:0px;margin:0px"></td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
      
        </td>
        <td colSpan="1" style="padding:0px;margin:0px"> </td>
      </tr>
    </table>
    <img alt="" src="https://ironlinc-dev.azurewebsites.net/ironlinc_email_upload/REPLACE_PIXEL_STR" />
  </body>
  </html>`;
};

export const generateHtmlButton = (url) => {
  return `<table border="0" cellspacing="0" cellpadding="0" style="cursor: pointer; height: 30px;">
  <tr>
    <td align="center" style="padding: 4px 10px; border-radius: 4px; background-color: rgb(34, 86, 132);"><a rel="noreferrer" target="_blank" href=${url} style="display: inline-block; font-family: Ubuntu, Helvetica, Arial, sans-serif; font-size: 13px; color: rgb(255, 255, 255); background-color: rgb(34, 86, 132); text-decoration: none; font-weight: bold;"><span style="font-family: Ubuntu, Helvetica, Arial, sans-serif; font-size: 13px; color: rgb(255, 255, 255); background-color: rgb(34, 86, 132); text-decoration: none; font-weight: bold;">View Scenario Comment</span></a></td>
  </tr>
</table>`;
};

export const generateHtmlDealCommentCard = (data) => {
  return `
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <title>
  </title>
  <!--[if !mso]><!-->
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <!--<![endif]-->
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <style type="text/css">
    #outlook a {
      padding: 0;
    }

    body {
      margin: 0;
      padding: 0;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }

    table,
    td {
      border-collapse: collapse;
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
    }

    img {
      border: 0;
      height: auto;
      line-height: 100%;
      outline: none;
      text-decoration: none;
      -ms-interpolation-mode: bicubic;
    }

    p {
      display: block;
      margin: 13px 0;
    }
  </style>
  <!--[if mso]>
              <noscript>
              <xml>
              <o:OfficeDocumentSettings>
                <o:AllowPNG/>
                <o:PixelsPerInch>96</o:PixelsPerInch>
              </o:OfficeDocumentSettings>
              </xml>
              </noscript>
              <![endif]-->
  <!--[if lte mso 11]>
              <style type="text/css">
                .mj-outlook-group-fix { width:100% !important; }
              </style>
              <![endif]-->
  <style type="text/css">
  </style>
</head>

<body style="word-spacing:normal" class="template-body">
  <table cellPadding="0" cellSpacing="0" border="0" style="padding:0 0;margin:0px;width:100%">
    <tr>
      <td colSpan="1" style="padding:0px;margin:0px"> </td>
      <td colSpan="1" style="padding:0px;margin:0px" width="620">
        <table cellPadding="0" cellSpacing="0" border="0" style="padding:0 0;margin:0px;width:100%;border:1px solid #dddddd">
          <tr>
            <td colSpan="1" style="padding:0px;margin:0px" >
              <table cellPadding="0" cellSpacing="0" border="0" style="padding:0 0;margin:0px;width:100%;background:#ffffff">
                <tr>
                  <td colSpan="1" style="padding:0px;margin:0px" width="30"></td>
                  <td colSpan="1" style="padding:16px 24px 24px 24px;margin:0px">
                    
             ${data}
                  </td>
                  <td colSpan="1" style="padding:0px;margin:0px" width="30"></td>
                </tr>

              </table>

            </td>
          </tr>
        </table>

      </td>
      <td colSpan="1" style="padding:0px;margin:0px"> </td>
    </tr>
  </table>
  <img alt="" src="https://ironlinc-dev.azurewebsites.net/ironlinc_email_upload/REPLACE_PIXEL_STR" />
</body>

</html>
  `;
};
