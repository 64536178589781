export const searchInComplexData = (string, tableDataArray) => {
  const results = [];

  function deepSearch(item) {
    if (JSON.stringify(item).includes(string)) {
      results.push(item);
    } else if (Array.isArray(item)) {
      item.forEach(deepSearch);
    } else if (typeof item === "object" && item !== null) {
      for (const key in item) {
        deepSearch(item[key]);
      }
    }
  }

  tableDataArray.forEach(deepSearch);

  return results;
};
