import React from "react";
import { Typography } from "@mui/material";
import { isBoolean, isNil } from "lodash";

const CardValue = ({ value, customDisplayValue }) => {
  const valueIsEmpty = isNil(value);

  const boolValue = value ? "Yes" : "No";
  const stringValue = value;
  const displayValue = isBoolean(value) ? boolValue : stringValue;
  console.log({ value });
  return (
    <Typography
      sx={{
        width: "100%",
        fontSize: ".9rem",
        opacity: valueIsEmpty ? 0.7 : 1,
        fontWeight: valueIsEmpty ? 500 : 600,
      }}
    >
      {valueIsEmpty ? "—" : customDisplayValue ? customDisplayValue : displayValue}
    </Typography>
  );
};

export default CardValue;
