import { isNil } from "lodash";
import { useMemo, useState } from "react";

import { getDayNumFromDate } from "./helpers/getDayNumFromDate";
import { useCrmAxiosHook } from "./useCrmAxiosHook";

// const { availableDates, getUserDetails } = useCalendarHook();

export const useCalendarHook = () => {
  // const [availableDates, setAvailableDates] = useState(null);
  const [data, setData] = useState(undefined);
  const { axiosPostIsLoading, axiosGetIsLoading, axiosError, axiosGetRequest, axiosPostRequest } = useCrmAxiosHook();
  const isLoading = axiosGetIsLoading || axiosPostIsLoading;
  const error = axiosError;

  const lineOfBusinessParam = "ironfund";
  const repParam = window.location.pathname.split("/").pop();

  const isValidPath = useMemo(() => (isNil(lineOfBusinessParam) || isNil(repParam) ? false : true), []);
  // const isValidPath = useMemo(() => (isNil(lineOfBusinessParam) || isNil(repParam) ? false : true), []);

  const availableDates = useMemo(() => data?.availableSlots, [data]);
  // const availableDates = useMemo(
  //   () => (isNil(data) ? undefined : filterDatesByCurrentMonth(arrayProtector(data?.availableSlots))),
  //   [data]
  // );
  const internalUserName = useMemo(() => data?.internalUserName, [data]);

  const getTimeSlotsForDay = (dates, dayNum) => {
    const days = dates?.filter((d) => getDayNumFromDate(d) === dayNum);
    return days;
  };

  const getUserDetails = async ({ onSuccessFn, onFailFn }) => {
    if (isNil(repParam) || isNil(lineOfBusinessParam)) {
      // setAvailableDates({});
      return;
    }
    const endpoint = `meeting/${lineOfBusinessParam}/${repParam}/`;

    const onRequestSuccess = (passedData) => {
      // console.log({ passedData });

      setData(passedData);
      // setAvailableDates(slots);
      if (onSuccessFn) {
        onSuccessFn(passedData);
      }
    };

    const params = {
      name: "Get Meeting Details",
      path: endpoint,
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not get meeting details",
      onFailFn,
      crm: true,
    };
    // console.log(params);
    // onRequestSuccess({
    //   availableSlots: CALENDAR_DUMMY,
    // });
    await axiosGetRequest(params);
  };

  const submitAppointmentRequest = async ({ payload, timezone, onSuccessFn, onFailFn }) => {
    const endpoint = `meeting/${lineOfBusinessParam}/${repParam}/create/`;

    const onRequestSuccess = (passedData) => {
      console.log({ passedData });
      // const contactsList = passedData;
      // dispatch(setContacts(contactsList));

      if (onSuccessFn) {
        onSuccessFn(passedData, payload);
      }
    };

    const params = {
      name: "Create Task",
      path: endpoint,
      payload,
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not create task",
      onFailFn,
      crm: true,
    };
    // console.log(params);
    // onRequestSuccess();
    await axiosPostRequest(params);
  };

  return {
    //States
    error,
    isValidPath,
    internalUserName,
    availableDates,
    isLoading,
    data,
    getTimeSlotsForDay,
    getUserDetails,
    submitAppointmentRequest,
  };
};
