import React from "react";
import { Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useFormState } from "react-final-form";
import { isNil } from "lodash";

import { getObjectValueFromStringPath } from "../../../../../helpers/getObjectValueFromStringPath";

import CardValue from "./CardValue";
import GridItem from "../../../../../components/shared/MuiGrid/GridItem";
import FlexBox from "../../../../../components/shared/FlexBox";
import MuiGrid from "../../../../../components/shared/MuiGrid";

const ProfileScreenCardRow = ({ label, value, name, children, input, border, customDisplayValue }) => {
  const isMobile = useSelector((state) => state.globalData?.isMobile);
  const isEditing = useSelector((state) => state.formData.isEditing);
  const { values } = useFormState();

  const rowValue = isNil(value) ? getObjectValueFromStringPath(values, name) : value;
  const dynamicFormData =
    isEditing && input ? input : <CardValue customDisplayValue={customDisplayValue} value={rowValue} />;
  return (
    <>
      <FlexBox alignCenter sx={{ width: "100%", position: "relative" }}>
        <MuiGrid
          sx={{
            minHeight: "45px",
            // padding: "10px 0",
            position: "relative",
          }}
        >
          <GridItem size={isMobile ? 12 : 3}>
            <FlexBox column justifyCenter sx={{ height: "100%" }}>
              <Typography sx={{ fontSize: ".8rem", fontWeight: 500, opacity: 0.8 }}>{label}</Typography>
            </FlexBox>
          </GridItem>
          <GridItem size={isMobile ? 12 : 9}>
            <FlexBox column justifyCenter sx={{ height: "100%" }}>
              {children ? children : dynamicFormData}
            </FlexBox>
          </GridItem>
        </MuiGrid>
      </FlexBox>
      {border ? <Divider light flexItem /> : null}
    </>
  );
};

export default ProfileScreenCardRow;
