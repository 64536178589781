import { ArrowRightAltRounded, EastRounded } from "@mui/icons-material";
import { Box, DialogContent, Divider, Stack, Typography } from "@mui/material";
import { isEmpty, isNil } from "lodash";
import React from "react";
import { useFormState } from "react-final-form";
import FlexBox from "../../../../../components/shared/FlexBox";
import MuiChip from "../../../../../components/shared/MuiChip";
import { useState } from "react";

const InviteUserPreviewCard = ({ url }) => {
  const { values } = useFormState();
  const [state, set] = useState(null);
  if (isEmpty(values)) {
    return null;
  }

  return (
    <Box
      sx={{
        background: "#ffffff !important",
        flexGrow: 1,
        borderRadius: "4px",
        boxShadow: "var(--bsMui)",
        position: "relative",
      }}
    >
      <DialogContent>
        <Stack>
          <Typography sx={{ margin: "10px 0", fontWeight: 600 }} variant="h3">
            Live Email Preview
          </Typography>
          <Divider flexItem />
          <Divider flexItem />
          <Divider flexItem />

          <FlexBox justifyBetween alignCenter>
            <EmailHeaderItem
              pre="From:"
              post={
                isNil(values?.loanSalesperson?.fullName) ? (
                  "—"
                ) : (
                  <MuiChip label={values?.loanSalesperson?.ironfund_email} />
                )
              }
            />
            <EastRounded />
            <EmailHeaderItem pre="To:" post={isNil(values?.recipient) ? "—" : <MuiChip label={values?.recipient} />} />
          </FlexBox>
          <Divider flexItem />
          <EmailHeaderItem pre="Subject:" post={values?.subject} />
          <Divider flexItem />
        </Stack>
        <DynamicBodyContent data={values?.body} />
        <DynamicSignatureContent data={values?.signature} />
      </DialogContent>
    </Box>
  );
};

const DynamicBodyContent = ({ data }) => {
  return <Box key={data} sx={{ paddingTop: "20px" }} dangerouslySetInnerHTML={{ __html: data }} />;
};
const DynamicSignatureContent = ({ data }) => {
  return <Box key={data} sx={{ paddingTop: "20px" }} dangerouslySetInnerHTML={{ __html: data }} />;
};

const EmailHeaderItem = ({ pre, post }) => {
  return (
    <FlexBox sx={{ padding: "10px 0" }} alignCenter>
      <Typography sx={{ fontSize: ".7rem", fontWeight: 600, marginRight: "5px" }} variant="body1">
        {isNil(pre) ? "—" : pre}
      </Typography>
      <Typography sx={{ fontSize: ".7rem", fontWeight: 500 }} variant="body1">
        {isNil(post) ? "—" : post}
      </Typography>
    </FlexBox>
  );
};
export default InviteUserPreviewCard;
