import { CheckRounded, ContentCopyOutlined, LinkOutlined } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";

const CopyToClipboardContent = ({ url }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleOnClick = () => {
    navigator.clipboard.writeText(url);
    setIsCopied(true);
  };

  return (
    <Stack spacing={1} alignItems={"flex-end"} sx={{ paddingTop: "10px" }}>
      <Box
        sx={{
          background: "#eaeefa",
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: "6px 0",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRight: "1px solid var(--borderGray)",
            width: "44px",
            height: "34px",
          }}
        >
          <LinkOutlined
            sx={{
              transform: "rotate(-45deg)",
            }}
          />
        </Box>

        <Typography
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            margin: "0 14px",
            width: "100%",
            textOverflow: "ellipsis",
          }}
        >
          {url}
        </Typography>
      </Box>
      <Button
        onClick={handleOnClick}
        sx={{
          marginRight: "6px",
        }}
        variant="contained"
        color={isCopied ? "success" : "primary"}
        endIcon={isCopied ? <CheckRounded /> : <ContentCopyOutlined sx={{ width: "1rem" }} />}
      >
        Copy Link
      </Button>
    </Stack>
  );
};

export default CopyToClipboardContent;
