import React from "react";
import Signature from "../../../components/form/components/inputs/generic/Signature/Signature";
import TextOverflow from "../../../components/shared/TextOverflow";

// import "./CreditAuthConfirmation.css";
// import Form from "../../../../UserAdmission/components/SignInForm/SignInForm";
// import "./CreditAuthFormStepTwo.css";

const BorrowerLoanAppConfirmation = (props) => {
  return (
    <div className="credit-auth-confirmation">
      <TextOverflow title={"Authorization Agreement"}>
        <p>
          The actual terms and conditions upon which Flatiron Realty Capital LLC (the Lender) might extend credit are
          subject to satisfactory completion of due diligence, internal approval, satisfactory review of documentation
          and such other or different items and conditions as may be determined by the Lender following the completion
          of its underwriting (the “Underwriting and Approval Process”). As a result, notwithstanding any agreement of
          the parties to the contrary, (i) Lender’s approval of the loan documents shall not constitute a final approval
          of the loan and (ii) the loan documents shall not be considered final until the Underwriting and Approval
          Process is completed (as determined in Lender’s sole discretion).
        </p>
        <p>
          The borrower hereby agrees that all aforementioned information is true and in no means is false and intended
          to influence the credit underwriting of the Lender in regard to origination of the loan associated with the
          subject property or properties. Additionally, the borrower understands that this loan application does not
          constitute a commitment from the Lender to originate the loan associated with the subject property or
          properties. Lastly, the borrower acknowledges that all fees collected in relation to loan due diligence are
          non-refundable, in any circumstance.
        </p>
      </TextOverflow>
      <Signature isRequired name={"signature"} label="Borrower Signature" />
    </div>
  );
};

export default BorrowerLoanAppConfirmation;
const alert = (
  <svg viewBox="0 0 24 24">
    <path d="M12.884 2.532c-.346-.654-1.422-.654-1.768 0l-9 17A.999.999 0 0 0 3 21h18a.998.998 0 0 0 .883-1.467L12.884 2.532zM13 18h-2v-2h2v2zm-2-4V9h2l.001 5H11z"></path>
  </svg>
);
