import React, { useEffect } from "react";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Field, useForm, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { buttonGhost } from "../../../assets/styles/SharedInlineStyles";
import { DatePickerTextFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/DatePickerTextFieldComponent";
import AddressAutofillField from "../../../components/form/components/inputs/generic/AddressAutofillField/AddressAutofillField";
import FormGroup from "../../../components/form/components/shared/FormGroup/FormGroup";

import { isNil } from "lodash";
import { useMemo } from "react";
import FinalFormSelectField from "../../../components/form/Fields/FinalFormSelectField";
import FinalFormTextField from "../../../components/form/Fields/FinalFormTextField";
import MuiGrid from "../../../components/shared/MuiGrid";
import GridItem from "../../../components/shared/MuiGrid/GridItem";
import { stateOfInteriorAccessOptions, subjectPropertyTypeOptions } from "../../_formQuestions/selectOptions";
import MultipleItemCard from "../../components/MultipleItemCard";
// import Form from "../../../../UserAdmission/components/SignInForm/SignInForm";
// import "./CreditAuthFormStepOne.css";

const CreateScenarioStepSubjectProperty = ({ display }) => {
  const { values } = useFormState();
  const { mutators } = useForm();

  // const additionalName = `${props.name}Additional`;

  const addAdditionalFieldHandler = (name) => {
    mutators.push(name, {
      softCosts: 0,
      hardCosts: 0,
      totalBudget: 0,
      rehabBudgetFinancedPercent: 100,
    });
  };

  useEffect(() => {
    if (isNil(values.subjectProperties)) {
      addAdditionalFieldHandler("subjectProperties");
    }
  }, []);

  return (
    <>
      <FieldArray name={"subjectProperties"}>
        {({ fields }) =>
          fields.map((name, index) => (
            <PipelineProperty
              name={name}
              index={index}
              fields={fields}
              display={display}
              // {...props}
            />
          ))
        }
      </FieldArray>
      <div className="add-additional-field-wrapper center">
        <button
          style={buttonGhost}
          onClick={() => addAdditionalFieldHandler("subjectProperties")}
          className="add-additional-button"
          type="button"
        >
          <span>Add Subject Property</span>
          <AddOutlinedIcon />
        </button>
      </div>
    </>
  );
};

const PipelineProperty = ({ display, name, index, fields }) => {
  const { values, errors, form } = useFormState();
  const { mutators, change } = useForm();
  // const { name, index, fields } = props;
  const addAdditionalFieldHandler = (name) => {
    mutators.push(name, {});
  };

  const options_yesNo = useMemo(() => ["Yes", "No"], []);
  const options_leaseType = useMemo(
    () => [
      "Long-term (original term >= 12 months)",
      "Month-to-Month",
      "Short-term (vacation rental, AirBnB or VRBO)",
      "No Lease in Place",
    ],
    []
  );

  return (
    <MultipleItemCard
      title={"Subject Property"}
      onRemove={() => fields.remove(index)}
      index={index}
      unitsPathName={`subjectProperties`}
    >
      <FormGroup noMargin>
        <MuiGrid>
          <GridItem mobile size={12}>
            <AddressAutofillField
              noMargin
              // noFields
              name={`${name}`}
              label="Subject Property Address"
              // value={
              //   values?.subjectProperties === undefined
              //     ? false
              //     : values?.subjectProperties[index]?.address
              // }
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>
      <FormGroup fieldLabel={"Property Details"}>
        <MuiGrid spacing={1}>
          <GridItem mobile size={6}>
            <FinalFormTextField
              display={display}
              required
              // disabled
              name={`${name}.numberOfUnits`}
              label="Number Of Units"
            />
          </GridItem>
          <GridItem mobile size={6}>
            <FinalFormSelectField
              required
              display={display}
              name={`${name}.propertyType`}
              label="Property Type"
              items={subjectPropertyTypeOptions}
            />
          </GridItem>
          <GridItem mobile size={6}>
            <FinalFormTextField display={display} name={`${name}.squareFootage`} label="Estimated Square Footage" />
          </GridItem>
          <GridItem mobile size={6}>
            <FinalFormSelectField
              name={`${name}.stateOfInteriorAccess`}
              label="State of Interior Access"
              items={stateOfInteriorAccessOptions}
            />
          </GridItem>

          {values?.loanPurpose === "Purchase" ? (
            <>
              <GridItem mobile size={4}>
                <FinalFormTextField name={`${name}.purchasePrice`} label="Purchase Price" dollar />
              </GridItem>
              <GridItem mobile size={4}>
                <FinalFormTextField name={`${name}.assignmentFee`} label="Assignment Fee" dollar />
              </GridItem>
              <GridItem mobile size={4}>
                <Field
                  display={display}
                  name={`${name}.purchaseDate`}
                  label="Purchase Date"
                  component={DatePickerTextFieldComponent}
                />
              </GridItem>
            </>
          ) : null}
        </MuiGrid>
      </FormGroup>
      <FormGroup fieldLabel={"Appraisal"}>
        <MuiGrid spacing={1}>
          <GridItem mobile size={6}>
            <FinalFormTextField display={display} name={`${name}.asIsValue`} required label="As Is Value" dollar />
          </GridItem>

          <GridItem mobile size={6}>
            <Field
              display={display}
              name={`${name}.asIsValueDate`}
              label="As Is Value Date"
              component={DatePickerTextFieldComponent}
            />
          </GridItem>

          {values?.loanProductType === "Fix and Flip" ||
          values?.loanProductType === "Ground Up Construction" ||
          values?.loanProductType === "Land Purchase" ? (
            <>
              <GridItem mobile size={6}>
                <FinalFormTextField
                  display={display}
                  required
                  name={`${name}.appraisalArv`}
                  label="Appraisal ARV"
                  dollar
                />
              </GridItem>
              <GridItem mobile size={6}>
                <Field
                  display={display}
                  name={`${name}.appraisalArvDate`}
                  label="Appraisal ARV Date"
                  component={DatePickerTextFieldComponent}
                />
              </GridItem>
            </>
          ) : null}
        </MuiGrid>
      </FormGroup>
    </MultipleItemCard>
  );
};

export default CreateScenarioStepSubjectProperty;
