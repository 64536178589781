import React from "react";
import { Field, useForm, useFormState } from "react-final-form";

import { VALIDATOR_MIN_CHAR, VALIDATOR_REQUIRE } from "../../../helpers/validators/inputValidators";

import DependentFields from "../../components/DependentFields";

import { DatePickerTextFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/DatePickerTextFieldComponent";
import { SelectFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/SelectFieldComponent";
import { TextareaFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/TextareaFieldComponent";
import { TextFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import FileUploadField from "../../../components/form/components/inputs/generic/FileUploadField/FileUploadField";
import MultiSelectFinalForm from "../../../components/form/components/inputs/generic/MultiSelectFinalForm";
import RadioField from "../../../components/form/components/inputs/generic/RadioField/RadioField";
import RadioFieldTrueFalse from "../../../components/form/components/inputs/generic/RadioFieldTrueFalse";
import FormGroup from "../../../components/form/components/shared/FormGroup/FormGroup";
import AdditionEquationRow from "../../../components/shared/AdditionEquationRow";
import MuiGrid from "../../../components/shared/MuiGrid";
import GridItem from "../../../components/shared/MuiGrid/GridItem";
import { composeValidators } from "../../../helpers/validators/composeValidators";
import {
  loanExitStrategy,
  rehabRequiredOptions,
  rehabWorkRequiredIsLightAdditionalInfoOptions,
  requestedLoanTermOptions,
  yesNo,
} from "../../_formQuestions/selectOptions";
import { fileDocumentSchema } from "../../_formQuestions/_formSchema/fileDocumentSchema";
import FinalFormTextField from "../../../components/form/Fields/FinalFormTextField";
import FinalFormSelectField from "../../../components/form/Fields/FinalFormSelectField";
import FinalFormFileUpload from "../../../components/form/Fields/FinalFormFileUpload";

const BorrowerLoanAppLoan = (props) => {
  const { values } = useFormState();
  const { change } = useForm();

  let dscr = "30-Year Rental Loan";
  let fix = "Fix and Flip";
  let stabilized = "Stabilized Bridge";
  let groundUp = "Ground Up Construction";
  let land = "Land Purchase";
  let other = "Other";

  const getSumOfLoan = (e) => {
    let num = Number(parseFloat(e.target.value.replace(/,/g, "")));
    let val = num === undefined || num === null || num === "" ? 0 : num;
    let financedBudget =
      values["totalFinancedBudget"] === undefined ||
      values["totalFinancedBudget"] === null ||
      values["totalFinancedBudget"] === ""
        ? 0
        : Number(values["totalFinancedBudget"]);
    let initialLoanAmount =
      values["initialLoanAmount"] === undefined ||
      values["initialLoanAmount"] === null ||
      values["initialLoanAmount"] === ""
        ? 0
        : Number(values["totalFinancedBudget"]);

    let name = e.target.id;
    let sum;
    console.log("log", val, name);
    if (name === "initialLoanAmount") {
      sum = financedBudget + val;
    }
    if (name === "totalFinancedBudget") {
      sum = initialLoanAmount + val;
    }

    // if (sum === undefined || sum === null || sum === "" || sum === NaN) {
    //   sum = 0;
    // }
    console.log("val", val);
    console.log("financedBudget", financedBudget);
    console.log("initialLoanAmount", initialLoanAmount);
    console.log("SUM", sum);
    change("requestedLoanAmount", sum);
  };
  return (
    <>
      {/* <WizardPageHeader>{`${values.loanProductType} Loan Details`}</WizardPageHeader> */}
      <DependentFields name="loanProductType" values={[dscr, stabilized, land, other]}>
        <FormGroup noMargin bold fieldLabel="Requested Loan Amount">
          <MuiGrid>
            <GridItem size={12}>
              <FinalFormTextField
                name={"requestedLoanAmount"}
                label="Loan Amount"
                required
                // validate={VALIDATOR_REQUIRE}
                noMargin
                dollar
                // format={formFormatDollar}
                // component={TextFieldComponent}
              />
            </GridItem>
          </MuiGrid>
        </FormGroup>
      </DependentFields>

      <DependentFields name="loanProductType" values={[fix, groundUp]}>
        <FormGroup noMargin bold fieldLabel="Requested Loan Amounts">
          <AdditionEquationRow value1={values.initialLoanAmount} value2={values.totalFinancedBudget}>
            <FinalFormTextField
              name={"initialLoanAmount"}
              label="Requested Initial"
              required
              // validate={VALIDATOR_REQUIRE}
              dollar
              // component={TextFieldComponent}
            />
            <FinalFormTextField
              name={"totalFinancedBudget"}
              label="Requested Financed Budget"
              required
              // validate={VALIDATOR_REQUIRE}
              dollar
              // component={TextFieldComponent}
            />
          </AdditionEquationRow>
        </FormGroup>
      </DependentFields>

      <DependentFields name="loanProductType" notEqual value={dscr}>
        <FormGroup bold fieldLabel="Additional Loan Details">
          <MuiGrid>
            <DependentFields name="loanProductType" values={[fix, stabilized, groundUp]}>
              <GridItem size={12}>
                <FinalFormSelectField
                  noMargin
                  name="requestedLoanTerm"
                  label="Requested Loan Term"
                  // label="Select loan term"
                  // validate={VALIDATOR_REQUIRE}
                  items={requestedLoanTermOptions}
                  // component={SelectFieldComponent}
                />
              </GridItem>
            </DependentFields>

            <DependentFields name="loanProductType" values={[fix, stabilized, groundUp]}>
              <GridItem size={12}>
                <FinalFormSelectField
                  name="loanExitStrategy"
                  label="What is your loan exit strategy?"
                  // label="Select exit strategy"
                  required
                  // validate={VALIDATOR_REQUIRE}
                  items={loanExitStrategy}
                  // component={SelectFieldComponent}
                />
              </GridItem>
            </DependentFields>

            <DependentFields name="loanProductType" values={[fix, stabilized, groundUp]}>
              <GridItem size={12}>
                <Field
                  name={"businessPlanDescription"}
                  label="Business Plan Description"
                  fieldLabel="Please briefly describe your business plan for this loan"
                  validate={composeValidators(VALIDATOR_REQUIRE, VALIDATOR_MIN_CHAR(50))}
                  minChar={50}
                  component={TextareaFieldComponent}
                />
              </GridItem>
            </DependentFields>

            <DependentFields name="loanProductType" values={[fix]}>
              <GridItem size={12}>
                <Field
                  name="rehabWorkRequiredIsLight"
                  fieldLabel="How would you describe the required rehab for the project?"
                  label="Select rehab required"
                  component={SelectFieldComponent}
                  validate={VALIDATOR_REQUIRE}
                  items={rehabRequiredOptions}
                />
              </GridItem>
            </DependentFields>

            <DependentFields name="loanProductType" values={[fix, groundUp]}>
              <DependentFields
                name="rehabWorkRequiredIsLight"
                value="Heavy - Rehab greater than 35% of the value of the home"
              >
                <GridItem size={12}>
                  <MultiSelectFinalForm
                    isRequired
                    name={"rehabWorkRequiredIsLightAdditionalInfo"}
                    items={rehabWorkRequiredIsLightAdditionalInfoOptions}
                    fieldLabel="For the heavy rehab, select all that apply:"
                  />
                </GridItem>
              </DependentFields>
            </DependentFields>

            <DependentFields name="loanProductType" values={[fix, groundUp]}>
              <GridItem size={12}>
                <RadioFieldTrueFalse
                  isRequired
                  name={"rehabPlansAreApproved"}
                  items={["Yes", "No"]}
                  fieldLabel="Are there approved plans for the project?"
                />
              </GridItem>
            </DependentFields>

            <DependentFields name="loanProductType" values={[groundUp]}>
              <GridItem size={12}>
                <RadioFieldTrueFalse
                  isRequired
                  name={"borrowerIsRequestingFullConstructionBudget"}
                  items={["Yes", "No"]}
                  fieldLabel="Is borrower requesting to finance 100% of the construction budget?"
                />
              </GridItem>
            </DependentFields>

            {/* --------Begin Branching  */}

            <DependentFields name="loanProductType" values={[fix]}>
              <GridItem size={12}>
                <RadioField
                  isRequired
                  name={"doesPlanToFinanceFullRenovationCost"}
                  items={yesNo}
                  fieldLabel="Do you plan to finance 100% of the renovation budget?"
                />
              </GridItem>
            </DependentFields>

            <DependentFields name="loanProductType" values={[fix]}>
              <GridItem size={12}>
                <RadioField
                  isRequired
                  name="plansForPropertyWillResultInChangeOfUse"
                  fieldLabel="Will plans for the property result in a change of use for the property (Change in number of units or conversion from one property type to another such as office to residential?)"
                  items={yesNo}
                />
              </GridItem>
            </DependentFields>

            <DependentFields name="loanProductType" values={[fix, groundUp]}>
              <GridItem size={12}>
                <RadioField
                  isRequired
                  name={"knownEnviornmentalConditionsDoExist"}
                  items={yesNo}
                  fieldLabel="Are there any known environmental conditions at the property that could impact renovation?"
                />
              </GridItem>
            </DependentFields>

            <DependentFields name="loanProductType" values={[fix, groundUp]}>
              <GridItem size={12}>
                <RadioField
                  isRequired
                  name={"outstandingPermitsOrViolationsDoExist"}
                  items={yesNo}
                  fieldLabel="Are there any outstanding construction permits or building code violations?"
                />
              </GridItem>
            </DependentFields>

            <DependentFields name="loanProductType" values={[groundUp]}>
              <GridItem size={12}>
                <Field
                  name={"purchaseDateOfLand"}
                  fieldLabel="What is the purchase date of the land?"
                  label="Date"
                  validate={VALIDATOR_REQUIRE}
                  component={DatePickerTextFieldComponent}
                />
              </GridItem>
            </DependentFields>

            <DependentFields name="loanProductType" values={[groundUp]}>
              <GridItem size={12}>
                <RadioField
                  isRequired
                  name={"propertyLandIsVacant"}
                  items={yesNo}
                  fieldLabel="Is the property vacant land?"
                />
              </GridItem>
            </DependentFields>

            <DependentFields name="loanProductType" values={[groundUp]}>
              <GridItem size={12}>
                <RadioField
                  isRequired
                  name={"borrowerIsContractingGeneralContractor"}
                  items={yesNo}
                  fieldLabel="Will borrower be contracting a General Contractor for the project?"
                />
              </GridItem>
            </DependentFields>

            {values.borrowerIsContractingGeneralContractor === "Yes" && (
              <GridItem size={12}>
                <RadioField
                  isRequired
                  name={"contractIsFinalized"}
                  items={yesNo}
                  fieldLabel="Has contract been finalized?"
                />
              </GridItem>
            )}
            <DependentFields name="loanProductType" values={[groundUp]}>
              <GridItem size={12}>
                <RadioField
                  isRequired
                  name={"projectConstructionIsStarted"}
                  items={yesNo}
                  fieldLabel="Has any construction for the project been started?"
                />
              </GridItem>
            </DependentFields>

            <DependentFields name="loanProductType" values={[fix, groundUp]}>
              <DependentFields name="loanPurpose" value="Purchase" notEqual>
                <GridItem size={12}>
                  <FormGroup label="What is the current outstanding principal balance of the existing loan?">
                    <FinalFormTextField
                      name={"valueOfOutstandingPrincipalBalanceOnExistingLoan"}
                      // fieldLabel="What is the current outstanding principal balance of the existing loan?"
                      label="Remaining Balance"
                      required
                      // validate={VALIDATOR_REQUIRE}
                      // format={formFormatDollar}
                      dollar
                      // component={TextFieldComponent}
                    />
                  </FormGroup>
                </GridItem>
                {/* FOR REFINANCE - Optional if valueOfOutstandingPrincipalBalanceOnExistingLoan = 0  */}
                {values.valueOfOutstandingPrincipalBalanceOnExistingLoan !== "0" && (
                  <>
                    <GridItem size={12}>
                      <RadioFieldTrueFalse
                        isRequired
                        name={"hasUnfinishedRenovationsPriorToFinancing"}
                        fieldLabel="Is there unfinished renovations from prior financing?"
                        items={yesNo}
                      />
                    </GridItem>

                    {/* FOR REFINANCE - Optional if valueOfOutstandingPrincipalBalanceOnExistingLoan = 0  */}
                    <GridItem size={12}>
                      <RadioFieldTrueFalse
                        name={"allRenovationConstructionFundsWereDrawn"}
                        fieldLabel="Were all renovation and/or construction funds drawn?"
                        items={["Yes", "No"]}
                      />
                    </GridItem>

                    {/* FOR REFINANCE - Optional if valueOfOutstandingPrincipalBalanceOnExistingLoan = 0  */}
                    <GridItem size={12}>
                      <Field
                        name={"nameOfCurrentLender"}
                        fieldLabel="What is the name of the current lender?"
                        label="Current Lender Name"
                        validate={
                          values.valueOfOutstandingPrincipalBalanceOnExistingLoan === "0"
                            ? undefined
                            : VALIDATOR_REQUIRE
                        }
                        component={TextFieldComponent}
                      />
                    </GridItem>
                  </>
                )}

                <GridItem size={12}>
                  <Field
                    name={"maturityDateOfCurrentLoan"}
                    fieldLabel="What is the maturity of the current loan?"
                    label="Date"
                    validate={VALIDATOR_REQUIRE}
                    component={DatePickerTextFieldComponent}
                  />
                </GridItem>
              </DependentFields>
            </DependentFields>

            {/* <DependentFields name="loanProductType" values={[fix]}>
              <GridItem size={12}>
                <FileUploadField
                  name={"rehabPlansDocs"}
                  fieldLabel="Upload rehab budget, plans, permits (optional)"
                  docGroup={fileDocumentSchema.constructionDocuments.name}
                  docType={fileDocumentSchema.constructionDocuments.types.plans.name}
                />
              </GridItem>
            </DependentFields> */}
          </MuiGrid>
        </FormGroup>
        <DependentFields name="loanProductType" values={[fix]}>
          <FormGroup label="Upload rehab budget, plans, permits (optional)">
            <MuiGrid>
              <GridItem mobile size={12}>
                <FinalFormFileUpload
                  isRequired
                  name={"rehabPlansDocs"}
                  // helperText="Please ensure form of identification is recent and valid"
                  docGroup={fileDocumentSchema.constructionDocuments.name}
                  docType={fileDocumentSchema.constructionDocuments.types.plans.name}
                />
              </GridItem>
            </MuiGrid>
          </FormGroup>
          {/* <GridItem size={12}>
            <FileUploadField
              name={"rehabPlansDocs"}
              fieldLabel="Upload rehab budget, plans, permits (optional)"
              docGroup={fileDocumentSchema.constructionDocuments.name}
              docType={fileDocumentSchema.constructionDocuments.types.plans.name}
            />
          </GridItem> */}
        </DependentFields>
      </DependentFields>
    </>
  );
};

export default BorrowerLoanAppLoan;
