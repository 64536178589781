import { Box, Card, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

import IronFundLogo from "./IronFundLogo";
import SuccessContent from "./SuccessContent";

const FormCard = ({ title, description, children, isSuccess }) => {
  return (
    <>
      <Card
        sx={{
          padding: "14px",
          maxWidth: "520px",
          width: "100%",
          borderRadius: "8px",
        }}
      >
        <Stack alignItems={"center"}>
          <IronFundLogo width={"135px"} />

          <Typography
            variant="h1"
            sx={{
              color: "var(--primary)",
              fontWeight: "800",
              fontSize: "2rem",
              lineHeight: "2.5rem",
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              color: "#707070",
              fontSize: " .9rem",
              marginTop: "5px",
              // lineHeight: " 24px",
            }}
          >
            {description}
          </Typography>
          <Box sx={{ width: "100%", paddingTop: "30px" }}>{isSuccess ? <SuccessContent /> : children}</Box>
        </Stack>
      </Card>
      {/* {old} */}
    </>
  );
};

export default FormCard;
