import { CancelRounded, DownloadRounded, EditRounded, RemoveRedEyeOutlined, SaveRounded } from "@mui/icons-material";
import { Box, IconButton, Select, TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FlexBox from "../../../../../../../components/shared/FlexBox";
import LoadingIconButton from "../../../../../../../components/shared/LoadingIconButton";
import MuiEditableTableCell from "../../../../../../../components/shared/MuiEditableTable/MuiEditableTableCell";
import { checkFileStorageNameType } from "../../../../../../../helpers/checkFileStorageNameType";
import { checkFilenameType } from "../../../../../../../helpers/checkFilenameType";
import { getFileExtensionFromString } from "../../../../../../../helpers/getFileExtensionFromString";
import { removeFileExtensionFromString } from "../../../../../../../helpers/removeFileExtensionFromString";
import { removeSpacesAfterLastWord } from "../../../../../../../helpers/removeSpacesAfterLastWord";
import { useBorrowerHook } from "../../../../../../../hooks/useBorrowerHook";
import { updateFormData } from "../../../../../../../redux/actions/formDataActions";
import ScenarioFileButton from "../../../../../../../components/shared/ScenarioFileButton";
import { getDocumentGroupName } from "../../../../../../../helpers/getDocumentGroupName";
import { isNil } from "lodash";
import { scenarioFilesDocGroups } from "../shared/scenarioFilesDocGroups";
import MuiSelectField from "../../../../../../../components/shared/MuiSelectField";

const editingTableCellStyles = {
  padding: "6px 6px",
  whiteSpace: "nowrap",
  ".MuiIconButton-root": {
    padding: "3px",
    ".MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  },
};

const defaultTableCellStyles = {
  whiteSpace: "nowrap",
  ".MuiIconButton-root": {
    padding: "3px",
    ".MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  },
};

const iconStyles = {
  color: "var(--primary)",
  fontSize: ".9rem !important",
};

const MuiCustomTableCellEditableScenarioFilename = ({ document, fileDisplayName }) => {
  const initFileDisplayName = document?.file_display_name;
  console.log("document", document);
  const fileExtension = getFileExtensionFromString(document?.file_storage_name);
  const [fileDisplayNameInput, setFileDisplayNameInput] = useState(fileDisplayName);
  const [fileDocGroup, setFileDocGroup] = useState(document?.docGroup);
  const [isEditing, setIsEditing] = useState(false);

  const { postIsLoading, updateScenarioFile } = useBorrowerHook();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.formData);

  //Functions
  const handleOnCancelEdit = () => {
    setFileDisplayNameInput(initFileDisplayName);
    setIsEditing(false);
  };

  const handleOnSubmitUpdate = (state) => {
    console.log("fileDocGroup", fileDocGroup);
    const stringWithExtRemoved = removeFileExtensionFromString(removeSpacesAfterLastWord(fileDisplayNameInput));
    const formattedNewName = `${stringWithExtRemoved}.${fileExtension}`;

    let uploadState = {
      file_display_name: formattedNewName,
      docGroup: fileDocGroup,
    };

    const params = {
      name: "Update Name",
      scenarioId: formData?._id,
      fileId: document?._id,
      payload: uploadState,
      onSuccessFn: (passedData) => {
        console.log("passedData", passedData);
        dispatch(updateFormData({ key: "dealDocuments", value: passedData.documents }));
        setIsEditing(false);
      },
    };

    // console.log("params", params);
    updateScenarioFile(params);
  };

  if (isEditing) {
    return (
      <>
        <MuiEditableTableCell sx={editingTableCellStyles}>
          <FilenameTextfield value={fileDisplayNameInput} onChange={(v) => setFileDisplayNameInput(v)} />
        </MuiEditableTableCell>
        <MuiEditableTableCell sx={editingTableCellStyles}>
          <FlexBox alignCenter>
            <DocGroupSelectField value={fileDocGroup} onChange={(v) => setFileDocGroup(v)} />
            <Tooltip title="Cancel Edit" arrow>
              <IconButton onClick={handleOnCancelEdit}>
                <CancelRounded />
              </IconButton>
            </Tooltip>
            <Tooltip title="Save Changes" arrow>
              <Box>
                <LoadingIconButton
                  sx={{ color: "var(--primary)" }}
                  isLoading={postIsLoading}
                  onClick={handleOnSubmitUpdate}
                  icon={<SaveRounded />}
                />
              </Box>
            </Tooltip>
          </FlexBox>
        </MuiEditableTableCell>
      </>
    );
  }

  //Final Return
  return (
    <>
      <MuiEditableTableCell sx={defaultTableCellStyles}>
        <PreviewIconButton document={document} iconStyles={iconStyles} />
        <DownloadIconButton document={document} iconStyles={iconStyles} />
        <EditIconButton setIsEditing={setIsEditing} iconStyles={iconStyles} />
        <ScenarioFileButton
          label={fileDisplayName}
          fileStorageName={document?.file_storage_name}
          scenarioId={formData?._id}
        />
      </MuiEditableTableCell>
      <MuiEditableTableCell sx={defaultTableCellStyles}>
        {isNil(document?.docGroup) ? "Unknown" : getDocumentGroupName(document?.docGroup)}
        {/* {isNil(document?.docGroup) ? "Unknown" : getDocumentGroupName(document?.docGroup)} */}
      </MuiEditableTableCell>
    </>
  );
  // return (
  //   <>
  //     <MuiEditableTableCell
  //       sx={{
  //         whiteSpace: "nowrap",
  //         ".MuiIconButton-root": {
  //           padding: "3px",
  //           ".MuiSvgIcon-root": {
  //             fontSize: "1rem",
  //           },
  //         },
  //       }}
  //     >
  //       {isEditing ? isEditingContent : defaultContent}
  //     </MuiEditableTableCell>
  //     <MuiEditableTableCell>
  //       {isNil(document?.docGroup) ? "Unknown" : getDocumentGroupName(document?.docGroup)}
  //     </MuiEditableTableCell>
  //   </>
  // );
};

//COMPONENTS
const PreviewIconButton = ({ document, iconStyles }) => {
  const fileStorageName = document?.file_storage_name;
  const { getIsLoading, previewScenarioFile } = useBorrowerHook();
  const formData = useSelector((state) => state.formData);

  const handlePreviewFile = async () => {
    const params = {
      fileStorageName: fileStorageName,
      scenarioId: formData._id,
    };

    previewScenarioFile(params);
  };
  return (
    <Tooltip title="Preview" arrow>
      <LoadingIconButton
        isLoading={getIsLoading}
        onClick={handlePreviewFile}
        icon={<RemoveRedEyeOutlined sx={iconStyles} />}
      />
    </Tooltip>
  );
};

const DownloadIconButton = ({ document, iconStyles }) => {
  const { getIsLoading, previewScenarioFile } = useBorrowerHook();
  const formData = useSelector((state) => state.formData);

  const handleDownloadFile = async () => {
    const params = {
      fileStorageName: checkFileStorageNameType(document),
      scenarioId: formData._id,
      downloadName: checkFilenameType(document),
    };

    previewScenarioFile(params);
  };
  return (
    <Tooltip title="Download" arrow>
      <LoadingIconButton
        isLoading={getIsLoading}
        onClick={handleDownloadFile}
        icon={<DownloadRounded sx={iconStyles} />}
      />
    </Tooltip>
  );
};

const EditIconButton = ({ setIsEditing, iconStyles }) => {
  return (
    <Tooltip title="Edit" arrow>
      <LoadingIconButton
        onClick={() => setIsEditing(true)}
        icon={<EditRounded sx={{ marginRight: "5px", ...iconStyles }} />}
      />
    </Tooltip>
  );
};

const FilenameTextfield = ({ value, onChange }) => {
  return (
    <TextField
      size="small"
      value={value}
      variant="standard"
      fullWidth={true}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
};
const DocGroupSelectField = ({ value, onChange }) => {
  return (
    <MuiSelectField
      size="small"
      value={value}
      variant="standard"
      fullWidth={true}
      items={scenarioFilesDocGroups}
      onChange={(e) => {
        console.log("e.target.value: ", e.target.value);
        onChange(e.target.value);
      }}
    />
  );
};
export default MuiCustomTableCellEditableScenarioFilename;
