import React from "react";

import { CloseRounded } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";

import SuccessContent from "../SuccessContent";
import { useSelector } from "react-redux";

const MuiDialogModal = ({
  title,
  show,
  onClose,
  dividers,
  paperWidth,
  maxWidth,
  headerAction,
  padding,
  isSuccess,
  successMessage,
  centerTitle,
  children,
  dialogSx,
}) => {
  const modalHeaderStyles = {
    // fontWeight: "bold !important",
    borderBottom: "1px solid rgba(128, 128, 128, 0.155)",
    padding: "24px 24px 22px",
    color: "var(--primary)",
    paddingBottom: " 14px !important",
  };

  const isMobile = useSelector((state) => state.globalData?.isMobile);
  const titleSuccessMessage = successMessage ? successMessage : "Submission Successful!";
  return (
    <Dialog
      id="mui-dialog-modal"
      className="mui-dialog"
      sx={{
        "& .MuiDialog-paper": {
          margin: isMobile ? "4px" : "initial",
          width: paperWidth ? paperWidth : maxWidth === "lg" ? "680px" : "80%",
        },
        ...dialogSx,
      }}
      maxWidth={maxWidth && !isSuccess ? maxWidth : "xs"}
      open={show}
    >
      <Box
        sx={modalHeaderStyles}
        display={"flex"}
        className="mui-dialog-title"
        justifyContent={centerTitle || isSuccess ? "center" : "space-between"}
      >
        {isSuccess ? (
          titleSuccessMessage
        ) : (
          <Typography sx={{ fontWeight: 600 }} variant="h1">
            {title}
          </Typography>
        )}
        {headerAction ? headerAction : null}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseRounded sx={{ width: "1.5rem", height: "1.5rem" }} />
        </IconButton>
      </Box>
      {/* <DialogTitle
        sx={modalHeaderStyles}
        display={"flex"}
        className="mui-dialog-title"
        justifyContent={centerTitle || isSuccess ? "center" : "space-between"}
      >
        {isSuccess ? titleSuccessMessage : title}
        {headerAction ? headerAction : null}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseRounded sx={{ width: "1.5rem", height: "1.5rem" }} />
        </IconButton>
      </DialogTitle> */}
      <DialogContent sx={{ paddingTop: padding ? "14px !important" : "0px" }} dividers={dividers ? dividers : false}>
        {isSuccess ? <SuccessContent /> : <div className="dialog-body">{children}</div>}
      </DialogContent>
    </Dialog>
  );
};

export default MuiDialogModal;
