import { Button, Typography } from "@mui/material";
import MuiChip from "../../components/shared/MuiChip";
import { parseISOToDate } from "../../helpers/parseISOToDate";
import { formFormatDollar } from "../../helpers/parse/formFormatDollar";
import { ChevronRightRounded } from "@mui/icons-material";

export const loanScenariosTableColumnsMobile = (onClick) => {
  return [
    {
      Header: "Name",
      accessor: (d) =>
        d.isDuplicate ? (
          <Typography position={"relative"} fontSize={".7rem"}>
            {d?.loanSubjectName}{" "}
            <MuiChip
              chipSx={{
                height: "unset",
                span: {
                  padding: "0 4px",
                },
              }}
              label={"Duplicate"}
            />
          </Typography>
        ) : (
          d?.loanSubjectName
        ),
    },

    {
      Header: "Loan Product Type",
      accessor: "loanProductType",
    },
    {
      Header: "Loan Purpose",
      accessor: "loanPurpose",
    },

    {
      Header: " ",
      accessor: (d) => (
        <Button
          size="small"
          onClick={() => onClick(d?._id)}
          endIcon={<ChevronRightRounded sx={{ marginLeft: "-8px" }} />}
          variant="contained"
          sx={{ marginLeft: "10px", margin: "4px 10px", padding: "2px 4px", fontWeight: 500, fontSize: ".7rem" }}
        >
          View
        </Button>
      ),
    },
  ];
};
