import { Box, Card } from "@mui/material";
import { isArray } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragAndDropFileUpload } from "../../../../../../../../components/shared/Files/DragAndDropFileUpload";
import { useFilesHook } from "../../../../../../../../hooks/useFilesHook";
import { updateFormData } from "../../../../../../../../redux/actions/formDataActions";
import LoanFileUploadTable from "./LoanFileUploadTable";
import { useBorrowerHook } from "../../../../../../../../hooks/useBorrowerHook";

const LoanFileUpload = ({ docGroup, index }) => {
  //State
  const [files, setFiles] = useState([]);
  const filesAreEmpty = !isArray(files) || files?.length < 1 ? true : false;

  //Redux
  // const { filesError, filesPostIsLoading, uploadFiles } = useFilesHook();
  const { error, postIsLoading, uploadScenarioFiles } = useBorrowerHook();
  const formData = useSelector((state) => state.formData);
  const dispatch = useDispatch();

  //Functions
  /*Adds docs to state*/
  const handleAddFiles = (addedFiles) => {
    let files_spread = [...files];
    let updatedFiles = [...files_spread, ...addedFiles];

    setFiles(updatedFiles);
  };

  /*Updates doc group & type*/
  const handleUpdateFile = ({ index, row, updateKey, updateValue }) => {
    let files_spread = [...files];
    files_spread[index][updateKey] = updateValue;

    setFiles(files_spread);
  };

  /*Deletes doc from table/state*/
  const handleRemoveFile = (id) => {
    let files_spread = [...files];
    const filteredFiles = files_spread.filter((file) => file.id !== id);
    setFiles(filteredFiles);
  };

  /*Sends POST to UPLOAD_DOCUMENT { documents{...} }*/
  const handleUploadDocuments = async (state) => {
    console.log("SUBMITTING FILES", files);

    const onSuccess = (responseData) => {
      console.log("SUCCESS FILE UPLOAD", responseData);
      const newDocuments = responseData?.documents;
      // const newDocuments = [...formData.documents, ...responseData.items]
      setFiles([]);
      dispatch(
        updateFormData({
          key: "dealDocuments",
          value: newDocuments,
        })
      );
    };
    let uploadState = {
      documents: files,
    };
    const params = {
      id: formData?._id,
      name: "Upload Deal Files",
      payload: uploadState,
      onSuccessFn: onSuccess,
      // onFailFn: onFail,
    };

    console.log("Upload Deal Files params", params);
    uploadScenarioFiles(params);
  };
  return (
    <>
      <DragAndDropFileUpload
        height={files?.length >= 1 ? "50px" : "100px"}
        onUpload={handleAddFiles}
        isLoading={postIsLoading}
        docGroup={docGroup}
      />

      <Box sx={{ position: "relative" }}>
        <LoanFileUploadTable
          files={files}
          onClick={handleUploadDocuments}
          onChange={handleUpdateFile}
          onRemove={handleRemoveFile}
          onCancel={() => setFiles([])}
          error={error}
          index={index}
          filesAreEmpty={filesAreEmpty}
          isLoading={postIsLoading}
        />
      </Box>
    </>
  );
};

export default LoanFileUpload;
