import { isNil } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { scenarioStatusOptions } from "../../forms/_formQuestions/selectOptions";
import { useBorrowerHook } from "../../hooks/useBorrowerHook";
import { useBrokerDrilldownHook } from "../../hooks/useBrokerDrilldownHook";
import { setFormData } from "../../redux/actions/formDataActions";
import FinalFormSelectField from "../form/Fields/FinalFormSelectField";
import ReactFinalForm from "../form/ReactFinalForm";
import MuiModal from "../shared/MuiModal";

const ChangeScenarioStatusModal = ({ show, onCancel, scenario }) => {
  const { postIsLoading, updateBrokerScenario } = useBorrowerHook();
  const dispatch = useDispatch();

  const handleOnScenarioUpdate = (data) => {
    const onRequestSuccess = (passedData) => {
      console.log("onrequest success", passedData);
      dispatch(setFormData(passedData));
      onCancel();
      // setShow(false);
    };

    const params = {
      payload: data,
      id: scenario?._id,
      onSuccessFn: onRequestSuccess,
    };

    updateBrokerScenario(params);
  };

  const formInitialValues = isNil(scenario?.scenarioStatus) ? { ...scenario, scenarioStatus: "New" } : scenario;
  return (
    <MuiModal
      // description={"Example"}
      title={"Change Scenario Status"}
      size={"sm"}
      show={show}
      onClose={onCancel}
      disableBackgroundCancel // optional
      // success={isSuccess} // optional
    >
      <ReactFinalForm
        // hideButton
        isLoading={postIsLoading}
        onSubmit={handleOnScenarioUpdate}
        initialValues={formInitialValues}
        submitButtonText={"Update Status"}
        // example
        // formSpy
      >
        <FormContent />
      </ReactFinalForm>
    </MuiModal>
  );
};

const FormContent = () => {
  const { isInternalEmployee } = useBrokerDrilldownHook();
  return (
    <FinalFormSelectField
      label={"Status"}
      name={`scenarioStatus`}
      disabled={!isInternalEmployee}
      items={scenarioStatusOptions}
    />
  );
};

export default ChangeScenarioStatusModal;
