import React from "react";
import { CardContent, Stack, Typography } from "@mui/material";

import BorderCard from "../../../../../components/shared/BorderCard";
import FlexBox from "../../../../../components/shared/FlexBox";

const ProfileScreenCard = ({ title, children }) => {
  return (
    // <BorderCard sx={{ padding: "8px" }}>
    <Stack sx={{ width: "100%" }}>
      <CardContent sx={{ position: "relative" }}>
        <Typography sx={{ position: "relative" }} variant="h2" color={"primary"}>
          {title}
        </Typography>
      </CardContent>

      <CardContent>
        <FlexBox column gap={"14px"}>
          {children}
        </FlexBox>
      </CardContent>
    </Stack>
    // </BorderCard>
  );
};

export default ProfileScreenCard;
