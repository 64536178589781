import { Box } from "@mui/material";
import React, { useMemo } from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../styles/AgThemeQuartz.css";
import { isNil } from "lodash";

const AgThemeQuartz = ({ children, height, classNameRoot, classNameInner, sx, innerSx, size = 5 }) => {
  const classNameRootValue = useMemo(() => (isNil(classNameRoot) ? "" : classNameRoot), []);
  const classNameInnerValue = useMemo(() => (isNil(classNameInner) ? "" : classNameInner), []);
  const tableSizeValue = useMemo(() => (isNil(size) ? "" : `grid-size-${size}`), [size]);
  return (
    <Box className={`ag-theme-quartz-root ${classNameRootValue}`} sx={{ width: "100%", height: "100%", ...sx }}>
      <Box
        className={`ag-theme-quartz ${classNameInnerValue} ${tableSizeValue}`}
        sx={{ width: "100%", height: height ? height : "100%", ...innerSx }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AgThemeQuartz;
