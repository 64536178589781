import { isArray } from "lodash";

export const concatenateArrayOfStrings = (array, prefix = "", suffix = "") => {
  if (!isArray(array)) {
    return "";
  }
  const modifiedArray = array.map((item) => prefix + item + suffix);
  const concatenatedString = modifiedArray.join(", ");
  return concatenatedString;
};
