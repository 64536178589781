import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import React, { useEffect } from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
// import { object  } from "lodash";
import { buttonGhost } from "../../../assets/styles/SharedInlineStyles";
import { TextFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import FormGroup from "../../../components/form/components/shared/FormGroup/FormGroup";
import FinalFormSelectField from "../../../components/form/Fields/FinalFormSelectField";

import FinalFormTextField from "../../../components/form/Fields/FinalFormTextField";
import MuiGrid from "../../../components/shared/MuiGrid";
import GridItem from "../../../components/shared/MuiGrid/GridItem";
import { fieldNames } from "../../../global/forms/fieldNames";
import { parsePhoneNumber } from "../../../helpers/parse/parsePhoneNumber";
import { citizenshipOptions, yesNo } from "../../_formQuestions/selectOptions";
import MultipleItemCard from "../../components/MultipleItemCard";
import { getObjectValueFromStringPath } from "../../../helpers/getObjectValueFromStringPath";

const CreateScenarioStepBorrowers = ({ display }) => {
  const { values, errors, form } = useFormState();
  const { mutators } = useForm();

  // const additionalName = `${props.name}Additional`;

  const addAdditionalFieldHandler = () => {
    mutators.push("borrowers", {});
  };

  useEffect(() => {
    if (values.borrowers === null || values.borrowers === undefined) {
      addAdditionalFieldHandler();
    }
  }, []);

  const checkIfYes = (name) => {
    return getObjectValueFromStringPath(values, name) === "Yes" ? true : false;
  };
  return (
    <>
      <FieldArray name={`${fieldNames.borrowers}`}>
        {({ fields }) =>
          fields.map((name, index) => (
            <MultipleItemCard
              key={name}
              title={"Borrower"}
              onRemove={() => fields.remove(index)}
              index={index}
              indexTitle={"Borrower"}
              unitsPathName={`${fieldNames.borrowers}`}
            >
              <FormGroup noMargin fieldLabel={"Contact Information"}>
                <MuiGrid spacing={display ? 7 : 1}>
                  <GridItem mobile size={4}>
                    <FinalFormTextField display={display} label="First Name" name={`${name}.${fieldNames.firstName}`} />
                  </GridItem>
                  <GridItem mobile size={4}>
                    <FinalFormTextField
                      display={display}
                      label="Middle Name"
                      placeholder="Middle"
                      name={`${name}.${fieldNames.middleName}`}
                    />
                  </GridItem>
                  <GridItem mobile size={4}>
                    <FinalFormTextField display={display} label="Last Name" name={`${name}.${fieldNames.lastName}`} />
                  </GridItem>
                  <GridItem mobile size={6}>
                    <FinalFormTextField
                      display={display}
                      label="Email Address"
                      // validate={VALIDATOR_EMAIL}
                      name={`${name}.${fieldNames.emailAddress}`}
                    />
                  </GridItem>
                  <GridItem mobile size={6}>
                    <Field
                      display={display}
                      name={`${name}.${fieldNames.phoneNumber}`}
                      parse={parsePhoneNumber}
                      label={`Phone Number`}
                      component={TextFieldComponent}
                    />
                  </GridItem>
                </MuiGrid>
              </FormGroup>
              <FormGroup fieldLabel={"Background"}>
                <MuiGrid spacing={display ? 7 : 1}>
                  <GridItem mobile size={12}>
                    <FinalFormSelectField
                      label="Citizenship Status"
                      name={`${name}.citizenshipStatus`}
                      items={citizenshipOptions}
                    />
                  </GridItem>
                  <GridItem mobile size={6}>
                    <FinalFormTextField
                      display={display}
                      label="FICO Mid Score"
                      required
                      number
                      name={`${name}.${fieldNames.ficoMidScore}`}
                    />
                  </GridItem>
                  <GridItem mobile size={6}>
                    <FinalFormTextField
                      display={display}
                      dollar
                      required
                      name={`${name}.cashLiquidity`}
                      label="Cash Liquidity"
                    />
                  </GridItem>
                </MuiGrid>
              </FormGroup>

              {/* --- */}
              <FormGroup label="Income Producing Properites">
                <MuiGrid>
                  {/* /1/ */}
                  <GridItem
                    mobile
                    size={
                      getObjectValueFromStringPath(values, `${name}.hasExperienceWithIncomeProducingProperties`) ===
                      "Yes"
                        ? 6
                        : 12
                    }
                  >
                    <FinalFormSelectField
                      noMargin
                      name={`${name}.hasExperienceWithIncomeProducingProperties`}
                      label="Does borrower have experience?"
                      items={yesNo}
                    />
                  </GridItem>
                  {getObjectValueFromStringPath(values, `${name}.hasExperienceWithIncomeProducingProperties`) ===
                    "Yes" && (
                    <>
                      <GridItem mobile size={6}>
                        <FinalFormTextField
                          name={`${name}.numOfIncomeProducingPropertiesWithinTwoYears`}
                          label="How many in the last two years?"
                          number
                        />
                      </GridItem>
                    </>
                  )}
                </MuiGrid>
              </FormGroup>
              <FormGroup label="Fix And Flip Properties">
                <MuiGrid>
                  <GridItem
                    mobile
                    size={getObjectValueFromStringPath(values, `${name}.hasExperienceWithFixFlips`) === "Yes" ? 6 : 12}
                  >
                    <FinalFormSelectField
                      name={`${name}.hasExperienceWithFixFlips`}
                      label="Does borrower have experience?"
                      items={yesNo}
                    />
                  </GridItem>
                  {getObjectValueFromStringPath(values, `${name}.hasExperienceWithFixFlips`) === "Yes" && (
                    <>
                      <GridItem mobile size={6}>
                        <FinalFormTextField
                          name={`${name}.numOfFixFlipsWithinTwoYears`}
                          label="How many in the last two years?"
                          number
                        />
                      </GridItem>
                    </>
                  )}
                </MuiGrid>
              </FormGroup>
              <FormGroup label="Ground Up Construction Projects">
                <MuiGrid>
                  <GridItem
                    mobile
                    size={
                      getObjectValueFromStringPath(values, `${name}.hasExperienceWithGroundUpConstruction`) === "Yes" // `hasExperienceWithGroundUpConstruction`
                        ? 6
                        : 12
                    }
                  >
                    <FinalFormSelectField
                      name={`${name}.hasExperienceWithGroundUpConstruction`}
                      label="Does borrower have experience?"
                      items={yesNo}
                    />
                  </GridItem>

                  {getObjectValueFromStringPath(values, `${name}.hasExperienceWithGroundUpConstruction`) === "Yes" && (
                    <>
                      <GridItem mobile size={6}>
                        <FinalFormTextField
                          name={`${name}.numOfGroundUpConstructionProjectsWithinTwoYears`}
                          label="How many in the last two years?"
                          number
                        />
                      </GridItem>
                    </>
                  )}
                </MuiGrid>
              </FormGroup>
              {/* <FormGroup fieldLabel={"Experience"}>
                <MuiGrid>
                  <GridItem mobile size={12}>
                    <FinalFormSelectField
                      display={display}
                      required
                      label="Is Experienced?"
                      name={`${name}.${fieldNames.isExperienced}`}
                      items={yesNo}
                    />
                  </GridItem>
                  <GridItem mobile size={6}>
                    <FinalFormTextField
                      number
                      required
                      label="Total Years Experience"
                      name={`${name}.${fieldNames.totalYearsExperience}`}
                    />
                  </GridItem>
                  <GridItem mobile size={6}>
                    <FinalFormTextField
                      required
                      number
                      label="Income Producing Properties"
                      name={`${name}.${fieldNames.incomeProducingProperties}`}
                    />
                  </GridItem>

                  <GridItem mobile size={6}>
                    <FinalFormTextField
                      number
                      required
                      label="Properties Flipped within 12 months"
                      name={`${name}.flipsWithin12months`}
                    />
                  </GridItem>
                  <GridItem mobile size={6}>
                    <FinalFormTextField
                      number
                      required
                      label="Properties Flipped within 24 months"
                      name={`${name}.flipsWithin24months`}
                    />
                  </GridItem>
                  <GridItem mobile size={6}>
                    <FinalFormTextField
                      number
                      required
                      label="Properties Owned within 24 months"
                      name={`${name}.propertiesOwnedWithin24months`}
                    />
                  </GridItem>

                  <GridItem mobile size={6}>
                    <FinalFormTextField
                      number
                      required
                      label="Number of Ground Up Projects"
                      name={`${name}.${fieldNames.numberOfGroundUpProjects}`}
                    />
                  </GridItem>
                </MuiGrid>
              </FormGroup> */}
            </MultipleItemCard>
          ))
        }
      </FieldArray>
      <div className="add-additional-field-wrapper center">
        <button style={buttonGhost} onClick={addAdditionalFieldHandler} className="add-additional-button" type="button">
          <span>Add Additional Borrower</span> <AddOutlinedIcon />
        </button>
      </div>
    </>
  );
};

export default CreateScenarioStepBorrowers;
