import { Box } from "@mui/material";
import React from "react";
import BorrowerLoginFormCard from "./BorrowerLoginFormCard";

const BorrowerLoginScreen = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
      }}
    >
      <BorrowerLoginFormCard />
    </Box>
  );
};

export default BorrowerLoginScreen;
