import React from "react";

import { Box } from "@mui/system";
import DrilldownCardTabSectionTitle from "./DrilldownCardTabSectionTitle";

const DrilldownCardTabSection = ({ title, bold, children, borderBottom, titleSx, sx }) => {
  const borderBottomStyles = {
    borderBottom: "var(--border)",
    paddingBottom: "30px",
    marginBottom: "15px",
  };

  const sharedStyles = {
    // marginBottom: "30px",
    paddingTop: "15px",
  };
  const conditionalStyles = borderBottom ? borderBottomStyles : null;

  const styles = {
    ...sharedStyles,
    ...conditionalStyles,
    ...sx,
  };

  const titleStyles = {
    fontSize: ".95rem",
    fontWeight: 600,
    color: "var(--primary)",
    marginBottom: "10px",
    ...titleSx,
  };

  const drilldownTitleStyles = bold ? titleStyles : titleSx;

  return (
    <Box sx={styles}>
      {title ? <DrilldownCardTabSectionTitle sx={drilldownTitleStyles}>{title}</DrilldownCardTabSectionTitle> : null}
      {children}
    </Box>
  );
};

export default DrilldownCardTabSection;
