import { get, isNil } from "lodash";

export const getObjectValueFromStringPath = (object, pathName, defaultReturnValue) => {
  const returnVal = isNil(defaultReturnValue) ? undefined : defaultReturnValue;

  let value = get(object, pathName);

  if (isNil(value)) {
    return returnVal;
  }
  return value;
};

export const getObjectValue = (object, pathName, defaultReturnValue) => {
  const returnVal = isNil(defaultReturnValue) ? undefined : defaultReturnValue;

  let value = get(object, pathName);

  if (isNil(value)) {
    return returnVal;
  }
  return value;
};
