import React from "react";
import FinalFormTextField from "../../../../../components/form/Fields/FinalFormTextField";
import ProfileScreenCard from "../components/ProfileScreenCard";
import ProfileScreenCardRow from "../components/ProfileScreenCardRow";

const CompanyCard = ({ profile }) => {
  return (
    <ProfileScreenCard title="Company">
      <ProfileScreenCardRow
        border
        label={"Company Name"}
        name={"company"}
        input={<FinalFormTextField name={"company"} label={"Company Name"} />}
      />
      <ProfileScreenCardRow
        label={"Company Role"}
        name={"companyRole"}
        input={<FinalFormTextField name={"companyRole"} label={"Company Role"} />}
      />
    </ProfileScreenCard>
  );
};

// const CompanyLogoRow = (props) => {
//   const { postIsLoading, updateUserProfileData } = useBorrowerHook();
//   const [show, setShow] = useState(false);
//   const [logo, setLogo] = useState(null);
//   const logoPreview = arrayProtector(logo)[0]?.file?.preview;
//   const handleOnUpload = (file) => {
//     console.log("file", file);
//     setLogo(file);
//     // setLogo(file[0]);
//   };

//   const handleOnCancel = () => {
//     setLogo(null);
//     setShow(false);
//   };
//   const handleOnSubmit = () => {
//     const payloadData = {
//       documents: logo,
//     };
//     const params = {
//       payload: payloadData,
//       formData: true,
//       onSuccessFn: (data) => console.log("Succes", data),
//     };
//     // console.log("params", params);
//     updateUserProfileData(params);
//   };
//   return (
//     <ProfileScreenCardRow name={"Company Logo"} onClick={() => setShow(true)}>
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           gap: "5px",
//         }}
//       >
//         <FlexBox
//           center
//           sx={{
//             width: "100%",
//             maxWidth: "150px",
//             position: "relative",
//             background: "#f1f1f1",
//             // height: "100%",
//             border: "var(--border)",
//             padding: "10px",
//             overflow: "hidden",
//           }}
//         >
//           <Avatar variant="circular" src="avatar1.jpg" sx={{ width: "44px", height: "44px" }} />
//         </FlexBox>

//         <MuiDialog
//           id="create-loan-form-modal"
//           title={"Upload Company Logo"}
//           successMessage={"Loan Scenario Successfully Created!"}
//           show={show}
//           centerTitle
//           // modal
//           disabled={isNil(logo) ? true : false}
//           maxWidth={"lg"}
//           isLoading={postIsLoading}
//           onSubmit={handleOnSubmit}
//           onSaveLabel="Upload Logo"
//           // isSuccess={isSuccess}
//           // paperWidth={"60%"}
//           onClose={handleOnCancel}
//         >
//           <MuiGrid spacing={3}>
//             <GridItem mobile size={isNil(logo) ? 12 : 6}>
//               <DragAndDropFileUpload
//                 height={"150px"}
//                 onUpload={handleOnUpload}
//                 // isLoading={postIsLoading}
//                 // docGroup={docGroup}
//               />
//             </GridItem>
//             <GridItem mobile size={6}>
//               {isNil(logo) ? null : (
//                 <FlexBox
//                   center
//                   sx={{
//                     width: "100%",
//                     position: "relative",
//                     height: "100%",
//                     border: "var(--border)",
//                     padding: "10px",
//                     overflow: "hidden",
//                   }}
//                 >
//                   <img
//                     style={{
//                       position: "absolute",
//                       top: "4px",
//                       left: "4px",
//                       width: "calc(100% - 8px)",
//                       height: "calc(100% - 8px)",
//                       objectFit: "contain",
//                     }}
//                     src={logoPreview}
//                     alt=""
//                   />
//                   <IconButton
//                     color="primary"
//                     sx={{
//                       position: "absolute",
//                       top: "-4px",
//                       right: "-4px",
//                       padding: "4px",
//                       backgroundColor: "#dfe7ed",
//                       zIndex: 1111,
//                     }}
//                     onClick={() => setLogo(null)}
//                   >
//                     <CloseRounded />
//                   </IconButton>
//                 </FlexBox>
//               )}
//             </GridItem>
//           </MuiGrid>
//         </MuiDialog>
//       </Box>
//     </ProfileScreenCardRow>
//   );
// };

export default CompanyCard;
