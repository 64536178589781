import React from "react";
import { isNil } from "lodash";
import { useStytchUser } from "@stytch/react";
import { Field, useFormState } from "react-final-form";

import { capitalizeFirstLetter } from "../../../../../helpers/capitalizeFirstLetter";
import { parseISOToDateTime } from "../../../../../helpers/parseISOToDateTime";

import FlexBox from "../../../../../components/shared/FlexBox";
import FinalFormTextField from "../../../../../components/form/Fields/FinalFormTextField";
import ProfileScreenCard from "../components/ProfileScreenCard";
import ProfileScreenCardRow from "../components/ProfileScreenCardRow";
import { usaStateInitials } from "../../../../../forms/_formQuestions/selectOptions";
import { MultiSelectFieldComponent } from "../../../../../components/form/components/inputs/finalFormComponents/MultiSelectFieldComponent";
import { concatenateArrayOfStrings } from "../../../../../helpers/concatenateArrayOfStrings";
import { getObjectValue } from "../../../../../helpers/getObjectValueFromStringPath";

const BasicInfoCard = () => {
  const { user } = useStytchUser();
  const stytchProfile = user?.trusted_metadata;
  const { values } = useFormState();

  return (
    <ProfileScreenCard title="Account & Basic Info">
      <ProfileScreenCardRow
        border
        label={"Name"}
        value={`${values?.firstName} ${isNil(values?.middleName) ? "" : values?.middleName} ${values?.lastName}`}
        input={
          <FlexBox alignCenter gap="10px">
            <FinalFormTextField name={"firstName"} label={"First Name"} />
            <FinalFormTextField name={"middleName"} label={"Middle Name"} />
            <FinalFormTextField name={"lastName"} label={"Last Name"} />
          </FlexBox>
        }
      />
      <ProfileScreenCardRow border label={"Email Address"} name={"emailAddress"} value={stytchProfile?.emailAddress} />
      <ProfileScreenCardRow
        border
        label={"Phone Number"}
        name={"phoneNumber"}
        input={<FinalFormTextField phone name={"phoneNumber"} label={"Phone Number"} />}
      />
      <ProfileScreenCardRow
        border
        label={"What states are you currently active in?"}
        name={"activeStates"}
        customDisplayValue={concatenateArrayOfStrings(getObjectValue(values, "activeStates"))}
        input={
          <Field
            name={"activeStates"}
            verticalOptions
            multiple
            label="What states are you currently active in?"
            items={usaStateInitials}
            component={MultiSelectFieldComponent}
          />
        }
      />
      <ProfileScreenCardRow
        border
        name={"userCategory"}
        label={"User Role"}
        value={capitalizeFirstLetter(stytchProfile?.userCategory)}
      />
      <ProfileScreenCardRow
        label={"Account Creation Date"}
        name="user_created_date"
        value={parseISOToDateTime(stytchProfile?.user_created_date)}
      />
    </ProfileScreenCard>
  );
};

export default BasicInfoCard;
