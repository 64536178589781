import { Box, Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useSelector } from "react-redux";
import DrilldownMultiItemCard from "../../../../../components/Drilldown/DrilldownMultiItemCard";
import FinalFormSelectField from "../../../../../components/form/Fields/FinalFormSelectField";
import FinalFormTextField from "../../../../../components/form/Fields/FinalFormTextField";
import MuiGrid from "../../../../../components/shared/MuiGrid";
import GridItem from "../../../../../components/shared/MuiGrid/GridItem";
import {
  stateOfInteriorAccessOptions,
  subjectPropertyTypeOptions,
  yesNo,
} from "../../../../../forms/_formQuestions/selectOptions";
import { getObjectValueFromStringPath } from "../../../../../helpers/getObjectValueFromStringPath";

import {
  AddBusinessRounded,
  AddRounded,
  CloseRounded,
  HouseRounded,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import DrilldownCardTabSection from "../../../../../components/Drilldown/DrilldownCardTabSection";
import { DatePickerTextFieldComponent } from "../../../../../components/form/components/inputs/finalFormComponents/DatePickerTextFieldComponent";

import { isNil } from "lodash";
import { useEffect } from "react";
import FlexBox from "../../../../../components/shared/FlexBox";
import MuiEditableTable from "../../../../../components/shared/MuiEditableTable/MuiEditableTable";
import MuiEditableTableCell from "../../../../../components/shared/MuiEditableTable/MuiEditableTableCell";
import MuiEditableTableRow from "../../../../../components/shared/MuiEditableTable/MuiEditableTableRow";
import { arrayProtector } from "../../../../../helpers/arrayProtector";
import { formatAsDollarString } from "../../../../../helpers/formatAsDollarString";
import { formatAsNumberString } from "../../../../../helpers/formatAsNumberString";
import EditableDrilldownTabCard from "../components/EditableDrilldownTabCard";
import FinalFormFieldTotalBudget from "../specialtyInputs/FinalFormFieldTotalBudget";
// import DrilldownCardTabSection from "../../../../../components/form/components/shared/DrilldownCardTabSection/DrilldownCardTabSection";

const ScenarioDrilldownTabProperties = (props) => {
  const formData = useSelector((state) => state.formData);

  const { mutators } = useForm();

  const handleAddNewSubjectProperty = () => {
    mutators.push("subjectProperties", {});
  };

  return (
    <EditableDrilldownTabCard
      cardHeader
      title={"Properties"}
      onClickText={"Add New Property"}
      onClickIcon={<AddBusinessRounded />}
      onClick={handleAddNewSubjectProperty}
    >
      <Stack spacing={2} sx={{ paddingBottom: "50px" }}>
        <FieldArray name={"subjectProperties"}>
          {({ fields }) => fields.map((name, index) => <PropertyCard name={name} index={index} />)}
        </FieldArray>
      </Stack>
    </EditableDrilldownTabCard>
  );
};

const PropertyCard = ({ index, name, isDisabled }) => {
  const [isHidden, setIsHidden] = useState(false);
  const formData = useSelector((state) => state.formData);
  const { change } = useForm();
  const { values } = useFormState();
  const includesRehab =
    values?.loanProductType === "Fix and Flip" || values?.loanProductType === "Ground Up Construction" ? true : false;
  const getFullAddressAsString = (index) => {
    const address = `${getObjectValueFromStringPath(
      values,
      `subjectProperties[${index}].address.streetNumber`
    )} ${getObjectValueFromStringPath(
      values,
      `subjectProperties[${index}].address.streetName`
    )}, ${getObjectValueFromStringPath(
      values,
      `subjectProperties[${index}].address.city`
    )} ${getObjectValueFromStringPath(
      values,
      `subjectProperties[${index}].address.state`
    )} ${getObjectValueFromStringPath(values, `subjectProperties[${index}].address.zip`)}`;
    return address;
  };

  useEffect(() => {
    const addressAsString = getFullAddressAsString(index);
    change(`${name}.address.fullAddress`, addressAsString);
  }, [values.subjectProperties[index].address]);

  return (
    <DrilldownMultiItemCard
      cardBodySx={{ paddingBottom: isHidden ? "0px !important" : "24px" }}
      title={
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <HouseRounded sx={{ fontSize: "1.1rem" }} /> {getFullAddressAsString(index)}
        </Box>
      }
      headerContent={
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <Tooltip title={isHidden ? "Show Property Details" : "Hide Property Details"}>
            <ShowHideButton isHidden={isHidden} setIsHidden={setIsHidden} />
          </Tooltip>
        </Box>
      }
    >
      {isHidden ? null : (
        <MuiGrid spacing={5}>
          <GridItem mobile size={12}>
            <DrilldownCardTabSection noMargin title={"Property Address"}>
              <MuiGrid>
                <GridItem mobile size={1.5}>
                  <FinalFormTextField name={`${name}.address.streetNumber`} label="Number" />
                </GridItem>
                <GridItem mobile size={3.5}>
                  <FinalFormTextField name={`${name}.address.streetName`} label="Street" />
                </GridItem>
                <GridItem mobile size={3}>
                  <FinalFormTextField name={`${name}.address.city`} label="City" />
                </GridItem>
                <GridItem mobile size={1}>
                  <FinalFormTextField name={`${name}.address.state`} label="State" />
                </GridItem>
                <GridItem mobile size={1}>
                  <FinalFormTextField name={`${name}.address.zip`} label="Zip Code" />
                </GridItem>
                <GridItem mobile size={2}>
                  <FinalFormTextField name={`${name}.address.country`} label="Country" />
                </GridItem>
              </MuiGrid>
            </DrilldownCardTabSection>
          </GridItem>

          <GridItem id="property-details-column" mobile size={includesRehab ? 3 : 4}>
            <DrilldownCardTabSection noMargin title={"Property Details"}>
              <MuiGrid>
                <GridItem mobile size={6}>
                  <FinalFormTextField name={`${name}.numberOfUnits`} label="Number Of Units" />
                </GridItem>
                <GridItem mobile size={6}>
                  <FinalFormSelectField
                    label="Property Type"
                    name={`${name}.propertyType`}
                    items={subjectPropertyTypeOptions}
                  />
                </GridItem>
                {values?.loanPurpose === "Purchase" ? (
                  <>
                    <GridItem mobile size={6}>
                      <FinalFormTextField name={`${name}.purchasePrice`} label="Purchase Price" dollar />
                    </GridItem>
                    <GridItem mobile size={6}>
                      <FinalFormTextField name={`${name}.assignmentFee`} label="Assignment Fee" dollar />
                    </GridItem>
                    <GridItem mobile size={6}>
                      <Field
                        name={`${name}.purchaseDate`}
                        label="Purchase Date"
                        component={DatePickerTextFieldComponent}
                      />
                    </GridItem>
                  </>
                ) : null}

                {formData?.loanProductType === "Stabilized Bridge" ? (
                  <GridItem mobile size={6}>
                    <FinalFormTextField name={`${name}.numberOfOccupiedUnits`} label="Number Of Occupied Units" />
                  </GridItem>
                ) : null}
                {formData?.loanPurpose === "Purchase" ? null : (
                  <GridItem mobile size={6}>
                    {/* Required if Refi, but not purchase */}
                    <FinalFormTextField dollar name={`${name}.unpaidPrincipalBalance`} label="UBP" />
                  </GridItem>
                )}
                <GridItem grow>
                  <FinalFormSelectField
                    name={`${name}.stateOfInteriorAccess`}
                    label="State of Interior Access"
                    items={stateOfInteriorAccessOptions}
                  />
                </GridItem>
                <GridItem mobile size={6}>
                  <FinalFormTextField name={`${name}.propertyCondition`} label="Property Condition" />
                </GridItem>

                <GridItem mobile size={6}>
                  <FinalFormTextField name={`${name}.totalSquareFootage`} label="Estimated Square Footage" number />
                </GridItem>
                <GridItem mobile size={6}>
                  <FinalFormTextField name={`${name}.postRehabSquareFootage`} label="Post-Rehab Square Footage" />
                </GridItem>
              </MuiGrid>
            </DrilldownCardTabSection>
          </GridItem>

          <GridItem id="budget-work-column" mobile size={includesRehab ? 3 : 4}>
            <DrilldownCardTabSection noMargin title={"Budget & Required Work"}>
              <MuiGrid>
                {/* <GridItem size={12}>
                  <FinalFormTextField name={`${name}.financedBudget`} label="Financed Budget" dollar />
                </GridItem> */}

                <GridItem mobile size={12}>
                  <TotalBudgetFinalFormDisplayField name={name} index={index} />
                </GridItem>
              </MuiGrid>
            </DrilldownCardTabSection>
          </GridItem>

          <GridItem id="appraisals-column" mobile size={includesRehab ? 3 : 4}>
            <DrilldownCardTabSection noMargin title={"Appraisals"}>
              <MuiGrid>
                <GridItem mobile size={6}>
                  <FinalFormTextField
                    name={`${name}.asIsValue`}
                    label="As Is Value"
                    dollar //
                  />
                </GridItem>
                <GridItem mobile size={6}>
                  <Field
                    name={`${name}.asIsValueDate`}
                    label="As Is Value Date"
                    component={DatePickerTextFieldComponent}
                  />
                </GridItem>

                {values?.loanProductType === "Fix and Flip" ||
                values?.loanProductType === "Ground Up Construction" ||
                values?.loanProductType === "Land Purchase" ? (
                  <>
                    <GridItem mobile size={6}>
                      <FinalFormTextField
                        name={`${name}.appraisalArv`}
                        label="Appraisal ARV"
                        dollar //
                      />
                    </GridItem>
                    <GridItem mobile size={6}>
                      <Field
                        name={`${name}.appraisalArvDate`}
                        label="Appraisal ARV Date"
                        component={DatePickerTextFieldComponent}
                      />
                    </GridItem>
                  </>
                ) : null}
                <GridItem mobile size={12}>
                  <FinalFormSelectField
                    name={`${name}.conditionOfProperty`}
                    label="Condition Of Property"
                    items={["C1", "C2", "C3", "C4", "C5", "C6"]}
                  />
                </GridItem>
              </MuiGrid>
            </DrilldownCardTabSection>
          </GridItem>

          <GridItem id="insurance-column" mobile size={includesRehab ? 3 : 4}>
            <DrilldownCardTabSection noMargin title={"Insurance, Taxes, & Fees"}>
              <MuiGrid>
                <GridItem mobile size={6}>
                  <FinalFormTextField name={`${name}.annualPropertyTaxes`} label="Annual Property Taxes" dollar />
                </GridItem>
                <GridItem mobile size={6}>
                  <FinalFormTextField name={`${name}.annualHazardInsurance`} label="Annual Hazard Insurance" dollar />
                </GridItem>
                <GridItem mobile size={6}>
                  <FinalFormTextField name={`${name}.annualFloodInsurance`} label="Annual Flood Insurance" dollar />
                </GridItem>
                <GridItem mobile size={6}>
                  <FinalFormTextField name={`${name}.annualHoaFees`} label="Annual HOA Fees" dollar />
                </GridItem>
                <GridItem mobile size={12}>
                  <FinalFormTextField
                    name={`${name}.annualPropertyManagementFees`}
                    label="Annual Property Management Fees"
                    dollar
                  />
                </GridItem>
              </MuiGrid>
            </DrilldownCardTabSection>
          </GridItem>
          <GridItem id="property-details-column" size={12}>
            {/* <PropertyUnitsTable name={name} is /> */}
            <PropertyUnitsTable name={name} />
          </GridItem>
        </MuiGrid>
      )}
    </DrilldownMultiItemCard>
  );
};

const PropertyUnitsTable = ({ name, isDisabled }) => {
  const { mutators } = useForm();
  const { values } = useFormState();
  const isMobile = useSelector((state) => state.globalData?.isMobile);
  const unitsArrayPathName = `${name}.units`;

  const handleAddNewUnit = () => {
    mutators.push(unitsArrayPathName, {});
  };

  const tableHeaderContent = isDisabled ? null : (
    <Button
      onClick={handleAddNewUnit}
      endIcon={<AddRounded sx={{ fontSize: "1rem" }} />}
      sx={{
        margin: "0px",
        padding: "0px 4px",
        color: "var(--blue)",
        fontWeight: 500,
      }}
    >
      Add Unit
    </Button>
    //   <IconButton
    //     sx={{
    //       margin: "0px",
    //       padding: "0px",
    //       color: "var(--blue)",
    //     }}
    //   >

    //   </IconButton>
    // </Tooltip>
  );

  const columns = [
    "Unit #",
    "Unit Occupied",
    "Unit Current Lease Term",
    "Unit Monthly In Place Rent",
    "Unit Monthly Market Rent",
    "Unit Expected Post Renovation Rent",
    "Unit Square Footage",
  ];

  const columnsContent = (
    <>
      <MuiEditableTableCell th sx={{ textAlign: "center", width: "55px" }}>
        Unit #
      </MuiEditableTableCell>
      <MuiEditableTableCell th>Unit Occupied</MuiEditableTableCell>
      <MuiEditableTableCell th>Unit Current Lease Term</MuiEditableTableCell>
      <MuiEditableTableCell th>Unit Monthly In Place Rent</MuiEditableTableCell>
      <MuiEditableTableCell th>Unit Monthly Market Rent</MuiEditableTableCell>
      <MuiEditableTableCell th>Unit Expected Post Renovation Rent</MuiEditableTableCell>
      <MuiEditableTableCell th>Unit Square Footage</MuiEditableTableCell>
    </>
  );

  return (
    <MuiEditableTable
      title={"Property Units"}
      tableHeaderContent={tableHeaderContent}
      columnsContent={columnsContent}
      maxWidth={isMobile ? "320px" : "unset"}
    >
      <FieldArray name={unitsArrayPathName}>
        {({ fields }) =>
          arrayProtector(getObjectValueFromStringPath(values, unitsArrayPathName)).length >= 1 ? (
            fields.map((name, unitIndex) => (
              <PropertyUnitTableRow key={unitIndex} index={unitIndex} is propertyName={name} />
            ))
          ) : (
            <MuiEditableTableRow>
              <MuiEditableTableCell colSpan={"100%"}>No units added</MuiEditableTableCell>
            </MuiEditableTableRow>
          )
        }
      </FieldArray>
    </MuiEditableTable>
  );
};

const PropertyUnitTableRow = ({ isDisabled, propertyName, index }) => {
  const { values } = useFormState();

  return (
    <MuiEditableTableRow>
      <MuiEditableTableCell sx={{ textAlign: "center", width: "55px" }}>{index + 1}</MuiEditableTableCell>

      <MuiEditableTableCell
        isDisplay={isDisabled}
        displayValue={getObjectValueFromStringPath(values, `${propertyName}.unitOccupied`)}
      >
        <FinalFormSelectField variant="standard" name={`${propertyName}.unitOccupied`} items={yesNo} />
      </MuiEditableTableCell>

      <MuiEditableTableCell
        isDisplay={isDisabled}
        displayValue={getObjectValueFromStringPath(values, `${propertyName}.leaseType`)}
      >
        <FinalFormSelectField
          variant="standard"
          name={`${propertyName}.leaseType`}
          items={[
            "Long-term (original term >= 12 months)",
            "Month-to-Month",
            "Short-term (vacation rental, AirBnB or VRBO)",
            "No Lease in Place",
          ]}
        />
      </MuiEditableTableCell>

      <MuiEditableTableCell
        isDisplay={isDisabled}
        displayValue={formatAsDollarString(
          getObjectValueFromStringPath(values, `${propertyName}.unitMonthlyInPlaceRent`)
        )}
      >
        <FinalFormTextField variant="standard" name={`${propertyName}.unitMonthlyInPlaceRent`} dollar />
      </MuiEditableTableCell>

      <MuiEditableTableCell
        isDisplay={isDisabled}
        displayValue={formatAsDollarString(
          getObjectValueFromStringPath(values, `${propertyName}.unitMonthlyMarketRent`)
        )}
      >
        <FinalFormTextField variant="standard" name={`${propertyName}.unitMonthlyMarketRent`} dollar />
      </MuiEditableTableCell>
      <MuiEditableTableCell
        isDisplay={isDisabled}
        displayValue={formatAsDollarString(
          getObjectValueFromStringPath(values, `${propertyName}.expectedPostRenovationRent`)
        )}
      >
        <FinalFormTextField variant="standard" name={`${propertyName}.expectedPostRenovationRent`} dollar />
      </MuiEditableTableCell>
      <MuiEditableTableCell
        isDisplay={isDisabled}
        displayValue={`${formatAsNumberString(
          getObjectValueFromStringPath(values, `${propertyName}.unitSquareFootage`)
        )} sq/ft`}
      >
        <FinalFormTextField variant="standard" name={`${propertyName}.unitSquareFootage`} number />
      </MuiEditableTableCell>
    </MuiEditableTableRow>
  );
};

const ShowHideButton = React.forwardRef(function MyComponent({ setIsHidden, isHidden }, ref) {
  //  Spread the props to the underlying DOM element.
  return (
    <IconButton onClick={() => setIsHidden((s) => !s)}>
      {isHidden ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
    </IconButton>
  );
});

const TotalBudgetFinalFormDisplayField = ({ name, index }) => {
  const { values } = useFormState();

  const totalBudget = getObjectValueFromStringPath(values, `${name}.totalBudget`);
  const rehabBudgetPercent = isNil(values?.rehabBudgetFinancedPercent) ? 100 : values?.rehabBudgetFinancedPercent;
  const financedBudget = totalBudget * (rehabBudgetPercent / 100);

  // return (
  //   <>
  //     <FinalFormTextField
  //       // onChange={handleOnInitialAmoutChange}
  //       label="Initial Loan amount"
  //       name={`initialLoanAmount`}
  //       dollar
  //     />
  //     <FinalFormTextField
  //       label="Rehab Budget"
  //       // onChange={handleOnRehabAmoutChange}
  //       name={`constructionRenovationBudget`}
  //       dollar
  //     />
  //   </>
  // );

  return (
    <Stack sx={{ background: "#eef2f5", padding: "2px", borderRadius: "4px" }} spacing={0}>
      {/* <FinalFormFieldTotalBudget name={name} /> */}
      <Stack
        display={"flex"}
        // direction={"row"}

        alignItems="center"
        justifyContent={"space-between"}
        spacing={0}
      >
        <FinalFormFieldTotalBudget name={name} />
        <FlexBox justifyBetween sx={{ alignItems: "flex-end", width: "100%", padding: "8px" }}>
          <CloseRounded sx={{ fontSize: "1rem" }} />
          <FlexBox alignCenter sx={{ padding: "0 0px" }} gap={"4px"}>
            <Typography fontWeight={500} sx={{ fontSize: ".7rem", textAlign: "center", whiteSpace: "nowrap" }}>
              Percent Financed:
            </Typography>
            <Typography fontWeight={600}> {rehabBudgetPercent}%</Typography>
          </FlexBox>
        </FlexBox>
      </Stack>

      <Stack sx={{ background: "#fcfdfd", padding: "10px" }} justifyContent={"center"} spacing={0}>
        <Typography fontWeight={500} sx={{ fontSize: ".7rem", textAlign: "center" }}>
          Total Financed Rehab Budget
        </Typography>
        <Typography fontWeight={600} sx={{ textAlign: "center" }}>
          {formatAsDollarString(financedBudget)}
        </Typography>
      </Stack>
    </Stack>
  );
};
export default ScenarioDrilldownTabProperties;
