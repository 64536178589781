import { MenuItem, TextField } from "@mui/material";
import React from "react";

const LoanFilesTableInput = ({ onChange, value, options, placeholder }) => {
  const inputStyles = {
    fontSize: ".7rem !important",
    border: "var(--border)",
    margin: "none",
    width: "100%",
  };

  const inputPropsStyles = {
    sx: {
      border: "none",
      fontSize: ".7rem !important",
      padding: "3px 6px 0px 6px",
      margin: "none",
      minWidth: "100px",
      width: "100%",
    },
  };

  if (options) {
    return (
      <TextField
        onChange={onChange}
        value={value}
        size="small"
        select
        InputProps={inputPropsStyles}
        sx={inputStyles}
        placeholder="aassd"
        variant="standard"
      >
        {placeholder ? (
          <MenuItem disabled value={""}>
            {placeholder}
          </MenuItem>
        ) : null}
        {options.map((option) => (
          <MenuItem key={option.name} value={option.name}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }
  return (
    <TextField
      onChange={onChange}
      value={value}
      size="small"
      InputProps={inputPropsStyles}
      sx={inputStyles}
      variant="standard"
    />
  );
};

export default LoanFilesTableInput;
