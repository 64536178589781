import {
  Delete,
  FileOpenRounded,
  FileUpload,
  KeyboardArrowDown,
  KeyboardArrowUp,
  UploadFile,
  UploadFileRounded,
} from "@mui/icons-material";
import { Box, Button, Chip, IconButton, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import TableHeader from "../../../../../shared/Table/components/TableHeader";

// import TableHeader from "../../../../../shared/Table/components/TableHeader";
import InputTable from "../../../../../shared/Table/InputTable/InputTable";

// import InputTable from "../../../../../shared/Table/InputTable/InputTable";
import FileUploadSelectedFileRow from "./FileUploadSelectedFileRow";
import { useState } from "react";
import FlexBox from "../../../../../shared/FlexBox";
import { arrayProtector } from "../../../../../../helpers/arrayProtector";

const FileUploadSelectedFiles = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  // const useStyles = makeStyles(() => ({
  //   chipCustom: {
  //     "& .MuiChip-label": {
  //       fontSize: ".75rem",

  //       // color: "#ffffff",
  //       fontWeight: "400 !important",
  //     }, // sub-selector
  //   },
  // }));
  // const customChipClass = useStyles();

  const filesList = (
    <Box
      sx={{
        position: "absolute",
        background: "#ffffff",
        boxShadow: "var(--bs)",
        zIndex: 11111,
        width: "calc(100% + 16px)",
        top: "4px",
        left: "-8px",
      }}
      className="file-thumbail-container"
    >
      <Stack
        // p={"10px 5px"}
        // spacing={1}
        sx={{
          ".uploaded-file": {
            borderBottom: "var(--border)",
            borderRadius: 0,
            fontWeight: 500,
            fontSize: ".7rem !important",
          },
        }}
      >
        {props.selectedFiles.map((f) => (
          <Button
            variant="text"
            key={f.file.path}
            onClick={() => props.onDelete(f.id)}
            className={"uploaded-file"}
            size="small"
            color="secondary"
            sx={{ justifyContent: "space-between" }}
            label={f.file.path}
            endIcon={<Delete sx={{ fontSize: "1rem !important" }} />}
          >
            {f.file.path}
          </Button>
          // <Chip
          //   key={f.file.path}
          //   onDelete={() => props.onDelete(f.id)}
          //   className={customChipClass.chipCustom}
          //   size="small"
          //   color="secondary"
          //   variant="outlined"
          //   label={f.file.path}
          //   deleteIcon={<Delete />}
          // />
        ))}
      </Stack>
    </Box>
  );

  const iconStyles = { fontSize: "1rem" };
  const iconStylesButton = { padding: "5px", position: "absolute", right: "-5px" };

  return (
    <Stack>
      <FlexBox alignCenter sx={{ position: "relative", paddingTop: "10px", paddingLeft: "3px" }}>
        <UploadFileRounded sx={{ fontSize: ".9rem", color: "var(--gray)", margin: "-2px 3px 0 3px" }} />
        <Typography sx={{ fontSize: ".7rem" }}>{`${
          arrayProtector(props.selectedFiles).length
        } Uploaded Files`}</Typography>
        <IconButton sx={iconStylesButton} onClick={() => setIsOpen((s) => !s)}>
          {isOpen ? <KeyboardArrowUp sx={iconStyles} /> : <KeyboardArrowDown sx={iconStyles} />}
        </IconButton>
      </FlexBox>
      <Box sx={{ position: "relative" }}>{isOpen ? filesList : null}</Box>
    </Stack>
  );
  // return (
  //   <div className="file-thumbail-container">
  //     {props.showDocOptions ? (
  //       <InputTable styled>
  //         {props.showDocOptions && (
  //           <TableHeader
  //             tableHeader={props.docGroup ? ["File Name", "Doc Type", ""] : ["File Name", "Doc Group", "Doc Type", ""]}
  //           />
  //         )}
  //         {/* <TableHeader tableHeader={["Fee Name", "Value"]} /> */}
  //         <tbody>
  //           {props.selectedFiles.map((f, i) => {
  //             // console.log(`file ${i}:`, f);

  //             return (
  //               <FileUploadSelectedFileRow
  //                 selectedFile={f}
  //                 index={i}
  //                 onUpdate={props.onUpdate}
  //                 onDelete={props.onDelete}
  //                 showDocOptions={props.showDocOptions}
  //                 docGroup={props.docGroup}
  //                 allFiles={props.selectedFiles}
  //                 // {...props}
  //               />
  //             );
  //           })}
  //         </tbody>
  //       </InputTable>
  //     ) : (
  //       <Stack p={"10px 5px"} direction="row" spacing={1}>
  //         {props.selectedFiles.map((f) => (
  //           // <div className="file-thumbnail-path">
  //           //   <span>{f.file.path}</span>
  //           //   <button type="button" onClick={() => props.onDelete(f.id)}>
  //           //     <Delete />
  //           //   </button>
  //           // </div>
  //           <Chip
  //             key={f.file.path}
  //             onDelete={() => props.onDelete(f.id)}
  //             className={customChipClass.chipCustom}
  //             size="small"
  //             color="secondary"
  //             variant="outlined"
  //             label={f.file.path}
  //             deleteIcon={<Delete />}
  //           />
  //         ))}
  //       </Stack>
  //     )}
  //   </div>
  // );
};

export default FileUploadSelectedFiles;
