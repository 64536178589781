import { GroupAddRounded, ShareRounded } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { isNil, size } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import DashboardScreen from "../../../../components/layout/DashboardScreen";
import FlexBox from "../../../../components/shared/FlexBox";
import TabSwitcher from "../../../../components/shared/TabSwitcher";
import TabPanel from "../../../../components/shared/TabSwitcher/TabPanel";
import InviteUserModal from "./InviteUserModal";

import { useStytchUser } from "@stytch/react";
import ScreenBody from "../../../../components/layout/Screen/ScreenBody";
import BulkInviteUsersToOrganizationModal from "../../../../components/modals/BulkInviteUsersToOrganizationModal";
import Loader from "../../../../components/shared/Loader/Loader";
import MuiReactTable from "../../../../components/shared/MuiReactTable";
import { arrayProtector } from "../../../../helpers/arrayProtector";
import { removeDuplicates } from "../../../../helpers/removeDuplicates";
import { useBorrowerHook } from "../../../../hooks/useBorrowerHook";
import { useBrokerDrilldownHook } from "../../../../hooks/useBrokerDrilldownHook";
import { assignedUsersTableColumns } from "../../../../shared/table_columns/assignedUsersTableColumns";
import { pendingUsersTableColumns } from "../../../../shared/table_columns/pendingUsersTableColumns";
import { salesAssignedUsersTableColumns } from "../../../../shared/table_columns/salesAssignedUsersTableColumns";
import { unassignedUsersTableColumns } from "../../../../shared/table_columns/unassignedUsersTableColumns";
import UserDetailsDrawer from "./UserDetailsDrawer";
import UserAnalyticsTab from "./tabs/UserAnalyticsTab";
import AgThemeQuartz from "../../../../assets/themes/AgThemeQuartz";
import SearchTextField from "../../../../components/buttons/SearchTextField";
import { AgGridReact } from "ag-grid-react";
import { valueFormatterFormatDate } from "../../../../components/agGrid/valueFormatter/valueFormatterFormatDate";
import PopoverDropdown from "../../../../components/buttons/PopoverDropdown";

const UsersScreenImproved = (props) => {
  const globalUsers = useSelector((state) => state.globalData?.users);
  const users = useMemo(() => globalUsers, [globalUsers]);
  const loanScenarios = useSelector((state) => state.loanScenarios);
  const { getIsLoading, getAllUsers } = useBorrowerHook();
  const { handleGetAndSetBrokerScenarios } = useBrokerDrilldownHook();

  useEffect(() => {
    // fetchAndSetUsers();

    if (isNil(users)) {
      getAllUsers({});
    }
    if (isNil(loanScenarios) || arrayProtector(loanScenarios).length === 0) {
      handleGetAndSetBrokerScenarios();
    }
  }, []);

  if (isNil(users) || getIsLoading) {
    return (
      <DashboardScreen>
        <Loader size={36} />
      </DashboardScreen>
    );
  }

  return (
    <DashboardScreen customScreenBody>
      <UsersTable data={users} />
      {/* <SCREEN_CONTENT data={users} /> */}
    </DashboardScreen>
  );
};

const UsersTable = ({ data }) => {
  const ref = useRef();
  const [tabValue, setTabValue] = React.useState(0);
  const employees = useSelector((state) => state?.globalData?.employees);
  const { user } = useStytchUser();
  const [quickFilterText, setQuickFilterText] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const isMyUsers = (brokerUser) => {
    return brokerUser.assignedSalesperson?.ironfund_email === user?.trusted_metadata?.emailAddress;
  };

  const isAssignedUser = (brokerUser) => {
    return !isNil(brokerUser.assignedSalesperson);
  };

  const isUnassignedUser = (brokerUser) => {
    return (
      !brokerUser?.isHidden &&
      isNil(brokerUser?.assignedSalesperson) &&
      (!isNil(brokerUser?.company) || !isNil(brokerUser?.scenarios))
    );
  };

  const MY_USERS = data?.filter(
    (u) => isMyUsers(u) //u.assignedSalesperson?.emailAddress === user?.trusted_metadata?.emailAddress
  ); //If they have loanSalesperson
  const ASSIGNED_USERS = data?.filter((u) => isAssignedUser(u)); //If they have loanSalesperson
  const UNASSIGNED_USERS = data?.filter((u) => isUnassignedUser(u));
  // const PENDING_USERS = data?.filter((u) => !isAssignedUser(u) && !isUnassignedUser(u));
  const PENDING_USERS = data?.filter(
    (u) => isNil(u.accountCreatedDate) && isNil(u?.company) && isNil(u?.scenarios) && isNil(u?.assignedSalesperson)
  );

  const columnDefs = useMemo(
    () => [
      { field: "_id", hide: true },
      {
        field: "assignedSalesperson",
        valueFormatter: (params) => {
          if (params?.value?.fullName) {
            return params?.value?.fullName;
          }
          return params?.value?.emailAddress;
        },
      },
      { field: "firstName" },
      { field: "lastName" },
      { field: "emailAddress" },
      { field: "phoneNumber" },
      { field: "company" },
      { field: "companyRole" },
      { field: "companyUrl" },
      { field: "activeStates" }, //array
      { field: "referralSource" },
      { field: "referralSourceOtherName" },
      { field: "userCategory" },
      { field: "lastSignIn", valueFormatter: valueFormatterFormatDate },
      { field: "accountCreatedDate", valueFormatter: valueFormatterFormatDate },
      { field: "inviteId" },
      { field: "signInCount" },
      { field: "isActiveUser" },
      { field: "isInternalUser" },
      { field: "fullName" },
      { field: "scenarioCount" },
      { field: "lastScenarioSubmission" },
      { field: "scenarios" },
      { field: "isHidden" },
    ],
    []
  );

  const onRowClicked = useCallback((params) => {
    console.log(params?.data);
  }, []);
  const [salespersonIndex, setDalespersonIndex] = useState(0);

  const salespersonIndexOptions = useMemo(() => {
    return employees?.map((e) => {
      const ee = {
        emailAddress: "dm@flatironrealtycapital.com",
        firstName: "David",
        lastName: "Meytin",
        fullName: "David Meytin",
        ironfund_email: "dm@ironfund.com",
      };
    });
  }, [employees]);
  return (
    <>
      <ScreenBody
        sx={{
          paddingTop: "16px",
          display: "flex",
          flexDirection: "column",

          flexGrow: 1,

          overflowY: "auto",
        }}
      >
        <FlexBox justifyBetween alignCenter>
          <TabSwitcher
            noBorder
            setValue={setTabValue}
            tabs={[
              `My Users (${size(MY_USERS)})`,
              `Assigned (${size(ASSIGNED_USERS)})`,
              `Unassigned Users (${size(UNASSIGNED_USERS)})`,
              `Sent Invites (${size(PENDING_USERS)})`,

              `Analytics`,
            ]}
          />
          <FlexBox gap={"10px"}>
            {/* <BulkInviteUsersModalButton /> */}
            <InviteIndividualUserModalButton />
          </FlexBox>
        </FlexBox>
        <FlexBox fw justifyBetween>
          <SearchTextField variant="white" quickFilterText={quickFilterText} setQuickFilterText={setQuickFilterText} />
          <FlexBox gap={"10px"}>
            <FlexBox gap={"1px"} alignCenter>
              <Typography sx={{ opacity: 0.7 }}>Salesperson:</Typography>
              <PopoverDropdown
                value={salespersonIndex}
                onChange={setDalespersonIndex}
                options={salespersonIndexOptions}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <FlexBox fw column sx={{ flexGrow: 1, height: "100%", position: "relative", zIndex: 11, overflowY: "auto" }}>
          <AgThemeQuartz hasExternalHeader borderRadius={"0px"} size={3}>
            <AgGridReact
              ref={ref}
              rowData={data}
              quickFilterText={quickFilterText}
              columnDefs={columnDefs}
              pagination
              defaultColDef={{ filter: true, floatingFilter: true }}
              // getRowId={getRowId}
              onRowClicked={onRowClicked}
              // onCellEditingStopped={onCellEditingStopped}
              // isExternalFilterPresent={isExternalFilterPresent}
              // externalFilterChanged={externalFilterChanged}
              // doesExternalFilterPass={doesExternalFilterPass}
            />
          </AgThemeQuartz>
        </FlexBox>
      </ScreenBody>
    </>
  );
};

const SCREEN_CONTENT = ({ data }) => {
  const [tabValue, setTabValue] = React.useState(0);
  const { user } = useStytchUser();
  const [showModal, setShowModal] = useState(false);

  const isMyUsers = (brokerUser) => {
    return brokerUser.assignedSalesperson?.ironfund_email === user?.trusted_metadata?.emailAddress;
  };

  const isAssignedUser = (brokerUser) => {
    return !isNil(brokerUser.assignedSalesperson);
  };

  const isUnassignedUser = (brokerUser) => {
    return (
      !brokerUser?.isHidden &&
      isNil(brokerUser?.assignedSalesperson) &&
      (!isNil(brokerUser?.company) || !isNil(brokerUser?.scenarios))
    );
  };

  const MY_USERS = data?.filter(
    (u) => isMyUsers(u) //u.assignedSalesperson?.emailAddress === user?.trusted_metadata?.emailAddress
  ); //If they have loanSalesperson
  const ASSIGNED_USERS = data?.filter((u) => isAssignedUser(u)); //If they have loanSalesperson
  const UNASSIGNED_USERS = data?.filter((u) => isUnassignedUser(u));
  // const PENDING_USERS = data?.filter((u) => !isAssignedUser(u) && !isUnassignedUser(u));
  const PENDING_USERS = data?.filter(
    (u) => isNil(u.accountCreatedDate) && isNil(u?.company) && isNil(u?.scenarios) && isNil(u?.assignedSalesperson)
  );

  return (
    <>
      <Box sx={{ background: "#ffffff", boxShadow: "var(--bs)" }}>
        <ScreenBody>
          <FlexBox justifyBetween alignCenter>
            <TabSwitcher
              noBorder
              setValue={setTabValue}
              tabs={[
                `My Users (${size(MY_USERS)})`,
                `Assigned (${size(ASSIGNED_USERS)})`,
                `Unassigned Users (${size(UNASSIGNED_USERS)})`,
                `Sent Invites (${size(PENDING_USERS)})`,

                `Analytics`,
              ]}
            />
            <FlexBox gap={"10px"}>
              {/* <BulkInviteUsersModalButton /> */}
              <InviteIndividualUserModalButton />
            </FlexBox>
          </FlexBox>
        </ScreenBody>
      </Box>
      <ScreenBody sx={{ paddingTop: "16px" }}>
        <MyUsersTab data={MY_USERS} tabValue={tabValue} />
        <AssignedUsersTab data={ASSIGNED_USERS} tabValue={tabValue} />
        <UnassignedUsersTab data={UNASSIGNED_USERS} tabValue={tabValue} />
        <PendingUsersTab data={PENDING_USERS} tabValue={tabValue} />
        <UserAnalyticsTab data={ASSIGNED_USERS} tabValue={tabValue} />
      </ScreenBody>
    </>
  );
};

const MyUsersTab = ({ data, onClick, tabValue }) => {
  const [user, setUser] = useState(false);

  const columns = React.useMemo(() => assignedUsersTableColumns, []);
  const tableData = React.useMemo(() => data, [data]);

  return (
    <TabPanel value={tabValue} index={0}>
      <MuiReactTable
        title={"My Assigned Users"}
        emptyTitle={"There are currently no users associated with you."}
        noBorder
        search
        columns={columns}
        rowOnClick={(d) => setUser(d)}
        // columns={isMobile ? cols_mobile : columns}
        data={tableData}
      />
      <UserDetailsDrawer show={user} user={user} onCancel={() => setUser(undefined)} />
    </TabPanel>
  );
};

const AssignedUsersTab = ({ data, onClick, tabValue }) => {
  const [user, setUser] = useState(false);
  const uniqueTableData = removeDuplicates(data, "assignedSalesperson.emailAddress");

  const allUsersColumns = React.useMemo(() => assignedUsersTableColumns, []);
  const assignedUsersColumns = React.useMemo(() => salesAssignedUsersTableColumns, []);
  const tableData = React.useMemo(() => data, [data]);
  const salespeopleTabNames = useMemo(() => uniqueTableData?.map((s) => s?.assignedSalesperson?.fullName), []);
  const [subTabValue, setSubTabValue] = useState(0);
  return (
    <TabPanel value={tabValue} index={1}>
      <TabSwitcher noBorder sub setValue={setSubTabValue} tabs={["All Assigned Users", ...salespeopleTabNames]} />
      <TabPanel value={subTabValue} index={0}>
        <MuiReactTable
          title={"All Assigned Users"}
          emptyTitle={"There are currently no users associated with you."}
          noBorder
          search
          pagination
          paginationDefault={50}
          rowOnClick={(d) => setUser(d)}
          columns={allUsersColumns}
          // columns={isMobile ? cols_mobile : columns}
          data={tableData}
        />
      </TabPanel>
      {uniqueTableData.map((salesperson, index) => {
        const filteredList = data?.filter(
          (d) => d?.assignedSalesperson?.emailAddress === salesperson?.assignedSalesperson?.emailAddress
        );
        return (
          <TabPanel value={subTabValue} index={index + 1}>
            <MuiReactTable
              title={`${salesperson?.assignedSalesperson?.fullName} (${arrayProtector(filteredList).length})`}
              emptyTitle={"There are currently no users associated with you."}
              noBorder
              search
              rowOnClick={(d) => setUser(d)}
              pagination
              columns={assignedUsersColumns}
              data={filteredList}
            />
          </TabPanel>
        );
      })}

      <UserDetailsDrawer show={user} user={user} onCancel={() => setUser(undefined)} />
    </TabPanel>
  );
};

const UnassignedUsersTab = ({ data, onClick, tabValue }) => {
  const [user, setUser] = useState(false);

  const columns = React.useMemo(() => unassignedUsersTableColumns, []);
  const tableData = React.useMemo(() => data, [data]);
  return (
    <TabPanel value={tabValue} index={2}>
      <MuiReactTable
        title={"Unassigned Users"}
        emptyTitle={"There are currently no unassigned users."}
        noBorder
        rowOnClick={(d) => setUser(d)}
        search
        columns={columns}
        data={tableData}
      />
      <UserDetailsDrawer show={user} user={user} onCancel={() => setUser(undefined)} />
    </TabPanel>
  );
};

const PendingUsersTab = ({ data, tabValue }) => {
  const [user, setUser] = useState(false);

  const columns = React.useMemo(() => pendingUsersTableColumns, []);
  const tableData = React.useMemo(() => data, [data]);
  return (
    <TabPanel value={tabValue} index={3}>
      <MuiReactTable
        title={"Pending Users"}
        emptyTitle={"There are currently no pending users."}
        noBorder
        rowOnClick={(d) => setUser(d)}
        columns={columns}
        data={tableData}
      />
      <UserDetailsDrawer show={user} user={user} onCancel={() => setUser(undefined)} />
    </TabPanel>
  );
};

const BulkInviteUsersModalButton = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button
        size="small"
        variant="outlined"
        endIcon={<GroupAddRounded sx={{ fontSize: "1rem !important" }} />}
        onClick={() => setShow(true)}
      >
        Bulk Invite User
      </Button>
      <BulkInviteUsersToOrganizationModal show={show} onCancel={() => setShow(false)} />
    </>
  );
};

const InviteIndividualUserModalButton = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button
        size="small"
        variant="outlined"
        endIcon={<ShareRounded sx={{ fontSize: "1rem !important" }} />}
        onClick={() => setShow(true)}
      >
        Invite User
      </Button>
      <InviteUserModal open={show} onClose={() => setShow(false)} />
    </>
  );
};

const exampleObj = {
  _id: "65b2ccf7c04ea679a5687ef0",
  firstName: "William ",
  lastName: "Chang",
  emailAddress: "WChang@advisorsmortgage.com",
  phoneNumber: "(609) 601-4390",
  company: "Advisors Mortgage",
  companyRole: "Branch Manager",
  companyUrl: "www.AdvisorsMortgage.com",
  activeStates: ["NJ"],
  referralSource: "Direct outreach",
  userCategory: "broker",
  isActiveUser: true,
  isInternalUser: false,
  fullName: "William  Chang",
  accountCreatedDate: "2024-01-25T21:04:53.586000",
  stytch_user_id: "user-live-22d4887e-badf-490d-b151-485ef4cc1e78",
  assignedSalesperson: {
    emailAddress: "lm@flatironrealtycapital.com",
    userType: "loanOfficer",
    firstName: "Leila",
    lastName: "Monasebian",
    fullName: "Leila Monasebian",
    ironfund_email: "lm@ironfund.com",
  },
};
export default UsersScreenImproved;
