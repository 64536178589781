import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
// import "./input.css";

import { makeStyles } from "@mui/styles";
import FormField from "../../shared/FormField/FormField";
const useStyles = makeStyles({
  root: {
    fontSize: "8px",
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 400,
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MultiSelectFieldComponent = (props) => {
  const {
    input: { name, value, onChange, ...restInput },
    meta,
    label,
    items,
    FormControlProps,
    noMargin,
    isRequired,
    helperText,
    id,
    multiple,
    ...rest
  } = props;
  // console.log("fieldState", props, input);
  const classes = useStyles();

  return (
    <>
      <FormField fullWidth={props.fullWidth ? props.fullWidth : true}>
        <FormControl fullWidth variant="filled">
          <InputLabel size="small" htmlFor={name}>
            {label}
            {isRequired && "*"}
          </InputLabel>
          <Select
            {...rest}
            name={name}
            labelId={name}
            id={id}
            size="small"
            multiple={multiple ? true : false}
            // error={true}
            label={label}
            value={[...value]}
            helperText="Incorrect entry."
            variant="filled"
            inputProps={restInput}
            onChange={onChange}
            MenuProps={MenuProps}
          >
            <MenuItem disabled value="">
              <em>Placeholder</em>
            </MenuItem>
            {items.map((i) => (
              <MenuItem value={i}>{i}</MenuItem>
            ))}
          </Select>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {meta.error && meta.touched && <span className="error">{meta.error}</span>}
        </FormControl>
      </FormField>
    </>
  );
};
// export default TextboxFormComponent;
