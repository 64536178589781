import { Stack } from "@mui/material";
import React from "react";
import DashboardScreen from "../../../../components/layout/DashboardScreen";

import FlexBox from "../../../../components/shared/FlexBox";
import BasicInfoCard from "./cards/BasicInfoCard";
import PrimaryProfileCard from "./cards/PrimaryProfileCard";

const ProfileScreen = (props) => {
  return (
    <DashboardScreen>
      <PrimaryProfileCard />
    </DashboardScreen>
  );
};

export default ProfileScreen;
