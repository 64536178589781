import { ChevronLeftRounded } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useForm, useFormState } from "react-final-form";

import { convertDateToIsoString } from "../helpers/convertDateToIsoString";
import { convertTimezone } from "../helpers/convertTimezone";
import { getTimeFromISO } from "../helpers/getTimeFromISO";
import { timezoneOptions } from "../timezoneOptions";
import { getDayNumFromDate } from "../helpers/getDayNumFromDate";
import { arrayIsEmpty } from "../../../../../helpers/arrayIsEmpty";
import { isMobile } from "react-device-detect";

const fStyles = {
  column: {
    display: "flex",
    flexDirection: "column",
    // width: "100%",
  },
  columnFw: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  columnCenter: {
    display: "flex",
    flexDirection: "column",
    justifyConent: "center",
    alignItems: "center",
  },
  center: {
    display: "flex",
    justifyConent: "center",
    alignItems: "center",
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  alignCenterFw: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
};

const CalendarTimeSlotPicker = ({ dates, setShowForm }) => {
  const { values } = useFormState();
  // const { availableDates, getUserDetails } = useCalendarHook();
  const availableDates = useMemo(() => dates, [dates]);

  const selected_day = getDayNumFromDate(convertDateToIsoString(values?.meetingDate));

  const time_slots_for_day = useMemo(
    () => availableDates?.filter((d) => getDayNumFromDate(d) === selected_day),
    [values?.meetingDate, availableDates]
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        width: "100%",
        overflowY: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: "10px",
          paddingBottom: "20px",
          overflow: "auto",
          maxHeight: isMobile ? "unset" : "400px",
        }}
      >
        {arrayIsEmpty(time_slots_for_day) ? (
          <Typography sx={{ marginTop: "10px" }}>There are no available slots for this day.</Typography>
        ) : (
          time_slots_for_day.map((ts) => <CalendarTimeSlot key={ts} onSelect={setShowForm} val={ts} />)
        )}
      </Box>
    </Box>
  );
};
const CalendarTimeSlot = ({ val, onSelect }) => {
  const { values } = useFormState();
  const { change } = useForm();

  const isSelected = useMemo(() => values?.meetingDateTime === val, [values]);
  const handleOnClick = () => {
    change("meetingDateTime", val);
  };
  const handleOnResetTimeSlot = () => {
    change("meetingDateTime", undefined);
  };

  if (isSelected) {
    return (
      <Box sx={{ ...fStyles.alignCenterFw, gap: "10px" }}>
        <CalendarTimeSlotButton
          sx={{ background: "var(--lightBlue)", borderWidth: "2px" }}
          utc={val}
          onClick={handleOnResetTimeSlot}
          isSelected={isSelected}
          startIcon={<ChevronLeftRounded />}
        />
        <Button
          sx={{ width: "100%", height: "100%", fontWeight: 700, padding: "16px 0", background: "#0069ff" }}
          size="large"
          variant={isSelected ? "contained" : "outlined"}
          // variant={isSelected ? "contained" : "text"}
          onClick={() => onSelect(true)}
        >
          Next
        </Button>
      </Box>
    );
  }

  return <CalendarTimeSlotButton utc={val} onClick={handleOnClick} isSelected={isSelected} />;
};

const CalendarTimeSlotButton = ({ utc, sx, onClick, startIcon }) => {
  const { values } = useFormState();
  const timezone = values?.clientSelectedTimezone;

  const utcDate = utc;
  // const utcDate = new Date(`${utc}Z`);

  const displayTimeValue = useMemo(
    () => getTimeFromISO(convertTimezone(utcDate, timezone)),
    [values?.clientSelectedTimezone]
  );

  const displayTimeValueEST = useMemo(
    () => getTimeFromISO(convertTimezone(utcDate, "America/New_York")),
    [values?.clientSelectedTimezone]
  );

  return (
    <Button
      sx={{
        width: "100%",
        fontWeight: 700,

        // padding: "14px 0",
        minHeight: "52px",
        display: "flex",
        borderColor: "#0069ff",
        color: "#0069ff",
        ".MuiButton-startIcon": {
          position: "absolute",
          left: "10px",
        },
        "&:hover": {
          borderColor: "#0069ff",
        },
        ...sx,
      }}
      size="large"
      startIcon={startIcon}
      variant={"outlined"}
      onClick={onClick}
    >
      <Box sx={{ gap: "2px", ...fStyles.columnCenter }}>
        <Box sx={{ gap: "4px", ...fStyles.alignCenter }}>
          <Typography sx={{ color: "#0069ff", fontWeight: "700", fontSize: ".75rem" }}>{displayTimeValue} </Typography>
          <Typography
            sx={{
              color: "#0069ff",
              fontWeight: "500",
              fontSize: ".75rem",
            }}
          >
            {timezoneOptions[timezone]}
          </Typography>
        </Box>
        {timezone === "America/New_York" ? null : (
          <>
            <Divider sx={{ borderColor: "#0069ff40" }} flexItem />
            <Typography
              sx={{
                color: "#0069ff",
                fontWeight: "500",
                fontSize: ".8rem",
              }}
            >{`${displayTimeValueEST} EST`}</Typography>
          </>
        )}
      </Box>
    </Button>
  );
};
export default CalendarTimeSlotPicker;
