import { Button, LinearProgress, Stack, Typography } from "@mui/material";
import MuiChip from "../../components/shared/MuiChip";
import { parseISOToDate } from "../../helpers/parseISOToDate";
import { formFormatDollar } from "../../helpers/parse/formFormatDollar";
import { ChevronRightRounded, Circle } from "@mui/icons-material";
import FlexBox from "../../components/shared/FlexBox";
import { isNil } from "lodash";
import ChangeLoanScenarioButton from "../../components/buttons/ChangeLoanScenarioButton";

export const loanScenariosTableColumns = (onClick) => {
  return [
    {
      Header: "Name",
      accessor: (d) =>
        d.isDuplicate ? (
          <>
            {d?.loanSubjectName}
            <MuiChip
              chipSx={{
                height: "unset",
                span: {
                  padding: "0 4px",
                },
              }}
              label={"Duplicate"}
            />
          </>
        ) : (
          d?.loanSubjectName
        ),
      accessorName: "loanSubjectName",
    },

    {
      Header: "Loan Product Type",
      accessor: "loanProductType",
    },
    {
      Header: "Loan Purpose",
      accessor: "loanPurpose",
    },
    {
      Header: "Initial Loan Amount",
      accessor: (d) => `$${formFormatDollar(d.initialLoanAmount)}`,
    },
    {
      Header: "Submitted By",
      accessor: (d) => d?.submittedBy?.fullName,
    },
    {
      Header: "Submitted On",
      accessor: (d) => parseISOToDate(d?.submitted_est),
    },

    {
      Header: "Iron Fund Contact",
      accessor: (d) => d?.loanSalesperson?.fullName,
    },
    // {
    //   Header: "Status",
    //   accessor: (d) => <LoanStatusProgress />,
    // },
    {
      Header: "Status",
      accessor: (d) => (
        <FlexBox alignCenter>
          <Typography fontSize={".7rem"} sx={{ width: "max-content" }}>
            {isNil(d?.scenarioStatus) ? "New" : d?.scenarioStatus}
          </Typography>
          <ChangeLoanScenarioButton scenario={d} />
        </FlexBox>
      ),
    },

    {
      Header: "Scenario Details",
      accessor: (d) => (
        <Button
          size="small"
          onClick={() => onClick(d?._id)}
          // color="primary"
          endIcon={<ChevronRightRounded sx={{ marginLeft: "-8px" }} />}
          variant="contained"
          sx={{ marginLeft: "10px", margin: "4px 10px", padding: "2px 4px", fontWeight: 500, fontSize: ".7rem" }}
        >
          View
        </Button>
      ),
    },
  ];
};

// const LoanStatusProgress = ({ loan }) => {
//   // return <LinearProgress variant="determinate" value={100} />;
//   return (
//     <Stack spacing={0.5} flexGrow={1}>
//       <Typography sx={{ fontSize: ".6rem !important", whiteSpace: "nowrap" }} variant="body2" color="text.secondary">
//         In Progress
//       </Typography>
//       <FlexBox
//         alignCenter
//         sx={{
//           ".MuiLinearProgress-root": {
//             width: "100%",
//           },
//         }}
//       >
//         <ProgressCircle isActive />
//         <LinearProgress variant="determinate" value={100} />

//         <ProgressCircle isActive />
//         <LinearProgress variant="determinate" value={-10} />
//         <ProgressCircle />
//         <LinearProgress variant="determinate" value={-1} />
//         <ProgressCircle />
//       </FlexBox>
//     </Stack>
//   );
// };

// const ProgressCircle = ({ isActive }) => {
//   const inactiveStyles = {
//     fill: "#ffffff",
//     stroke: "var(--primary)",
//   };
//   const activeStyles = {
//     stroke: "#ffffff",
//     fill: "var(--primary)",
//   };

//   const styles = isActive ? activeStyles : inactiveStyles;
//   const size = 7;

//   return (
//     <Circle
//       sx={{
//         width: `.${size}rem !important`,
//         height: `.${size}rem !important`,
//         ...styles,
//         // stroke: "#ffffff",
//         // fill: "var(--primary)",
//       }}
//     />
//   );
// };
