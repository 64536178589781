import { format, isValid, parseISO } from "date-fns";

export const valueFormatterFormatDate = (params) => {
  const d = params?.value;

  return formatISOtoShortDate(d);
};

const formatISOtoShortDate = (iso, returnValue) => {
  const returnVal = returnValue ? returnValue : iso;
  // console.log("iso", iso);
  if (!iso) {
    return returnVal;
  }

  let date = parseISO(iso); // Convert to date object
  if (!isValid(date)) {
    return returnVal;
  }
  const timezoneOffset = date.getTimezoneOffset(); // Get timezone offset in minutes

  // Adjust the date by subtracting the timezone offset
  date = new Date(date.getTime() + timezoneOffset * 60 * 1000);

  return format(date, "MM/dd/yyyy");
};
