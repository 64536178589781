import { TableCell } from "@mui/material";
import React from "react";
const MuiTableCell = ({ onClick, role, children, component, sx, colSpan }) => {
  const styles = {
    position: "relative",
    height: "38px",
    // height: "44px",
    // height: "36px",
    padding: "0 8px",
    // padding: "0 4px",
    borderBottom: "1px solid #e0e0e08a",
    fontSize: ".7rem",

    // height: "30px",
    // minHeight: "30px",
    // padding: isEditing ? "0 4px" : "8px 4px",
    // padding: 0,
    ...sx,
  };

  return (
    <TableCell role={role} onClick={onClick} component={component ? component : "td"} sx={styles} colSpan={colSpan}>
      {children}
    </TableCell>
  );
};
export default MuiTableCell;
