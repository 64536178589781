import { EditRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { useBrokerDrilldownHook } from "../../hooks/useBrokerDrilldownHook";
import ChangeScenarioStatusModal from "../modals/ChangeScenarioStatusModal";

const ChangeLoanScenarioButton = ({ scenario }) => {
  const [show, setShow] = useState(false);

  const { isInternalEmployee } = useBrokerDrilldownHook();
  if (!isInternalEmployee) {
    return null;
  }
  return (
    <>
      <IconButton sx={{ marginLeft: "4px", padding: "1px" }} onClick={() => setShow(true)}>
        <EditRounded sx={{ color: "var(--primary)", fontSize: ".9rem !important" }} />
      </IconButton>
      <ChangeScenarioStatusModal show={show} onCancel={() => setShow(false)} scenario={scenario} />
    </>
  );
};

export default ChangeLoanScenarioButton;
