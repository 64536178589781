import React from "react";
import { useSelector } from "react-redux";
import EditableDrilldownTabCard from "../../components/EditableDrilldownTabCard";

import { CircularProgress, Divider, IconButton, Stack, Tooltip } from "@mui/material";
import { useForm, useFormState } from "react-final-form";
import DrilldownCardTabSection from "../../../../../../components/Drilldown/DrilldownCardTabSection";
import DrilldownCardTabSectionTitle from "../../../../../../components/Drilldown/DrilldownCardTabSectionTitle";
import MuiGrid from "../../../../../../components/shared/MuiGrid";
import GridItem from "../../../../../../components/shared/MuiGrid/GridItem";
import { fileDocumentSchema } from "../../../../../../forms/_formQuestions/_formSchema/fileDocumentSchema";
import { arrayProtector } from "../../../../../../helpers/arrayProtector";
import ScenarioFileUploadCard from "./components/ScenarioFileUploadCard";
import {
  default as ScenarioPendingFilesTable,
  default as UploadedPendingFilesTable,
} from "./tables/ScenarioPendingFilesTable";
import ScenarioApprovedFilesTable from "./tables/ScenarioApprovedFilesTable";
import FlexBox from "../../../../../../components/shared/FlexBox";
import { useBorrowerHook } from "../../../../../../hooks/useBorrowerHook";
import { Download, FolderZipOutlined, FolderZipRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { scenarioFilesDocGroups } from "./shared/scenarioFilesDocGroups";

const ScenarioDrilldownTabFiles = (props) => {
  return (
    // <ReactFinalForm
    //   hideButton
    //   // initialValues={{}}
    //   formSpy
    // >
    //   <FormContent />
    // </ReactFinalForm>
    <FormContent />
  );
};
const FormContent = (props) => {
  const { getIsLoading, downloadScenarioFilesAsZip } = useBorrowerHook();
  const [display, setDisplay] = React.useState(true);
  const formData = useSelector((state) => state.formData);
  const { values } = useFormState();
  const { change } = useForm();

  const initState = {
    documents: [],
  };

  const show = values?.documents?.length >= 1 ? true : false;

  const titleStyles = {
    fontSize: ".95rem",
    fontWeight: 600,
    color: "var(--primary)",
    marginBottom: "15px",
  };

  const uploadableDocGroupCards = scenarioFilesDocGroups.map((group, index) => {
    return (
      <GridItem key={group.name} xs={12} sm={6} md={4} lg={3} xl={2}>
        <ScenarioFileUploadCard noMargin name={group.name} title={group.label} docGroup={group.name} index={index} />
      </GridItem>
    );
  });

  const handleOnCancel = () => {
    change("documents", initState);
  };

  const handleOnZipDownload = () => {
    const params = {
      scenarioId: formData?._id,
      // params: ["approvedForUnderwriting=False"],
      downloadName: "Scenario Files",
    };

    downloadScenarioFilesAsZip(params);
  };

  return (
    <>
      <EditableDrilldownTabCard
        sx={{ height: "auto" }}
        isFiles
        title={"Loan Scenario Files"}
        show={show}
        onCancel={handleOnCancel}
      >
        <LoadingButton
          variant="text"
          loading={getIsLoading}
          onClick={handleOnZipDownload}
          endIcon={<FolderZipOutlined sx={{ fontSize: "1rem !important" }} />}
          sx={{
            // margin: "0px",
            position: "absolute",
            right: "10px",
            top: "10px",
            fontSize: ".7rem",
            padding: "5px",
            // marginRight: "5px",
            color: "var(--blue)",
          }}
        >
          Download As Zip
          {/* {getIsLoading ? <CircularProgress size={14} /> : <Download sx={{ fontSize: "1.2rem" }} />} */}
        </LoadingButton>

        <Stack flexGrow={1} sx={{ form: { flexGrow: 1 } }}>
          <DrilldownCardTabSection sx={{ flexGrow: 1 }}>
            {/* <DrilldownCardTabSectionTitle sx={titleStyles}>Document Groups</DrilldownCardTabSectionTitle> */}
            <MuiGrid spacing={2}>{uploadableDocGroupCards}</MuiGrid>
          </DrilldownCardTabSection>

          <Divider light sx={{ margin: "30px 0 10px" }} flexItem />
          <DrilldownCardTabSection sx={{ flexShrink: 0, flexBasis: "320px" }}>
            <FlexBox justifyBetween alignCenter>
              <DrilldownCardTabSectionTitle sx={titleStyles}>Uploaded Files</DrilldownCardTabSectionTitle>
            </FlexBox>

            <MuiGrid spacing={3}>
              <GridItem mobile size={7}>
                <ScenarioPendingFilesTable title={"Pending Approval"} emptyMessage={"There are no pending documents"} />
              </GridItem>
              <GridItem mobile size={5}>
                <ScenarioApprovedFilesTable
                  title={"Approved For Underwriting"}
                  emptyMessage={"There are no approved documents"}
                  isApproved
                />
              </GridItem>
            </MuiGrid>
          </DrilldownCardTabSection>
        </Stack>
      </EditableDrilldownTabCard>
    </>
  );
};

export default ScenarioDrilldownTabFiles;
