import { isNil } from "lodash";

export const formatAsNumberString = (value, returnVal) => {
  const returnValue = isNil(returnVal) ? "" : returnVal;
  if (isNil(value)) {
    return returnValue;
  }
  let formattedNumber = Intl.NumberFormat("en-US").format(value);

  return formattedNumber;
};
