import React from "react";
import RenderCount from "../components/RenderCount/RenderCount";
// import "./FormResults.css";
import ReactJson from "react-json-view";
const FormResults = ({
  full,
  style,
  rendercount,
  formSpyRaw,
  values,
  theme,
  collapsed,
  collapseStringsAfterLength,
}) => {
  // return <ReactJson src={props.values} />;
  const handleCopy = (copy) => {
    navigator.clipboard.writeText(JSON.stringify(copy.src, null, "\t"));
  };
  return (
    <pre
      style={{
        position: "relative",
        marginTop: full ? 0 : "20px",
        textAlign: "left",
        background: "#efefef",
        // padding: "10px",
        overflowX: "auto",
        maxHeight: full ? "unset" : "650px",
        ...style,
      }}
    >
      {rendercount ? <RenderCount /> : null}

      {formSpyRaw ? (
        JSON.stringify(values, undefined, 2)
      ) : (
        <ReactJson
          enableClipboard={handleCopy}
          // enableClipboard
          style={{ padding: "10px" }}
          theme={theme ? theme : "threezerotwofour"}
          displayDataTypes={false}
          src={values}
          collapsed={collapsed ? collapsed : 1}
          collapseStringsAfterLength={collapseStringsAfterLength ? collapseStringsAfterLength : 30}
        />
      )}
    </pre>
  );
};

export default FormResults;
