import { isArray } from "lodash";

export const replaceObjectInArray = (array, oldObject, newObject) => {
  if (!isArray(array)) {
    return [];
  }
  // Find the index of the old object in the array.
  let index = array.findIndex((ob) => ob._id === oldObject?._id);

  // If the old object is not found in the array, return the original array.
  if (index === -1) {
    return array;
  }

  // Replace the old object with the new object in the array.
  array[index] = newObject;

  // Return the new array.
  // console.log('array: ',array)
  return array;
};
