import { Box, Card, CardContent, Typography } from "@mui/material";
import { isString } from "lodash";
import React from "react";

const DrilldownMultiItemCard = ({ title, headerContent, children, id, cardSx, titleSx, cardBodySx }) => {
  const titleStyles = {
    fontSize: ".95rem",
    fontWeight: 600,
    color: "var(--primary)",
  };

  const titleContent = isString(title) ? (
    <Typography variant="body1" sx={{ ...titleStyles, ...titleSx }}>
      {title}
    </Typography>
  ) : (
    <Box variant="body1" sx={{ ...titleStyles, ...titleSx }}>
      {title}
    </Box>
  );
  return (
    <Card id={id} sx={{ boxShadow: "none", border: "var(--gb)" }}>
      <CardContent
        title={title}
        sx={{
          position: "relative",
          fontSize: "1.4rem",
          borderBottom: "4px solid #e8ecf0e6",
          padding: "16px 4px 8px",
          // paddingBottom: "8px",
          margin: "0 12px",
          // boxShadow: "var(--bs)",
          display: "flex",
          justifyContent: "space-between",
          ...cardSx,
        }}
      >
        {/* <Typography variant="body1" sx={{ ...titleStyles, ...titleSx }}>
          {title}
        </Typography> */}
        {titleContent}
        {headerContent}
      </CardContent>
      <CardContent id="card-body" sx={cardBodySx}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            // mb: 2,
          }}
        >
          {children}
        </Box>
      </CardContent>
    </Card>
  );
};

export default DrilldownMultiItemCard;
