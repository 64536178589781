import React from "react";
import BorderCard from "../BorderCard";

const WidgetCard = ({ id, children, style, sx }) => {
  return (
    <BorderCard id={id} style={{ padding: "14px 10px", ...style, ...sx }}>
      {children}
    </BorderCard>
  );
};

export default WidgetCard;
