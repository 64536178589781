import { isNil } from "lodash";
import { parseISOToDate } from "../../helpers/parseISOToDate";

export const unassignedUsersTableColumns = [
  {
    Header: "Name",
    accessor: (d) => `${d?.firstName} ${d?.lastName}`,
  },

  {
    Header: "Email",
    accessor: "emailAddress",
  },
  {
    Header: "Phone Number",
    accessor: "phoneNumber",
  },
  {
    Header: "Company",
    accessor: "company",
  },
  {
    Header: "Role",
    accessor: "companyRole",
    sortType: "string",
  },
  {
    Header: "Account Created Date",
    accessor: (d) => parseISOToDate(d?.accountCreatedDate),
  },
  {
    Header: "Source",
    accessor: (d) => (isNil(d?.referralSource) ? "Unknown" : getReferralSource(d)),
  },
];

const getReferralSource = (user) => {
  if (isNil(user)) {
    return "Unknown";
  }

  const source = user?.referralSource;
  const isBrokerReferral = source === "Broker referral";
  const isOther = source === "Other";

  if (isBrokerReferral) {
    return user?.referralSourceBrokerName;
  }
  if (isOther) {
    return user?.referralSourceOtherName;
  }

  return source;
};
