import React from "react";
import PseudoFlatironTable from "../../../../../../../components/shared/PseudoFlatironTable";
import FileUploadField from "../../../../../../../components/form/components/inputs/generic/FileUploadField/FileUploadField";
import { Box } from "@mui/material";
import LoanFileUpload from "./LoanFileUpload/LoanFileUpload";

const ScenarioFileUploadCard = ({ title, tableSx, name, docGroup, index }) => {
  return (
    <PseudoFlatironTable noMargin style={{ ...tableSx, overflow: "unset" }} noPadding title={title}>
      <Box
        sx={{
          padding: "6px 10px  !important",
        }}
        alignItems={"center"}
        direction={"row"}
        justifyContent="space-between"
        position="relative"
      >
        <LoanFileUpload noMargin name={name} uploadTitle={"Upload"} docGroup={docGroup} index={index} />
        {/* <FileUploadField noMargin name={name} uploadTitle={"Upload"} docGroup={docGroup} /> */}
      </Box>
    </PseudoFlatironTable>
  );
};

export default ScenarioFileUploadCard;
