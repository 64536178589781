import React from "react";
import DrilldownScreenTab from "../../../../../components/Drilldown/DrilldownScreenTab";
import { AppBar, Box, Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { AddRounded, CloseRounded, EditRounded, SaveRounded, UploadRounded } from "@mui/icons-material";
import Form from "../../../../../components/form/Form/Form";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useBorrowerHook } from "../../../../../hooks/useBorrowerHook";
import ReactFinalForm from "../../../../../components/form/ReactFinalForm";
import { useForm, useFormState } from "react-final-form";
import { setFormData } from "../../../../../redux/actions/formDataActions";
import { isEqual, isNil } from "lodash";
import FlexBox from "../../../../../components/shared/FlexBox";

const EditableDrilldownTabCard = ({
  title,
  children,
  onClick,
  onClickIcon,
  onClickText,
  onCancel,
  cardHeaderBody,
  cardHeader,
  sx,
  show,
  isFiles,
}) => {
  // const [display, setDisplay] = React.useState(true);
  const formData = useSelector((state) => state.formData);
  const { values } = useFormState();
  const isChanged = isEqual(values, formData) ? false : true;
  const showBar = isNil(show) ? isChanged : show;
  const dispatch = useDispatch();
  const { postIsLoading, updateBrokerScenario, uploadScenarioFiles } = useBorrowerHook();

  // const handleClick = () => {
  //   // setDisplay((s) => !s);
  //   onClick((s) => !s);EditableDrilldownTabCard
  // };

  // const handleCancel = () => {
  //   // setDisplay(true);
  //   onClick(true);
  // };
  const handleOnScenarioUpdate = (state) => {
    const onRequestSuccess = (passedData) => {
      console.log("onrequest success", passedData);
      dispatch(setFormData(passedData));
      // onCancel();
    };

    if (isFiles) {
      const documents = {
        documents: state.documents,
      };

      const params = {
        payload: documents,
        onSuccessFn: onRequestSuccess,
      };
      console.log("params", params);
      // uploadScenarioFiles(params);
    } else {
      const params = {
        payload: state,
        id: formData?._id,
        onSuccessFn: onRequestSuccess,
      };
      console.log("params", params);

      updateBrokerScenario(params);
    }
  };

  const valuesAreModifiedButtons = showBar ? (
    <SaveChangesButtons
      isFiles={isFiles}
      onCancel={onCancel}
      onSubmit={handleOnScenarioUpdate}
      isLoading={postIsLoading}
    />
  ) : null;

  const onClickButton = onClick ? (
    <Tooltip placement="top" arrow title={onClickText ? onClickText : " Add New"}>
      <IconButton sx={{ position: "absolute", right: "10px" }} onClick={onClick}>
        {onClickIcon ? onClickIcon : <AddRounded />}
      </IconButton>
    </Tooltip>
  ) : null;

  if (cardHeader) {
    return (
      <Stack>
        <DrilldownScreenTab card title={title} headerContent={onClickButton} noBodyPadding>
          {cardHeaderBody}
        </DrilldownScreenTab>
        {valuesAreModifiedButtons}
        <Stack padding={" 8px 4px"}>{children}</Stack>
      </Stack>
    );
  }
  return (
    <DrilldownScreenTab card title={title} headerContent={onClickButton} sx={sx}>
      {children}
      {valuesAreModifiedButtons}
    </DrilldownScreenTab>
  );
};

const SaveChangesButtons = ({ isFiles, onSubmit, isLoading }) => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.formData);
  const { change } = useForm();
  const { values } = useFormState();

  const onCancelClick = () => {
    dispatch(setFormData(formData));
    change(values, formData);
    // onCancel();
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#ffffffe6",
        top: "auto",
        bottom: 0,
        zIndex: 111111,
        filter: "blue",
        boxShadow: "0px 1px 5px 2px #00000047  , 0px 1px 5px 2px #478ece57 ",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backdropFilter: "blur(2px)",
        gap: "10px",
      }}
    >
      <FlexBox sx={{ width: "100%", padding: "10px 15px" }} justifyBetween alignCenter>
        <Typography variant="body1" fontWeight={600} fontStyle={"italic"}>
          Scenario values have been modified.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            // flexWrap: "wrap",
          }}
        >
          <Button variant="outlined" onClick={onCancelClick} sx={{ fontWeight: "400 !important" }}>
            Cancel changes
          </Button>
          <LoadingButton
            onClick={() => onSubmit(values)}
            variant="contained"
            endIcon={isFiles ? <UploadRounded /> : <SaveRounded />}
            loading={isLoading}
          >
            {isFiles ? "Upload Files" : "Save Changes"}
          </LoadingButton>
        </Box>
      </FlexBox>
    </AppBar>
  );
};

export default EditableDrilldownTabCard;
