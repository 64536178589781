import React from "react";

import FormWizard from "../../components/form/FormWizard/FormWizard";
import { borrowerCreditAuthFormSteps } from "./formSteps";
import { useFormsHook } from "../../hooks/useFormsHook";
import { formStateWithQueryParams } from "../../helpers/formStateWithQueryParams";

const BorrowerCreditAuthorizationFormWizard = ({ onSuccess }) => {
  const { isLoading, submitCreditAuth } = useFormsHook();

  const handleFormSubmit = async (formState) => {
    const params = {
      payload: formStateWithQueryParams(formState),
      onSuccessFn: () => onSuccess(),
    };
    // console.log("params", params);
    // onSuccess();
    await submitCreditAuth(params);
  };

  return (
    <>
      <FormWizard
        id="credit-authorization"
        isLoading={isLoading}
        muiStepper
        alt
        formType={"flatironSiteCreditAuth"}
        formSteps={borrowerCreditAuthFormSteps}
        onSubmit={handleFormSubmit}
        submitButtonText={"Submit Credit Authorization"}
        // clickable
        // formSpy={true}
      />
    </>
  );
};

export default BorrowerCreditAuthorizationFormWizard;
