import { isString } from "lodash";
import { parseISOToDate } from "../../helpers/parseISOToDate";
import { formatPhoneNum } from "../../helpers/phoneNumber/formatPhoneNumber";

export const pendingUsersTableColumns = [
  {
    Header: "Name",
    accessor: (d) => `${d?.firstName} ${d?.lastName}`,
  },
  {
    Header: "Email Sent To",
    accessor: "emailAddress",
  },

  {
    Header: "Phone Number",
    accessor: (d) => (isString(d?.phoneNumber) ? d?.phoneNumber : formatPhoneNum(d?.phoneNumber)),
  },

  {
    Header: "Inviation Date",
    accessor: (d) => parseISOToDate(d?.hubspot_contact?.createdate),
  },
];
