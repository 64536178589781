import React from "react";

import RadioField from "../../../components/form/components/inputs/generic/RadioField/RadioField";

import RadioFieldTrueFalse from "../../../components/form/components/inputs/generic/RadioFieldTrueFalse";
import { Field, useForm, useFormState } from "react-final-form";
import { useSelector } from "react-redux";
import { loanOfficersSelectOptions, loanPurposeOptions, productTypeOptions } from "../../_formQuestions/selectOptions";
import { SelectFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/SelectFieldComponent";
import { VALIDATOR_REQUIRE } from "../../../helpers/validators/inputValidators";
import DependentFields from "../../components/DependentFields";
import { TextFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import { parseNumber } from "../../../helpers/parse/parseNumber";
import { createSelectItemsEmployees } from "../../../helpers/createSelectItems/createSelectItemsEmployees";
import FormGroup from "../../../components/form/components/shared/FormGroup/FormGroup";
import MuiGrid from "../../../components/shared/MuiGrid";
import GridItem from "../../../components/shared/MuiGrid/GridItem";
import { isNil } from "lodash";
import { useMemo } from "react";

const BorrowerLoanAppProduct = (props) => {
  const { change } = useForm();

  const handleEmployeeChange = (data) => {
    console.log(data.target.value);
    let filteredEmployee = loanOfficersSelectOptions.filter((e) => e.fullName === data.target.value);
    let employee = filteredEmployee === undefined ? "" : filteredEmployee[0];
    change("loanSalesperson", employee);
  };

  const handleNumOfPropertyChange = (data, dataValue) => {
    console.log(dataValue);
    let val = dataValue;

    if (val === "Single") {
      change("subjectProperties", []);
    }

    if (val === "Multiple") {
      change("numberOfSubjectProperties", 1);
    }
  };

  const handleNumPropertiesInit = (data, dataValue) => {
    let val = isNil(dataValue) || dataValue === "" ? 1 : dataValue;
    let properties = 1;

    if (val >= 10) {
      properties = 10;
    } else {
      properties = val;
    }

    let initializedArray = new Array(Number(properties));
    console.log("initializedArray", initializedArray);
    change(`subjectProperties`, initializedArray);
  };

  const employeeList = useMemo(() => createSelectItemsEmployees(loanOfficersSelectOptions), []);

  return (
    <>
      <FormGroup noMargin>
        <MuiGrid>
          <GridItem size={12}>
            <Field
              noMargin
              name={"loanProductType"}
              fieldLabel="Which product are you interested in?"
              label="Product Type"
              component={SelectFieldComponent}
              validate={VALIDATOR_REQUIRE}
              items={productTypeOptions}
            />
          </GridItem>
          <GridItem size={12}>
            <RadioField isRequired name={"loanPurpose"} items={loanPurposeOptions} fieldLabel="Loan Purpose" />
          </GridItem>

          <DependentFields name="loanProductType" values={["30-Year Rental Loan", "Fix and Flip", "Stabilized Bridge"]}>
            <GridItem size={12}>
              <RadioFieldTrueFalse
                name={"loanPropertyHasMoreThanFourUnits"}
                fieldLabel="Would any of the properties to be financed have greater than 4-units or be considered mixed-use?"
                isRequired
                items={["Yes", "No"]}
                helperText={"(i.e. residential combined with office or retail space)"}
              />
            </GridItem>
            <GridItem size={12}>
              <RadioField
                isRequired
                name={"applicationIsForMultipleProperties"}
                fieldLabel="Is application related to financing of a single or multiple subject properties?"
                items={["Single", "Multiple"]}
                onChange={handleNumOfPropertyChange}
              />
            </GridItem>
            <DependentFields name="applicationIsForMultipleProperties" value="Multiple">
              <GridItem size={12}>
                <Field
                  name={`numberOfSubjectProperties`}
                  fieldLabel="How many properties will be financed?"
                  label="Number of Properties"
                  validate={VALIDATOR_REQUIRE}
                  parse={parseNumber}
                  onChange={handleNumPropertiesInit}
                  component={TextFieldComponent}
                />
              </GridItem>
            </DependentFields>
            <GridItem size={12}>
              <RadioFieldTrueFalse
                isRequired
                name={"hasWorkedWithFlatironInPast"}
                items={["Yes", "No"]}
                fieldLabel="Have you worked with Flatiron Realty Capital in the past?"
              />
            </GridItem>
            <DependentFields name="hasWorkedWithFlatironInPast" value="true">
              <GridItem size={12}>
                <Field
                  name={"previousFlatironListedContact"}
                  fieldLabel="Who was your primary contact?"
                  label="Employee Name"
                  validate={VALIDATOR_REQUIRE}
                  component={SelectFieldComponent}
                  // component={EmployeeSelectFieldComponent}
                  onSelectChange={handleEmployeeChange}
                  items={employeeList}
                />
              </GridItem>
              <DependentFields name="previousFlatironListedContact" value="Not Listed">
                <GridItem size={12}>
                  <Field
                    name={"previousFlatironNonListedContact"}
                    label="Employee Name"
                    validate={VALIDATOR_REQUIRE}
                    component={TextFieldComponent}
                  />
                </GridItem>
              </DependentFields>
            </DependentFields>
          </DependentFields>
        </MuiGrid>
      </FormGroup>
    </>
  );
};

export default BorrowerLoanAppProduct;
