import { useStytch, useStytchSession, useStytchUser } from "@stytch/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuthHook } from "../hooks/useAuthHook";
import BrokerView from "../views/BrokerView/BrokerView";
import { useBorrowerHook } from "../hooks/useBorrowerHook";
import { isNil } from "lodash";

const PortalWorkspace = (props) => {
  const { performRequiredRequests } = useAuthHook();
  const { session } = useStytchSession();
  const stytch = useStytch();
  const { user } = useStytchUser();

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("🚀 ~ WORKSPACE INIT");
      console.log("      ~ SESSION", session);
      console.log("      ~ USER", user);
      console.log("      ~ STYCH TOKENS", stytch.session.getTokens());
    }

    if (user && session) {
      performRequiredRequests();
    }
  }, []);

  return (
    <>
      {/* {(userType.userType === undefined || userType.userType === "") && (
        <FullPageLoader />
      )} */}

      {user?.trusted_metadata?.userCategory === "broker" ? <BrokerView /> : null}
      {user?.trusted_metadata?.userCategory === "borrower" ? <BrokerView /> : null}
    </>
  );
};

export default PortalWorkspace;
