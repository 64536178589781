import React, { useEffect } from "react";
import { Field, useForm, useFormState } from "react-final-form";

import AddressAutofillField from "../../../components/form/components/inputs/generic/AddressAutofillField/AddressAutofillField";

import FormGroup from "../../../components/form/components/shared/FormGroup/FormGroup";
import { VALIDATOR_REQUIRE } from "../../../helpers/validators/inputValidators";

import { AddOutlined } from "@mui/icons-material";
import { isNil } from "lodash";
import { FieldArray } from "react-final-form-arrays";
import { buttonGhost } from "../../../assets/styles/SharedInlineStyles";
import { DatePickerTextFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/DatePickerTextFieldComponent";
import { SelectFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/SelectFieldComponent";
import { TextFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import FileUploadField from "../../../components/form/components/inputs/generic/FileUploadField/FileUploadField";
import RadioField from "../../../components/form/components/inputs/generic/RadioField/RadioField";
import WizardPageSubHeader from "../../../components/form/FormWizard/Components/WizardPageSubHeader";
import AdditionEquationRow from "../../../components/shared/AdditionEquationRow";
import MuiGrid from "../../../components/shared/MuiGrid";
import GridItem from "../../../components/shared/MuiGrid/GridItem";
import { fieldNames } from "../../../global/forms/fieldNames";
import { getObjectValueFromStringPath } from "../../../helpers/getObjectValueFromStringPath";
import DependentFields from "../../components/DependentFields";
import MultipleItemCard from "../../components/MultipleItemCard";
import PropertyUnitCard from "../../components/PropertyUnitCard";
import { subjectPropertyTypeOptions } from "../../_formQuestions/selectOptions";
import { fileDocumentSchema } from "../../_formQuestions/_formSchema/fileDocumentSchema";
import FinalFormTextField from "../../../components/form/Fields/FinalFormTextField";
import FinalFormSelectField from "../../../components/form/Fields/FinalFormSelectField";

let dscr = "30-Year Rental Loan";
let fix = "Fix and Flip";
let stabilized = "Stabilized Bridge";
let groundUp = "Ground Up Construction";
let land = "Land Purchase";
let other = "Other";

const BorrowerLoanAppSubjectProperty = (props) => {
  const { values } = useFormState();
  const { change, mutators } = useForm();

  const addAdditionalFieldHandler = (name) => {
    mutators.push(name, {});
  };
  const headerText =
    values?.applicationIsForMultipleProperties === "Single" || values?.applicationIsForMultipleProperties === undefined
      ? "Subject Property"
      : `Subject Properties`;

  useEffect(() => {
    if (values.subjectProperties === null || values.subjectProperties === undefined) {
      addAdditionalFieldHandler("subjectProperties");
    }
  }, []);

  return (
    <>
      <div className={`subject-properties-current-step-container  `}>
        {/* <WizardPageHeader>{headerText}</WizardPageHeader> */}
        <FieldArray name={"subjectProperties"}>
          {({ fields }) =>
            fields.map((name, index) => <SubjectPropertyCard name={name} index={index} fields={fields} {...props} />)
          }
        </FieldArray>
        <div className="add-additional-field-wrapper center">
          <button
            style={buttonGhost}
            onClick={() => addAdditionalFieldHandler("subjectProperties")}
            className="add-additional-button"
            type="button"
          >
            <span>Add Subject Property</span>
            <AddOutlined />
          </button>
        </div>
      </div>
    </>
  );
};

const SubjectPropertyCard = (props) => {
  const { values, errors, form } = useFormState();
  const { mutators, change } = useForm();
  const { name, index, fields } = props;

  const addAdditionalFieldHandler = (name) => {
    mutators.push(name, {});
  };
  let dscr = "30-Year Rental Loan";
  let fix = "Fix and Flip";
  let stabilized = "Stabilized Bridge";

  const handleNumPropertiesInit = (data, dataValue) => {
    // console.log("handleNumPropertiesInit-------------------:::", dataValue);
    let val = isNil(dataValue) || dataValue === "" ? 1 : dataValue;
    let properties = 1;
    // let val = data.target.value;

    if (val >= 10) {
      properties = 10;
    } else {
      properties = val;
    }

    let initializedArray = new Array(Number(properties)).fill({});
    // console.log("initializedArray", initializedArray);
    change(`${name}.units`, initializedArray);
  };

  return (
    <MultipleItemCard
      title={"Subject Property"}
      onRemove={() => fields.remove(index)}
      index={index}
      unitsPathName={`subjectProperties`}
    >
      <FormGroup noMargin>
        <WizardPageSubHeader>Property Information & Details</WizardPageSubHeader>
        <MuiGrid>
          <GridItem mobile size={12}>
            <AddressAutofillField
              noMargin
              // noFields
              placeholder="Begin typing property address..."
              name={name}
              label="Subject Property Address"
              // value={
              //   values?.subjectProperties === undefined
              //     ? false
              //     : values?.subjectProperties[index]?.address
              // }
            />
          </GridItem>
        </MuiGrid>

        <MuiGrid>
          <DependentFields name="loanProductType" values={[stabilized, dscr]}>
            <GridItem mobile size={6}>
              <FormGroup noMargin>
                <RadioField
                  fieldLabel="Are you managing the property yourself?"
                  isRequired
                  name={`${name}.${fieldNames.isSelfManagingProperty}`}
                  items={["Yes", "No"]}
                />
              </FormGroup>
            </GridItem>
          </DependentFields>

          <DependentFields name="loanPurpose" value="Purchase">
            <GridItem mobile size={6}>
              <FormGroup noMargin>
                <RadioField
                  fieldLabel="Will property be delivered vacant?"
                  isRequired
                  name={`${name}.propertyWillBeVacant`}
                  items={["Yes", "No"]}
                />
              </FormGroup>
            </GridItem>
          </DependentFields>
        </MuiGrid>
      </FormGroup>
      {/* --------------------PROPERTY DETAILS SECTION--------------------------- */}
      <FormGroup
        // formGroupStyle={{
        //   paddingTop: "10px",
        //   marginTop: "20px",
        //   borderTop: "1px solid #80808052",
        // }}
        bold
        fieldLabel={"Property Details"}
      >
        <MuiGrid>
          <GridItem mobile size={8}>
            <FinalFormSelectField
              name={`${name}.propertyType`}
              label="Property Type"
              // component={SelectFieldComponent}
              items={subjectPropertyTypeOptions}
            />
          </GridItem>
          <GridItem mobile size={4}>
            <Field
              noMargin
              name={`${name}.numberOfUnits`}
              label="Number Of Units"
              validate={VALIDATOR_REQUIRE}
              onChange={handleNumPropertiesInit}
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem mobile size={12}>
            <FinalFormTextField name={`${name}.${fieldNames.asIsValue}`} label="As-Is Value" required dollar />
          </GridItem>
          <DependentFields name="loanProductType" value={groundUp} notEqual>
            <GridItem mobile size={12}>
              <FinalFormTextField name={`${name}.squareFootage`} label="Square Footage" />
            </GridItem>
          </DependentFields>
          <DependentFields name="loanPurpose" value="Purchase">
            <GridItem mobile size={6}>
              <FinalFormTextField
                name={`${name}.purchasePrice`}
                label="Purchase Price"
                required
                // validate={VALIDATOR_REQUIRE}
                dollar
                // component={TextFieldComponent}
              />
            </GridItem>
            <GridItem mobile size={6}>
              <Field name={`${name}.purchaseDate`} label="Purchase Date" component={DatePickerTextFieldComponent} />
            </GridItem>

            <DependentFields name="loanProductType" value={groundUp}>
              <GridItem mobile size={12}>
                <FinalFormTextField
                  name={`${name}.constructionBudget`}
                  label="Construction Budget"
                  // validate={VALIDATOR_REQUIRE}
                  isRequired
                  dollar
                  // component={TextFieldComponent}
                />
              </GridItem>
            </DependentFields>
          </DependentFields>
          <DependentFields name="loanPurpose" value="Purchase" notEqual>
            <GridItem mobile size={6}>
              <FinalFormTextField
                name={`${name}.originalPurchasePrice`}
                label="Original Purchase Price"
                // validate={VALIDATOR_REQUIRE}
                required
                // component={TextFieldComponent}
                dollar
              />
            </GridItem>
            <GridItem mobile size={6}>
              <Field
                name={`${name}.originalPurchaseDate`}
                label="Original Purchase Date"
                validate={VALIDATOR_REQUIRE}
                component={DatePickerTextFieldComponent}
              />
            </GridItem>
            <GridItem mobile size={12}>
              <FinalFormTextField
                name={`${name}.unpaidPrincipalBalance`}
                label="UPB Existing Loan"
                dollar
                // validate={VALIDATOR_REQUIRE}
                required
                // component={TextFieldComponent}
                helperText="(Unpaid Principal Balance)"
              />
            </GridItem>
          </DependentFields>

          <DependentFields name="loanProductType" value={fix}>
            <GridItem mobile size={12}>
              <FinalFormTextField
                name={`${name}.postRenovationSquareFootage`}
                label="Post-Rehab Square Footage"
                // validate={VALIDATOR_REQUIRE}
                required
                // component={TextFieldComponent}
              />
            </GridItem>

            <GridItem mobile size={12}>
              <FormGroup bold fieldLabel="Renovation Costs">
                <AdditionEquationRow
                  value1={getObjectValueFromStringPath(values, `${name}.softRenovationCosts`)}
                  value2={getObjectValueFromStringPath(values, `${name}.hardRenovationCosts`)}
                >
                  <FinalFormTextField
                    name={`${name}.softRenovationCosts`}
                    label="Soft Renovation Costs"
                    required
                    // validate={VALIDATOR_REQUIRE}
                    dollar
                    // component={TextFieldComponent}
                  />
                  <FinalFormTextField
                    name={`${name}.hardRenovationCosts`}
                    label="Hard Renovation Costs"
                    required
                    // validate={VALIDATOR_REQUIRE}
                    dollar
                    // component={TextFieldComponent}
                  />
                </AdditionEquationRow>
              </FormGroup>
            </GridItem>
            <GridItem mobile size={12}>
              <FinalFormTextField
                name={`${name}.postRenovationValue`}
                label="After Renovation Value"
                // validate={VALIDATOR_REQUIRE}
                required
                dollar
                // component={TextFieldComponent}
              />
            </GridItem>
          </DependentFields>
        </MuiGrid>
      </FormGroup>

      <DependentFields name="loanProductType" values={[fix, stabilized, dscr]}>
        {/* If Not Purchase  */}

        {/*  '---------------------Property Costs--------------------' */}

        {/*  '--------------------- Taxes & Insurance --------------------' */}
        <FormGroup bold fieldLabel="Taxes & Insurance">
          <MuiGrid>
            <GridItem mobile size={6}>
              <FinalFormTextField
                name={`${name}.annualPropertyTaxes`}
                label="Annual Property Taxes"
                // validate={VALIDATOR_REQUIRE}
                required
                dollar
                // component={TextFieldComponent}
              />
            </GridItem>
            <GridItem mobile size={6}>
              <FinalFormTextField
                name={`${name}.annualPropertyInsurance`}
                label="Annual Property Insurance"
                required
                // validate={VALIDATOR_REQUIRE}
                // component={TextFieldComponent}
                dollar
              />
            </GridItem>

            <GridItem mobile size={12}>
              <Field
                fieldLabel="Does this property have additonal insurance or fees?"
                isRequired
                label="Yes/No"
                component={SelectFieldComponent}
                name={`${name}.hasAdditionalPropertyOrInsuranceFees`}
                items={["Yes", "No"]}
                helperText={"(i.e. Flood Insurance, Homeowners Association, or Property Management Fees)"}
              />
            </GridItem>
            {values?.subjectProperties[props.index]?.hasAdditionalPropertyOrInsuranceFees === "Yes" && (
              <>
                <GridItem mobile size={12}>
                  <FinalFormTextField
                    name={`${name}.annualFloodInsurance`}
                    label="Annual Flood Insurance"
                    dollar
                    // validate={VALIDATOR_REQUIRE}

                    // component={TextFieldComponent}
                    helperText="(if applicable)"
                  />
                </GridItem>
                <GridItem mobile size={12}>
                  <FinalFormTextField
                    name={`${name}.annualHomeownersAssociationFees`}
                    label="Annual HOA Fees"
                    dollar
                    // validate={VALIDATOR_REQUIRE}

                    // component={TextFieldComponent}
                    helperText="(if applicable)"
                  />
                </GridItem>
                <GridItem mobile size={12}>
                  <FinalFormTextField
                    name={`${name}.annualPropertyManagementFees`}
                    label="Annual Property Management Fees"
                    dollar
                    // validate={VALIDATOR_REQUIRE}

                    // component={TextFieldComponent}
                    helperText="(if applicable)"
                  />
                </GridItem>
              </>
            )}
          </MuiGrid>
        </FormGroup>
      </DependentFields>

      {/* <FormGroup fieldLabel="Appraisals"> */}
      {!isNil(getObjectValueFromStringPath(values, `${name}.${fieldNames.units}`)) ? (
        <>
          <FormGroup
            formGroupStyle={{
              paddingTop: "20px",
              borderTop: "2px dashed #80808052",
            }}
          >
            <WizardPageSubHeader>Property Units</WizardPageSubHeader>

            <FieldArray name={`${name}.${fieldNames.units}`}>
              {({ fields }) =>
                fields.map((unitName, unitIndex) => (
                  <PropertyUnitCard
                    title="Unit Details"
                    id="subject-property-unit"
                    index={unitIndex}
                    unitsPathName={`${name}.${fieldNames.units}`}
                    onRemove={() => fields.remove(unitIndex)}
                  >
                    <MuiGrid>
                      <DependentFields name="loanProductType" value={groundUp}>
                        <GridItem mobile size={12}>
                          <FinalFormSelectField
                            name={`${unitName}.expectedPostRenovationRent`}
                            label="Expected Post Renovation Rent"
                            dollar
                            items={["Residential Unit", "Non-Residential"]}
                            // component={TextFieldComponent}
                          />
                        </GridItem>

                        <GridItem mobile size={12}>
                          <FinalFormTextField
                            name={`${unitName}.unitMonthlyInPlaceRent`}
                            label="Unit Monthly In-Place Rent"
                            dollar
                            // component={TextFieldComponent}
                          />
                        </GridItem>

                        <GridItem mobile size={12}>
                          <FinalFormTextField
                            name={`${name}.squareFootage`}
                            label="Square Footage"
                            // validate={VALIDATOR_REQUIRE}
                            // component={TextFieldComponent}
                          />
                        </GridItem>
                      </DependentFields>
                      <DependentFields name="loanProductType" value={groundUp} notEqual>
                        <GridItem mobile size={12}>
                          <RadioField
                            // vertical
                            noMargin
                            name={`${unitName}.unitOccupied`}
                            items={["Yes", "No"]}
                            fieldLabel="Is the unit occupied?"
                          />
                        </GridItem>
                        <DependentFields name="loanProductType" value={stabilized} notEqual>
                          <GridItem mobile size={12}>
                            <FinalFormTextField
                              name={`${unitName}.expectedPostRenovationRent`}
                              label="Expected Post Renovation Rent"
                              dollar
                              items={["Residential Unit", "Non-Residential"]}
                              // component={TextFieldComponent}
                            />
                          </GridItem>
                        </DependentFields>

                        <GridItem mobile size={4}>
                          <FinalFormTextField
                            name={`${unitName}.unitMonthlyMarketRent`}
                            label="Unit Monthly Market Rent"
                            dollar
                            items={["Residential Unit", "Non-Residential"]}
                            // component={TextFieldComponent}
                          />
                        </GridItem>
                        <GridItem mobile size={4}>
                          <FinalFormTextField
                            name={`${unitName}.unitMonthlyInPlaceRent`}
                            label="Unit Monthly In-Place Rent"
                            dollar
                            // component={TextFieldComponent}
                          />
                        </GridItem>
                        <GridItem mobile size={4}>
                          <FinalFormTextField
                            name={`${unitName}.expectedStabilizedRent`}
                            label="Expected Stabilized Rent"
                            dollar
                            // component={TextFieldComponent}
                          />
                        </GridItem>

                        <GridItem mobile size={12}>
                          <FinalFormSelectField
                            name={`${unitName}.leaseType`}
                            label="Current Lease Type"
                            items={[
                              "Long-term (original term >= 12 months)",
                              "Month-to-Month",
                              "Short-term (vacation rental, AirBnB or VRBO)",
                              "No Lease in Place",
                            ]}
                            component={SelectFieldComponent}
                          />
                        </GridItem>
                        <GridItem mobile size={6}>
                          <Field
                            name={`${unitName}.leaseBeginDate`}
                            label="Lease Begin Date"
                            component={DatePickerTextFieldComponent}
                          />
                        </GridItem>
                        <GridItem mobile size={6}>
                          <Field
                            name={`${unitName}.leasEndDate`}
                            label="Lease End Date"
                            component={DatePickerTextFieldComponent}
                          />
                        </GridItem>
                        <GridItem mobile size={12}>
                          <FileUploadField
                            name={`${unitName}.unitLeaseDocs`}
                            fieldLabel="Unit Lease Docs"
                            docGroup={fileDocumentSchema.leasesAndRents.name}
                            docType={fileDocumentSchema.leasesAndRents.types.unitLeaseDocs.name}
                          />
                        </GridItem>
                      </DependentFields>
                    </MuiGrid>
                  </PropertyUnitCard>
                ))
              }
            </FieldArray>
          </FormGroup>
          <div className="add-additional-field-wrapper center">
            <button
              style={{ background: "#1c456a1c", ...buttonGhost }}
              onClick={() => addAdditionalFieldHandler(`${name}.${fieldNames.units}`)}
              className="add-additional-button"
              type="button"
            >
              <span>Add Unit</span>
              <AddOutlined />
            </button>
          </div>
        </>
      ) : null}
    </MultipleItemCard>
  );
};

export default BorrowerLoanAppSubjectProperty;
