import { Box } from "@mui/material";
import React from "react";

const FlexBox = ({
  column,
  justifyBetween,
  justifyCenter,
  alignBetween,
  center,
  alignCenter,
  justifyContent,
  alignItems,
  gap,
  fullWidth,
  grow,
  className,
  sx,
  children,
}) => {
  const justifyContentDefault = justifyBetween ? "space-between" : "flex-start";
  const alignItemsDefault = alignBetween ? "space-between" : "flex-start";

  const justifyContentStyles = center || justifyCenter ? "center" : justifyContentDefault;
  const alignItemsStyles = center || alignCenter ? "center" : alignItemsDefault;

  return (
    <Box
      display={"flex"}
      sx={sx}
      width={fullWidth ? "100%" : "initial"}
      flexGrow={grow ? 1 : "initial"}
      className={className}
      flexDirection={column ? "column" : "row"}
      gap={gap}
      justifyContent={justifyContent ? justifyContent : justifyContentStyles}
      alignItems={alignItems ? alignItems : alignItemsStyles}
    >
      {children}
    </Box>
  );
};

export default FlexBox;
