import { TableCell } from "@mui/material";
import React from "react";
const LoanFilesTableCell = ({
  isEditing,
  children,
  component,
  sx,
  colSpan,
}) => {
  const styles = {
    position: "relative",
    padding: isEditing ? "0 4px" : "8px 4px",
    fontSize: ".7rem",
    ...sx,
  };

  return (
    <TableCell
      component={component ? component : "td"}
      sx={styles}
      colSpan={colSpan}
    >
      {children}
    </TableCell>
  );
};
export default LoanFilesTableCell;
