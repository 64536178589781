import { TableCell, Tooltip } from "@mui/material";
import React from "react";

const MuiCustomTableCell = ({ isDisplay, th, displayValue, children, toolTipTitle, colSpan, sx, noBorder }) => {
  const tableCellStyles = {
    position: "relative",
    padding: "6px 10px",
    // padding: !isDisplay ? "8px 6px" : "10px",
    fontSize: ".7rem",
    borderBottom: noBorder ? "none" : "1px solid rgba(224, 224, 224, 1)",
    ...sx,
    "& .MuiInput-input": {
      padding: "6px",
      fontSize: ".7rem",
    },
    "#date-picker": {
      ".MuiIconButton-root": {
        padding: 0,
        margin: 0,
        svg: {
          fontSize: ".8rem",
        },
        ".MuiSvgIcon-root": {
          marginLeft: "0px !important",
        },
      },
      ".MuiInputAdornment-root": {
        marginLeft: "0 !important",
        marginRight: "2px",
      },
    },
  };
  const tableHeadStyles = { fontWeight: "600" };

  if (toolTipTitle) {
    return (
      <Tooltip arrow title={toolTipTitle}>
        <TableCell component={"td"} sx={tableCellStyles}>
          {isDisplay ? displayValue : children}
        </TableCell>
      </Tooltip>
    );
  }

  if (th) {
    return (
      <TableCell colSpan={colSpan} component={"th"} sx={{ ...tableCellStyles, ...tableHeadStyles }}>
        {children}
      </TableCell>
    );
  }

  return (
    <TableCell colSpan={colSpan} component={"td"} sx={tableCellStyles}>
      {isDisplay ? displayValue : children}
    </TableCell>
  );
};

export default MuiCustomTableCell;
