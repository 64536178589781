import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import BrokerDashboardScreen from "./screens/BrokerDashboardScreen/BrokerDashboardScreen";
import BrokerDealsInUnderwritingScreen from "./screens/BrokerDealsInUnderwritingScreen/BrokerDealsInUnderwritingScreen";
import BrokerLoanScenarioDrilldownScreen from "./screens/BrokerLoanScenarioDrilldownScreen/BrokerLoanScenarioDrilldownScreen";
import BrokerLoanScenariosScreen from "./screens/BrokerLoanScenariosScreen/BrokerLoanScenariosScreen";
import BrokerUnderwritingMaterialScreen from "./screens/BrokerUnderwritingMaterialScreen/BrokerUnderwritingMaterialScreen";
import ProfileScreen from "./screens/ProfileScreen/ProfileScreen";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalData } from "../../redux/actions/globalDataActions";
import { useEffect } from "react";
import MyOrganizationScreen from "./screens/MyOrganizationScreen/MyOrganizationScreen";
import UsersScreen from "./screens/UsersScreen/UsersScreen";
import { useBrokerDrilldownHook } from "../../hooks/useBrokerDrilldownHook";
import CompaniesScreen from "./screens/CompaniesScreen/CompaniesScreen";
import CompanyDrilldownScreen from "./screens/CompanyDrilldownScreen/CompanyDrilldownScreen";
import UsersScreenImproved from "./screens/UsersScreen/UsersScreenImproved";
// import "./styles/BorrowerView.css";

const BrokerView = (props) => {
  const globalData = useSelector((state) => state.globalData);
  const dispatch = useDispatch();
  const { isInternalEmployee } = useBrokerDrilldownHook();
  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 900) {
        const updatedData = {
          ...globalData,
          isOpen: false,
          isMobile: true,
        };
        dispatch(setGlobalData(updatedData));
      } else {
        const updatedData = {
          ...globalData,
          isOpen: true,
          isMobile: false,
        };
        dispatch(setGlobalData(updatedData));
      }
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <Routes>
      <Route path={"/*"} element={<BrokerDashboardScreen />} />
      <Route path={"/scenarios/*"}>
        <Route index element={<BrokerLoanScenariosScreen />} />
        <Route path={":id/*"} element={<BrokerLoanScenarioDrilldownScreen />} />
      </Route>
      <Route path={"/deals-in-underwriting"} element={<BrokerDealsInUnderwritingScreen />} />

      {/* <Route path={"/my-organization"} element={<MyOrganizationScreen />} /> */}
      {isInternalEmployee ? (
        <Route path={"/companies/*"}>
          <Route index element={<CompaniesScreen />} />
          {/* <Route path={":id/*"} element={<CompanyDrilldownScreen />} /> */}
        </Route>
      ) : null}
      {/* {isInternalEmployee ? <Route path={"/companies"} element={<CompaniesScreen />} /> : null} */}
      {isInternalEmployee ? <Route path={"/users2"} element={<UsersScreenImproved />} /> : null}
      {isInternalEmployee ? <Route path={"/users"} element={<UsersScreen />} /> : null}
      <Route path={"/underwriting-material"} element={<BrokerUnderwritingMaterialScreen />} />
      <Route path={"/profile"} element={<ProfileScreen />} />
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
};
const userSchema = {
  _id: "65b2ccf7c04ea679a5687ef0",
  firstName: "William ",
  lastName: "Chang",
  emailAddress: "WChang@advisorsmortgage.com",
  phoneNumber: "(609) 601-4390",
  company: "Advisors Mortgage",
  companyRole: "Branch Manager",
  companyUrl: "www.AdvisorsMortgage.com",
  activeStates: ["NJ"],
  referralSource: "Direct outreach",
  userCategory: "broker",
  isActiveUser: true,
  isInternalUser: false,
  fullName: "William  Chang",
  accountCreatedDate: "2024-01-25T21:04:53.586000",
  stytch_user_id: "user-live-22d4887e-badf-490d-b151-485ef4cc1e78",
  assignedSalesperson: {
    emailAddress: "lm@flatironrealtycapital.com",
    userType: "loanOfficer",
    firstName: "Leila",
    lastName: "Monasebian",
    fullName: "Leila Monasebian",
    ironfund_email: "lm@ironfund.com",
  },
};
export default BrokerView;
