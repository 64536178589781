import React from "react";
// import IronFundLogo from "../../assets/images/iron-fund-logo.png";
import IronFundIronLogo from "../../assets/images/iron-fun-logo-iron.png";

const IronFundLogo = ({ width, center, color, building, sx }) => {
  const styles = {
    display: "flex",
    justifyContent: "center",
  };

  const ironFund = (
    <svg
      // style={{ fill: "var(--primary)" }}
      style={{ fill: "url(#silver)", width: "100%" }}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 1500 250"
    >
      <defs>
        <linearGradient id="silver" x1="0.97" y1="0.67" x2="0.03" y2="0.33">
          <stop offset="0%" stopColor="#6b6b6b" />
          <stop offset="5.6%" stopColor="#747474" />
          <stop offset="11.2%" stopColor="#7d7d7d" />
          <stop offset="16.8%" stopColor="#868686" />
          <stop offset="28%" stopColor="#999999" />
          <stop offset="36.4%" stopColor="#8c8c8c" />
          <stop offset="44.8%" stopColor="#808080" />
          <stop offset="53.2%" stopColor="#737373" />
          <stop offset="70%" stopColor="#5b5b5b" />
          <stop offset="76%" stopColor="#666666" />
          <stop offset="82%" stopColor="#727171" />
          <stop offset="88%" stopColor="#7d7c7d" />
          <stop offset="100%" stopColor="#949495" />
          {/* <stop offset="0%" stopColor="#6b6b6b" />
          <stop offset="8.6%" stopColor="#777777" />
          <stop offset="17.2%" stopColor="#848384" />
          <stop offset="25.8%" stopColor="#909091" />
          <stop offset="43%" stopColor="#a9aaaa" />
          <stop offset="54.4%" stopColor="#a5a6a6" />
          <stop offset="65.8%" stopColor="#a0a1a2" />
          <stop offset="77.2%" stopColor="#9c9d9d" />
          <stop offset="100%" stopColor="#949495" /> */}
        </linearGradient>
        <linearGradient id="silver-vertical" x1="100%" y1="0%" x2="50%" y2="100%">
          <stop offset="0%" stopColor="#808080"></stop>
          <stop offset="100%" stopColor="#e0e0e0"></stop>
        </linearGradient>
        <linearGradient id="silver-horizontal" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#808080"></stop>
          <stop offset="100%" stopColor="#e0e0e0"></stop>
        </linearGradient>
        {/* <linearGradient id="silver-horizontal" x1="0%" y1="50%" x2="100%" y2="50%">
          <stop offset="0%" stopColor="#808080"></stop>
          <stop offset="100%" stopColor="#e0e0e0"></stop>
        </linearGradient> */}
      </defs>
      <g>
        <path d="M53.8,205.2V50.4h41.5v154.8H53.8z" />
        <path d="M139.4,205.2V50.4h86.3c23.5,0,38.2,6.9,48.1,17.3c8.3,8.8,13.3,20.2,13.3,33c0,19-10.9,33.9-28.4,42.9l35.6,61.6h-46.9   l-29.6-54.5h-36.7v54.5H139.4z M180.9,84.1v33H228c9.7,0,17.5-5.9,17.5-16.6c0-4.3-1.7-8.5-4.5-11.6c-3.8-4-8.8-4.7-13-4.7H180.9z" />
        <path d="M399.2,45.7c49.3,0,88.9,36.7,88.9,81.8c0,45.8-39.6,82.5-88.9,82.5s-87.9-36.7-87.9-82.5   C311.2,82.4,349.9,45.7,399.2,45.7z M399.2,175.3c25.6,0,45.8-21.8,45.8-47.9c0-25.6-20.2-46.9-45.8-46.9c-25.4,0-45,21.3-45,46.9   C354.1,153.5,373.8,175.3,399.2,175.3z" />
        <path d="M664.7,50.4v154.8h-35.1L561.3,121h-0.2v84.2h-41.7V50.4h34.8l68.5,84.4h0.2V50.4H664.7z" />
        <path d="M762.1,205.2V50.4h131.3v34.8h-89.8v24.4h54.5v33.4h-54.5v62.1H762.1z" />
        <path d="M1062.5,50.4v98.4c0,39.1-30.3,61.2-72.8,61.2c-40.5,0-72.5-22-72.5-61.2V50.4h41.7v98.4c0,17.3,14.2,26.6,30.8,26.6   c18.3,0,31.3-9.2,31.3-26.6V50.4H1062.5z" />
        <path d="M1249.3,50.4v154.8h-35.1l-68.3-84.2h-0.2v84.2h-41.7V50.4h34.8l68.5,84.4h0.2V50.4H1249.3z" />
        <path d="M1293.3,205.2V50.4h71.4c28.9,0,50.3,8.1,64.7,23.2c13.3,14,20.6,33,20.6,54c0,21.6-7.6,41.5-21.8,55.5   c-13.5,13.3-33,22-63.5,22H1293.3z M1363.8,170.3c15.9,0,23.9-3.6,30.8-10.2c8.1-7.8,12.6-17.5,12.6-32c0-13.5-4-23.5-11.4-31.3   c-6.9-7.3-14.9-11.6-32-11.6h-28.9v85.1H1363.8z" />
      </g>
    </svg>
  );

  // return (
  //   <div
  //     id="flat-iron-logo"
  //     style={{
  //       ...styles,
  //       width: width ? width : "120px",
  //       height: "auto",
  //       margin: center ? "0 auto" : "unset",
  //     }}
  //     className="logo-wrapper"
  //   >
  //     <img
  //       style={{ width: "100%", height: "100%", objectFit: "cover" }}
  //       // src={IronFundLogoShadow}
  //       src={IronFundLogo}
  //       alt="iron fund logo"
  //       srcset=""
  //     />
  //     {/* {building ? buildingIcon : full} */}
  //   </div>
  // );
  return (
    <div
      id="flat-iron-logo"
      style={{
        width: width ? width : "120px",
        height: "auto",
        margin: center ? "0 auto" : "unset",
        ...styles,
        ...sx,
      }}
      className="logo-wrapper"
    >
      {/* {ironFund} */}
      <img width={'100%'} height={'auto'} src={IronFundIronLogo} alt="" />
      {/* {building ? buildingIcon : full} */}
    </div>
  );
};

export default IronFundLogo;
