export const setProfileState = (state) => {
  return {
    type: "SET_PROFILE_STATE",
    payload: state,
  };
};

export const updateProfileState = (data) => {
  return {
    type: "UPDATE_PROFILE_STATE",
    payload: data,
  };
};

export const clearProfileState = () => {
  return {
    type: "CLEAR_PROFILE_STATE",
  };
};
