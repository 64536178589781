import { StorefrontOutlined } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DashboardScreen from "../../../../components/layout/DashboardScreen";
import ScreenBody from "../../../../components/layout/Screen/ScreenBody";
import ScreenSubTitle from "../../../../components/layout/Screen/ScreenSubTitle";
import CreateCompanyFormModal from "../../../../components/modals/CreateCompanyFormModal";
import FlexBox from "../../../../components/shared/FlexBox";
import Loader from "../../../../components/shared/Loader/Loader";
import MuiReactTable from "../../../../components/shared/MuiReactTable";
import { arrayProtector } from "../../../../helpers/arrayProtector";
import { useIronfundCompaniesHook } from "../../../../hooks/useIronfundCompaniesHook";
import CompanyDrilldownPreviewCard from "./CompanyDrilldownPreviewCard";
import { trimStringEnd } from "../../../../helpers/trimStringEnd";

const CompaniesScreen = (props) => {
  const companies = useSelector((state) => state.globalData.companies);
  const { isLoading, getIronFundCompany, getIronFundCompanies } = useIronfundCompaniesHook();
  const showLoader = isNil(companies) || isLoading;

  useEffect(() => {
    if (showLoader) {
      console.log("getCompanies");
      getIronFundCompanies({});
    }
  }, []);

  if (showLoader) {
    return (
      <DashboardScreen>
        <Loader />
      </DashboardScreen>
    );
  }
  return <Content />;
};

const Content = () => {
  const [preview, setPreview] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);

  const handleOnCancel = () => {
    setPreview(null);
    setFullScreen(false);
  };

  const handleOnFullscreenToggle = () => {
    setFullScreen((s) => !s);
  };
  return (
    <DashboardScreen customScreenBody>
      <Box sx={{ background: "#ffffff", boxShadow: "var(--bs)", padding: "10px 0" }}>
        <ScreenBody>
          <FlexBox justifyBetween alignCenter>
            <ScreenSubTitle>All Iron Fund Companies</ScreenSubTitle>
            <CreateCompanyModalButton />
          </FlexBox>
        </ScreenBody>
      </Box>
      <ScreenBody sx={{ paddingTop: "16px" }}>
        <FlexBox fullWidth gap={"20px"}>
          {fullScreen ? null : <AllCompaniesTable company={preview} setPreview={setPreview} />}
          {preview ? (
            <CompanyDrilldownPreviewCard
              company={preview}
              fullScreen={fullScreen}
              onExpand={handleOnFullscreenToggle}
              onCancel={handleOnCancel}
            />
          ) : null}
        </FlexBox>
      </ScreenBody>
    </DashboardScreen>
  );
};

const AllCompaniesTable = ({ company, setPreview }) => {
  const [search, setSearch] = useState(null);
  const users = useSelector((state) => state.globalData.users);
  const companies = useSelector((state) => state.globalData.companies);
  const { ironFundUserCompanyNamesList } = useIronfundCompaniesHook();
  const [tableData, setTableData] = useState(companies);

  const getCompanyNumbers = (companyName, returnType) => {
    const companyUsers = users?.filter((u) => trimStringEnd(u.company) === companyName);

    if (returnType === "users") {
      return arrayProtector(companyUsers).length;
    }

    const companyScenarioLength = arrayProtector(companyUsers).reduce(function (prev, current) {
      if (!isNil(current?.scenarioCount)) {
        return prev + current?.scenarioCount;
      }
      return prev;
    }, 0);

    return companyScenarioLength;
  };

  const cols = [
    {
      Header: "Company Name",
      accessor: (d) => `${d.companyName} `,
    },
    {
      Header: "Total Users",
      accessor: (d) => getCompanyNumbers(d?.companyName, "users"),
    },
    {
      Header: "Total Scenarios",
      accessor: (d) => getCompanyNumbers(d?.companyName),
    },
  ];
  const columns = React.useMemo(() => cols, []);

  function findMatchingStrings(stringInput, stringArray) {
    const string = stringInput.toLowerCase();
    const matchingStrings = stringArray.filter((str) => {
      let inputIndex = 0;
      for (let char of str) {
        if (inputIndex < string.length && char.toLowerCase() === string[inputIndex].toLowerCase()) {
          inputIndex++;
        }
      }
      return inputIndex === string.length;
    });

    return matchingStrings;
  }

  const handleOnSearchChange = (val) => {
    setSearch(val);
    const result = findMatchingStrings(val, ironFundUserCompanyNamesList);
    console.log("result", result);
    setTableData(result);
    // const isExisting = ironFundUserCompanyNamesList.f((option) => val === option);
    // console.log({ ironFundUserCompanyNamesList, val, isExisting });
  };

  const handleClearSeach = () => {
    setSearch("");
    setTableData(ironFundUserCompanyNamesList);
  };

  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }}>
      <MuiReactTable
        emptyTitle={"There are currently no users associated with you."}
        noBorder
        active={{ name: "company", value: company }}
        rowOnClick={setPreview}
        columns={columns}
        data={tableData}
        // search
      />
    </Stack>
  );
};

const CreateCompanyModalButton = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button
        size="small"
        variant="outlined"
        endIcon={<StorefrontOutlined sx={{ fontSize: "1rem !important" }} />}
        onClick={() => setShow(true)}
      >
        Create New Company
      </Button>
      <CreateCompanyFormModal show={show} onCancel={() => setShow(false)} />
    </>
  );
};

export default CompaniesScreen;
