import { Box, Typography } from "@mui/material";
import React from "react";
import FlexBox from "./FlexBox";
import { isNil } from "lodash";

const SectionWithTitle = ({
  title,
  headerContent,
  bold,
  children,
  topMargin,
  noMargin,
  borderBottom,
  titleWrapperSx,
  titleSx,
  sx,
}) => {
  const borderBottomStyles = {
    borderBottom: "var(--border)",
    paddingBottom: "30px",
    marginBottom: "15px",
  };

  const topMargins = {
    sm: "15px",
    md: "30px",
    lg: "45px",
  };

  const customTopMarginSize =
    topMargin === "sm" || topMargin === "md" || topMargin === "lg" ? topMargins[topMargin] : topMargin;

  const sharedStyles = {
    marginTop: noMargin ? "0px" : isNil(topMargin) ? "30px" : customTopMarginSize,
    // paddingTop: "15px",
  };
  const conditionalStyles = borderBottom ? borderBottomStyles : null;

  const styles = {
    ...sharedStyles,
    ...conditionalStyles,
    ...sx,
  };

  const titleStyles = {
    fontSize: ".8rem",
    // fontSize: ".95rem",
    fontWeight: 600,
    // color: "var(--primary)",
    // marginBottom: "10px",
    ...titleSx,
  };

  const drilldownTitleStyles = bold ? titleStyles : titleSx;

  const titleContent = (
    <FlexBox
      className="secton-title-wrapper"
      justifyContent={headerContent ? "space-between" : "flex-start"}
      // alignItems={"flex-end"}
      alignCenter
      sx={{
        position: "relative",
        minHeight: "28px",
        // borderBottom: "2px solid #dbe7ed",
        // marginBottom: "10px",
        ...titleWrapperSx,
      }}
    >
      <Typography
        className="secton-title"
        variant="body1"
        // variant="h3"
        sx={{
          marginBottom: "0px",
          // marginBottom: "10px ",
          width: "initial",
          // fontSize: ".7rem !important",
          fontSize: ".8rem",
          fontWeight: 600,
          // fontWeight: bold ? 600 : 500,
          letterSpacing: 0,
          // fontFamily: "var(--mont)",
          // color: "var(--black)",
          color: "#25323d",
          // color: "var(--primary)",
          // color: "var(--blueButton) !important",
          ...drilldownTitleStyles,
          ...sx,
        }}
      >
        {title}
      </Typography>
      {headerContent ? headerContent : null}
      {/* {headerContent ? <Box sx={{ position: "absolute", top: 0, right: 0 }}>{headerContent}</Box> : null} */}
    </FlexBox>
  );
  return (
    <Box sx={styles}>
      {title ? titleContent : null}
      {children}
    </Box>
  );
};

export default SectionWithTitle;
