import React from "react";
import { Field, useFormState } from "react-final-form";
import FinalFormTextField from "../../../../../components/form/Fields/FinalFormTextField";
import { VALIDATOR_REQUIRE } from "../../../../../helpers/validators/inputValidators";
import RadioField from "../../../../../components/form/components/inputs/generic/RadioField/RadioField";
import { usaStates } from "../../../../../forms/_formQuestions/selectOptions";
import { MultiSelectFieldComponent } from "../../../../../components/form/components/inputs/finalFormComponents/MultiSelectFieldComponent";
import RadioFieldTrueFalse from "../../../../../components/form/components/inputs/generic/RadioFieldTrueFalse";
import MuiGrid from "../../../../../components/shared/MuiGrid";
import GridItem from "../../../../../components/shared/MuiGrid/GridItem";
import FormGroup from "../../../../../components/form/components/shared/FormGroup/FormGroup";
import FinalFormSelectField from "../../../../../components/form/Fields/FinalFormSelectField";
import FileUploadField from "../../../../../components/form/components/inputs/generic/FileUploadField/FileUploadField";

const AuctionBorrowerExperience = (props) => {
  const { values } = useFormState();
  return (
    <>
      <FormGroup label="Experience with Properties">
        <MuiGrid>
          <GridItem size={12}>
            <FinalFormTextField
              name={"yearsExperienceProducingProperties"}
              label="Experience with managing income producing properties"
              // label="Total Years Experience"
              number
              validate={VALIDATOR_REQUIRE}
            />
          </GridItem>

          {Number(values.yearsExperienceProducingProperties) > 0 && (
            <GridItem size={12}>
              <FinalFormTextField
                name={"numberOfPropertiesOwnedInLastThreeYears"}
                label="Properties Owned Within Last Three Years"
                validate={VALIDATOR_REQUIRE}
                number
              />
            </GridItem>
          )}
          <GridItem size={12}>
            <FinalFormTextField
              name={"flipsInLastThreeYears"}
              label="Experience with ground up or fix-and-flip properties within the last three years."
              // label="Properties Flipped"
              validate={VALIDATOR_REQUIRE}
              number
            />
          </GridItem>
          <GridItem size={12}>
            <FinalFormTextField
              name={"groundUpConstructionProjectsInLastThreeYears"}
              label="Ground Up Construction Projects Completed"
              validate={VALIDATOR_REQUIRE}
              number
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>
      <FormGroup label="Experience at Auction">
        <MuiGrid>
          <GridItem size={values.hasPurchasedPropertiesAtAuction === "Yes" ? 6 : 12}>
            <FinalFormSelectField
              name={"hasPurchasedPropertiesAtAuction"}
              items={["Yes", "No"]}
              label="Have you ever purchased properties at auction before?"
            />
          </GridItem>

          {values.hasPurchasedPropertiesAtAuction === "Yes" && (
            <>
              <GridItem size={6}>
                <FinalFormTextField
                  name={"numOfpropertiesPurchasedAtAuction"}
                  label="Total Auction properties purchased"
                  validate={VALIDATOR_REQUIRE}
                  number
                />
              </GridItem>
              <GridItem size={12}>
                <FinalFormSelectField
                  name={"hasPurchasedPropertiesAtAuctionWithinThreeYears"}
                  label="Are these Auction properties purchased within the last three years?"
                  items={["Yes", "No"]}
                />
              </GridItem>
            </>
          )}

          {values.yearsExperienceProducingProperties === 0 &&
            values.flipsInLastThreeYears === 0 &&
            values.groundUpConstructionProjectsInLastThreeYears === 0 &&
            values.hasPurchasedPropertiesAtAuction === "No" && (
              <>
                {/* <RadioField
              name={"hasTwoPlusYearsExperienceOfRealEstate"}
              verticalOptions
              multiple
              fieldLabel="Do you have 2+ years experience of any of the following positions?"
              items={[
                "A real estate agent",
                "Real estate finance/legal professional",
                "Mortgage loan originator",
                "General contractor",
                "Other (Any other capacity that provides direct experience and understanding of real estate valuation, market trends and/or costs of owning investment properties)",
              ]}
            /> */}

                <GridItem size={12}>
                  <Field
                    name={"hasTwoPlusYearsExperienceOfRealEstate"}
                    verticalOptions
                    multiple
                    label="Select Positions"
                    fieldLabel="Do you have 2+ years experience of any of the following positions?"
                    items={[
                      "A real estate agent",
                      "Real estate finance/legal professional",
                      "Mortgage loan originator",
                      "General contractor",
                      "Other (Any other capacity that provides direct experience and understanding of real estate valuation, market trends and/or costs of owning investment properties)",
                    ]}
                    component={MultiSelectFieldComponent}
                  />
                </GridItem>

                <GridItem size={12}>
                  <Field
                    name={"statesWithFinanceExperienceAndInterest"}
                    fieldLabel="What states do you have experience in or are you interested in financing transactions?"
                    label="Select States"
                    items={usaStates}
                    validate={VALIDATOR_REQUIRE}
                    multiple
                    helperText="Select all applicable states"
                    component={MultiSelectFieldComponent}
                  />
                </GridItem>
              </>
            )}
        </MuiGrid>
      </FormGroup>
      <FormGroup label="Proof of Experience">
        <MuiGrid>
          <GridItem size={12}>
            <FileUploadField
              name={"experienceDocs"}
              helperText="The more detail you provide, the faster we can approve"
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>
    </>
  );
};

export default AuctionBorrowerExperience;
