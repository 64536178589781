export const userTypes = {
  LOAN_OFFICER: "loanOfficer",
  OPS: "op",
  UNDERWRITER: "underwriter",
  ADMIN: "admin",
  BORROWER: "borrower",
  BROKER: "broker",
  LEGAL: "legal",
  FLATIRON_EMPLOYEE:
    "flatironEmployee" || "loanOfficer" || "op" || "admin" || "underwriter",
  // ADMIN_UNDERWRITER:
};
