import { ChevronLeftRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Slide, Typography } from "@mui/material";
import React from "react";
import { Field, useForm, useFormState } from "react-final-form";

import { composeValidators } from "../../../../helpers/validators/composeValidators";
import { VALIDATOR_MIN_CHAR, VALIDATOR_REQUIRE } from "../../../../helpers/validators/inputValidators";
import CalendarSectionWrapper from "./CalendarSectionWrapper";
import { useCalendarHook } from "./useCalendarHook";
import { convertDateToIsoString } from "./helpers/convertDateToIsoString";
import FinalFormTextField from "../../../../components/form/Fields/FinalFormTextField";
import FinalFormSelectField from "../../../../components/form/Fields/FinalFormSelectField";
import { TextareaFieldComponent } from "../../../../components/form/components/inputs/finalFormComponents/TextareaFieldComponent";
import { isNil } from "lodash";
import { useState } from "react";
import { validateEmail } from "../../../../helpers/validateEmail";
import { parsePhoneNumber } from "../../../../helpers/parse/parsePhoneNumber";
import { TextFieldComponent } from "../../../../components/form/components/inputs/finalFormComponents/TextFieldComponent";

const CalendarInviteForm = ({ show, container, showForm, setShowForm, setShowSuccess }) => {
  const { values } = useFormState();
  const { change } = useForm();

  const { isLoading, submitAppointmentRequest } = useCalendarHook();
  const [error, setError] = useState(undefined);

  const handleOnCancel = () => {
    console.log("onc run");
    const init = {
      clientSelectedTimezone: values?.clientSelectedTimezone,
      meetingDate: values?.meetingDate,
      meetingDateTime: values?.meetingDateTime,
    };
    change(values, init);
    setShowForm(false);
  };

  // const handleOnSuccess = ( ) => {
  //   console.log("SUCCESS", values);
  //   setShowSuccess(payload_prop);
  //   // setShowSuccess(true);
  // };
  function isValidPhoneNumber(phoneNumber) {
    const phonePattern = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phonePattern.test(phoneNumber);
  }

  const handleSubmitForm = () => {
    if (
      isNil(values?.meetingDateTime) ||
      isNil(values?.meetingDate) ||
      isNil(values?.clientSelectedTimezone) ||
      isNil(values?.firstName) ||
      isNil(values?.lastName) ||
      isNil(values?.phoneNumber) ||
      isNil(values?.emailAddress) ||
      isNil(values?.description)
    ) {
      setError("Please make sure all fields are filled out");
      return;
    }

    if (!isValidPhoneNumber(values?.phoneNumber)) {
      setError("Please make sure you are using a valid phone number");
      return;
    }

    if (!validateEmail(values?.emailAddress)) {
      setError("Please make sure are using a valid email address");
      return;
    }
    const payload_prop = { ...values };
    payload_prop["meetingDate"] = convertDateToIsoString(values?.meetingDate);
    const params = {
      payload: payload_prop,
      onSuccessFn: () => {
        setError(null);
        setShowSuccess(payload_prop);
      },
    };

    // console.log(params);
    submitAppointmentRequest(params);
  };

  const textboxIsValid = values?.description?.length >= 50;
  const textboxValidation = {
    ".MuiFormHelperText-root": {
      color: textboxIsValid ? "green" : "rgba(0, 0, 0, 0.6)",

      position: "absolute",
      zIndex: "11",
      right: "8px",
      bottom: "2px",
      background: "#ffffff",
      padding: "0 6px",
      borderRadius: "50px",
      border: "1px solid #cecece",
      fontSize: "0.7rem",
    },
  };

  return (
    <Slide in={show} container={container} direction="left">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "45px",
          textarea: {
            padding: "10px !important",
            height: "80px !important",
          },
          "#text-area-field-component": {
            ".error": {
              position: "absolute",
              zIndex: "11",
              left: "8px",
              bottom: "8px",
            },
          },
          ...textboxValidation,
        }}
      >
        <CalendarSectionWrapper title={"Your Information"}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",

                width: "100%",
                gap: "10px",
              }}
            >
              <FinalFormTextField required name="firstName" label={"First Name"} />
              <FinalFormTextField required name="lastName" label={"Last Name"} />
            </Box>
            <Field
              validate={VALIDATOR_REQUIRE}
              name={"phoneNumber"}
              // icon={<PhoneOutlined />}
              parse={parsePhoneNumber}
              label={`Phone Number`}
              component={TextFieldComponent}
            />
            {/* <FinalFormTextField phone required name="phoneNumber" label={"Phone Number"} /> */}
            <FinalFormTextField required name="emailAddress" label={"Email Address"} />
            <FinalFormSelectField
              required
              name="contact_profession"
              label={"What is your profession?"}
              items={["Broker", "Direct Borrower", "Real Estate Agent", "Existing Client"]}
            />
            <Field
              validate={composeValidators(VALIDATOR_REQUIRE, VALIDATOR_MIN_CHAR(30))}
              minChar={50}
              required
              name={"description"}
              fieldLabel="Why would you like to set up this meeting?"
              // label="Please provide Description"
              component={TextareaFieldComponent}
            />
          </Box>
        </CalendarSectionWrapper>
        {showForm ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "10px",
              // justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={handleOnCancel}
                startIcon={<ChevronLeftRounded sx={{ marginLeft: "-6px" }} />}
                variant="outlined"
              >
                Back
              </Button>
              <LoadingButton loading={isLoading} onClick={handleSubmitForm} variant="contained">
                Confirm Appointment
              </LoadingButton>
            </Box>
            {error ? (
              <Typography sx={{ textAlign: "center" }} color="error">
                {error}
              </Typography>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </Slide>
  );
};

export default CalendarInviteForm;
