import { FormControl, FormControlLabel, FormHelperText, Switch, TextField, Tooltip } from "@mui/material";
import { get, isNil } from "lodash";
import React, { useState } from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { VALIDATOR_REQUIRE } from "../../../helpers/validators/inputValidators";
import RenderCount from "../components/RenderCount/RenderCount";
import FormField from "../components/shared/FormField/FormField";
import DisplayDataFieldItem from "../../shared/DisplayDataFieldItem";
import { parsePhoneNumber } from "../../../helpers/parse/parsePhoneNumber";
import { useMemo } from "react";
import { getObjectValueFromStringPath } from "../../../helpers/getObjectValueFromStringPath";

/**
 * Example:
 *  <FinalFormToggle
        label="Requested Loan amount"
        name={`${fieldNames.requestedLoanAmount}`}
        dollar
    />
 */

const FinalFormToggle = ({
  name,
  label,
  validate,
  fullWidth,
  value,
  required,
  dollar,
  disabled,
  percent,
  variant,
  helperText,
  placeholder,
  onChange,
  color,
  focused,
  phone,
  format,
  parse,
  number,
  count,
}) => {
  const validators = validate ? validate : required ? VALIDATOR_REQUIRE : undefined;

  // if (display) {
  //   return (
  //     <DisplayDataFieldItem
  //       dot
  //       label={label}
  //       value={originalValue}
  //       percent={percent}
  //       dollar={dollar}
  //     />
  //   );
  // }

  const { change } = useForm();
  const { values } = useFormState();
  const handleChange = (event) => {
    const value = event.target.checked;
    console.log("event", value);
    change(name, value);
  };

  const toggleValue = useMemo(() => getObjectValueFromStringPath(values, name), [values]);
  const defaultLabel = toggleValue ? "Enabled" : "Disabled";
  return (
    <FormField fullWidth={fullWidth ? fullWidth : true}>
      <Field
        name={name}
        format={format}
        validate={validators}
        parse={phone ? parsePhoneNumber : number ? (v) => Number(v) : undefined}
      >
        {({ input, meta }) => (
          <FormControlLabel
            fullWidth={fullWidth ? fullWidth : true}
            variant={variant ? variant : "filled"}
            required
            size="small"
            control={<Switch size="small" checked={toggleValue} color="success" onChange={handleChange} />}
            label={isNil(label) ? defaultLabel : label}
            sx={{ fontSize: ".70rem" }}
          />
        )}
      </Field>
    </FormField>
  );
};

// const NumberFormatDollar = (props) => {
//   const { onChange, disabled, ...other } = props;

//   return (
//     <NumericFormat
//       {...other}
//       onValueChange={(formattedValue) => props.onChange(formattedValue.floatValue)}
//       disabled={disabled}
//       value={props.value}
//       valueIsNumericString
//       thousandSeparator
//       // isNumericString
//       prefix="$"
//     />
//   );
// };
// const NumberFormatPercent = (props) => {
//   const { onChange, disabled, ...other } = props;

//   return (
//     <NumericFormat
//       {...other}
//       onValueChange={(formattedValue) => props.onChange(formattedValue.floatValue)}
//       disabled={disabled}
//       value={props.value}
//       valueIsNumericString
//       thousandSeparator
//       // isNumericString

//       suffix="%"
//     />
//   );
// };

const NumberFormatPercent = React.forwardRef(function MyComponent({ onChange, value, disabled, ...other }, ref) {
  //  Spread the props to the underlying DOM element.

  return (
    <NumericFormat
      {...other}
      onValueChange={(formattedValue) => onChange(formattedValue.floatValue)}
      disabled={disabled}
      value={value}
      valueIsNumericString
      thousandSeparator
      // isNumericString
      prefix="$"
    />
  );
});
const NumberFormatDollar = React.forwardRef(function MyComponent({ onChange, value, disabled, ...other }, ref) {
  //  Spread the props to the underlying DOM element.

  return (
    <NumericFormat
      {...other}
      onValueChange={(formattedValue) => onChange(formattedValue.floatValue)}
      disabled={disabled}
      value={value}
      valueIsNumericString
      thousandSeparator
      // isNumericString
      prefix="$"
    />
  );
});

export default React.memo(FinalFormToggle);
