export const parseISOToDateTime = (string) => {
  if (string === undefined || string === null) {
    return "—";
  }
  if (typeof string === "object") {
    return string;
  }
  let date = new Date(string);

  let dtOffset = new Date(date.setMinutes(date.getMinutes() - date.getTimezoneOffset()));
  const time = dtOffset.toLocaleTimeString("en", {
    timeStyle: "short",
    hour12: true,
    // timeZone: "UTC",
  });
  let year = dtOffset.getFullYear();
  let month = dtOffset.getMonth() + 1;
  let dt = dtOffset.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  let formattedDate = `${month}/${dt}/${year} @ ${time}`;

  return formattedDate;
};
