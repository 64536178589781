import { AddRounded, ChevronRightRounded, DownloadRounded } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardScreen from "../../../../components/layout/DashboardScreen";
import DisplayTableWithHeader from "../../../../components/shared/DisplayTableWithHeader";
import BrokerCreateScenarioFormModal from "../../../../forms/BrokerCreateScenario/BrokerCreateScenarioFormModal";
import MuiEditableTable from "../../../../components/shared/MuiEditableTable/MuiEditableTable";
import MuiEditableTableRow from "../../../../components/shared/MuiEditableTable/MuiEditableTableRow";
import MuiEditableTableCell from "../../../../components/shared/MuiEditableTable/MuiEditableTableCell";
import { isNil } from "lodash";
import { arrayProtector } from "../../../../helpers/arrayProtector";
import { useBorrowerHook } from "../../../../hooks/useBorrowerHook";
import { useEffect } from "react";
import TableCellFilename from "../BrokerLoanScenarioDrilldownScreen/tabs/ScenarioDrilldownTabFiles/components/LoanUploadedFiles/LoanUploadedFilesTableRow/tableCells/TableCellFilename";
import FileButton from "../../../../components/shared/FileButton";
import { parseISOToDate } from "../../../../helpers/parseISOToDate";

const BrokerUnderwritingMaterialScreen = (props) => {
  const [files, setFiles] = useState([]);
  const { getIsLoading, getGuidelineMarketingMaterials } = useBorrowerHook();

  useEffect(() => {
    const handleOnSuccess = (files) => {
      console.log(files);

      setFiles(arrayProtector(files));
    };
    getGuidelineMarketingMaterials({ onSuccessFn: handleOnSuccess });
  }, []);

  return (
    <DashboardScreen>
      <MuiEditableTable
        isLoading={getIsLoading}
        tableSx={{
          th: {
            whiteSpace: "nowrap",
          },
        }}
        columns={["Filename", "Upload Date"]}
      >
        {arrayProtector(files)?.map((document, index) => (
          <DocumentRow key={document?.file_storage_name} document={document} />
        ))}
        {arrayProtector(files).length === 0 ? <DocumentRowEmpty /> : null}
      </MuiEditableTable>
    </DashboardScreen>
  );
};

const DocumentRow = ({ document }) => {
  const fileDisplayName = document?.file_display_name;
  const uploadDate = document?.uploadDate;
  const fileStorageName = document?.file_storage_name;

  const convertFilename = (filename) => {
    // Replace the dashes with spaces.
    let string = "";

    string = filename.replaceAll("_", " ");

    // Convert the string to sentence case.
    string = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

    // Remove the .pdf at the end.
    string = string.substring(0, string.length - 4);

    return string;
  };
  return (
    <MuiEditableTableRow>
      <MuiEditableTableCell sx={{ whiteSpace: "nowrap" }}>
        <FileButton
          sx={{ textTransform: "capitalize !important" }}
          endIcon={<DownloadRounded sx={{ fontSize: "1rem !important", marginLeft: "-4px" }} />}
          filename={fileDisplayName}
          label={convertFilename(fileDisplayName)}
          // path={`download_guideline_doc`}
          path={`download_guideline_doc?file_storage_name=${fileStorageName}`}
        />
      </MuiEditableTableCell>
      <MuiEditableTableCell sx={{ whiteSpace: "nowrap" }}>{parseISOToDate(uploadDate)}</MuiEditableTableCell>
    </MuiEditableTableRow>
  );
};
const DocumentRowEmpty = ({ emptyMessage }) => {
  return (
    <MuiEditableTableRow>
      <MuiEditableTableCell colSpan={"100%"}>There are no files</MuiEditableTableCell>
    </MuiEditableTableRow>
  );
};

export default BrokerUnderwritingMaterialScreen;
