import { isArray, isNil } from "lodash";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAxiosHook } from "./useAxiosHook";
import { setGlobalData, updateGlobalData } from "../redux/actions/globalDataActions";
import { removeDuplicates } from "../helpers/removeDuplicates";
import { useEffect } from "react";
import { arrayProtector } from "../helpers/arrayProtector";
import { toKebabCase } from "../helpers/toKebabCase";
import { toNormalCase } from "../helpers/toNormalCase";
import { setFormData } from "../redux/actions/formDataActions";
import { useIronfundUsersHook } from "./useIronfundUsersHook";
import { stringProtector } from "../helpers/stringProtector";
import { mapNestedObjectFormData } from "../helpers/mapObjectFormData";

export const useIronfundCompaniesHook = () => {
  const globalUsers = useSelector((state) => state.globalData?.users);
  const loanScenarios = useSelector((state) => state.loanScenarios);
  const users = useMemo(() => globalUsers, [globalUsers]);
  const { axiosPostIsLoading, axiosGetIsLoading, axiosGetRequest, axiosPostRequest } = useAxiosHook();
  const isLoading = axiosGetIsLoading || axiosPostIsLoading;
  const dispatch = useDispatch();

  // -------- Requests ------------

  const getIronFundCompanies = async ({ onSuccessFn, onFailFn }) => {
    const endpoint = `get_iron_fund_companies`;

    const onRequestSuccess = (passedData) => {
      // let scenarios = passedData?.userScenarios;
      // const scenarios_reversed = arrayProtector(scenarios).reverse();
      const companies = passedData?.companies;
      // const companies = [];
      dispatch(updateGlobalData({ key: "companies", value: companies }));
      if (onSuccessFn) {
        onSuccessFn(companies);
      }
    };

    const params = {
      name: "Get Iron Fund Companies",
      path: endpoint,
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could Not Get Iron Fund Companies",
      onFailFn,
    };

    await axiosGetRequest(params);
  };

  const getIronFundCompany = async ({ id, onSuccessFn, onFailFn }) => {
    const endpoint = `get_iron_fund_company?companyId=${id}`;

    const onRequestSuccess = (passedData) => {
      if (onSuccessFn) {
        onSuccessFn(passedData);
      }
    };

    const params = {
      name: "Get Iron Fund Company",
      path: endpoint,
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could Not Get Iron Fund Company",
      onFailFn,
    };

    await axiosGetRequest(params);
  };

  const createIronFundCompany = async ({ payload, id, onSuccessFn, onFailFn }) => {
    const endpoint = `iron_fund_create_company`;

    const onRequestSuccess = (passedData) => {
      if (onSuccessFn) {
        onSuccessFn(passedData);
      }
    };

    const params = {
      name: "Create Fund Company",
      path: endpoint,
      payload: mapNestedObjectFormData(payload),
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could Not Create cIron Fund Company",
      onFailFn,
    };

    await axiosPostRequest(params);
  };

  // -------- Helpers ------------

  const getIronFundUserCompaniesList = () => {
    if (isNil(users)) {
      return;
    }
    const uniqueCompanyTableData = removeDuplicates(users, "company");
    let companyNameList = uniqueCompanyTableData.map((obj) => obj.company);
    const result = companyNameList.filter((str) => !isNil(str) && str !== "" && str !== " ").sort();
    return result;
  };

  const getUsersByCompanyName = (company) => {
    /**
     * requires: "company"
     * returns: array of users that belong in that company
     */
    // console.log("company", company);

    const filteredList = users.filter((c) => stringProtector(c?.company).trimRight() === company);
    return filteredList;
  };

  const getScenariosByCompanyName = (company) => {
    const companyUsersList = getUsersByCompanyName(company);

    let allCompanyScenarioIds = [];
    companyUsersList.forEach((u) => {
      if (isNil(u.scenarios)) {
        return;
      }
      allCompanyScenarioIds = [...allCompanyScenarioIds, ...u.scenarios];
      // allCompanyScenarioIds.push(u.scenarios);
    });
    // let allCompanyScenarioIds = companyUsersList.map((u) => u.scenarios);
    // console.log("allCompanyScenarioIds", allCompanyScenarioIds);
    // const companyScenarios = companyUsersList.map((user) => (isNil(user?.scenarioCount) ? 0 : user?.scenarioCount));
    // return companyScenarios;
    const mapAndCheck = (objectsArray, idArray) => {
      const resultArray = objectsArray.filter((obj) => {
        const idExists = idArray.includes(obj._id);
        // console.log("idExists: ", idExists);
        if (idExists) {
          return { ...obj };
        }

        // return { ...obj, idExists };
      });

      return resultArray;
    };
    // const allCompanyScenarioObjects = loanScenarios.map((sc) => allCompanyScenarioIds.includes(sc._id));
    // console.log({ loanScenarios, allCompanyScenarioIds });
    const result = mapAndCheck(loanScenarios, allCompanyScenarioIds);
    // console.log("result", result);
    return result;
    // return allCompanyScenarioObjects;
  };

  const getNumberOfCompanyScenarios = (company) => {
    // if (usersHookGuardClause()) {
    //   return;
    // }

    const numberOfScenarios = arrayProtector(getScenariosByCompanyName(company)).length;
    // const numberOfScenarios = getScenariosByCompanyName(company).reduce((prev, next) => prev + next);

    return numberOfScenarios;
  };

  const setCompanyDrilldownFormData = (id) => {
    const name = toNormalCase(id).trimRight();
    const init = {
      name: toNormalCase(id),
      users: getUsersByCompanyName(name),
      scenarios: getScenariosByCompanyName(name),
    };
    dispatch(setFormData(init));
  };

  const ironFundUserCompanyNamesList = useMemo(() => arrayProtector(getIronFundUserCompaniesList()), [users]);

  return {
    isLoading,
    users,
    ironFundUserCompanyNamesList,
    getIronFundCompany,
    getIronFundCompanies,
    getNumberOfCompanyScenarios,
    getUsersByCompanyName,
    setCompanyDrilldownFormData,
    getScenariosByCompanyName,
    createIronFundCompany,
    getIronFundUserCompaniesList,
  };
};
