import React, { useEffect } from "react";

import FormWizard from "../../components/form/FormWizard/FormWizard";
import { globalFormTypes } from "../../global/forms/globalFormTypes";

import { useBorrowerHook } from "../../hooks/useBorrowerHook";
import { formSteps } from "./formSteps";

const BrokerCreateScenarioFormWizard = (props) => {
  const { postIsLoading, createBrokerScenario } = useBorrowerHook();

  const handleFormSubmit = async (formState) => {
    console.log("SUBMITTING CREATE LOAN DEAL", formState);

    const guardClauseState = {
      totalReportedBudget: 0,
      ...formState,
    };

    const showGuardClauseState = formState?.loanProductType === "Fix and Flip" ? true : false;
    const params = {
      // payload: {},
      payload: showGuardClauseState ? guardClauseState : formState,
      name: "Create Deal",
      onSuccessFn: props.onComplete,
      onFailMsg: "Could not create deal",
    };

    await createBrokerScenario(params);
  };

  return (
    <>
      <FormWizard
        id="create-new-loan"
        initialValues={{ scenarioStatus: "New" }}
        isLoading={postIsLoading}
        // clickable
        muiStepper
        alt
        formType={globalFormTypes.CREATE_DEAL}
        formSteps={formSteps}
        onSubmit={handleFormSubmit}
        submitButtonText={"Create Loan Scenario"}
        // formSpy
      />
    </>
  );
};

export default BrokerCreateScenarioFormWizard;
