import {
  AddBoxOutlined,
  AddBoxRounded,
  AddCircleOutlineOutlined,
  ChevronRight,
  ChevronRightRounded,
} from "@mui/icons-material";
import { Badge, Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import WidgetCard from "./Widgets/WidgetCard";
import WidgetTitle from "./Widgets/WidgetTitle";
import TickerWidgetStatus from "./Widgets/TickerWidget/TickerWidgetStatus";

const CardGraphicButton = ({
  icon,
  disabled,
  onClick,
  title,
  id,
  description,
  statusLabel,
  statusValue,
  comingSoon,
  badge,
}) => {
  const graphic = <Box sx={{ maxWidth: "180px", marginBottom: "15px" }}>{icon}</Box>;

  const status = statusLabel ? (
    <Box
      sx={{
        padding: "5px",
        position: "absolute",
        right: "10px",
        top: "10px",
        opacity: disabled ? ".55" : "1",
      }}
      size="small"
    >
      <TickerWidgetStatus label={statusLabel} value={statusValue} />
    </Box>
  ) : null;

  return (
    <WidgetCard
      id={id}
      sx={{
        padding: 0,
        position: "relative",
        background: "#ffffff",
        border: comingSoon ? "2px solid #ababab" : "var(--border)",
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        width: "100%",
        justifyContent: "center",
      }}
    >
      {status}
      <Button
        disabled={disabled}
        sx={{
          opacity: disabled ? ".75" : "1",
          padding: "30px 0 20px",
          flexGrow: 1,
        }}
        onClick={comingSoon ? null : onClick}
      >
        <Stack alignItems={"center"}>
          {badge ? (
            <Badge badgeContent={badge} color="error">
              {graphic}
            </Badge>
          ) : (
            graphic
          )}
          <WidgetTitle>
            {title}
            <ChevronRightRounded />
          </WidgetTitle>
          <Typography sx={{ marginTop: "2px", opacity: ".8", fontSize: ".75rem" }} variant="body1">
            {description}
          </Typography>
        </Stack>
      </Button>
      {comingSoon ? <ComingSoonContentShield /> : null}
    </WidgetCard>
  );
};

const ComingSoonContentShield = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        background: "#00000054",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          background: "#b4dbff",
          // width: "200%",
          textAlign: "center",
          borderRadius: "40px",
          padding: "5px 12px",
          top: "15px",
          right: "15px",
          // transform: "translateY(-50%)  ",
        }}
      >
        <Typography fontWeight={400} fontSize={"0.8rem"} color={"#235685"} fontStyle="italic">
          Coming Soon
        </Typography>
      </Box>
    </Box>
  );
};
export default CardGraphicButton;
