import { useStytchUser } from "@stytch/react";
import React from "react";

import { Avatar, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { isNil } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import Form from "../../../../../components/form/Form/Form";
import BorderCard from "../../../../../components/shared/BorderCard";
import FlexBox from "../../../../../components/shared/FlexBox";
import TabSwitcher from "../../../../../components/shared/TabSwitcher";
import TabPanel from "../../../../../components/shared/TabSwitcher/TabPanel";
import ProfileEditingButtons from "../components/ProfileEditingButtons";
import BasicInfoCard from "./BasicInfoCard";
import CompanyCard from "./CompanyCard";
import NotificationsCard from "./NotificationsCard";
import { Field } from "react-final-form";
import { usaStateInitials } from "../../../../../forms/_formQuestions/selectOptions";
import { MultiSelectFieldComponent } from "../../../../../components/form/components/inputs/finalFormComponents/MultiSelectFieldComponent";

const PrimaryProfileCard = ({ title, children }) => {
  const [value, setValue] = useState(0);
  const { user } = useStytchUser();
  const stytchProfile = user?.trusted_metadata;
  const profile = useSelector((state) => state.profile);

  const stytchUserProfile = user.trusted_metadata;

  const initState = { ...profile, ...stytchUserProfile };
  // const initState = { ...profile, ...stytchUserProfile, emailNotifications: true };

  // useEffect(() => {
  //   if (isNil(profile)) {
  //     getUserProfileData({ onSuccessFn: (d) => dispatch(updateGlobalData({ key: "profile", value: d?.userProfile })) });
  //   }
  // }, [dispatch, getUserProfileData, profile]);

  if (isNil(profile)) {
    return (
      // <DashboardScreen>
      <FlexBox center>
        <CircularProgress size={24} />
      </FlexBox>
      // </DashboardScreen>
    );
  }
  return (
    <BorderCard sx={{ position: "relative" }}>
      <FlexBox
        sx={{
          ".MuiButtonBase-root.MuiTab-root": {
            alignItems: "flex-start",
            minWidth: "180px",
            paddingLeft: "20px",
          },
          ".tab-panel": {
            width: "100%",
          },
        }}
      >
        <Stack>
          <FlexBox column center sx={{ padding: "25px" }}>
            <Avatar variant="circular" src="avatar1.jpg" sx={{ width: "54px", height: "54px" }} />
            <Typography
              sx={{ fontWeight: 600, marginTop: "15px" }}
              variant="h3"
            >{`${profile?.firstName} ${profile?.lastName}`}</Typography>
          </FlexBox>
          <Divider flexItem light />
          <TabSwitcher vertical noBorder setValue={setValue} tabs={["Account", "Company", "Notifications"]} />
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack sx={{ borderLeft: "1px solid var(--borderGray)", width: "100%" }}>
          <Form
            initialValues={initState}
            hideButton
            submitButtonText={"Send Email Invitation"}
            // formSpy
            //
          >
            <FlexBox column gap={"14px"}>
              {/* {children} */}

              <TabPanel value={value} index={0}>
                <BasicInfoCard />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <CompanyCard />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <NotificationsCard />
              </TabPanel>
            </FlexBox>
            <ProfileEditingButtons />
          </Form>
        </Stack>
      </FlexBox>
    </BorderCard>
  );
};

const ProfileLinks = () => {
  return;
};

export default PrimaryProfileCard;
