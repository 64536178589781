import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import EditableDrilldownTabCard from "../components/EditableDrilldownTabCard";

import { isEqual, isNil } from "lodash";
import { Field, useFormState } from "react-final-form";
import DrilldownCardTabSection from "../../../../../components/Drilldown/DrilldownCardTabSection";
import FinalFormTextField from "../../../../../components/form/Fields/FinalFormTextField";
import { EmployeeSelectFieldComponent } from "../../../../../components/form/components/inputs/finalFormComponents/EmployeeSelectFieldComponent";
import MuiGrid from "../../../../../components/shared/MuiGrid";
import GridItem from "../../../../../components/shared/MuiGrid/GridItem";
import { fieldNames } from "../../../../../global/forms/fieldNames";
import { formatAsDollarString } from "../../../../../helpers/formatAsDollarString";
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Text } from "recharts";
import { Box, Stack, Typography } from "@mui/material";
import FlexBox from "../../../../../components/shared/FlexBox";
import { getFinalFormTotalLoanAmount } from "../../../../../helpers/getFinalFormTotalLoanAmount";
import FinalFormSelectField from "../../../../../components/form/Fields/FinalFormSelectField";
import {
  loanExitStrategy,
  loanPurposeOptions,
  productTypeOptions,
} from "../../../../../forms/_formQuestions/selectOptions";
import { DatePickerTextFieldComponent } from "../../../../../components/form/components/inputs/finalFormComponents/DatePickerTextFieldComponent";
import { useBrokerDrilldownHook } from "../../../../../hooks/useBrokerDrilldownHook";

const ScenarioDrilldownTabDetails = (props) => {
  const formData = useSelector((state) => state.formData);
  const { values } = useFormState();
  const display = isEqual(values, formData) ? true : false;
  const { isInternalEmployee } = useBrokerDrilldownHook();
  console.log("formData", formData);
  return (
    <EditableDrilldownTabCard display={display} sx={{ height: "auto" }} title={"Loan Scenario Details"}>
      <MuiGrid spacing={1}>
        <GridItem mobile size={7}>
          <Stack spacing={3}>
            <DrilldownCardTabSection title={"Scenario Details"}>
              <MuiGrid spacing={1}>
                <GridItem mobile size={12}>
                  <FinalFormTextField
                    label="Scenario Display Name"
                    name={`${fieldNames.loanSubjectName}`}
                    // helperText="This is the name that will be displayed in the table"
                  />
                </GridItem>
                <GridItem mobile size={4}>
                  <Field label="Account Executive" name={`loanSalesperson`} component={EmployeeSelectFieldComponent} />
                </GridItem>
                <GridItem mobile size={4}>
                  <FinalFormSelectField label="Loan Exit Strategy" name={`loanExitStrategy`} items={loanExitStrategy} />
                </GridItem>
                <GridItem mobile size={4}>
                  <Field name={`loanClosingDate`} label="Loan Closing Date" component={DatePickerTextFieldComponent} />
                </GridItem>
              </MuiGrid>
            </DrilldownCardTabSection>
            <DrilldownCardTabSection title={"Senario Loan Terms"}>
              <MuiGrid>
                <GridItem mobile size={6}>
                  <FinalFormSelectField
                    label="Product Type"
                    name={`${fieldNames.loanProductType}`}
                    items={productTypeOptions}
                  />
                </GridItem>
                <GridItem mobile size={6}>
                  <FinalFormSelectField
                    label="Loan Purpose"
                    name={`${fieldNames.loanPurpose}`}
                    items={loanPurposeOptions}
                  />
                </GridItem>
                <GridItem size={6}>
                  <FinalFormTextField label="Initial Loan amount" name={`${fieldNames.initialLoanAmount}`} dollar />
                </GridItem>
                <GridItem mobile size={6}>
                  <FinalFormTextField label="Rehab Budget" name={`constructionRenovationBudget`} dollar />
                </GridItem>
                <GridItem size={4}>
                  <FinalFormTextField
                    disabled={isInternalEmployee ? false : true}
                    label="Proposed Interest Rate"
                    name={`proposedInterestRate`}
                    percent
                  />
                </GridItem>
                <GridItem size={4}>
                  <FinalFormTextField
                    disabled={isInternalEmployee ? false : true}
                    label="Proposed Origination Points"
                    name={`proposedOriginationPoints`}
                    percent
                  />
                </GridItem>
                <GridItem size={4}>
                  <FinalFormTextField
                    disabled={isInternalEmployee ? false : true}
                    label="Proposed Discount Points"
                    name={`proposedDiscountPoints`}
                    percent
                  />
                </GridItem>
              </MuiGrid>
            </DrilldownCardTabSection>
          </Stack>
        </GridItem>
        <GridItem mobile size={5}>
          <LoanInfoGraphic />
        </GridItem>
      </MuiGrid>
    </EditableDrilldownTabCard>
  );
};

const LoanInfoGraphic = (props) => {
  const { values } = useFormState();

  const data = useMemo(() => {
    const includesRehab =
      values?.loanProductType === "Fix and Flip" || values?.loanProductType === "Ground Up Construction" ? true : false;
    const dataNoRehab = [{ name: "Total Loan Amount", value: values?.initialLoanAmount }];
    const dataRehab = [
      { name: "Initial Loan Amount", value: values?.initialLoanAmount },

      {
        name: "Rehab Budget",
        value: isNil(values?.constructionRenovationBudget) ? 0 : values?.constructionRenovationBudget,
      },
    ];
    return includesRehab ? dataRehab : dataNoRehab;
  }, [values?.loanProductType, values?.constructionRenovationBudget, values?.initialLoanAmount]);

  const COLORS = ["#0088FE", "#00C49F"];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name, index, ...props }) => {
    // console.log("props", props);
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <Text
        x={x}
        y={y - 50}
        className="recharts-text recharts-label"
        fontSize="11"
        fontWeight="600"
        fill={COLORS[index]}
        {...props}
      >
        {formatAsDollarString(props.value)}
      </Text>
    );
  };
  return (
    <FlexBox center sx={{ height: "100%" }}>
      <Box sx={{ width: "100%", height: "240px" }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              innerRadius={60}
              paddingAngle={5}
            >
              <Label
                content={<CustomLabel labelText="Total Loan Amout" value={getFinalFormTotalLoanAmount(values, true)} />}
                position="center"
              />
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Legend content={<CustomizedLegend />} />
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </FlexBox>
  );
};

const CustomizedLegend = (props) => {
  const { payload } = props;
  return (
    <FlexBox justifyContent={"space-evenly"} sx={{ width: "100%" }}>
      {payload.map((entry, index) => (
        <FlexBox key={entry.value} sx={{ gap: "10px" }} alignCenter>
          <Box
            sx={{
              backgroundColor: entry.payload.fill,
              width: "10px",
              height: "10px",
            }}
          />
          <Typography className="BulletLabelText">{entry.value}</Typography>
        </FlexBox>
      ))}
    </FlexBox>
  );
};

const CustomLabel = ({ viewBox, labelText, value }) => {
  const { cx, cy } = viewBox;
  return (
    <g>
      <text
        x={cx}
        y={cy - 10}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fontSize="11"
      >
        {labelText}
      </text>
      <text
        x={cx}
        y={cy + 10}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill="#0088FE"
        fontSize="16"
        fontWeight="600"
      >
        {value}
      </text>
    </g>
  );
};
export default ScenarioDrilldownTabDetails;
