import {
  ChatBubbleRounded,
  CircleRounded,
  CloseRounded,
  HelpRounded,
  QuestionMark,
  Send,
  SendRounded,
} from "@mui/icons-material";
import { Badge, Box, IconButton, Stack, Typography } from "@mui/material";
import { isNil } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { arrayProtector } from "../../../helpers/arrayProtector";
import FlatIronLogo from "../FlatIronLogo";
import FlexBox from "../FlexBox";
import MuiTextfield from "../MuiTextfield";
import HelpContactForm from "../../../forms/HelpContactForm";
import FormSubmitButton from "../../form/components/shared/FormSubmitButton/FormSubmitButton";
import { useAxiosHook } from "../../../hooks/useAxiosHook";
import { useFormState } from "react-final-form";
import { isMobile } from "react-device-detect";

const FloatingHelpContactForm = (props) => {
  const [show, setShow] = useState(false);

  const [newMessages, setNewMessages] = useState(2);
  // const [messages, setMessages] = useState(DUMMY_CHAT_MESSAGES);
  // const chatMessageList = useMemo(()=>messages, [messages])
  const handleOnClose = () => {
    setShow(false);
  };
  const handleOnOpen = () => {
    setNewMessages(0);
    setShow((s) => !s);
  };

  return (
    <Box
      id="help-contact-form"
      sx={{
        position: "fixed",
        right: isMobile ? "10px" : "35px",
        bottom: isMobile ? "10px" : "35px",

        zIndex: 11111,

        ".MuiSvgIcon-root": {
          transition: "100ms",
        },
      }}
    >
      <IconButton
        color="secondary"
        size="large"
        onClick={handleOnOpen}
        sx={{
          background: "var(--primary)",
          width: "56px",
          height: "56px",
          boxShadow: "2px 2px 8px -3px #000",
          transition: "100ms",
          "&:hover": {
            background: "var(--blueHover)",
          },
        }}
      >
        {show ? (
          <CloseRounded
            sx={{
              fontSize: "1.8rem",
              color: "#ffffff",
            }}
          />
        ) : (
          <FlexBox center sx={{ position: "relative" }}>
            <FlexBox sx={{ position: "absolute", top: "5px" }}>
              <QuestionMark sx={{ color: "var(--blueGradient)", fontSize: ".95rem" }} />
              {/* <CircleRounded sx={{ color: "var(--blueGradient)", fontSize: ".25rem" }} />
              <CircleRounded sx={{ color: "var(--blueGradient)", fontSize: ".25rem" }} />
              <CircleRounded sx={{ color: "var(--blueGradient)", fontSize: ".25rem" }} /> */}
            </FlexBox>
            <ChatBubbleRounded
              sx={{
                fontSize: "1.9rem",
                // stroke: "#ffffff",
                // color: "var(--blueGradient)",
                color: "#ffffff",
              }}
            />
          </FlexBox>
        )}
      </IconButton>

      {show ? <ChatBox onClose={handleOnClose} /> : null}
    </Box>
  );
};

const ChatBox = ({ messages, setMessages, onClose }) => {
  // const [messages, setMessages] = useState(DUMMY_CHAT_MESSAGES);
  const isTyping = true;
  return (
    <Box
      sx={{
        position: "absolute",
        right: isMobile ? "0px" : "5px",
        zIndex: 111111,
        bottom: isMobile ? "70px" : "75px",
        boxShadow: "var(--bs2)",
        // boxShadow: "0 7px 6px 1px rgba(0,0,0,.16)",
        borderRadius: "6px",
        width: "350px",
        background: "#ffffff",
        // height: "520px",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ChatHeader onClose={onClose} />
      <ChatBody messages={messages} />

      {/* <ChatMessageComposer messages={messages} setMessages={setMessages} isTyping={isTyping} /> */}
    </Box>
  );
};

const ChatHeader = ({ onClose }) => {
  return (
    <Box
      component={"header"}
      id="header"
      sx={{
        padding: "0 10px",
        // borderRadius: "6px",
        flexShrink: 0,
        zIndex: 111111,
        height: "44px",
        boxShadow: "0 0 3px 3px #00000059",
        // boxShadow: "0 0 4px 3px #00000052",
        background: "var(--blueGradient)",
        color: "#ffffff",
      }}
    >
      <FlexBox alignCenter justifyBetween sx={{ height: "100%" }}>
        <FlexBox alignCenter sx={{ height: "100%" }}>
          <Typography
            sx={{
              color: "#ffffff",
              // marginLeft: "12px",
              marginTop: "2px",
              fontSize: "1.1rem",
              fontFamily: "var(--mont) !important",
            }}
          >
            Contact Iron Fund
          </Typography>
        </FlexBox>
        <IconButton onClick={onClose}>
          <CloseRounded sx={{ color: "#ffffff" }} />
        </IconButton>
      </FlexBox>
    </Box>
  );
};

const ChatBody = ({ messages }) => {
  const chatRef = useRef(null);
  useEffect(() => {
    console.log("chatRef", chatRef.current.scrollHeight);
    const height = chatRef.current.scrollHeight;
    chatRef.current.scrollTo(0, height);
    // chatRef.scrollTop = chatRef.scrollHeight;
  }, [messages]);
  return (
    <Box
      ref={chatRef}
      id="body"
      sx={{
        background: "#ffffff",
        padding: "15px ",
        overflow: "auto",
        flexGrow: 1,
        // boxShadow: 'inset -8px 3px 6px 0px #0000007a',
        // overflowX: 'hidden'
      }}
    >
      <HelpContactForm />
    </Box>
  );
};

export default FloatingHelpContactForm;
