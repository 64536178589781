import React from "react";

import { Field, useForm, useFormState } from "react-final-form";
import FinalFormSelectField from "../../../components/form/Fields/FinalFormSelectField";
import { PasswordFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/PasswordFieldComponent";
import { SelectFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/SelectFieldComponent";
import AddressAutofillField from "../../../components/form/components/inputs/generic/AddressAutofillField/AddressAutofillField";
import FormGroup from "../../../components/form/components/shared/FormGroup/FormGroup";
import MuiGrid from "../../../components/shared/MuiGrid";
import GridItem from "../../../components/shared/MuiGrid/GridItem";
import { fieldNames } from "../../../global/forms/fieldNames";
import { VALIDATOR_REQUIRE } from "../../../helpers/validators/inputValidators";
import { fileDocumentSchema } from "../../_formQuestions/_formSchema/fileDocumentSchema";
import { citizenshipOptions } from "../../_formQuestions/selectOptions";
import FinalFormFileUpload from "../../../components/form/Fields/FinalFormFileUpload";

// import Form from "../../../../UserAdmission/components/SignInForm/SignInForm";
// import "./CreditAuthFormStepOne.css";

const BorrowerCreditAuthBorrowerBackground = (props) => {
  const { submitFailed } = useFormState();
  const { change } = useForm();

  const { values } = useFormState();
  // console.log("----", submitFailed);

  const handleIDAddress = (name, value) => {
    // console.log(name, value);
    if (value === "true") {
      // console.log("change");
      change("identificationAddress.address", values?.primaryAddress?.address);
    } else {
      change("identificationAddress", undefined);
    }
  };
  return (
    <>
      <FormGroup noMargin label="Background Information">
        <MuiGrid>
          <GridItem size={12}>
            <FinalFormSelectField
              validate={VALIDATOR_REQUIRE}
              noMargin
              name={"isMarried"}
              items={[
                { name: "true", label: "Married" },
                { name: "false", label: "Not Married" },
              ]}
              // items={["Married", "Not Married"]}
              label="Marriage Status"
            />
          </GridItem>
          <GridItem mobile size={values.citizenshipStatus !== "Non-resident foreign national" ? 6 : 12}>
            <FinalFormSelectField
              validate={VALIDATOR_REQUIRE}
              noMargin
              // isRequired
              name={"citizenshipStatus"}
              items={citizenshipOptions}
              label="Citizenship Status"
            />
          </GridItem>

          {values.citizenshipStatus !== "Non-resident foreign national" && (
            <GridItem mobile size={6}>
              <Field
                validate={VALIDATOR_REQUIRE}
                name={"ssn"}
                label="Social Security Number"
                // parse={parseNumberMax}
                component={PasswordFieldComponent}
                // component={TextFieldComponent}
              />
            </GridItem>
          )}
        </MuiGrid>
      </FormGroup>
      <FormGroup label="Upload Photo Identification">
        <MuiGrid>
          <GridItem mobile size={12}>
            <FinalFormFileUpload
              isRequired
              name={fieldNames.photoId}
              fieldLabel="Upload Photo Identificationsss"
              helperText="Please ensure form of identification is recent and valid"
              docGroup={fileDocumentSchema.borrowerDocs.name}
              docType={fileDocumentSchema.borrowerDocs.types.photoId.name}
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>
      <FormGroup label="What is the address listed on Photo Identification?">
        <MuiGrid>
          <GridItem size={12}>
            {/* <FinalFormSelectField
              validate={VALIDATOR_REQUIRE}
              noMargin
              name={"identificationAddressIsSameAsPrimary"}
              items={[
                {
                  name: "true",
                  label: `${values?.primaryAddress?.address?.fullAddress}`,
                },
                { name: "false", label: "Choose a different address" },
              ]}
              label="Address on Identification"
              onChange={handleIDAddress}
              // component={SelectFieldComponent}
            /> */}
            <Field
              validate={VALIDATOR_REQUIRE}
              noMargin
              name={"identificationAddressIsSameAsPrimary"}
              items={[
                {
                  name: "true",
                  label: `${values?.primaryAddress?.address?.fullAddress}`,
                },
                { name: "false", label: "Choose a different address" },
              ]}
              label="Address on Identification"
              onChange={handleIDAddress}
              component={SelectFieldComponent}
            />
            {/* <RadioFieldTrueFalse
              isRequired
              noMargin
              name={"identificationAddressIsSameAsPrimary"}
              items={["Same as Primary", "Additioanl Address"]}
            /> */}
          </GridItem>
          {values.identificationAddressIsSameAsPrimary === "false" ? (
            <GridItem size={12}>
              <AddressAutofillField
                isRequired
                name={"identificationAddress"}
                label="Listed address on Photo Identification"
              />
            </GridItem>
          ) : null}
        </MuiGrid>
      </FormGroup>
    </>
  );
};

export default BorrowerCreditAuthBorrowerBackground;
