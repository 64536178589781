import React, { useState } from "react";

import EmailOutlined from "@mui/icons-material/EmailOutlined";
import { Field } from "react-final-form";

import { PhoneOutlined, SendRounded } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { Stack } from "@mui/system";
import Form from "../components/form/Form/Form";
import { TextFieldComponent } from "../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import { TextareaFieldComponent } from "../components/form/components/inputs/finalFormComponents/TextareaFieldComponent";
import FormGroup from "../components/form/components/shared/FormGroup/FormGroup";
import FormSubmitButton from "../components/form/components/shared/FormSubmitButton/FormSubmitButton";
import ErrorMessage from "../components/shared/ErrorMessage";
import MuiGrid from "../components/shared/MuiGrid";
import GridItem from "../components/shared/MuiGrid/GridItem";
import SuccessContent from "../components/shared/SuccessContent";
import { parsePhoneNumber } from "../helpers/parse/parsePhoneNumber";
import { VALIDATOR_EMAIL, VALIDATOR_REQUIRE } from "../helpers/validators/inputValidators";
import { useAxiosHook } from "../hooks/useAxiosHook";

const HelpContactForm = ({ onSuccess }) => {
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [errorSuggestions, setErrorSuggestios] = useState([]);
  const { axiosPostIsLoading, axiosPostRequest } = useAxiosHook();

  const signUp = async (state) => {
    const pathname = window.location.pathname;
    const payload = {
      ...state,
      fullName: `${state?.firstName} + ${state?.lastName}`,
      origin: window.location.origin,
      path: pathname,
    };

    const params = {
      name: "Create Borrower",
      // url: "https://ironlinc-crm-app.azurewebsites.net/ironlincEmail",
      url: "https://ironlinc-crm-app.azurewebsites.net/formsubmit",

      payload: payload,
      noAuth: true,
      headers: { "Content-Type": "text/plain" },
      onSuccessFn: (data) => setShow(true),
      onFailFn: (data) =>
        setError(
          "Sorry, we could not process your request at this time. Please email us directly at contact@ironfund.com"
        ),
    };
    console.log("params", params);
    // setShow(true);
    await axiosPostRequest(params);
  };

  if (show) {
    return <SuccessContent message={"Thank your for your submission, we will reach out as soon as possible."} />;
  }

  return (
    <Form
      id="create"
      hideButton
      // initialValues={}
      submitButtonText={"props.submitButtonText"}
      onClick={signUp}
      // formSpy
      // onClick={handleCreateUser}
    >
      <FormGroup noMargin label="Your Contact Information">
        <MuiGrid spacing={1}>
          <GridItem size={6}>
            <Field
              validate={VALIDATOR_REQUIRE}
              name={"firstName"}
              label={`First Name`}
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={6}>
            <Field validate={VALIDATOR_REQUIRE} name={"lastName"} label={`Last Name`} component={TextFieldComponent} />
          </GridItem>
          <GridItem size={12}>
            <Field
              validate={VALIDATOR_REQUIRE}
              name={"phoneNumber"}
              icon={<PhoneOutlined />}
              parse={parsePhoneNumber}
              label={`Phone Number`}
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={12}>
            <Field
              validate={VALIDATOR_EMAIL}
              name={"emailAddress"}
              label={`Email Address`}
              icon={<EmailOutlined />}
              component={TextFieldComponent}
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>
      <FormGroup label="Your Message">
        <MuiGrid
          spacing={1}
          sx={{
            textarea: {
              padding: "10px !important",
            },
          }}
        >
          <GridItem size={12}>
            <Field noMargin name={"message"} component={TextareaFieldComponent} />
          </GridItem>
        </MuiGrid>
      </FormGroup>
      <FormSubmitButton
        isLoading={axiosPostIsLoading}
        buttonText={"Send Message"}
        endIcon={
          <SendRounded
            sx={{
              color: "#ffffff",
            }}
          />
        }
      />
      {/* <FormSubmitButton isLoading={axiosPostIsLoading} buttonText={"Create Account"} /> */}
      <ErrorMessage style={{ marginTop: "10px" }} show={error} message={error} />
      {errorSuggestions.length >= 1 ? <ErrorSuggestions errors={errorSuggestions} /> : null}
      {}
    </Form>
  );
};

const ErrorSuggestions = (props) => {
  return (
    <>
      <ErrorMessage
        bold
        style={{ marginTop: "15px" }}
        show={true}
        message={"Password not strong enough, here's what we suggest."}
      />
      <Stack direction={"column"} color="error">
        {props.errors.map((err) => (
          // <ErrorMessage style={{ background: "red" }} show={true} message={err} />
          <Chip
            sx={{
              color: "var(--red)",
              // display: 'flex',
              width: "min-content",
              margin: "5px auto 0",
              background: "#d32f2f24",
            }}
            label={err}
          />
        ))}
      </Stack>
    </>
  );
};

export default HelpContactForm;
