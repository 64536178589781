export const usaStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
export const usaStateInitials = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const loanOfficersSelectOptions = [
  { emailAddress: "ash@flatironrealtycapital.com", firstName: "Arsh", lastName: "Singh", fullName: "Arsh Singh" },
  {
    _id: "6312307975c143b35cf36c10",
    emailAddress: "sh@flatironrealtycapital.com",
    hubspot_owner_id: 50639236,
    firstName: "Samuel",
    lastName: "Huang",
    verified: true,
    userType: "loanOfficer",
    importedFromTeamstand: true,
    isActiveUser: true,
    isInternalUser: true,
    fullName: "Samuel Huang",
  },

  {
    _id: "6312307975c143b35cf36c05",
    emailAddress: "mf@flatironrealtycapital.com",
    hubspot_owner_id: 87568657,
    firstName: "Max",
    lastName: "Fine",
    verified: true,
    userType: "loanOfficer",
    importedFromTeamstand: true,
    isActiveUser: true,
    isInternalUser: true,
    fullName: "Max Fine",
  },

  {
    _id: "6312307975c143b35cf36c0e",
    emailAddress: "rm@flatironrealtycapital.com",
    hubspot_owner_id: 49400328,
    firstName: "Ryan",
    lastName: "Moghadasian",
    verified: true,
    userType: "loanOfficer",
    importedFromTeamstand: true,
    isActiveUser: true,
    isInternalUser: true,
    fullName: "Ryan Moghadasian",
  },

  {
    _id: "6312307975c143b35cf36c11",
    emailAddress: "sm@flatironrealtycapital.com",
    hubspot_owner_id: 82334058,
    firstName: "Sasha",
    lastName: "Moghadasian",
    verified: true,
    userType: "loanOfficer",
    importedFromTeamstand: true,
    isActiveUser: true,
    isInternalUser: true,
    fullName: "Sasha Moghadasian",
  },

  {
    _id: "6312307975c143b35cf36bea",
    emailAddress: "arr@flatironrealtycapital.com",
    hubspot_owner_id: 51265180,
    firstName: "Ariel",
    lastName: "Rayhanian",
    verified: true,
    userType: "loanOfficer",
    importedFromTeamstand: true,
    isActiveUser: true,
    isInternalUser: true,
    fullName: "Ariel Rayhanian",
  },

  {
    _id: "6312307975c143b35cf36bff",
    emailAddress: "jt@flatironrealtycapital.com",
    hubspot_owner_id: 50209261,
    firstName: "Joseph",
    lastName: "Talas",
    verified: true,
    userType: "loanOfficer",
    importedFromTeamstand: true,
    isActiveUser: true,
    isInternalUser: true,
    fullName: "Joseph Talas",
  },

  {
    _id: "6312307975c143b35cf36c01",
    emailAddress: "kt@flatironrealtycapital.com",
    hubspot_owner_id: 50772769,
    firstName: "Kayvan",
    lastName: "Talas",
    verified: true,
    userType: "loanOfficer",
    importedFromTeamstand: true,
    isActiveUser: true,
    isInternalUser: true,
    fullName: "Kayvan Talas",
  },

  {
    _id: "6312307975c143b35cf36c15",
    emailAddress: "zf@flatironrealtycapital.com",
    hubspot_owner_id: 119969294,
    firstName: "Zakary",
    lastName: "Fouladi",
    verified: true,
    userType: "loanOfficer",
    importedFromTeamstand: true,
    isActiveUser: true,
    isInternalUser: true,
    fullName: "Zakary Fouladi",
  },

  {
    _id: "6312307975c143b35cf36bf3",
    emailAddress: "dk@flatironrealtycapital.com",
    hubspot_owner_id: 49400116,
    firstName: "Daniel",
    lastName: "Kaziev",
    verified: true,
    userType: "loanOfficer",
    importedFromTeamstand: true,
    isActiveUser: true,
    isInternalUser: true,
    fullName: "Daniel Kaziev",
  },

  {
    _id: "6312307975c143b35cf36bf4",
    emailAddress: "dm@flatironrealtycapital.com",
    hubspot_owner_id: 51439638,
    firstName: "David",
    lastName: "Meytin",
    verified: true,
    userType: "loanOfficer",
    importedFromTeamstand: true,
    isActiveUser: true,
    isInternalUser: true,
    fullName: "David Meytin",
  },

  {
    _id: "6312307975c143b35cf36c12",
    emailAddress: "st@flatironrealtycapital.com",
    hubspot_owner_id: 50209327,
    firstName: "Seena",
    lastName: "Talas",
    verified: true,
    userType: "loanOfficer",
    importedFromTeamstand: true,
    isActiveUser: true,
    isInternalUser: true,
    fullName: "Seena Talas",
  },

  {
    _id: "6312307975c143b35cf36c13",
    emailAddress: "stalas@flatironrealtycapital.com",
    hubspot_owner_id: 160692486,
    firstName: "Sep",
    lastName: "Talas",
    verified: true,
    userType: "loanOfficer",
    importedFromTeamstand: true,
    isActiveUser: true,
    isInternalUser: true,
    fullName: "Sep Talas",
  },
  {
    emailAddress: "ek@flatironrealtycapital.com",
    hubspot_owner_id: "50728986",
    firstName: "Edwin",
    lastName: "Kokhabi",
    fullName: "Edwin Kokhabi",
  },
];

export const photoIdDocTypeOptions = [
  "US Passport",
  "Voter's ID",
  "Driver's License",
  "US Visa",
  "Permanent Resident (Green) Card",
  "Employment Authorization Card",
  "Foreign Passport",
];

export const borrowerBackgroundFlags = [
  { label: "Derogatory Events", name: "derogatoryEvents" },
  { label: "Foreclosure", name: "foreclosure" },
  { label: "Felony", name: "felony" },
  { label: "Criminal Records", name: "criminalRecords" },
  { label: "Bankruptcy", name: "bankruptcy" },
  { label: "Tax Leans", name: "taxLeans" },
  { label: "Judgements", name: "judgements" },
  { label: "Charge-Offs/Material Liens", name: "chargeOffsOrMaterialLiens" },
];
// export const borrowerBackgroundFlags = [
//   "Derogatory Events",
//   "Foreclosure",
//   "Felony",
//   "Criminal Records",
//   "Bankruptcy",
//   "Tax Leans",
//   "Judgements",
//   "Charge-Offs/Material Liens",
// ];

export const borrowerMortgageLates = ["30-day Mortgage Lates", "60-day Mortgage Lates"];
export const citizenshipOptions = [
  "US Citizen",
  "Foreign National with SSN",
  "Non-resident foreign national",
  "Non-citizen US resident",
];

export const estimatedCreditScore = [
  "≥ 780",
  "760-779",
  "740-759",
  "720-739",
  "700-719",
  "680-699 ",
  "660-679",
  "< 660",
];

export let subjectPropertyTypeOptions = [
  "SFR (Single Family)",
  "PUD (Planned Development Unit)",
  "2 - 4 units",
  "5+ units",
  "Mixed-Use",
  "Condo",
  "Coop",
  "Vacant Land",
  // "Non-Warrantable Condo",
  // "Townhouse",
  // "Manufactured Housing",  ???Ask chris
];
export let subjectPropertyTypeOptionss = [
  "PUD (Planned Development Unit)",
  "Mixed-Use",
  "Condo",
  "Non-Warrantable Condo",
  "Townhouse",
  "Manufactured Housing",
  "Coop",
  "SFR (Single Family)",
  "2 - 4 units",
  "5+ units",
  "2 unit",
  "3 unit",
  "4 unit",
  "5 unit",
  "6 unit",
  "7 unit",
  "8 unit",
  "9 unit",
  "10 unit",
  "11 unit",
  "12 unit",
  "13 unit",
  "14 unit",
  "15 unit",
  "16 unit",
  "17 unit",
  "18 unit",
  "19 unit",
  "20 unit",
  "21 unit",
  "22 unit",
  "23 unit",
  "24 unit",
  "25 unit",
  "26 unit",
  "27 unit",
  "28 unit",
  "29 unit",
  "30+ unit",
];

export let numberOfUnitsFivePlus = [
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30+",
];

export const productTypeOptions = [
  "30-Year Rental Loan",
  "Stabilized Bridge",
  "Fix and Flip",
  "Ground Up Construction",
  "Land Purchase",
  "Other",
];
export const loanPurposeOptions = ["Purchase", "Refinance - Cash Out", "Refinance - No Cash Out"];

export const loanExitStrategy = ["Refinance the property and hold as rental", "Sell the property"];
export const rehabRequiredOptions = [
  "Light - Rehab less than 35% of the value of the home",
  "Heavy - Rehab greater than 35% of the value of the home",
];
export const rehabPlansDocsOptions = ["Rehab Budget", "Rehab Plan", "Permit", "Other"];
export const amcPreferenceOptions = [
  "Appraisal Nation",
  "Bowery",
  "Incenter ",
  "NAN-AMC ",
  "Norman Hubbard",
  "Settlement One",
  "Vision",
  "Universal",
  "Westrock Appraisal",
  "Other",
];
export const rehabWorkRequiredIsLightAdditionalInfoOptions = [
  { label: "Full gut rehab", name: "fullGutRehab" },
  {
    label: "20% expansion of the property by square footage",
    name: "twentyPercentPropertyExpansion",
  },
  {
    label: "Addition of 750 or more square fee",
    name: "additionOfSquareFootageBy750",
  },
  {
    label: "Refinancing a construction loan and the property lacks a certificate of occupancy",
    name: "refinancingConstructionLoan ",
  },
];
// export const rehabWorkRequiredIsLightAdditionalInfoOptions = [
//   "Full gut rehab",
//   "20% expansion of the property by square footage",
//   "Addition of 750 or more square feet",
//   "Refinancing a construction loan and the property lacks a certificate of occupancy",
// ];
export const requestedLoanTermOptions = ["12-month", "12-month w/ 6-month extension"];

export const docGroupTypes = [
  { name: "photoId", label: "Photo Id" },
  { name: "loanApplication", label: "Loan Application" },
  { name: "entityDocs", label: "Entity Documents" },
  { name: "bankStatements", label: "Bank Statements" },
  { name: "creditAuth", label: "Credit Authorization" },
  { name: "closingPackage", label: "Closing Package" },
  { name: "scopeOfWork", label: "Scope Of Work" },
  { name: "liquidity", label: "Liquidity" },
  { name: "floodCert", label: "Flood Cert" },
  { name: "insuranceDocs", label: "Insurance Documents" },
  { name: "secondaryValuation", label: "Secondary Valuation" },
  { name: "purchaseContract", label: "Purchase Contract" },
  { name: "experienceDocs", label: "Experience Docs" },
  { name: "ofacReport", label: "Of AC Report" },
  { name: "appraisal", label: "Appraisal" },
  { name: "backgroundReport", label: "Background Report" },
  { name: "creditReport", label: "Credit Report" },
];

export const scenarioStatusOptions = [
  "New",
  "Proceeding to Underwriting",
  "Appraisal In",
  "In Underwriting",
  "Final Exceptions",
  "Closed",
  "Inactive",
  "Duplicate",
];

export const loanStatus = [
  "New",
  "Money In",
  "Appraisal In",
  "Underwriting",
  "Closed",
  "Closed w/Stips",
  "Final Review",
  "Dead",
  "Invalid",
  "Duplicate",
  // "2 - Doc Collection",
];

// export const scenarioStatusOptions = [
//   "Newly Submitted Loan",
//   "In Review",
//   "Loan Approval",
//   "Loan Denial",
//   "In Underwriting",
//   "Scenario Withdrawn",
// ];

export const loanProductTypeOptions = [
  "Stabilized Bridge",
  "30 Year Investor Loan",
  "Ground Up Construction",
  "Land Purchase",
  "Fix and Flip",
  "N/A",
];

// export const yesNo =useMemo(() => ["Yes", "No"], []);;
export const yesNo = ["Yes", "No"];
export const stateOfInteriorAccessOptions = ["Has interior access", "Does not have interior access"];

export const entityType = ["LLC", "Corporation"];
export const hubspotPipeline = ["FIRC Pipeline", "TPO Deals Pipeline"];
export const moneyInProductTypes = ["RTL", "DSCR"];
export const moneyInPaymentTypes = ["Zelle", "Wire", "Bill.com", "Check", "Cash", "Stripe", "Unknown"];
export const moneyInRequirements = [
  { label: "ARV Required", name: "arvRequired" },
  { label: "Rush Order Required", name: "rushOrderRequired" },
  { label: "As-Is-Value Required", name: "asIsValueRequired" },
  {
    label: "Market Rent Addendum Required",
    name: "marketRentAddendumRequired",
  },
];
// export const moneyInRequirements = [
//   "ARV Required",
//   "Rush Order Required",
//   "As-Is-Value Required",
//   "Market Rent Addendum Required",
// ];
