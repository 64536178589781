// const formInitStat = undefined;
const formInitStat = [];
// const formInitStat = [];
const _ = require("lodash");

const loanScenariosReducer = (state = formInitStat, action) => {
  let spreadState = { ...state };
  switch (action.type) {
    case "SET_SCENARIOS":
      return action.payload;
    case "UPDATE_SCENARIOS":
      let updatedState = _.set(spreadState, action.payload.key, action.payload.value);

      return updatedState;

    case "ADD_SCENARIOS":
      let newState = [...state, action.payload];
      // spreadState[action.payload.key] = action.payload.value;
      return newState;

    case "REMOVE_SCENARIOS":
      delete spreadState[action.payload.key];
      return spreadState;

    case "CLEAR_SCENARIOS":
      return formInitStat;

    default:
      return state;
  }
};

export default loanScenariosReducer;
