export const styles = {
  dealCommentCard: {
    display: "flex",
    flexShrink: 0,
    width: "100%",
    background: "#ffffff",
    boxShadow: "var(--rpv-thumbnail__container-box-shadow)",
    zIndex: 11111111,

    ".DraftEditor-root": {
      border: "1px solid var(--borderGray)",
      borderRadius: "4px",
      ".DraftEditor-editorContainer": {
        position: "absolute",
        width: "100%",
        height: "100%",
      },
      ".public-DraftEditorPlaceholder-root": {
        top: "11px",
        left: "12px",
        "div , span": {
          fontSize: ".7rem",
        },
      },
    },
  },
  dealCommentCardArrow: {
    position: "absolute",
    borderTop: "3px solid var(--blue)",
    left: "0px",
    background: "var(--blue)",
    top: "0px",
    width: "2px",
    height: "100%",
  },
  messageContainerColumn: { height: "100%", flexGrow: 1, display: "flex", flexDirection: "column" },
  messageItemThread: {
    marginBottom: "8px",
    paddingTop: "0px",
  },
  repliesContainer: {
    position: "relative",
    marginLeft: "10px",
    paddingTop: "4px",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
};
