import {
  AssignmentIndOutlined,
  ChevronRightRounded,
  CloseRounded,
  HistoryOutlined,
  PersonOutlined,
  SaveOutlined,
  StoreOutlined,
  TextSnippetOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { isEqual, isNil, isObject } from "lodash";
import React, { useState } from "react";
import { Field, useFormState } from "react-final-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FinalFormDraftJsField from "../../../../components/form/Fields/FinalFormDraftJsField";
import Form from "../../../../components/form/Form/Form";
import { EmployeeSelectFieldComponent } from "../../../../components/form/components/inputs/finalFormComponents/EmployeeSelectFieldComponent";
import DisplayDataFieldItem from "../../../../components/shared/DisplayDataFieldItem";
import FlexBox from "../../../../components/shared/FlexBox";
import MuiReactTableWithHeader from "../../../../components/shared/MuiReactTableWithHeader";
import TabSwitcher from "../../../../components/shared/TabSwitcher";
import TabPanel from "../../../../components/shared/TabSwitcher/TabPanel";
import { formFormatDollar } from "../../../../helpers/parse/formFormatDollar";
import { parseISOToDate } from "../../../../helpers/parseISOToDate";
import { useBorrowerHook } from "../../../../hooks/useBorrowerHook";
import DrawerSection from "./DrawerSection";
import InviteUserEmailContent from "./InviteUserModal/InviteUserEmailContent";
import { arrayProtector } from "../../../../helpers/arrayProtector";
import FinalFormToggle from "../../../../components/form/Fields/FinalFormToggle";
import FormResults from "../../../../components/form/FormResults";
import { useEffect } from "react";
import { useBrokerDrilldownHook } from "../../../../hooks/useBrokerDrilldownHook";
import { loanScenariosTableColumns } from "../../../../shared/table_columns/loanScenariosTableColumns";
import MuiReactTable from "../../../../components/shared/MuiReactTable";
import IronFundUserLastSignInPopover from "../../../../shared/IronFundUserLastSignInPopover";

const UserDetailsDrawer = ({ user, show, onCancel }) => {
  const { postIsLoading, updateUserContactData, getAllUsers } = useBorrowerHook();
  const [tabValue, setTabValue] = useState(0);
  const handleOnSubmitUserUpdate = async (state) => {
    console.log("statestate", state);
    const handleOnSuccess = () => {
      getAllUsers({});
      onCancel();
    };
    const params = {
      payload: state,
      onSuccessFn: handleOnSuccess,
      userId: state?._id,
    };

    console.log(params);
    // handleOnSuccess();
    await updateUserContactData(params);
  };

  if (isNil(user) || !isObject(user)) {
    return null;
  }
  return (
    <Drawer
      anchor={"right"}
      open={show}
      onClose={onCancel}
      sx={{
        ".MuiPaper-root": {
          display: "flex",
          width: "100%",
          // maxWidth: "68vw",
          // maxWidth: "620px",
          maxWidth: tabValue === 1 ? "1220px" : "620px",
          padding: "20px 15px",
        },
      }}
    >
      <Form
        initialValues={user}
        submitButtonText={"Save"}
        // onClick={handleUpdateUser}
        hideButton
        //
        //  formSpy
      >
        <USER_FORM
          user={user}
          onCancel={onCancel}
          handleOnSubmitUserUpdate={handleOnSubmitUserUpdate}
          isLoading={postIsLoading}
          tabValue={tabValue}
          setTabValue={setTabValue}
        />
      </Form>
    </Drawer>
  );
};

const USER_FORM = ({ user, onCancel, handleOnSubmitUserUpdate, isLoading, setTabValue, tabValue }) => {
  const { values } = useFormState();

  const isModified = isEqual(values, user) ? false : true;

  return (
    <>
      <Stack>
        <IconButton onClick={onCancel} color="primary" sx={{ position: "absolute", top: "5px", right: "5px" }}>
          <CloseRounded />
        </IconButton>
        <Typography variant="h3" pb={2} fontWeight={700}>{`${user?.firstName} ${user?.lastName}`}</Typography>
        <TabSwitcher sub setValue={setTabValue} tabs={[`User Details`, `Submitted Scenarios`, "Raw Data"]} />
        {/* <Divider /> */}
        <TabPanel value={tabValue} index={0}>
          <USER_DETAILS_SECTION />
          <COMPANY_DETAILS_SECTION />
          <PREVIOUS_ACTIVITY_SECTION />
          <IRONFUND_CONTENT_SECTION />
          <USER_NOTES_SECTION />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <USER_SCENARIOS />
        </TabPanel>
        {/* <TabPanel value={tabValue} index={2}>
          <EmailTab />
        </TabPanel> */}
        <TabPanel value={tabValue} index={2}>
          <FormResults formSpyRaw={true} values={values} />
        </TabPanel>
      </Stack>
      {isModified ? (
        <FlexBox
          sx={{
            top: "auto",
            bottom: 0,
            left: 0,
            position: "absolute",
            width: "100%",
            zIndex: 111111,
          }}
        >
          <LoadingButton
            onClick={() => handleOnSubmitUserUpdate(values)}
            loading={isLoading}
            sx={{ flexGrow: 1, borderRadius: 0 }}
            variant="contained"
            size="large"
            endIcon={<SaveOutlined />}
          >
            Save Changes
          </LoadingButton>
        </FlexBox>
      ) : null}
    </>
  );
};

// --- //
const USER_DETAILS_SECTION = (props) => {
  const { values } = useFormState();
  return (
    <DrawerSection
      mt={3}
      title={"User Details"}
      titleIcon={<PersonOutlined sx={{ fontSize: "1rem !important", opacity: 0.75, marginRight: "5px" }} />}
    >
      <DisplayDataFieldItem row dot label={"Email Address"} value={values?.emailAddress} />
      <DisplayDataFieldItem row dot label={"Phone Number"} value={values?.phoneNumber} />
      <DisplayDataFieldItem
        row
        dot
        sx={{
          ".display-data-field-item__value": {
            marginLeft: "10px !important",
          },
        }}
        // sx={{ justifyContent: "space-between" }}
        label={"Mark User as Hidden"}
        value={
          <Box sx={{ marginLeft: "10px" }}>
            <FinalFormToggle name={"isHidden"} />
          </Box>
        }
      />
    </DrawerSection>
  );
};

// --- //
const COMPANY_DETAILS_SECTION = (props) => {
  const { values } = useFormState();
  return (
    <DrawerSection
      title={"Company Details"}
      titleIcon={<StoreOutlined sx={{ fontSize: "1rem !important", opacity: 0.75, marginRight: "5px" }} />}
    >
      <DisplayDataFieldItem row dot label={"Company Name"} value={values?.company} />
      <DisplayDataFieldItem row dot label={"Company Role"} value={values?.companyRole} />
      <DisplayDataFieldItem row dot label={"Company Url"} value={values?.companyUrl} />
    </DrawerSection>
  );
};

// --- //
const PREVIOUS_ACTIVITY_SECTION = (props) => {
  const { values } = useFormState();
  return (
    <DrawerSection
      title={"Previous Activity"}
      titleIcon={<HistoryOutlined sx={{ fontSize: "1rem !important", opacity: 0.75, marginRight: "5px" }} />}
    >
      <DisplayDataFieldItem row dot label={"Last Sign In"} value={<IronFundUserLastSignInPopover user={values} />} />
      <DisplayDataFieldItem row dot label={"Last Scenario Submission"} value={values?.lastScenarioSubmission} />
      <DisplayDataFieldItem row dot label={"Last File Upload"} value={values?.lastFileUpload} />
    </DrawerSection>
  );
};

// --- //
const IRONFUND_CONTENT_SECTION = (props) => {
  return (
    <DrawerSection
      title={"Iron Fund Contact"}
      titleIcon={<AssignmentIndOutlined sx={{ fontSize: "1rem !important", opacity: 0.75, marginRight: "5px" }} />}
    >
      <Field label="Contact Name" name={`assignedSalesperson`} component={EmployeeSelectFieldComponent} />
    </DrawerSection>
  );
};

// --- //
const USER_NOTES_SECTION = ({ user }) => {
  return (
    <DrawerSection
      title={"User Notes"}
      titleIcon={<TextSnippetOutlined sx={{ fontSize: "1rem !important", opacity: 0.75, marginRight: "5px" }} />}
    >
      <FinalFormDraftJsField label="Notes" name={`notes`} />
      {/* <DjsTextEditor value={userNotes} /> */}
    </DrawerSection>
  );
};

// --- //
const USER_SCENARIOS = ({ user }) => {
  const { values } = useFormState();
  const navigate = useNavigate();

  const loanScenarios = useSelector((state) => state.loanScenarios);
  const handleClick = (id) => {
    navigate(`/scenarios/${id}`);
  };
  const cols = [
    {
      Header: "Name",
      accessor: "loanSubjectName",
    },
    {
      Header: "Submitted By",
      accessor: (d) => d?.submittedBy?.fullName,
    },
    {
      Header: "Loan Product Type",
      accessor: "loanProductType",
    },
    {
      Header: "Loan Purpose",
      accessor: "loanPurpose",
    },
    {
      Header: "Initial Loan Amount",
      accessor: (d) => `$${formFormatDollar(d.initialLoanAmount)}`,
    },
    {
      Header: "Salesperson",
      accessor: (d) => d?.loanSalesperson?.fullName,
    },

    {
      Header: "Submitted On",
      accessor: (d) => parseISOToDate(d?.submitted_est),
    },

    {
      Header: " ",
      accessor: (d) => (
        <IconButton
          onClick={() => handleClick(d?._id)}
          color="primary"
          sx={{ background: "#39679112", marginLeft: "10px" }}
        >
          <ChevronRightRounded />
        </IconButton>
      ),
    },
  ];

  const columns = React.useMemo(() => loanScenariosTableColumns(handleClick), []);

  const tableData = React.useMemo(
    () => values?.scenarios?.map((id) => loanScenarios.find((sc) => String(sc._id) === String(id))),
    []
  );

  console.log("tableData", tableData);
  // const tableData = React.useMemo(() => loanScenarios?.filter((sc) =>  values?.scenarios.includes(sc._id)), []);
  // const userScenarios =
  return (
    <MuiReactTable
      id="submitted-scenarios-table"
      title={`Submitted Loan Scenarios (${arrayProtector(tableData).length})`}
      // hideHeader
      // search
      emptyTitle={"There are currently no scenarios"}
      noBorder
      // widget
      // search
      columns={columns}
      data={tableData}
      // title={"Unassigned Users"}
      // emptyTitle={"There are currently no unassigned users."}
      // noBorder
      search
      // isLoading={getIsLoading}
    />
  );
};

const EmailTab = () => {
  return (
    <FlexBox
      sx={{
        flexGrow: 1,
        width: "100%",
        gap: "20px",
        justifyContent: "center",
        form: {
          width: "100%",
        },
      }}
    >
      <Form
        // initialValues={{
        //   subject: "Welcome To Iron Fund | By IronLinc",
        //   body: templates[0],
        // }}
        hideButton
        // hideButton={value === 0 ? false : true}
        submitButtonText={"Send Email Invitation"}
        // onClick={handleOnSubmit}
        // isLoading={axiosPostIsLoading}
        // formSpy
        //
      >
        <InviteUserEmailContent />
      </Form>
    </FlexBox>
  );
};

export default UserDetailsDrawer;
