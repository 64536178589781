import creditAuthorizationsReducer from "./creditAuthorizationsReducer";
import profileReducer from "./profileReducer";
import documentsReducer from "./documentsReducer";
import formDataReducer from "./formDataReducer";
import globalAlertReducer from "./globalAlertReducer";
import globalDataReducer from "./globalDataReducer";

import { combineReducers } from "redux";
import isLoadingReducer from "./isLoadingReducer";
import loanScenariosReducer from "./loanScenariosReducer";
import modalReducer from "./modalReducer";

const rootReducer = combineReducers({
  formData: formDataReducer,
  loanScenarios: loanScenariosReducer,
  globalData: globalDataReducer,
  profile: profileReducer,
  creditAuthorizations: creditAuthorizationsReducer,
  documents: documentsReducer,
  isLoading: isLoadingReducer,
  modal: modalReducer,
  globalAlert: globalAlertReducer,
});
// const rootReducer = combineReducers({
//   formData: formDataReducer,
//   formDataUpdate: formDataUpdateReducer,
//   userType: userTypeReducer,
//   user: userReducer,
//   borrowerProfile: borrowerProfileReducer,
//   globalAlert: globalAlertReducer,
//   dealSubmissions: dealSubmissionsReducer,
//   creditAuthorizations: creditAuthorizationsReducer,
//   documents: documentsReducer,
//   isLoading: isLoadingReducer,
//   isLoggedIn: isLoggedInReducer,
//   modal: modalReducer,
//   globalData: globalDataReducer,
//   formIsValid: formIsValidReducer,
//   styles: stylesReducer,
// });

export default rootReducer;
