import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { mapNestedObjectFormData } from "../helpers/mapObjectFormData";
import { setFormData } from "../redux/actions/formDataActions";
import { globalAlertSuccess } from "../redux/actions/globalAlertActions";
import { setScenarios } from "../redux/actions/loanScenariosActions";
import { useAxiosHook } from "./useAxiosHook";
import { useCallback } from "react";
import { isArray, isNil } from "lodash";
import { useFilesHook } from "./useFilesHook";
import { addParamsToString } from "../helpers/addParamsToString";
import { arrayProtector } from "../helpers/arrayProtector";
import { replaceObjectInArray } from "../helpers/replaceObjectInArray";
import { updateGlobalData } from "../redux/actions/globalDataActions";

export const useBorrowerHook = () => {
  const { axiosPostIsLoading, axiosGetIsLoading, axiosError, axiosGetRequest, axiosPostRequest } = useAxiosHook();
  const { filesGetIsLoading, filesPostIsLoading, previewFile, updateFile, downloadZip } = useFilesHook();
  // Hooks
  const getIsLoading = axiosGetIsLoading || filesGetIsLoading;
  const postIsLoading = axiosPostIsLoading || filesPostIsLoading;
  const loanScenarios = useSelector((state) => state.loanScenarios);
  const globalUsers = useSelector((state) => state.global?.users);
  const error = axiosError;
  const dispatch = useDispatch();

  // -------------------------------------
  // -------------------------------------
  /**
   * GET
   *  - (Global)
   * -- Return/display borrower data (view profile)
   * -- Plaid account connection ?
   * -- Display dashboard messages/comments (messages section on dashboard home)
   * -- Display global level files (all files submitted by user accross all deals) - is this needed?  //Would be for /files page
   * -- Display all credit authorizations (at least info for displaying in table)
   * -- Display full submitted credit authorization info
   * - (Deal)
   * -- Display submitted loan submissions (at least info for displaying in table)
   * -- Display full loan data
   * POST
   * - (Global)
   * -- Submit credit auth
   * -- Submit loan app
   * -- Submit a dashboard message/comment
   * -- Update borrower data/profile info
   * - (Deal)
   * -- Upload file on deal
   * -- Update data on deal
   * -- Add comment on deal
   */

  /** USER
   * Get user
   * Scenario preview files
   * Scenario download files
   * Scenario update file metadata
   */

  const getIronFundSalesDropdownList = async ({ onSuccessFn, onFailFn }) => {
    const endpoint = `portal_ironfund_salesteam?scenarioType=IronLincBrokerScenario`;

    const onRequestSuccess = (passedData) => {
      console.log("passedData", passedData);
      dispatch(updateGlobalData({ key: "employees", value: passedData.items }));
      if (onSuccessFn) {
        onSuccessFn(passedData);
      }
    };

    const params = {
      name: "Get Iron Fund Sales Dropdown",
      path: endpoint,
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not get sales people",
      onFailFn,
    };

    await axiosGetRequest(params);
  };

  const getUserProfileData = async ({ onSuccessFn }) => {
    /** Get User Profile Data **/
    const endpoint = `get_user_profile`;

    const onRequestSuccess = (passedData) => {
      // dispatch(setFormData(passedData));
      if (onSuccessFn) {
        onSuccessFn(passedData);
      }
    };

    const params = {
      name: "Get User Profile Data",
      path: endpoint,
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not get profile data",
    };

    await axiosGetRequest(params);
  };

  const updateUserProfileData = async ({
    payload, //required
    onSuccessFn,
    formData,
  }) => {
    /** Update User Profile Data
     *  - Returns: data={userProfile}:  */
    const endpoint = `update_user_profile`;

    const onRequestSuccess = () => {
      dispatch(globalAlertSuccess("Profile Updated Successfully"));
      if (onSuccessFn) {
        onSuccessFn(payload);
      }
    };

    const params = {
      name: "Update User Profile",
      path: endpoint,
      payload,
      formData,
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not update",
    };

    await axiosPostRequest(params);
  };

  const updateUserContactData = async ({
    payload, //required
    userId, //required
    onSuccessFn,
    formData,
  }) => {
    /** Update User Profile Data
     *  - Returns: data={userProfile}:  */
    const endpoint = `update_user_contact_detail?userId=${userId}`;

    const onRequestSuccess = (passedData) => {
      console.log("User Updated Successfully passedData->", passedData);
      dispatch(globalAlertSuccess("User Updated Successfully"));
      if (isArray(globalUsers)) {
        const newUpdatedArray = replaceObjectInArray(globalUsers, payload, passedData);
        dispatch(updateGlobalData({ key: "users", value: newUpdatedArray }));
      }
      if (onSuccessFn) {
        onSuccessFn(payload);
      }
    };

    const params = {
      name: "Update Contact Data",
      path: endpoint,
      payload,
      // formData: true,
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not update",
    };

    await axiosPostRequest(params);
  };

  const getUserMessages = async ({ onSuccessFn }) => {
    /** Get User Messages **/
    // Returns: array of Global user messages
    const endpoint = `get_user_messages`;

    const onRequestSuccess = (passedData) => {
      if (onSuccessFn) {
        onSuccessFn(passedData);
      }
    };

    const params = {
      name: "Get Global User Message",
      path: endpoint,
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not get user messages",
    };

    await axiosGetRequest(params);
  };

  const getUserScenarios = async ({ onSuccessFn, onFailFn }) => {
    /** Get User Scenarios **/
    // Returns: data={userScenarios}:
    const endpoint = `get_user_scenarios?scenarioType=IronLincBrokerScenario`;

    const onRequestSuccess = (passedData) => {
      let scenarios = passedData?.userScenarios;
      const scenarios_reversed = arrayProtector(scenarios).reverse();
      dispatch(setScenarios(scenarios_reversed));
      if (onSuccessFn) {
        onSuccessFn(scenarios_reversed);
      }
    };

    const params = {
      name: "Get Broker Scenarios",
      path: endpoint,
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not get scenarios",
      onFailFn,
    };
    // console.log("DUMMY SEND", params);
    // onRequestSuccess({ userScenarios: DUMMY_RESPONSE_create_broker_scenario });
    await axiosGetRequest(params);
  };

  const getIronfundUsers = async ({ onSuccessFn, onFailFn }) => {
    // const endpoint = `portal_ironfund_users?scenarioType=IronLincBrokerScenario`;
    const endpoint = `portal_ironfund_salesteam?scenarioType=IronLincBrokerScenario`;

    const onRequestSuccess = (passedData) => {
      if (onSuccessFn) {
        onSuccessFn(passedData);
      }
    };

    const params = {
      name: "Get Broker Scenarios",
      path: endpoint,
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not get scenarios",
      onFailFn,
    };
    // console.log("DUMMY SEND", params);
    // onRequestSuccess({ userScenarios: DUMMY_RESPONSE_create_broker_scenario });
    await axiosGetRequest(params);
  };

  //in progress of moving to useIronfundUserHook --> getIronFundUsers
  const getAllUsers = async ({ onSuccessFn, onFailFn }) => {
    const endpoint = `get_all_ironfund_users`;
    // const endpoint = `get_all_ironfund_users?scenarioType=IronLincBrokerScenario`;

    const onRequestSuccess = (passedData) => {
      let users = passedData?.users;
      let reversedUsers;
      if (isArray(users)) {
        let filteredUsers = users.filter(
          (u) => !u?.emailAddress.includes("@ironfund.com") && !u?.emailAddress.includes("@flatironrealtycapital.com")
        );
        reversedUsers = filteredUsers.reverse();
        dispatch(updateGlobalData({ key: "users", value: reversedUsers }));
      }
      if (onSuccessFn) {
        onSuccessFn(reversedUsers);
      }
    };

    const params = {
      name: "Get All Users",
      path: endpoint,
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not get scenarios",
      onFailFn,
    };
    // console.log("DUMMY SEND", params);
    // onRequestSuccess({ userScenarios: DUMMY_RESPONSE_create_broker_scenario });
    await axiosGetRequest(params);
  };

  const getGuidelineMarketingMaterials = async ({ onSuccessFn, onFailFn }) => {
    /** Get User Scenarios **/
    // Returns: data={userScenarios}:
    const endpoint = `get_guideline_marketing_materials`;

    const onRequestSuccess = (passedData) => {
      let documents = passedData?.documents;

      if (onSuccessFn) {
        onSuccessFn(documents);
      }
    };

    const params = {
      name: "Get Marketing Material",
      path: endpoint,
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not get marketing materials",
      onFailFn,
    };
    // console.log("DUMMY SEND", params);
    // onRequestSuccess({ userScenarios: DUMMY_RESPONSE_create_broker_scenario });
    await axiosGetRequest(params);
  };

  const postUserMessage = async ({
    payload, //required
    onSuccessFn,
  }) => {
    /** Update User Profile Data
     *   - Returns: updated array of Global user messages   **/
    const endpoint = `post_user_message`;

    const onRequestSuccess = (passedData) => {
      dispatch(globalAlertSuccess("Submission Successful"));
      if (onSuccessFn) {
        onSuccessFn(passedData);
      }
    };

    const params = {
      name: "Post User Comment",
      path: endpoint,
      payload,
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not post comment",
    };

    await axiosPostRequest(params);
  };

  const postLoanScenarioComment = async ({
    id,
    payload, //required
    onSuccessFn,
  }) => {
    /** Update User Profile Data
     *   - Returns: updated array of Global user messages   **/
    const endpoint = `post_scenario_message?scenarioType=IronLincBrokerScenario&scenarioId=${id}`;

    const onRequestSuccess = (passedData) => {
      dispatch(globalAlertSuccess("Submission Successful"));
      if (onSuccessFn) {
        onSuccessFn(passedData);
      }
    };

    const params = {
      name: "Post User Comment",
      path: endpoint,
      payload,
      formData: true,
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not post comment",
    };

    await axiosPostRequest(params);
  };

  const createBrokerScenario = async ({
    payload, //required
    name,
    onSuccessFn,
    onFailMsg,
  }) => {
    /**Create Broker Scenario **/
    console.log("payload", payload);
    const endpoint = `create_broker_scenario?scenarioType=IronLincBrokerScenario `;

    const onRequestSuccess = (data) => {
      dispatch(globalAlertSuccess("Submission Successful"));
      if (onSuccessFn) {
        onSuccessFn(data);
      }
      // setLoading(null);
    };

    const params = {
      name: "Create Broker Scenario",
      path: endpoint,
      payload: mapNestedObjectFormData(payload),
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not create scenario",
    };

    // onRequestSuccess(DUMM);
    await axiosPostRequest(params);
  };

  const submitAuctionForm = async ({
    payload, //required
    name,
    onSuccessFn,
    onFailFn,
    onFailMsg,
  }) => {
    /**Create Broker Scenario **/
    console.log("payload", payload);
    const endpoint = `auction_borrower_preapproval?actionCategory=auctionPreApproval `;

    const onRequestSuccess = (data) => {
      dispatch(globalAlertSuccess("Submission Successful"));
      if (onSuccessFn) {
        onSuccessFn(data);
      }
      // setLoading(null);
    };

    const params = {
      name: "Submit Auction Form",
      path: endpoint,
      payload: mapNestedObjectFormData(payload),
      onSuccessFn: onRequestSuccess,
      onFailFn: onFailFn,
      onFailMsg: "Could not submit Auction Form",
    };

    // onRequestSuccess(DUMM);
    await axiosPostRequest(params);
  };

  const createBorrowerScenario = async ({
    payload, //required
    name,
    onSuccessFn,
    onFailMsg,
  }) => {
    /** Create Borrower Scenario **/
    const endpoint = `create_borrower_scenario`;

    const onRequestSuccess = (data) => {
      dispatch(globalAlertSuccess("Submission Successful"));
      if (onSuccessFn) {
        onSuccessFn(data);
      }
      // setLoading(null);
    };

    const params = {
      name: "Create Borrower Scenario",
      path: endpoint,
      payload: mapNestedObjectFormData(payload),
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not create scenario",
    };
    await axiosPostRequest(params);
  };

  const createIronlincApplication = async ({
    payload, //required
    name,
    onSuccessFn,
    onFailMsg,
  }) => {
    /** Create Borrower Scenario **/
    const endpoint = `ironlinc_applications`;

    const onRequestSuccess = (data) => {
      dispatch(globalAlertSuccess("Submission Successful"));
      if (onSuccessFn) {
        onSuccessFn(data);
      }
      // setLoading(null);
    };

    const params = {
      name: "Create Application",
      path: endpoint,
      payload: mapNestedObjectFormData(payload),
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not create application",
    };
    await axiosPostRequest(params);
  };

  const updateBrokerScenario = async ({
    payload, //required
    name,
    onSuccessFn,
    onFailMsg,
    id,
  }) => {
    /** Create Borrower Scenario **/
    const endpoint = `update_broker_scenario?scenarioType=IronLincBrokerScenario&scenarioId=${id}&actionCategory=updateScenario`;

    const onRequestSuccess = (data) => {
      dispatch(globalAlertSuccess("Scenario Updated Successfully"));

      const newUpdatedArray = replaceObjectInArray(loanScenarios, payload, data);
      dispatch(setScenarios(newUpdatedArray));
      if (onSuccessFn) {
        onSuccessFn(data);
      }
      // setLoading(null);
    };

    const params = {
      name: "Update Broker Scenario",
      path: endpoint,
      payload: payload,
      // payload: mapNestedObjectFormData(payload),
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not update scenario",
    };
    console.log("params", params);
    // onRequestSuccess(payload);
    await axiosPostRequest(params);
  };

  /** FILES
   * Scenario upload preview files
   * Scenario preview files
   * Scenario download files
   * Scenario update file metadata
   */
  const uploadScenarioFiles = useCallback(
    async ({
      id, //required
      payload, //required
      ironId, //required
      name,
      onSuccessFn,
      onFailFn,
      loadingName,
    }) => {
      const endpoint = `portal_upload_documents?scenarioType=IronLincBrokerScenario&scenarioId=${id}`;

      const onRequestSuccess = (data) => {
        dispatch(globalAlertSuccess("Scenario File Uploaded Successfully"));
        if (onSuccessFn) {
          onSuccessFn(data);
        }
        // setLoading(null);
      };

      const params = {
        name: "Upload Broker Files",
        path: endpoint,
        // payload: payload,
        payload: mapNestedObjectFormData(payload),
        onSuccessFn: onRequestSuccess,
        onFailMsg: "Could not update scenario",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      console.log("Uploap File Params: ", params);
      await axiosPostRequest(params);
    },
    []
  );

  const previewScenarioFile = useCallback(
    async ({ fileStorageName, scenarioId, downloadName, onSuccessFn, loadingName }) => {
      console.log("new");
      const endpoint = `portal_get_file_by_name?file_storage_name=${fileStorageName}&scenarioType=IronLincBrokerScenario&scenarioId=${scenarioId}`;

      const getFileParams = {
        // filename: filename,
        path: endpoint,
        downloadName: downloadName,
        onSuccessFn: onSuccessFn,
        // dealId: formData._id,
        // ironId: formData.ironId,
        // actionCategory,
      };

      console.log("params", getFileParams);
      await previewFile(getFileParams);
    },
    []
  );

  const downloadScenarioFilesAsZip = useCallback(
    async ({ scenarioId, params, downloadName, onSuccessFn, loadingName }) => {
      console.log("new");
      const endpoint = `portal_download_zip_file?scenarioType=IronLincBrokerScenario&scenarioId=${scenarioId}`;
      const endpointWithParams = `portal_download_zip_file?scenarioType=IronLincBrokerScenario&scenarioId=${scenarioId}&${addParamsToString(
        params,
        "&"
      )}`;

      const zipParams = {
        endpoint: isNil(params) ? endpoint : endpointWithParams,
        downloadName: downloadName,
        // onSuccessFn: onSuccessFn,
      };

      console.log("params", zipParams);
      await downloadZip(zipParams);
    },
    []
  );

  const updateScenarioFile = useCallback(async ({ payload, fileId, scenarioId, name, onSuccessFn }) => {
    let uploadState = {
      _id: fileId,
      ...payload,
    };

    const params = {
      name: name,
      scenarioId: scenarioId,
      payload: uploadState,
      onSuccessFn: onSuccessFn,
    };

    updateFile(params);
  }, []);

  const approveScenarioFileForUnderwriting = useCallback(async ({ fileId, scenarioId, ironId, onSuccessFn }) => {
    let payloadData = {
      _id: fileId,
      approvedForUnderwriting: true,
    };
    let payloadDataIronId = {
      _id: fileId,
      approvedForUnderwriting: true,
      ironId: ironId,
    };

    // const params = {
    //   name: name,
    //   scenarioId: scenarioId,
    //   payload: uploadState,
    //   onSuccessFn: onSuccessFn,
    // };

    // updateFile(params);
    const endpoint = `portal_approve_document_for_underwriting?scenarioType=IronLincBrokerScenario&scenarioId=${scenarioId}`;
    // const endpointWithIronId = `portal_approve_document_for_underwriting?scenarioType=IronLincBrokerScenario&scenarioId=${scenarioId}`;

    const onRequestSuccess = (data) => {
      dispatch(globalAlertSuccess("File Approved Successfully"));
      if (onSuccessFn) {
        onSuccessFn(data);
      }
    };

    const params = {
      name: "Approve File For Underwriting",
      path: endpoint,
      payload: isNil(ironId) ? payloadData : payloadDataIronId,

      // payload: mapNestedObjectFormData(payload),
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not update scenario",
    };
    console.log("params", params);
    // onRequestSuccess(payload);
    await axiosPostRequest(params);
  }, []);

  /**
   * Scenarios
   * - Move scenario to underwriting
   * -
   */
  const moveScenarioToUnderwriting = useCallback(async ({ payload, fileId, scenarioId, name, onSuccessFn }) => {
    const endpoint = `move_scenario_to_underwriting?scenarioType=IronLincBrokerScenario&scenarioId=${scenarioId}`;

    const onRequestSuccess = (data) => {
      dispatch(globalAlertSuccess("Scenario is now in Underwriting"));
      if (onSuccessFn) {
        onSuccessFn(data);
      }
    };

    const params = {
      name: "Approve File For Underwriting",
      path: endpoint,
      payload: payload,
      // payload: mapNestedObjectFormData(payload),
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not update scenario",
    };
    // console.log("params", params);
    // onRequestSuccess();
    await axiosPostRequest(params);
  }, []);

  return {
    error,
    postIsLoading,
    getIsLoading,
    getIronFundSalesDropdownList,
    getUserProfileData,
    getUserScenarios,
    getAllUsers,
    getIronfundUsers,
    getGuidelineMarketingMaterials,
    getUserMessages,
    updateUserProfileData,
    updateUserContactData,
    updateBrokerScenario,
    uploadScenarioFiles,
    updateScenarioFile,
    moveScenarioToUnderwriting,
    postUserMessage,
    postLoanScenarioComment,
    createBrokerScenario,
    createBorrowerScenario,
    createIronlincApplication,
    previewScenarioFile,
    downloadScenarioFilesAsZip,
    submitAuctionForm,
    approveScenarioFileForUnderwriting,
  };
};
