import { TableRow } from "@mui/material";
import React from "react";

const MuiEditableTableRow = ({ children, sx, isTotal, colSpan }) => {
  const totalRowStyles = {
    background: "#dddde12b",
    borderTop: "3px solid rgba(224, 224, 224, 1)",

    ".MuiTableCell-root": {
      fontWeight: 600,
      color: "#7b7b7d",
    },
    ...sx,
  };

  return (
    <TableRow colSpan={colSpan} sx={isTotal ? totalRowStyles : sx}>
      {children}
    </TableRow>
  );
};

export default MuiEditableTableRow;
