import React from "react";
import FormField from "../form/components/shared/FormField/FormField";
import { Box } from "@mui/material";

const TextOverflow = (props) => {
  const containerStyles = {
    position: "relative",
    maxHeight: "200px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    overflow: "hidden",
    borderRadius: "4px",
    textAlign: "left",
  };
  const overflowStyles = {
    padding: "12px 10px",
    maxHeight: "inherit",
    overflowY: "auto",
  };
  return (
    <Box
      id={props.id}
      sx={{
        ".overflow-inner-wrapper p": {
          fontSize: ".7rem",
          lineHeight: "1.2rem",
          marginBottom: "10px",
        },
      }}
      className="text-overflow"
    >
      <FormField fieldLabel={props.title} noMargin={props.noMargin}>
        <div style={containerStyles} className="text-overflow-container">
          <div style={overflowStyles} className="overflow-inner-wrapper">
            {props.children}
          </div>
        </div>
      </FormField>
    </Box>
  );
};

export default TextOverflow;
