import React from "react";

const DrilldownOverviewBox = ({ children, sx }) => {
  const styles = {
    display: "flex",
    justifyContent: "space-between",
    gap: "30px",
    alignItems: "center",
    flexGrow: 1,
    padding: " 4px 12px",
    background: "#1c456a14",
    border: "1px solid #bebdbd59",
    ...sx,
  };
  return (
    <div style={styles} className="drilldown-overview-box">
      {children}
    </div>
  );
};

export default DrilldownOverviewBox;
