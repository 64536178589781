import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormCard from "../../../../components/shared/FormCard";
import BrokerSignUpForm from "../../../../forms/BrokerSignUpForm";
import FlexBox from "../../../../components/shared/FlexBox";

const BrokerSignUpScreen = (props) => {
  const [isSuccess, setIsSuccess] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const handleOnSuccess = (email) => {
    setIsSuccess(true);
    setUserEmail(email);
  };

  const navigate = useNavigate();
  return (
    <Box
      sx={{
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        // flexGrow: 1,
        // flexBasis: "100%",
        // padding: "10px",
        background: "var(--lightBlue)",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        padding: "20px 10px",
        overflowY: "auto",
      }}
    >
      <FlexBox center column sx={{}}>
        <FormCard
          title={isSuccess ? "Account Created!" : "Broker Account Creation"}
          description={
            isSuccess
              ? ` We sent a confirmation email to ${userEmail}, please confirm this is you.`
              : "Enter the following information to get started."
          }
          isSuccess={isSuccess}
        >
          <BrokerSignUpForm onSuccess={handleOnSuccess} setIsLogin={props.setIsLogin} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
              gap: "3px",
            }}
          >
            <Typography sx={{ fontWeight: 500, lineHeight: 1.75 }}>Already have an account?</Typography>
            <Button
              sx={{ padding: "0px 3px", fontWeight: 500, minWidth: "unset" }}
              variant="text"
              onClick={() => navigate("/login")}
            >
              Log In
            </Button>
          </Box>
        </FormCard>
      </FlexBox>
    </Box>
  );
};

export default BrokerSignUpScreen;
