import React from "react";
import { Field, useForm, useFormState } from "react-final-form";
import FinalFormSelectField from "../../../components/form/Fields/FinalFormSelectField";
import { EmployeeSelectFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/EmployeeSelectFieldComponent";
import FormGroup from "../../../components/form/components/shared/FormGroup/FormGroup";

import { Typography } from "@mui/material";
import { isNil } from "lodash";
import FinalFormDraftJsField from "../../../components/form/Fields/FinalFormDraftJsField";
import FinalFormTextField from "../../../components/form/Fields/FinalFormTextField";
import { DatePickerTextFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/DatePickerTextFieldComponent";
import FlexBox from "../../../components/shared/FlexBox";
import MuiGrid from "../../../components/shared/MuiGrid";
import GridItem from "../../../components/shared/MuiGrid/GridItem";
import { fieldNames } from "../../../global/forms/fieldNames";
import { formatAsDollarString } from "../../../helpers/formatAsDollarString";
import { loanPurposeOptions, productTypeOptions } from "../../_formQuestions/selectOptions";

// import "./CreditAuthConfirmation.css";
// import Form from "../../../../UserAdmission/components/SignInForm/SignInForm";
// import "./CreditAuthFormStepTwo.css";

const CreateScenarioStepLoanDetails = ({ display }) => {
  const { values, errors, form } = useFormState();
  const { change } = useForm();

  const includesRehab =
    values?.loanProductType === "Fix and Flip" || values?.loanProductType === "Ground Up Construction" ? true : false;

  const handleOnInitialAmoutChange = (val) => {
    const initialAmt = val;
    const rehabAmt = isNil(values?.constructionRenovationBudget) ? 0 : values?.constructionRenovationBudget;
    const totalLoanAmountValue = initialAmt + rehabAmt;
    if (includesRehab) {
      change("totalLoanAmount", totalLoanAmountValue);
    } else {
      change("totalLoanAmount", val);
    }
  };

  const handleOnRehabAmoutChange = (val) => {
    const initialAmt = isNil(values?.initialLoanAmount) ? 0 : values?.initialLoanAmount;
    const rehabAmt = val;
    const totalLoanAmountValue = initialAmt + rehabAmt;
    change("totalLoanAmount", totalLoanAmountValue);
  };
  const totalLoanAmountValueDisplayValue = isNil(values?.totalLoanAmount) ? 0 : values?.totalLoanAmount;

  return (
    <>
      <FormGroup noMargin fieldLabel={"Loan Scenario Dispay Name"}>
        <MuiGrid>
          <GridItem mobile size={12}>
            <FinalFormTextField
              label="Name"
              required
              name={`${fieldNames.loanSubjectName}`}
              helperText="This is the name that will be displayed in the table"
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>
      <FormGroup fieldLabel={"Loan Details"}>
        <MuiGrid>
          <GridItem mobile size={4}>
            <FinalFormSelectField
              label="Product Type"
              name={`${fieldNames.loanProductType}`}
              items={productTypeOptions}
            />
          </GridItem>
          <GridItem mobile size={4}>
            <FinalFormSelectField label="Loan Purpose" name={`${fieldNames.loanPurpose}`} items={loanPurposeOptions} />
          </GridItem>
          <GridItem mobile size={4}>
            <Field name={`loanClosingDate`} label="Desired Closing Date" component={DatePickerTextFieldComponent} />
          </GridItem>
          <GridItem mobile size={includesRehab ? 4.5 : 12}>
            <FinalFormTextField
              onChange={handleOnInitialAmoutChange}
              label="Initial Loan amount"
              name={`${fieldNames.initialLoanAmount}`}
              dollar
            />
          </GridItem>
          {includesRehab ? (
            <>
              <GridItem mobile size={4.5}>
                <FinalFormTextField
                  label="Rehab Budget"
                  onChange={handleOnRehabAmoutChange}
                  name={`constructionRenovationBudget`}
                  dollar
                />
              </GridItem>
              <GridItem mobile size={3}>
                <FlexBox
                  sx={{
                    borderRadius: "4px",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    padding: "0 10px",
                    background: "var(--accent)",
                  }}
                >
                  <Typography fontSize=".7rem">Total Loan Amount:</Typography>
                  <Typography fontWeight={"bold"}>{formatAsDollarString(totalLoanAmountValueDisplayValue)}</Typography>
                </FlexBox>
              </GridItem>
            </>
          ) : null}
        </MuiGrid>
      </FormGroup>
      <FormGroup fieldLabel={"Assignment"}>
        <MuiGrid>
          <GridItem mobile size={12}>
            <Field label="Account Executive" name={`loanSalesperson`} component={EmployeeSelectFieldComponent} />
          </GridItem>
        </MuiGrid>
      </FormGroup>
      <FormGroup fieldLabel={"Transaction Details"}>
        <MuiGrid>
          <GridItem mobile size={12}>
            <FinalFormDraftJsField label="Notes" name={`transactionDetails`} />
            {/* <Field name={"notes"} component={WYSIWYGEditor} /> */}
          </GridItem>
        </MuiGrid>
      </FormGroup>
    </>
  );
};

export default CreateScenarioStepLoanDetails;
