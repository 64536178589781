import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useReduxHook } from "../../../../redux/helpers/useReduxHook";

const LogoutScreen = (props) => {
  const loanScenarios = useSelector((state) => state.loanScenarios);
  const { handleLogoutUser } = useReduxHook();
  const navigate = useNavigate();

  useEffect(() => {
    handleLogoutUser();
  }, []);
  return;
};

export default LogoutScreen;
