import CreateScenarioStepBorrowers from "./CreateScenarioStepBorrowers";
import CreateScenarioStepFiles from "./CreateScenarioStepFiles";
import CreateScenarioStepLoanDetails from "./CreateScenarioStepLoanDetails";
import CreateScenarioStepSubjectProperty from "./CreateScenarioStepSubjectProperty";

export const formSteps = [
  {
    stepName: "Loan Details",
    stepComponent: <CreateScenarioStepLoanDetails />,
    // pageHeader: "Loan Assignment",
  },
  {
    stepName: "Borrowers",
    stepComponent: <CreateScenarioStepBorrowers />,
    // pageHeader: "Borrowers",
  },

  {
    stepName: "Properties",
    stepComponent: <CreateScenarioStepSubjectProperty />,
    // pageHeader: "Subject Properties",
  },
  {
    stepName: "Upload Files",
    stepComponent: <CreateScenarioStepFiles />,
    // pageHeader: "Subject Properties",
  },
];
