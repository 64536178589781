import { isNil } from "lodash";
import { parseISOToDate } from "../../helpers/parseISOToDate";
import { convertStringToTime } from "../../helpers/convertStringToTime";
import { convertStringToDate } from "../../helpers/convertStringToDate";

export const salesAssignedUsersTableColumns = [
  {
    Header: "Name",
    accessor: (d) => `${d?.firstName} ${d?.lastName}`,
  },

  {
    Header: "Email",
    accessor: "emailAddress",
  },
  {
    Header: "Company",
    accessor: "company",
  },
  {
    Header: "Role",
    accessor: "companyRole",
  },
  {
    Header: "Scenarios",
    accessor: (d) => (isNil(d?.scenarioCount) ? 0 : d?.scenarioCount),
  },
  {
    Header: "Last Sign In",
    accessor: (d) =>
      isNil(d?.lastSignIn) ? "-" : `${convertStringToDate(d?.lastSignIn)} at ${convertStringToTime(d?.lastSignIn)}`,
  },
  {
    Header: "Created",
    accessor: (d) => (isNil(d?.accountCreatedDate) ? "-" : parseISOToDate(d?.accountCreatedDate)),
  },
];
