import { CircularProgress, TableBody, TableRow } from "@mui/material";
import React from "react";
import MuiEditableTableRow from "./MuiEditableTableRow";
import MuiEditableTableCell from "./MuiEditableTableCell";
import { arrayProtector } from "../../../helpers/arrayProtector";
import FlexBox from "../FlexBox";

const MuiLoadingTableRow = ({ children, title, files }) => {
  return (
    <TableBody>
      <MuiEditableTableRow>
        <MuiEditableTableCell colSpan={"100%"}>
          <FlexBox center sx={{ flexGrow: 1, padding: "20px", width: "100%" }}>
            <CircularProgress size={24} />
          </FlexBox>
        </MuiEditableTableCell>
      </MuiEditableTableRow>
    </TableBody>
  );
};

export default MuiLoadingTableRow;
