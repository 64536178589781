import React, { useState } from "react";

import { Box } from "@mui/material";

import MuiGrid from "../../../../components/shared/MuiGrid";
import GridItem from "../../../../components/shared/MuiGrid/GridItem";

import { useStytchUser } from "@stytch/react";
import DashboardScreen from "../../../../components/layout/DashboardScreen";
import ScreenSubTitle from "../../../../components/layout/Screen/ScreenSubTitle";
import FlexBox from "../../../../components/shared/FlexBox";
import BrokerDashboardScreenTutorial from "./BrokerDashboardScreenTutorial";
import { CreateLoanScenariosWidget } from "./widgets/CreateLoanScenariosWidget";
import { ShareCreditAuthorizationWidget } from "./widgets/ShareCreditAuthorizationWidget";
import { ShareLoanApplicationWidget } from "./widgets/ShareLoanApplicationWidget";
import LoanScenariosTable from "./widgets/LoanScenariosTable";
import RequestTesterButton from "../../../../components/shared/RequestTesterButton";

const BrokerDashboardScreen = (props) => {
  const [modalIsActive, setModalIsActive] = useState(null);

  const { user } = useStytchUser();
  const { name } = user;

  return (
    <DashboardScreen>
      <FlexBox>
        <ScreenSubTitle variant={"h3"}>{` Welcome back, ${name?.first_name} ${name?.last_name}`}</ScreenSubTitle>
        <FlexBox alignCenter sx={{ position: "absolute", top: "12px", right: "20px" }}>
          {/* <ReactPlaidLink /> */}
          <BrokerDashboardScreenTutorial />
        </FlexBox>
      </FlexBox>
      <Box sx={{ display: "flex", flexGrow: 1, minHeight: "100%" }}>
        <Box sx={{ overflowY: "auto", flexGrow: 1, height: "100%", paddingTop: "14px" }}>
          <MuiGrid spacing={2}>
            <GridItem lg={4} md={12} sm={12} xs={12}>
              <CreateLoanScenariosWidget onClick={() => setModalIsActive("create-scenario")} />
            </GridItem>
            <GridItem lg={4} md={6} sm={6} xs={12}>
              <ShareLoanApplicationWidget onClick={() => setModalIsActive("loan-application")} />
            </GridItem>
            <GridItem lg={4} md={6} sm={6} xs={12}>
              <ShareCreditAuthorizationWidget onClick={() => setModalIsActive("credit-auth")} />
            </GridItem>

            <GridItem size={12}>
              <LoanScenariosTable />
            </GridItem>
          </MuiGrid>
        </Box>
      </Box>
    </DashboardScreen>
  );
};

export default BrokerDashboardScreen;
