import React from "react";
import TextOverflow from "../../../components/shared/TextOverflow";
import Signature from "../../../components/form/components/inputs/generic/Signature/Signature";

// import "./CreditAuthConfirmation.css";
// import Form from "../../../../UserAdmission/components/SignInForm/SignInForm";
// import "./CreditAuthFormStepTwo.css";

const BorrowerCreditAuthConfirmation = (props) => {
  return (
    <div className="credit-auth-confirmation">
      <TextOverflow noMargin title={"Authorization Agreement"}>
        <p>
          I hereby authorize and instruct Flatiron Realty Capital LLC and its vendors/affiliates to obtain and review my
          personal credit report, background checks, and all other reports and documents and involving due-diligence in
          connection with my real estate loan.
        </p>
        <p>
          I understand and agree that Flatiron Realty Capital LLC and its vendors/affiliates intends to use the credit
          report for the purpose of evaluating my financial readiness to personally guarantee the real estate loan that
          I have applied for.
        </p>
        <p>
          My signature below authorizes the release to credit report and screening agencies of financial or other
          information that I have supplied to Flatiron Realty Capital LLC and its vendors/affiliates in connection with
          such evaluation. Authorization is further granted to the credit report and screening agencies to use a copy of
          this form to obtain any information the agencies may deem necessary to complete my credit report and/or
          background screenings.
        </p>
        <p>
          With this authorization, I acknowledge that all fees paid to Flatiron Realty Capital LLC and its
          vendors/affiliates are non-refundable once paid.
        </p>
      </TextOverflow>
      <Signature isRequired name="signature" label="Authorization Signature" />
    </div>
  );
};

export default BorrowerCreditAuthConfirmation;
