import { Box } from "@mui/material";
import { filter, isNil } from "lodash";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import MuiEditableTable from "../../../../../../../components/shared/MuiEditableTable/MuiEditableTable";
import MuiEmptyTableRow from "../../../../../../../components/shared/MuiEditableTable/MuiEmptyTableRow";
import { getDocumentGroupName } from "../../../../../../../helpers/getDocumentGroupName";
import { parseISOToDate } from "../../../../../../../helpers/parseISOToDate";
import { useBrokerDrilldownHook } from "../../../../../../../hooks/useBrokerDrilldownHook";
import ScenarioPendingFilesTableCellActions from "./ScenarioPendingFilesTableCellActions";

import MuiCustomTableCell from "../../../../../../../components/shared/MuiCustomTable/MuiCustomTableCell";
import MuiCustomTableRow from "../../../../../../../components/shared/MuiCustomTable/MuiCustomTableRow";
import { useBorrowerHook } from "../../../../../../../hooks/useBorrowerHook";
import MuiCustomTableCellEditableScenarioFilename from "./MuiCustomTableCellEditableScenarioFilename";

const ScenarioPendingFilesTable = ({ title, isApproved, emptyMessage }) => {
  const { isInternalEmployee } = useBrokerDrilldownHook();
  const { getIsLoading, downloadScenarioFilesAsZip } = useBorrowerHook();
  const columnsExternal = ["Filename", "Doc Group", "Upload Date"];
  const columnsInternal = [...columnsExternal, "Actions"];
  const formData = useSelector((state) => state.formData);
  const isMobile = useSelector((state) => state.globalData?.isMobile);

  const tableFiles = useMemo(
    () =>
      filter(formData?.dealDocuments, (f) => f.approvedForUnderwriting !== true && isNil(f.approvedForUnderwriting)),
    [formData?.dealDocuments]
  );

  const handleOnZipDownload = () => {
    const params = {
      scenarioId: formData?._id,
      // params: ["approvedForUnderwriting=False"],
      downloadName: "Scenario - Pending Files",
    };

    downloadScenarioFilesAsZip(params);
  };

  return (
    <MuiEditableTable
      title={title}
      columns={isInternalEmployee ? columnsInternal : columnsExternal}
      maxWidth={isMobile ? "320px" : "unset"}
      tableSx={{
        th: {
          whiteSpace: "nowrap",
        },
      }}
    >
      {tableFiles?.map((document, index) => (
        <DocumentRow key={document?._id} document={document} isInternalEmployee={isInternalEmployee} />
      ))}
      <MuiEmptyTableRow files={tableFiles} title="There are no pending files" />
    </MuiEditableTable>
  );
};

const DocumentRow = ({ document, isInternalEmployee }) => {
  return (
    <MuiCustomTableRow>
      <MuiCustomTableCellEditableScenarioFilename fileDisplayName={document?.file_display_name} document={document} />
      {/* <MuiCustomTableCell>
        {isNil(document?.docGroup) ? "Unknown" : getDocumentGroupName(document?.docGroup)}
      </MuiCustomTableCell> */}
      <MuiCustomTableCell>{parseISOToDate(document?.uploadDate)}</MuiCustomTableCell>
      {isInternalEmployee ? (
        <MuiCustomTableCell
          sx={{
            width: "40px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <ScenarioPendingFilesTableCellActions fileData={document} />
          </Box>
        </MuiCustomTableCell>
      ) : null}
    </MuiCustomTableRow>
  );
};

export default ScenarioPendingFilesTable;
