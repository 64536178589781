import {
  ArrowCircleRightOutlined,
  ChevronRightRounded,
  CloseRounded,
  ExpandCircleDownOutlined,
  FullscreenExitRounded,
  FullscreenRounded,
  OpenInNewRounded,
  PersonAddOutlined,
} from "@mui/icons-material";
import { Box, Button, Card, Divider, Link, Tooltip, Typography } from "@mui/material";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ScreenSubTitle from "../../../../components/layout/Screen/ScreenSubTitle";
import BulkInviteUsersToOrganizationModal from "../../../../components/modals/BulkInviteUsersToOrganizationModal";
import FlexBox from "../../../../components/shared/FlexBox";
import Loader from "../../../../components/shared/Loader/Loader";
import MuiReactTable from "../../../../components/shared/MuiReactTable";
import TabSwitcher from "../../../../components/shared/TabSwitcher";
import TabPanel from "../../../../components/shared/TabSwitcher/TabPanel";
import TooltipIconButton from "../../../../components/shared/TooltipIconButton";
import { arrayProtector } from "../../../../helpers/arrayProtector";
import { useIronfundCompaniesHook } from "../../../../hooks/useIronfundCompaniesHook";
import IronFundUserSidePanel from "../../../../shared/IronFundUserSidePanel";
import { extractDomainFromUrl } from "../../../../helpers/extractDomainFromUrl";
import { concatenateArrayOfStrings } from "../../../../helpers/concatenateArrayOfStrings";

const CompanyDrilldownPreviewCard = ({ fullScreen, company, onExpand, onCancel }) => {
  const [drilldownData, setDrilldownData] = useState(undefined);
  const [tabValue, setTabValue] = useState(0);
  const { isLoading, getIronFundCompany } = useIronfundCompaniesHook();

  useEffect(() => {
    const params = {
      id: company?._id,
      onSuccessFn: (passedData) => {
        console.log({ passedData });
        setDrilldownData(passedData);
      },
    };
    getIronFundCompany(params);
  }, [company]);
  return (
    <Card
      variant="outlined"
      sx={{
        position: "sticky",
        top: "10px",
        maxHeight: "96vh",
        flexBasis: fullScreen ? "100%" : "63%",
        padding: "0 14px 14px",
        overflowY: "auto",
      }}
    >
      <FlexBox fullWidth column sx={{ position: "sticky", top: 0, background: "#ffffff", zIndex: 11 }}>
        <FlexBox
          fullWidth
          justifyBetween
          alignCenter
          sx={{ width: "100%", padding: "10px 0 10px" }}
          // sx={{ padding: "14px 0 7px", position: "sticky", top: 0, background: "#ffffff", zIndex: 11 }}
        >
          <ScreenSubTitle>{company?.companyName}</ScreenSubTitle>
          <FlexBox alignCenter gap={"5px"}>
            <AddBulkUserToCompanyModalButton />
            <TooltipIconButton
              title={fullScreen ? "Minimize" : "Make full screen"}
              icon={fullScreen ? <FullscreenExitRounded /> : <FullscreenRounded />}
              size={"1.25rem"}
              onClick={onExpand}
            />
            <TooltipIconButton title={"Close"} icon={<CloseRounded />} size={"1.25rem"} onClick={onCancel} />
          </FlexBox>
        </FlexBox>
        <Divider flexItem />
      </FlexBox>
      {isLoading || isNil(drilldownData) ? (
        <Loader size={36} />
      ) : (
        <>
          <FlexBox sx={{ padding: "20px 0px 40px" }} gap={"15px"} justifyContent={"space-between"}>
            <DrilldownOverviewGroup title={"Company Overview"}>
              <DrilldownOverviewItem
                label={"Assigned Salesperson"}
                value={drilldownData?.companyAssignedSalesperson?.fullName}
              />
              <DrilldownOverviewItem label={"NMLS #"} value={drilldownData?.companyNMLS} />
              <DrilldownOverviewItem
                label={"Website"}
                value={extractDomainFromUrl(drilldownData?.companyWebsite)}
                headerContent={
                  <Link
                    style={{ position: "absolute", top: "8px", right: "8px" }}
                    href={drilldownData?.companyWebsite}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <OpenInNewRounded sx={{ fontSize: ".9rem", color: "#242526" }} />
                  </Link>
                }
              />
              {arrayProtector(drilldownData?.companyEmailDomains).length === 0 ? null : (
                <DrilldownOverviewItem
                  label={"Associated Domains"}
                  value={concatenateArrayOfStrings(drilldownData?.companyEmailDomains, "@")}
                />
              )}
            </DrilldownOverviewGroup>
            <Divider orientation="vertical" flexItem />
            <DrilldownOverviewGroup title={"Company Totals"}>
              <DrilldownOverviewItem label={"Scenarios"} value={arrayProtector(drilldownData?.scenarios).length} />
              <DrilldownOverviewItem label={"Users"} value={arrayProtector(drilldownData?.companyUsers).length} />
            </DrilldownOverviewGroup>
          </FlexBox>

          <TabSwitcher sub noBorder setValue={setTabValue} tabs={["Scenarios", "Users"]} />
          <TabPanel value={tabValue} index={0}>
            <CompanyScenariosTable company={company} drilldownData={drilldownData} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <CompanyUsersTable company={company} drilldownData={drilldownData} />
          </TabPanel>
        </>
      )}
    </Card>
  );
};

const CompanyScenariosTable = ({ drilldownData, setPreview }) => {
  const cols = [
    {
      Header: "Name",
      accessor: (d) => `${d.loanSubjectName} `,
    },
    {
      Header: "Status",
      accessor: (d) => d?.scenarioStatus,
    },

    {
      Header: "Submitted By",
      accessor: (d) => d?.submittedBy?.fullName,
    },

    {
      Header: "View",
      CellWidth: "80px",
      accessor: (d) => (
        <FlexBox gap={"10px"} alignCenter sx={{ paddingRight: "10px" }}>
          <Tooltip arrow title="Open in new tab">
            <Link
              href={`/scenarios/${d?._id}`}
              target="_blank"
              rel="noreferrer"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <OpenInNewRounded sx={{ fontSize: "1rem", color: "#242526" }} />
            </Link>
          </Tooltip>
          <TooltipIconButton
            title={"View Scenario"}
            icon={<ExpandCircleDownOutlined sx={{ transform: "rotate(-90deg)", color: "#242526" }} />}
            onClick={() => setPreview(d)}
            size={"1.2rem"}
          />
        </FlexBox>
      ),
    },
  ];

  const columns = React.useMemo(() => cols, []);

  return (
    <>
      <MuiReactTable
        // title={"User Companies"}
        emptyTitle={"There are currently no users associated with you."}
        noBorder
        // search
        // rowOnClick={setPreview}

        columns={columns}
        data={drilldownData?.scenarios}
      />
    </>
  );
};

const CompanyUsersTable = ({ drilldownData, setPreview }) => {
  console.log("drilldownData", drilldownData);
  const [userId, setUserId] = useState(undefined);
  const cols = [
    {
      Header: "Name",
      accessor: (d) => `${d?.firstName} ${d?.lastName}`,
    },

    {
      Header: "Email",
      accessor: "emailAddress",
    },
  ];

  const columns = React.useMemo(() => cols, []);

  return (
    <>
      <MuiReactTable
        title={`${drilldownData?.companyName} Users`}
        emptyTitle={"There are currently no users associated with you."}
        noBorder
        search
        pagination
        columns={columns}
        rowOnClick={(d) => setUserId(d?._id)}
        // columns={isMobile ? cols_mobile : columns}
        data={drilldownData?.companyUsers}
      />
      <IronFundUserSidePanel show={userId} id={userId} onCancel={() => setUserId(undefined)} />
    </>
  );
};

const DrilldownOverviewGroup = ({ title, children }) => {
  return (
    <FlexBox column gap={"6px"} sx={{ flexGrow: 1 }}>
      <Typography fontWeight={600} sx={{ opacity: 0.75 }} fontSize={".75rem"} variant="body1" whiteSpace={"nowrap"}>
        {title}
      </Typography>
      <FlexBox gap={"10px"} sx={{ flexGrow: 1, width: "100%", flexWrap: "wrap" }}>
        {children}
      </FlexBox>
    </FlexBox>
  );
};

const DrilldownOverviewItem = ({ headerContent, label, value, sx }) => {
  const styles = {
    position: "relative",
    background: "var(--lightBlue)",
    padding: "10px 12px",
    borderRadius: "4px",
    gap: "6px",
    flexGrow: 1,
  };

  const overValueStyles = {
    fontFamily: "var(--mont) !important",
    fontSize: "1.1rem",
    lineHeight: 1,
    fontWeight: 800,
    padding: "0px 4px",
  };

  return (
    <FlexBox column sx={{ ...styles, ...sx }}>
      <Typography fontWeight={500} sx={{ opacity: 0.75 }} fontSize={".7rem"} variant="body1">
        {label}
      </Typography>
      {headerContent ? headerContent : null}
      <Typography
        variant="body1"
        // fontWeight={800}
        // fontFamily={"var(--mont) !important"}
        // fontSize={"1.2rem"}
        // lineHeight={1}
        // sx={{ padding: "0px 4px" }}
        sx={overValueStyles}
      >
        {value}
      </Typography>
    </FlexBox>
  );
};

const AddBulkUserToCompanyModalButton = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <TooltipIconButton
        title={"Add New Users"}
        icon={<PersonAddOutlined />}
        size={"1.25rem"}
        onClick={() => setShow(true)}
      />
      <BulkInviteUsersToOrganizationModal show={show} onCancel={() => setShow(false)} />
    </>
  );
};

export default CompanyDrilldownPreviewCard;
