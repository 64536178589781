import { useState } from "react";
import CardGraphicButton from "../../../../../components/shared/CardGraphicButton";
import CopyToClipboardModal from "../../../../../components/shared/CopyToClipboardModal";
import TickerWidgetStatus from "../../../../../components/shared/Widgets/TickerWidget/TickerWidgetStatus";
import { ReactComponent as Icon } from "../assets/loanAppGraphicDark.svg";
import { useStytchUser } from "@stytch/react";
import { addParamsToString } from "../../../../../helpers/addParamsToString";

export const ShareLoanApplicationWidget = () => {
  const { user } = useStytchUser();
  const initUrl = `https://ironlinc.ironfund.com/loan-application?brokerId=${user?.trusted_metadata?.mongoid}&email=${user?.trusted_metadata?.emailAddress}`;
  // const initUrl = `https://ironlinc.getflatiron.com/loan-application?brokerId=${user?.trusted_metadata?.mongoid}&email=${user?.trusted_metadata?.emailAddress}`;

  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(initUrl);

  return (
    <>
      <CardGraphicButton
        // comingSoon
        title="Share Loan Application"
        description="Get started with a loan application."
        onClick={() => setOpen(true)}
        icon={<Icon />}
      >
        <TickerWidgetStatus value="Not Submitted" />
      </CardGraphicButton>
      <CopyToClipboardModal
        open={open}
        description={"Have your borrower fill out a loan application by sending them this link."}
        onClose={() => setOpen(false)}
        title={"Shareable Loan Application"}
        url={url}
      />
    </>
  );
};
