import { EditRounded, SaveRounded } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import React from "react";
import { useForm, useFormState } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

import { useBorrowerHook } from "../../../../../hooks/useBorrowerHook";
import { setFormData } from "../../../../../redux/actions/formDataActions";
import { updateGlobalData } from "../../../../../redux/actions/globalDataActions";

import { LoadingButton } from "@mui/lab";
import FlexBox from "../../../../../components/shared/FlexBox";

const ProfileEditingButtons = (props) => {
  const isEditing = useSelector((state) => state.formData.isEditing);
  const profile = useSelector((state) => state.globalData.profile);
  const { postIsLoading, updateUserProfileData } = useBorrowerHook();
  const dispatch = useDispatch();

  const { values } = useFormState();
  const { change } = useForm();

  //Functions
  const setIsEditing = (bool) => {
    dispatch(setFormData({ isEditing: bool }));
  };

  const onSuccess = (data) => {
    dispatch(updateGlobalData(data));
    setIsEditing(false);
  };

  const handleOnSubmit = () => {
    const params = {
      payload: values,
      onSuccessFn: onSuccess,
    };
    updateUserProfileData(params);
  };

  const handleCancelEdit = () => {
    change(values, profile);
    setIsEditing(false);
  };

  //Content
  const isEditingContent = (
    <>
      <Button variant="outlined" color="primary" onClick={handleCancelEdit}>
        Cancel Edit
      </Button>
      <LoadingButton
        color="primary"
        variant="contained"
        type="submit"
        loading={postIsLoading}
        onClick={handleOnSubmit}
        endIcon={<SaveRounded />}
      >
        Update Information
      </LoadingButton>
    </>
  );

  const isNotEditingContent = (
    <IconButton color="primary" onClick={() => setIsEditing(true)}>
      <EditRounded />
    </IconButton>
  );

  if (!isEditing) {
    return <Box sx={{ position: "absolute", top: "10px", right: "10px", zIndex: 111 }}>{isNotEditingContent}</Box>;
  }

  return (
    <FlexBox alignCenter gap={"10px"} justifyContent={"flex-end"} sx={{ padding: "24px" }}>
      {isEditing ? isEditingContent : isNotEditingContent}
    </FlexBox>
  );
};

export default ProfileEditingButtons;
