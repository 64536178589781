import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { isArray, isEqual, isNil } from "lodash";
import { useFormState } from "react-final-form";

import { Box, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import DrilldownCardTabSection from "../../../../../../components/Drilldown/DrilldownCardTabSection";
import DrilldownCardTabSectionTitle from "../../../../../../components/Drilldown/DrilldownCardTabSectionTitle";
import DrilldownScreenTab from "../../../../../../components/Drilldown/DrilldownScreenTab";
import DraftJsTextEditor from "../../../../../../components/shared/DraftJsTextEditor";
import { arrayProtector } from "../../../../../../helpers/arrayProtector";
import { parseISOToDateTime } from "../../../../../../helpers/parseISOToDateTime";
import { useBorrowerHook } from "../../../../../../hooks/useBorrowerHook";
import { updateFormData } from "../../../../../../redux/actions/formDataActions";
import EditableDrilldownTabCard from "../../components/EditableDrilldownTabCard";
import DealCommentCardItem from "./components/DealCommentCardItem";
import { styles } from "./styles";
import { converDraftJsDataToHtml } from "../../../../../../helpers/converDraftJsDataToHtml";
import { useStytchUser } from "@stytch/react";
import { getTodaysDate } from "../../../../../../helpers/getTodaysDate";
import { generateDealCommentHtml } from "../../../../../../components/htmlGeneration/generateDealCommentHtml";

const ScenarioDrilldownTabComments = (props) => {
  // const [display, setDisplay] = React.useState(true);
  const formData = useSelector((state) => state.formData);
  const { values } = useFormState();

  const display = isEqual(values, formData) ? true : false;

  const [showTextbox, setShowTextbox] = useState(true);

  // const commentsList = DUMMY_LARGE_COMMENT_LIST;
  const commentsList = useMemo(() => formData?.dealComments, [formData?.dealComments]);

  return (
    <Stack spacing={2}>
      <EditableDrilldownTabCard title={"Comments"} display={display}>
        <AddNewMessage />
      </EditableDrilldownTabCard>
      <DrilldownScreenTab card>
        <DrilldownCardTabSectionTitle>Previous Comments</DrilldownCardTabSectionTitle>
        <MessagesContainerColumn
          commentsList={commentsList}
          showTextbox={showTextbox}
          setShowTextbox={setShowTextbox}
        />
      </DrilldownScreenTab>
    </Stack>
  );
};

const AddNewMessage = (props) => {
  // const { commentsPostIsLoading, postDealComment } = useCommentsHook();
  const formData = useSelector((state) => state.formData);
  const { postIsLoading, postLoanScenarioComment } = useBorrowerHook();
  const { user } = useStytchUser();

  console.log("user", user);
  const [initData, setInitData] = useState({});
  const { values } = useFormState();
  const dispatch = useDispatch();

  const handleCommentSubmit = (data) => {
    const handleOnSuccess = (returnedCommentList) => {
      const newCommentList = returnedCommentList?.dealComments;

      dispatch(updateFormData({ key: "dealComments", value: newCommentList }));
      setInitData({});
    };

    const convertedHtmlData = converDraftJsDataToHtml(data);
    const postedByHtml = generateDealCommentHtml({
      postedByName: `${user?.name?.first_name} ${user?.name?.last_name} (${user?.trusted_metadata?.emailAddress})`,
      loanName: values?.loanSubjectName,
      loanId: values?._id,
      postedByDate: getTodaysDate(),
      commentHtml: convertedHtmlData,
    });

    console.log(postedByHtml);
    const params = {
      id: formData?._id,
      payload: {
        data: data,
        htmlstring: postedByHtml,
      },

      onSuccessFn: handleOnSuccess,
      onFailFn: (data) => setInitData(data),
    };

    console.log("params", params);
    postLoanScenarioComment(params);
  };
  return (
    <Box sx={styles.dealCommentCard} id="text-editor">
      <DraftJsTextEditor
        sx={{ minHeight: "180px" }}
        onSubmit={handleCommentSubmit}
        readOnly={false}
        data={initData}
        buttonText={"Post Comment"}
        isLoading={postIsLoading}
      />
    </Box>
  );
};

const MessagesContainerColumn = ({ commentsList, showTextbox, setShowTextbox }) => {
  return (
    <DrilldownCardTabSection bold noMargin sx={styles.messageContainerColumn}>
      <Stack>
        {isNil(commentsList) || arrayProtector(commentsList).length < 1 ? (
          <Typography padding={1.5} fontSize=".7rem" sx={{ opacity: 0.8 }} variant="body1">
            There are no comments yet for this deal.
          </Typography>
        ) : (
          commentsList?.map((comment) => (
            <MessageThread key={comment?._id} comment={comment} setShowTextbox={setShowTextbox} />
          ))
        )}
      </Stack>
    </DrilldownCardTabSection>
  );
};

const MessageThread = ({ comment, setShowTextbox }) => {
  const [isEditing, setIsEditing] = useState(false);
  const comment_id = comment?._id;
  // console.log("comment", comment_id);
  const handleOnReplyClick = () => {
    setIsEditing(true);
    setShowTextbox(false);
  };
  const handleOnReplyCancel = () => {
    setIsEditing(false);
    setShowTextbox(true);
  };
  return (
    <Box className="message-item-thread" sx={styles.messageItemThread}>
      <DealCommentCardItem
        name={comment?.submittedBy?.fullName}
        body={comment?.data}
        date={parseISOToDateTime(comment?.posted)}
        onReplyClick={handleOnReplyClick}
        isEditing={isEditing}
      />
      {isArray(comment?.replies) || isEditing ? (
        <Box className="deal-replies-container" sx={styles.repliesContainer}>
          <>
            <Box className="deal-comment-card-item__arrow--vertical" sx={styles.dealCommentCardArrow} />
          </>
          {!isArray(comment?.replies)
            ? null
            : comment?.replies.map((reply, index) => (
                <DealCommentCardItem
                  key={reply?.data}
                  name={reply?.submittedBy?.fullName}
                  body={reply?.data}
                  date={parseISOToDateTime(reply?.posted)}
                  parentId={comment_id}
                  isLast={!isEditing && index === comment?.replies?.length - 1 ? true : false}
                />
              ))}
          {isEditing ? (
            <DealCommentCardItem
              name={comment?.submittedBy?.fullName}
              onCancelClick={handleOnReplyCancel}
              parentId={comment_id}
              isLast
              isEditing
            />
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default ScenarioDrilldownTabComments;
