import React from "react";
import { Box } from "@mui/material";

import DisplayDataFieldItemValue from "../DisplayDataFieldItemValue/DisplayDataFieldItemValue";
import { formFormatDollar } from "../../../helpers/parse/formFormatDollar";

import "./DisplayDataFieldItem.css";

const DisplayDataFieldItem = ({ label, value, id, row, large, dot, style, sx, children, percent, dollar }) => {
  const itemIsEmpty =
    value === undefined || value === null || value === "null" || value === "undefined" || value === "" ? true : false;

  const isSpecialFormat = dollar || percent ? true : false;

  let finalValue;
  if (dollar) {
    finalValue = `$${formFormatDollar(value)}`;
  }
  if (percent) {
    finalValue = `${value}%`;
  }
  if (!isSpecialFormat) {
    finalValue = value;
  }

  const itemValue = itemIsEmpty ? "—" : finalValue;

  const styles = { ...style, ...sx };
  return (
    <Box id={id} style={styles} className={`display-data-field-item ${row ? "row" : ""} ${large ? "large" : ""}`}>
      {label !== "hide" && (
        <span className="display-data-field-item__label">
          {label}
          {dot ? ": " : null}
        </span>
      )}
      {value !== "hide" && <DisplayDataFieldItemValue>{itemValue}</DisplayDataFieldItemValue>}
      {children ? <DisplayDataFieldItemValue>{children}</DisplayDataFieldItemValue> : null}
    </Box>
  );
};

export default DisplayDataFieldItem;
