import { CloseRounded } from "@mui/icons-material";
import { Box, Dialog, DialogContent, Divider, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import FlexBox from "./FlexBox";
import SuccessContent from "./SuccessContent";

/*

    <MuiModal
      title={"Example"}
      description={"Example"}
      show={show} 
      onClose={handleOnCancel}
      disableBackgroundCancel // optional
      success={isSuccess} // optional
    >
      {children}
    </MuiModal>

*/

const MuiModal = ({ show, onClose, children, title, description, size, sx, disableBackgroundCancel, success }) => {
  //size: 'sm' || 'md' || 'lg'

  const defaultSize = success ? "sm" : "md";

  const HEADER_TITLE = (
    <Typography sx={{ fontWeight: 600, color: "var(--primary)", fontSize: "20px" }} variant="h1">
      {title}
    </Typography>
  );

  const HEADER_DESCRIPTION = (
    <Typography sx={{ opacity: 0.8 }} gutterBottom>
      {description}
    </Typography>
  );

  const HEADER = (
    <DialogContent
      sx={{
        position: "relative",
        padding: "15px 20px",
      }}
    >
      <Stack
        spacing={1}
        width={"100%"}
        sx={{
          display: "flex",
          // alignItems: "center"
        }}
      >
        {HEADER_TITLE}
        {description ? HEADER_DESCRIPTION : null}
      </Stack>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseRounded />
      </IconButton>
    </DialogContent>
  );

  const BODY = <DialogContent id="dialog-body">{success ? <SuccessContent /> : children}</DialogContent>;

  return (
    <Dialog
      maxWidth={size ? size : defaultSize}
      onClose={disableBackgroundCancel ? undefined : onClose}
      open={show}
      sx={{
        zIndex: 111111,
        ".MuiPaper-root": {
          flexGrow: 1,
        },
        ...sx,
      }}
    >
      {HEADER}
      <Divider light />
      {BODY}
    </Dialog>
  );
};

// const HEADER = (
//   <DialogContent
//     sx={{
//       background: "#ffffff !important",
//       // flexGrow: 1,
//       overflow: "unset",
//       borderRadius: "4px",
//       boxShadow: "var(--bsMui)",
//       position: "relative",
//     }}
//   >
//     <FlexBox
//       column
//       alignCenter
//       id="customized-dialog-title"
//       spacing={1}
//       padding="00px 0 10px"
//       width={"100%"}
//       sx={{ display: "flex", alignItems: "center" }}
//     >
//       <IconButton
//         aria-label="close"
//         onClick={onClose}
//         sx={{
//           position: "absolute",
//           right: "15px",

//           top: "50%",
//           transform: "translateY(-50%)",
//           color: (theme) => theme.palette.grey[500],
//         }}
//       >
//         <CloseRounded sx={{ fontSize: "1.5rem !important" }} />
//       </IconButton>
//       <Typography sx={{ fontWeight: 800, color: "#000000de" }} variant="h1">
//         {title}
//       </Typography>
//       {description ? (
//         <Typography sx={{ marginBottom: 0, marginTop: "5px", opacity: 0.8 }} gutterBottom>
//           {description}
//         </Typography>
//       ) : null}
//     </FlexBox>
//   </DialogContent>
// );

export default MuiModal;
