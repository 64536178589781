import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormCard from "../../../../components/shared/FormCard";
import UserAuthFormCard from "../../../../components/UserAuthScreen/UserAuthFormCard";
import UserAuthBorrowerMagicLinkLoginForm from "../../../../forms/UserAuthBorrowerMagicLinkLoginForm";

const BorrowerLoginFormCard = (props) => {
  const [isSuccess, setIsSuccess] = useState(null);
  const [userEmail, setUserEmail] = useState(null);

  const handleOnSuccess = (email) => {
    setIsSuccess(true);
    setUserEmail(email);
    // resetLocalStorage();
  };

  const navigate = useNavigate();
  return (
    <FormCard
      title={isSuccess ? "Magic Link Sent!" : "Broker Portal Login"}
      description={
        isSuccess ? `We sent an email to ${userEmail}` : "Enter your email address  to receive a login link."
      }
    >
      <UserAuthBorrowerMagicLinkLoginForm onSuccess={handleOnSuccess} />
      <Divider sx={{ marginTop: "10px", marginBottom: "15px" }}>
        <Typography color={"#a1a1a1"}>Or</Typography>
      </Divider>
      <Button
        sx={{
          width: "100%",
          height: "52px ",
          fontWeight: 500,
        }}
        variant="outlined"
        onClick={() => navigate("/sign-up")}
      >
        Create My Broker Account
      </Button>
    </FormCard>
  );
};

export default BorrowerLoginFormCard;
