import { TableBody, TableRow } from "@mui/material";
import React from "react";

import { arrayProtector } from "../../../helpers/arrayProtector";
import MuiEditableTableCell from "../MuiEditableTable/MuiEditableTableCell";
import MuiEditableTableRow from "../MuiEditableTable/MuiEditableTableRow";

const MuiEmptyTableRow = ({ children, title, files }) => {
  if (arrayProtector(files).length > 0) {
    return null;
  }

  const emptyTitle = title ? title : "No items found";
  return (
    <MuiEditableTableRow>
      <MuiEditableTableCell sx={{ borderBottom: "none" }} colSpan={"100%"}>
        {title ? emptyTitle : children}
      </MuiEditableTableCell>
    </MuiEditableTableRow>
  );
};

export default MuiEmptyTableRow;
