import { isArray, isNil } from "lodash";
import { arrayProtector } from "./arrayProtector";

export const addParamsToString = (params, separator) => {
  if (!isArray(params)) {
    return "";
  }
  const paramStrings = params.map(function (param, index) {
    return `${param}${separator}`;
    // return `${param}${isNil(separator) || arrayProtector(params).length >= index + 1 ? "" : separator}`;
  });

  const joinedString = paramStrings.join("");
  return separator ? joinedString.slice(0, -1) : joinedString;
};
