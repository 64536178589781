import { fileDocumentSchema } from "../../../../../../../forms/_formQuestions/_formSchema/fileDocumentSchema";

export const scenarioFilesDocGroups = [
  {
    label: "Appraisal",
    name: fileDocumentSchema.appraisal.name,
  },
  {
    label: "Borrower Documents",
    name: fileDocumentSchema.borrowerDocs.name,
  },
  {
    label: "Closing Package",
    name: fileDocumentSchema.closingPackage.name,
  },
  {
    label: "Construction Documents",
    name: fileDocumentSchema.constructionDocuments.name,
  },
  {
    label: "Entity",
    name: fileDocumentSchema.entityDocs.name,
  },
  {
    label: fileDocumentSchema.insuranceDocs.label,
    name: fileDocumentSchema.insuranceDocs.name,
  },

  {
    label: "Leases & Rents",
    name: fileDocumentSchema.leasesAndRents.name,
  },
  {
    label: "Liquidity",
    name: fileDocumentSchema.liquidity.name,
  },

  {
    label: "Property Documents",
    name: fileDocumentSchema.propertyDocuments.name,
  },
  {
    label: "Third Party Reports",
    name: fileDocumentSchema.thirdPartyReports.name,
  },
  {
    label: "Title Docs",
    name: fileDocumentSchema.titleDocs.name,
  },

  {
    label: "No Category",
    name: fileDocumentSchema.noCategory.name,
  },
];
