import React from "react";
import { Field, useForm, useFormState } from "react-final-form";
import FinalFormTextField from "../../../../../components/form/Fields/FinalFormTextField";
import { VALIDATOR_REQUIRE } from "../../../../../helpers/validators/inputValidators";
import RadioField from "../../../../../components/form/components/inputs/generic/RadioField/RadioField";
import {
  stateOfInteriorAccessOptions,
  subjectPropertyTypeOptions,
  usaStates,
} from "../../../../../forms/_formQuestions/selectOptions";
import { MultiSelectFieldComponent } from "../../../../../components/form/components/inputs/finalFormComponents/MultiSelectFieldComponent";
import RadioFieldTrueFalse from "../../../../../components/form/components/inputs/generic/RadioFieldTrueFalse";
import MuiGrid from "../../../../../components/shared/MuiGrid";
import GridItem from "../../../../../components/shared/MuiGrid/GridItem";
import FormGroup from "../../../../../components/form/components/shared/FormGroup/FormGroup";
import FinalFormSelectField from "../../../../../components/form/Fields/FinalFormSelectField";
import { useEffect } from "react";
import { isNil } from "lodash";
import { FieldArray } from "react-final-form-arrays";
import { AddOutlined } from "@mui/icons-material";
import { buttonGhost } from "../../../../../assets/styles/SharedInlineStyles";
import { useMemo } from "react";
import MultipleItemCard from "../../../../../forms/components/MultipleItemCard";
import AddressAutofillField from "../../../../../components/form/components/inputs/generic/AddressAutofillField/AddressAutofillField";
import { DatePickerTextFieldComponent } from "../../../../../components/form/components/inputs/finalFormComponents/DatePickerTextFieldComponent";
import { TextareaFieldComponent } from "../../../../../components/form/components/inputs/finalFormComponents/TextareaFieldComponent";
import { Divider } from "@mui/material";
import MultiSelectFinalForm from "../../../../../components/form/components/inputs/generic/MultiSelectFinalForm";

const AuctionBorrowerUpcomingAuctions = ({ display }) => {
  const { values } = useFormState();
  const { mutators } = useForm();

  // const additionalName = `${props.name}Additional`;

  const addAdditionalFieldHandler = (name) => {
    mutators.push(name, {
      softCosts: 0,
      hardCosts: 0,
      totalBudget: 0,
      rehabBudgetFinancedPercent: 100,
    });
  };

  useEffect(() => {
    if (isNil(values.subjectProperties)) {
      addAdditionalFieldHandler("subjectProperties");
    }
  }, []);

  return (
    <>
      <FormGroup
        fieldLabel={"Let us help you research your next investment. Select info you would like to subscribe to:"}
      >
        <MuiGrid spacing={1}>
          <GridItem size={12}>
            <MultiSelectFinalForm
              columns
              name={`subscribedTo`}
              items={[
                { name: "salesComps", label: "Sales Comps" },
                { name: "rentalComps", label: "Rental Comps" },
                { name: "reoProperties", label: "REO Properties" },
              ]}
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>
      <FormGroup
        fieldLabel={"Select the state and county/municipality where investor plans to purchase auction properties"}
      >
        <MuiGrid spacing={1}>
          <GridItem size={6}>
            <FinalFormSelectField
              display={display}
              required
              // disabled
              name={`intendedAuctionPropertyPurchaseState`}
              label="State"
              items={usaStates}
            />
          </GridItem>
          <GridItem size={6}>
            <FinalFormTextField
              required
              display={display}
              name={`intendedAuctionPropertyPurchaseCounty`}
              label="County"
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>
      <FormGroup fieldLabel={"Date of the next auction investor will be bidding on properties"}>
        <MuiGrid>
          <GridItem size={12}>
            <Field
              display={display}
              name={`dateOfNextAuction`}
              label="Date of the next auction"
              component={DatePickerTextFieldComponent}
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>
      <Divider sx={{ marginTop: "30px" }} />
      <FormGroup label="Enter any property addresses investor is expecting to bid on">
        <FieldArray name={"subjectProperties"}>
          {({ fields }) =>
            fields.map((name, index) => (
              <PipelineProperty
                name={name}
                index={index}
                fields={fields}
                display={display}
                // {...props}
              />
            ))
          }
        </FieldArray>
        <div className="add-additional-field-wrapper center">
          <button
            style={buttonGhost}
            onClick={() => addAdditionalFieldHandler("subjectProperties")}
            className="add-additional-button"
            type="button"
          >
            <span>Add Auction Property of Interest</span>
            <AddOutlined />
          </button>
        </div>
      </FormGroup>
    </>
  );
};

const PipelineProperty = ({ display, name, index, fields }) => {
  const { values, errors, form } = useFormState();
  const { mutators, change } = useForm();
  // const { name, index, fields } = props;
  const addAdditionalFieldHandler = (name) => {
    mutators.push(name, {});
  };

  const options_yesNo = useMemo(() => ["Yes", "No"], []);
  const options_leaseType = useMemo(
    () => [
      "Long-term (original term >= 12 months)",
      "Month-to-Month",
      "Short-term (vacation rental, AirBnB or VRBO)",
      "No Lease in Place",
    ],
    []
  );

  return (
    <MultipleItemCard
      title={"Auction Property of Interest"}
      onRemove={() => fields.remove(index)}
      index={index}
      unitsPathName={`subjectProperties`}
    >
      <FormGroup noMargin>
        <MuiGrid>
          <GridItem size={12}>
            <AddressAutofillField
              noMargin
              // noFields
              name={`${name}`}
              label="Auction Property Address"
              // value={
              //   values?.subjectProperties === undefined
              //     ? false
              //     : values?.subjectProperties[index]?.address
              // }
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>
      <FormGroup fieldLabel={"Property Details"}>
        <MuiGrid spacing={1}>
          <GridItem size={12}>
            <FinalFormSelectField
              required
              display={display}
              name={`${name}.propertyType`}
              label="Property Type"
              items={subjectPropertyTypeOptions}
            />
          </GridItem>
          <GridItem size={6}>
            <FinalFormTextField required name={`${name}.expectedAuctionPrice`} label="Expected Auction Price" dollar />
          </GridItem>
          <GridItem size={6}>
            <FinalFormTextField
              required
              name={`${name}.expectedRenovationBudget`}
              label="Expected RenovationBudget"
              dollar
            />
          </GridItem>
          {/* <GridItem size={4}>
            <Field
              display={display}
              name={`${name}.purchaseDate`}
              label="Purchase Date"
              component={DatePickerTextFieldComponent}
            />
          </GridItem> */}
          <GridItem size={12}>
            <Field
              name={`${name}.auctionPropertyComments`}
              label="Auction Property Comments (optional)"
              // label="Please provide Description"
              component={TextareaFieldComponent}
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>
    </MultipleItemCard>
  );
};

export default AuctionBorrowerUpcomingAuctions;
