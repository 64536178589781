import { CheckRounded, CloseRounded, ContentCopyOutlined, LinkOutlined, LinkRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";

const CopyToClipboardModal = ({ open, onClose, title, description, url }) => {
  const [isCopied, setIsCopied] = useState(false);
  const handleOnClick = () => {
    navigator.clipboard.writeText(url);
    setIsCopied(true);
  };

  const handleOnClose = () => {
    setIsCopied(false);
    onClose();
  };
  return (
    <Dialog onClose={handleOnClose} aria-labelledby="customized-dialog-title" open={open} sx={{ zIndex: 111111111 }}>
      <DialogContent>
        <Box id="customized-dialog-title" onClose={handleOnClose}>
          <Stack spacing={1} padding="20px 0 30px" width={"100%"} sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ fontWeight: 600, color: "var(--primary)" }} variant="h1">
              {title}
            </Typography>
            <Typography sx={{ opacity: 0.8 }} gutterBottom>
              {/* Copy the link below and send it to any potential borrower. */}
              {description}
            </Typography>
          </Stack>
          <IconButton
            aria-label="close"
            onClick={handleOnClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseRounded />
          </IconButton>
        </Box>
        <Stack spacing={1} alignItems={"flex-end"}>
          <Box
            sx={{
              background: "#eaeefa",
              display: "flex",
              alignItems: "center",
              width: "100%",
              padding: "6px 0",
              borderRadius: "4px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRight: "1px solid var(--borderGray)",
                width: "44px",
                height: "34px",
              }}
            >
              <LinkOutlined
                sx={{
                  transform: "rotate(-45deg)",
                }}
              />
            </Box>

            <Typography
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                margin: "0 14px",
                width: "100%",
                textOverflow: "ellipsis",
              }}
            >
              {url}
            </Typography>
          </Box>
          <Button
            onClick={handleOnClick}
            sx={{
              marginRight: "6px",
            }}
            variant="contained"
            color={isCopied ? "success" : "primary"}
            endIcon={isCopied ? <CheckRounded /> : <ContentCopyOutlined sx={{ width: "1rem" }} />}
          >
            Copy Link
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CopyToClipboardModal;
