import React, { useState } from "react";

import EmailOutlined from "@mui/icons-material/EmailOutlined";
import { Field } from "react-final-form";

import Form from "../components/form/Form/Form";
import { TextFieldComponent } from "../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import FormGroup from "../components/form/components/shared/FormGroup/FormGroup";
import FormSubmitButton from "../components/form/components/shared/FormSubmitButton/FormSubmitButton";
import MuiGrid from "../components/shared/MuiGrid";
import GridItem from "../components/shared/MuiGrid/GridItem";
import { formStateWithQueryParams } from "../helpers/formStateWithQueryParams";
import { VALIDATOR_EMAIL } from "../helpers/validators/inputValidators";
import { useAxiosHook } from "../hooks/useAxiosHook";
import axios from "axios";

const EmailUnsubscribeForm = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);

  // const { axiosPostIsLoading, axiosPostRequest } = useAxiosHook();

  const onSubmit = async (state) => {
    setLoading(true);
    const endpoint = `https://ironlinc-crm-app.azurewebsites.net/unsubscribe`;

    const headers = {
      "Content-Type": "text/plain",
    };

    axios
      .post(
        endpoint,

        {
          emailAddress: state.emailAddress,
        },

        { headers }
      )

      .then(function (response) {
        console.log(response);
        console.log("S", response);
        setLoading(false);
        onSuccess();
      })

      .catch(function (error) {
        console.log("E", error);
        setLoading(false);
        onSuccess();
      });

    // const params = {
    //   name: "Create Borrower",
    //   path: "create_external_user",
    //   payload: formStateWithQueryParams(state),
    //   onSuccessFn: () => onSuccess(state.emailAddress),
    // };

    // await axiosPostRequest(params);
  };

  return (
    <Form
      id="create"
      hideButton
      customFormTypes={{ userCategory: "broker" }}
      // customFormTypes={{ userCategory: "borrower" }}
      submitButtonText={"props.submitButtonText"}
      onClick={onSubmit}
      // onClick={handleCreateUser}
    >
      <FormGroup noMargin>
        <MuiGrid spacing={1}>
          <GridItem size={12}>
            <Field
              validate={VALIDATOR_EMAIL}
              name={"emailAddress"}
              label={`Email Address`}
              icon={<EmailOutlined />}
              component={TextFieldComponent}
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>
      <FormSubmitButton isLoading={loading} buttonText={"Submit"} />
    </Form>
  );
};

export default EmailUnsubscribeForm;
