import {
  KeyboardArrowDown,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  UploadFileOutlined,
  UploadRounded,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Divider, IconButton, Stack, Table, TableBody, TableHead, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { isArray } from "lodash";
import React, { useMemo } from "react";
import FileDocumentGroupModal from "../../../../../../../../components/form/components/modals/FileDocumentGroupModal";
import ErrorMessage from "../../../../../../../../components/shared/ErrorMessage";
import FilesTableCell from "../shared/LoanFilesTableCell";
import LoanFileUploadTableRow from "./LoanFileUploadTableRow";
import { useState } from "react";
import FlexBox from "../../../../../../../../components/shared/FlexBox";

const LoanFileUploadTable = ({
  files,
  onClick,
  onCancel,
  onRemove,
  onChange,
  isLoading,
  filesAreEmpty,
  error,
  index,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const filesArray = useMemo(() => files, [files]);
  const thStyles = {
    fontWeight: "600",
    fontSize: ".7rem",
    background: "#fafafa",
  };

  if (!isArray(filesArray) || filesAreEmpty) {
    return null;
  }

  return (
    <Box
      sx={{
        // position: "relative",
        background: "#ffffff",
        // background: "#fafafb",
        position: "absolute",
        left: "-12px",
        top: 0,
        width: "calc(100% + 24px)",
        zIndex: 1111 - index,
        border: "2px solid var(--borderGray)",
        // boxShadow: "0px 5px 4px 0px #0000003b, 0px 7px 3px 0px #2356852b",
        borderTop: 0,
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        // background: "#fafafb",
        // boxShadow: "0 0 4px 4px black",
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} padding={"8px 10px"}>
        <FlexBox alignCenter>
          <UploadFileOutlined sx={{ color: "gray", fontSize: ".9rem" }} />
          <Typography
            sx={{ fontWeight: 600, color: "gray", fontSize: ".7rem", lineHeight: ".9rem", marginLeft: "5px" }}
          >
            {filesArray.length} Files
          </Typography>
        </FlexBox>
        <IconButton sx={{ position: "absolute", right: "2px", padding: "5px" }} onClick={() => setIsOpen((s) => !s)}>
          {isOpen ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
        </IconButton>
      </Stack>
      {isOpen ? (
        <Stack
        // sx={{
        //   position: "absolute",
        //   left: 0,
        //   width: "100%",
        //   zIndex: 11111,
        //   background: "#fafafb",
        //   boxShadow: "0 0 4px 4px black",
        // }}
        >
          <Box
            sx={{
              width: "100%",
              borderTop: "1px solid var(--borderGray)",
              // borderWidth: "2px",
              // borderBottomRightRadius: "4px",
              // borderBottomLeftRadius: "4px",
              // borderTop: "none",
              zIndex: 111,
            }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead sx={{ display: "none" }}>
                <FilesTableCell component="th" sx={thStyles}>
                  {/* Filename */}
                </FilesTableCell>

                <FilesTableCell component="th" sx={{ ...thStyles, textAlign: "center", width: "32px" }}>
                  {/* Remove */}
                </FilesTableCell>
              </TableHead>
              <TableBody>
                {filesArray.map((row, index) => {
                  return (
                    <LoanFileUploadTableRow
                      key={row.name}
                      row={row}
                      name={row.id}
                      index={index}
                      onRemove={onRemove}
                      onChange={onChange}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
              // background: "#fafafb",
              borderTop: "1px solid #e9e8e8",
              gap: "10px",
              // marginTop: "10px",
              padding: "8px 10px",
              // marginTop: "10px",
              // margin: "18px -10px -6px",
            }}
          >
            <LoadingButton
              loading={isLoading}
              onClick={onClick}
              sx={{ fontSize: ".75rem", padding: "6px 10px", lineHeight: "1rem" }}
              variant="contained"
              endIcon={
                <UploadRounded
                  sx={{
                    fontSize: "1rem !important",
                  }}
                />
              }
            >
              Upload Files
            </LoadingButton>
            <Button
              onClick={onCancel}
              sx={{ fontSize: ".75rem", fontWeight: 500, padding: "0px", lineHeight: "1rem" }}
              variant="text"
            >
              Clear All Files
            </Button>
          </Box>
          <ErrorMessage show={error} message={"Could not upload files"} />
        </Stack>
      ) : null}
    </Box>
  );
};

export default LoanFileUploadTable;
