import React from "react";

import { Box } from "@mui/material";
import DrilldownCardTabHeader from "./DrilldownCardTabHeader";
import DrilldownCardTabBody from "./DrilldownCardTabBody";
import DrilldownCard from "./DrilldownCard";

const DrilldownScreenTab = ({
  title,
  headerContent,
  headerBodyContent,
  noPadding,
  noBodyPadding,
  noBodySidePadding,
  noHeaderPadding,
  id,
  children,
  card,
  sx,
}) => {
  const content = (
    <Box id={id} sx={{ flexGrow: 1, position: "relative", ...sx }} p={noPadding ? 0 : "4px"}>
      {title ? (
        <DrilldownCardTabHeader
          headerContent={headerContent}
          headerBodyContent={headerBodyContent}
          noPadding={noPadding || noHeaderPadding}
        >
          {title}
        </DrilldownCardTabHeader>
      ) : null}
      {children ? (
        <DrilldownCardTabBody noPadding={noPadding || noBodyPadding} noBodySidePadding={noBodySidePadding}>
          {children}
        </DrilldownCardTabBody>
      ) : null}
    </Box>
  );

  if (card) {
    return <DrilldownCard sx={sx}>{content}</DrilldownCard>;
  }
  return content;
};

export default DrilldownScreenTab;
