import React, { useState } from "react";

import { Container, Stack } from "@mui/material";
import BrokerDashboardHeader from "../../views/BrokerView/BrokerDashboardHeader/BrokerDashboardHeader";
import BrokerVerticalNavigation from "../../views/BrokerView/BrokerVerticalNavigation/BrokerVerticalNavigation";
import ScreenSubTitleSection from "./Screen/sections/ScreenSubTitleSection";
import UserView from "./UserView";
import VerticalNavLayout from "./VerticalNavLayout";
import VerticalNavRightColumn from "./VerticalNavLayout/VerticalNavRightColumn";
import { isMobile } from "react-device-detect";
import ScreenBody from "./Screen/ScreenBody";
import ScreenSubTitle from "./Screen/ScreenSubTitle";
import { useDispatch, useSelector } from "react-redux";
import { updateGlobalData } from "../../redux/actions/globalDataActions";
import { useEffect } from "react";
// import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
const DashboardScreen = ({ title, children, collapsed, customScreenBody }) => {
  const globalData = useSelector((state) => state.globalData);
  const isOpen = useSelector((state) => state.globalData?.isOpen);
  const isMobile = useSelector((state) => state.globalData?.isMobile);
  const dispatch = useDispatch();
  // const [screenIsMobile, setscreenIsMobile] = useState(isMobile);
  // const [isOpen, setIsOpen] = useState(screenIsMobile ? false : true);
  const handleSetIsOpen = (value) => {
    dispatch(updateGlobalData({ key: "isOpen", value: value }));
  };
  // React.useEffect(() => {
  //   function handleResize() {
  //     if (window.innerWidth < 900) {
  //       setscreenIsMobile(true);
  //       setIsOpen(false);
  //     } else {
  //       setscreenIsMobile(false);
  //       setIsOpen(true);
  //     }
  //   }

  //   window.addEventListener("resize", handleResize);

  //   return (_) => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // });

  const bodyContent = (
    <>
      {title ? <ScreenSubTitle h3>{title}</ScreenSubTitle> : null}
      <Stack sx={{ height: "100%", flexGrow: 1 }}>{children}</Stack>
    </>
  );

  useEffect(() => {
    const updatedData = {
      ...globalData,
      key: "isOpen",
      value: isMobile ? false : true,
    };
    dispatch(updateGlobalData(updatedData));
  }, []);
  return (
    <UserView id="broker" sx={{ height: "100%" }}>
      <VerticalNavLayout>
        <BrokerVerticalNavigation
          collapsed={collapsed}
          isMobile={isMobile}
          isOpen={isOpen}
          setIsOpen={handleSetIsOpen}
        />
        <VerticalNavRightColumn>
          <BrokerDashboardHeader isMobile={isMobile} setIsOpen={handleSetIsOpen} isOpen={isOpen} />
          {customScreenBody ? (
            bodyContent
          ) : (
            <ScreenBody sx={{ paddingTop: "24px", paddingBottom: "24px" }} maxWidth={false}>
              {bodyContent}
            </ScreenBody>
          )}
        </VerticalNavRightColumn>
      </VerticalNavLayout>
    </UserView>
  );
};
export default React.memo(DashboardScreen);
