import { FormControl, FormHelperText, TextField, Tooltip } from "@mui/material";
import { get, isNil } from "lodash";
import React, { useState } from "react";
import { Field } from "react-final-form";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { VALIDATOR_REQUIRE } from "../../../helpers/validators/inputValidators";
import RenderCount from "../components/RenderCount/RenderCount";
import FormField from "../components/shared/FormField/FormField";
import DisplayDataFieldItem from "../../shared/DisplayDataFieldItem";
import { parsePhoneNumber } from "../../../helpers/parse/parsePhoneNumber";

/**
 * Example:
 *  <FinalFormTextField
        label="Requested Loan amount"
        name={`${fieldNames.requestedLoanAmount}`}
        dollar
    />
 */

const FinalFormTextField = ({
  name,
  label,
  validate,
  fullWidth,
  value,
  required,
  dollar,
  disabled,
  percent,
  variant,
  helperText,
  placeholder,
  onChange,
  color,
  focused,
  phone,
  format,
  parse,
  number,
  count,
}) => {
  let inputIcon = null;

  if (dollar === true || dollar === "true") {
    inputIcon = {
      inputComponent: NumberFormatDollar,
    };
  }
  if (percent === true || percent === "true") {
    inputIcon = {
      inputComponent: NumberFormatPercent,
    };
  }

  const validators = validate ? validate : required ? VALIDATOR_REQUIRE : undefined;

  // if (display) {
  //   return (
  //     <DisplayDataFieldItem
  //       dot
  //       label={label}
  //       value={originalValue}
  //       percent={percent}
  //       dollar={dollar}
  //     />
  //   );
  // }
  return (
    <FormField fullWidth={fullWidth ? fullWidth : true}>
      <Field
        name={name}
        format={format}
        validate={validators}
        parse={phone ? parsePhoneNumber : number ? (v) => Number(v) : undefined}
      >
        {({ input, meta }) => (
          <FormControl fullWidth={fullWidth ? fullWidth : true} variant={variant ? variant : "filled"}>
            {/* <RenderCount /> */}
            {count ? <RenderCount /> : null}
            {/* {console.log(input.value)} */}

            <TextField
              id={name}
              label={label}
              size="small"
              // type={number ? "number" : "text"}
              disabled={disabled}
              value={value}
              variant={variant ? variant : "filled"}
              fullWidth={fullWidth ? fullWidth : true}
              placeholder={placeholder}
              color={color ? color : "secondary"}
              focused={focused}
              error={meta.error && meta.touched}
              InputProps={dollar || percent ? inputIcon : null}
              {...input}
              onChange={(e) => {
                input.onChange(e); //final-form's onChange
                // console.log("val", e);
                if (onChange) {
                  //props.onChange
                  onChange(e);
                  // handleOnChange(e);
                }
              }}
            />

            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {meta.error && meta.touched && <span className="error">{meta.error}</span>}
          </FormControl>
        )}
      </Field>
    </FormField>
  );
};

const NumberFormatPercent = React.forwardRef(function MyComponent({ onChange, value, disabled, ...other }, ref) {
  return (
    <NumericFormat
      {...other}
      decimalScale={value >= 100 ? 0 : 3}
      decimalSeparator="."
      onValueChange={(formattedValue) => onChange(formattedValue.floatValue)}
      value={value}
      valueIsNumericString
      suffix="%"
    />
  );
});

const NumberFormatDollar = React.forwardRef(function MyComponent({ onChange, value, disabled, ...other }, ref) {
  //  Spread the props to the underlying DOM element.

  return (
    <NumericFormat
      {...other}
      onValueChange={(formattedValue) => onChange(formattedValue.floatValue)}
      disabled={disabled}
      value={value}
      valueIsNumericString
      thousandSeparator
      // isNumericString
      prefix="$"
    />
  );
});

export default React.memo(FinalFormTextField);
