import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const GridItem = ({ grow, children, sx, size, xs, sm, md, lg, xl, mobile }) => {
  // xs, extra-small: 0px
  // sm, small: 600px
  // md, medium: 900px
  // lg, large: 1200px
  // xl, extra-large: 1536px

  // <GridItem  xs={12} sm={6} md={4} lg={3} xl={2}>

  // </GridItem>
  const growStyles = { flexGrow: 1, ...sx };
  const xsSize = xs ? xs : size;
  const globalData = useSelector((state) => state.globalData);
  const isMobile = useSelector((state) => state.globalData?.isMobile);
  return (
    <Grid item xs={mobile && isMobile ? 12 : xsSize} sm={sm} md={md} lg={lg} xl={xl} sx={grow ? growStyles : sx}>
      {children}
    </Grid>
  );
};

export default GridItem;
