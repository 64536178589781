import React, { useEffect } from "react";
import FinalFormTextField from "../../../../../components/form/Fields/FinalFormTextField";
import { useForm, useFormState } from "react-final-form";
import { getObjectValue } from "../../../../../helpers/getObjectValueFromStringPath";
import { isNil, sum } from "lodash";

const FinalFormFieldTotalBudget = ({ name, variant }) => {
  const { values } = useFormState();
  const { change } = useForm();

  const hardCosts = getObjectValue(values, `${name}.hardCosts`);
  const softCosts = getObjectValue(values, `${name}.softCosts`);
  const rehabBudgetPercent = isNil(values?.rehabBudgetFinancedPercent) ? 100 : values?.rehabBudgetFinancedPercent / 100;
  const hardCostValue = isNil(hardCosts) || isNaN(hardCosts) ? 0 : hardCosts;
  const softCostValue = isNil(softCosts) || isNaN(softCosts) ? 0 : softCosts;
  const totalBudget = Number(hardCostValue) + Number(softCostValue);
  const softCostsIsValid = isNil(softCosts) || (softCosts !== "" && softCosts / totalBudget <= 0.15) ? true : false;
  const maxSoftCost = totalBudget * 0.15;

  const getSumOfAllValues = (name) => {
    // console.log("name", name);
    const vals = values?.subjectProperties.map((p) => p[name]);
    const sum_asIsValue = sum(vals);
    return sum_asIsValue;
  };
  const getSumOfAllValuesTotalBudget = (name) => {
    // console.log("name", name);
    const vals = values?.subjectProperties.map((p) => p[name]);
    const sum_asIsValue = sum(vals);
    // change(`totalReportedBudget`, sum_asIsValue);
    return sum_asIsValue;
  };

  const handleUpdateTotalBudget = (val) => {
    const totalBudget = Number(hardCostValue) + Number(val);
    const financedBudget = totalBudget * rehabBudgetPercent;
    console.log("FinalFormFieldTotalBudget val", val);
    // const newTotalReportedBudget = getSumOfAllValues()
    // change(`${name}.totalBudget`, totalBudget);
    // change(`totalReportedBudget`, newTotalReportedBudget);
    // change(`${name}.financedBudget`, financedBudget);
  };

  return (
    <FinalFormTextField
      dollar
      onChange={handleUpdateTotalBudget}
      name={`${name}.totalBudget`}
      label={variant === "standard" ? undefined : "Total Budget"}
      variant={variant}
    />
  );
};

export default FinalFormFieldTotalBudget;
