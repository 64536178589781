import {
  Button,
  Card,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { isArray, isNil, isObject, isString, toLower } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { ExpandCircleDownOutlined, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { arrayProtector } from "../../../helpers/arrayProtector";
import { parseISOString } from "../../../helpers/parseISOString";
import { searchInComplexData } from "../../../helpers/searchInComplexData";
import ScreenSubTitle from "../../layout/Screen/ScreenSubTitle";
import FlexBox from "../FlexBox";
import MuiEmptyTableRow from "../MuiCustomTable/MuiEmptyTableRow";
import MuiSelectField from "../MuiSelectField";
import MuiTableCell from "../MuiTableCell";
import { styles } from "./MuiReactTable.styles";

const tableSortTypes = {
  string: "string",
  number: "number",
  date: "date",
};

const MuiReactTable = ({
  columns,
  data,
  title,
  search,
  secondaryRow,
  headerContent,
  emptyTitle,
  id,
  active,
  rowOnClick,
  pagination,
  paginationDefault,
}) => {
  const [page, setPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(paginationDefault ? paginationDefault : 20);

  const [tableData, setTableData] = useState(arrayProtector(data) ? data : []);
  const [sort, setSort] = useState(undefined);
  const [ascending, setAscending] = useState(false);

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handleOnSortClick = (columnItem) => {
    const sortHeaderName = sort?.Header;
    const sortAccessorName = sort?.accessor;
    const columnHeaderName = columnItem?.Header;
    const columnAccessorName = columnItem?.accessor;
    console.log({ sortHeaderName, columnHeaderName });
    if (sortHeaderName !== columnHeaderName) {
      console.log("1) sortHeaderName !== columnHeaderName");
      setSort(columnItem);
      setAscending(true);
      sortArrayBySortName(tableData, true, columnItem);
      return;
    }

    // setSort(name);
    if (isNil(ascending)) {
      console.log("2) (isNil(ascending)");
      setAscending(true);
      sortArrayBySortName(tableData, true, columnItem);
      return;
    }

    if (ascending) {
      setAscending(false);
      sortArrayBySortName(tableData, false, columnItem);
      console.log("3) ascending", sortArrayBySortName(tableData, false, columnItem));
    }
    if (!ascending) {
      console.log("4) !ascending", tableData);
      setAscending(undefined);
      setTableData(tableData);
      return;
    }
  };

  const sortArrayBySortName = (array, ascending, columnItem) => {
    if (!isArray(array)) {
      return;
    }

    const compareUploadDates = (obj1, obj2) => {
      let compare1;
      let compare2;
      // if (sortName === sortNameTypes.uploadDate) {
      const sortName = sort?.Header;
      if (sortName === tableSortTypes.date) {
        compare1 = parseISOString(obj1?.uploadDate);
        compare2 = parseISOString(obj2?.uploadDate);
        if (ascending) {
          return compare2 - compare1;
        } else {
          return compare1 - compare2;
        }
      }

      if (isString(columnItem?.accessor)) {
        const name = columnItem?.accessor;
        // const name = isString(columnItem?.accessor) ? columnItem?.accessor : columnItem?.accessor();
        compare1 = toLower(obj1[name]);
        compare2 = toLower(obj2[name]);

        if (ascending) {
          // return compare2 - compare1;
          return compare1.localeCompare(compare2);
        } else {
          return compare2.localeCompare(compare1);
          // return compare1 - compare2;
        }
      }
      if (isObject(columnItem?.accessor)) {
        // const name = isString(columnItem?.accessor) ? columnItem?.accessor : columnItem?.accessor();
        compare1 = toLower(columnItem?.accessor(obj1));
        compare2 = toLower(columnItem?.accessor(obj2));

        if (ascending) {
          // return compare2 - compare1;
          return compare1.localeCompare(compare2);
        } else {
          return compare2.localeCompare(compare1);
          // return compare1 - compare2;
        }
      }
    };

    array.sort(compareUploadDates);

    return array;
  };

  const paginationTableData = useMemo(() => {
    if (isNil(tableData)) {
      return [];
    }

    if (countPerPage === "All") {
      return tableData;
    }
    const sliceVal = (page - 1) * countPerPage;
    return tableData.slice(sliceVal, sliceVal + countPerPage);
  }, [tableData, page, countPerPage, sort, ascending]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  return (
    <FlexBox column sx={{ flexGrow: 1 }}>
      <Card id={id} sx={styles.card} variant="outlined" noPadding>
        <CardHeaderContent headerContent={headerContent} title={title} search={search} />

        {secondaryRow ? secondaryRow : null}
        <Table size="small">
          <TableHeadUtils
            page={page}
            tableData={tableData}
            originalData={data}
            setCountPerPage={setCountPerPage}
            setTableData={setTableData}
            countPerPage={countPerPage}
            handlePaginationChange={handlePaginationChange}
            // showPagination={true}
            showPagination={pagination}
            // showSearch={true}
            showSearch={search}
          />

          <TableHead>
            {arrayProtector(columns).map((columnItem) => {
              return (
                <TableSortHeaderCell
                  columnItem={columnItem}
                  ascending={ascending}
                  sort={sort}
                  // name={sortNameTypes.fileName}
                  onClick={handleOnSortClick}
                />
              );
            })}
            {rowOnClick ? <MuiTableCell component="th" sx={styles.table.tableHeadCell}></MuiTableCell> : null}
          </TableHead>
          <TableBody>
            <MappedListOfTableRows
              // tableData={tableData}
              tableData={pagination ? paginationTableData : tableData}
              columns={columns}
              emptyTitle={emptyTitle}
              active={active}
              rowOnClick={rowOnClick}
            />
          </TableBody>
        </Table>
      </Card>
    </FlexBox>
  );
};

const TableHeadUtils = ({
  page,
  originalData,
  tableData,
  countPerPage,
  setTableData,
  showSearch,
  showPagination,
  setCountPerPage,
  handlePaginationChange,
}) => {
  if ((isNil(showSearch) && isNil(showPagination)) || arrayProtector(originalData).length === 0) {
    return null;
  }
  return (
    <TableHead>
      <TableRow>
        <TableCell variant="th" colSpan={"100%"} sx={{ padding: "8px" }}>
          <FlexBox justifyBetween alignCenter>
            <TableSearchInput data={originalData} setTableData={setTableData} show={showSearch} />
            <TablePagination
              page={page}
              tableData={tableData}
              setCountPerPage={setCountPerPage}
              countPerPage={countPerPage}
              originalData={originalData}
              show={showPagination}
              onChange={handlePaginationChange}
            />
          </FlexBox>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const TableSearchInput = ({ data, setTableData, show }) => {
  const [search, setSearch] = useState(null);

  const handleOnSearchChange = (val) => {
    setSearch(val);

    const results = searchInComplexData(val, data);
    console.log({ val, results });
    setTableData(results);
  };

  const handleClearSeach = () => {
    setSearch("");
    setTableData(data);
  };

  if (isNil(show)) {
    return null;
  }
  return (
    <FlexBox alignCenter>
      <TextField
        variant="standard"
        size="small"
        placeholder={`Search`}
        value={search}
        // value={search || ""}
        sx={{ flexGrow: 1 }}
        onChange={(e) => {
          handleOnSearchChange(e.target.value);
        }}
        InputProps={styles.pagination.textFieldInputProps}
      />
      {search !== "" && !isNil(search) ? (
        <Button size="small" sx={styles.pagination.clearSearchButton} variant="text" onClick={handleClearSeach}>
          Clear Search
        </Button>
      ) : null}
    </FlexBox>
  );
};

const TablePagination = ({ show, tableData, originalData, countPerPage, setCountPerPage, page, onChange }) => {
  const countPerPageAsNumber = Number(countPerPage);
  const count = useMemo(
    () => (isArray(tableData) ? Math.ceil(tableData.length / countPerPageAsNumber) : 0),
    [tableData, countPerPageAsNumber]
  );

  if (tableData < 10 || !show) {
    return null;
  }

  const handleOnTableSizeChange = (e) => {
    const number = e.target.value;
    // console.log({ number });
    if (number === "All") {
      setCountPerPage("All");
      return;
    }
    setCountPerPage(Number(number));
  };
  return (
    <FlexBox alignCenter gap={"20px"} sx={styles.pagination.root}>
      <Pagination count={countPerPage === "All" ? 0 : count} size="small" page={page} onChange={onChange} />
      <FlexBox gap={"4px"} alignCenter sx={styles.pagination.countSelect}>
        <Typography>View</Typography>
        <MuiSelectField
          value={String(countPerPage)}
          onChange={handleOnTableSizeChange}
          variant={"standard"}
          items={["10", "20", "30", "50", "100", "All"]}
        />
        <Typography>per page</Typography>
      </FlexBox>
    </FlexBox>
  );
};

const MappedListOfTableRows = ({ tableData, columns, emptyTitle, active, rowOnClick }) => {
  const tableRows = useMemo(() => tableData, [tableData]);

  if (!isArray(tableData) || tableData?.length < 1) {
    return <MuiEmptyTableRow title={emptyTitle} />;
  }

  return tableRows.map((row) => {
    return (
      <TableRowItem key={row?._id} rowDataObject={row} columns={columns} active={active} rowOnClick={rowOnClick} />
    );
  });
};

const TableRowItem = ({ columns, rowDataObject, active, rowOnClick }) => {
  // console.log({ columns, rowDataObject, active });

  const checkIfActive = () => {
    if (isString(rowDataObject)) {
      if (active?.value === rowDataObject) {
        return true;
      }
    }
    const activeName = active?.name;
    const activeValue = active?.value;

    if (rowDataObject[activeName] === activeValue) {
      return true;
    }
    return false;
  };

  const isActive = isNil(active) ? false : checkIfActive();
  const tableRef = useRef(null);

  if (rowOnClick) {
    return (
      <TableRow
        role="button"
        onClick={() => rowOnClick(rowDataObject)}
        sx={{ background: isActive ? "var(--lightBlue)" : "inherit", cursor: "pointer !important" }}
        ref={tableRef}
      >
        {columns.map((col) => {
          return <TableCellItem columns={columns} column={col} rowDataObject={rowDataObject} />;
        })}
        <MuiTableCell sx={{ width: "40px" }}>
          {/* <ChevronRight /> */}
          <ExpandCircleDownOutlined sx={styles.table.rowOnClick} />
        </MuiTableCell>
      </TableRow>
    );
  }
  return (
    <TableRow sx={{ background: isActive ? "var(--lightBlue)" : "inherit" }} ref={tableRef}>
      {columns.map((col) => {
        return <TableCellItem columns={columns} column={col} rowDataObject={rowDataObject} />;
      })}
    </TableRow>
  );
};

const TableSortHeaderCell = ({ sort, ascending, onClick, colSpan, sx, columnItem }) => {
  const sortHeaderName = sort?.Header;

  const columnHeaderName = columnItem?.Header;

  const showArrow = !isNil(ascending) && sortHeaderName === columnHeaderName;
  // const showArrow = !isNil(ascending) && sort === columnAccessorName;
  const arrow = ascending ? <KeyboardArrowDown /> : <KeyboardArrowUp />;

  const handleOnClick = () => {
    onClick(columnItem);
  };
  return (
    <MuiTableCell
      component="th"
      colSpan={colSpan}
      onClick={handleOnClick}
      role={"button"}
      sx={styles.table.tableHeadCell}
    >
      <Link
        variant="text"
        underline={"none"}
        sx={{ ...styles.table.tableHeadLinkCell, ...sx }}
        // onClick={handleOnClick}
      >
        {columnHeaderName}
        {showArrow ? arrow : null}
      </Link>
    </MuiTableCell>
  );
};

const TableCellItem = ({ column, columns, rowDataObject }) => {
  const key = column.accessor;

  const cellStyles = {
    width: column?.CellWidth ? column?.CellWidth : "auto",
  };

  if (isString(key)) {
    return <MuiTableCell sx={cellStyles}>{rowDataObject[key]}</MuiTableCell>;
  }
  // console.log("column", typeof column);
  return <MuiTableCell sx={cellStyles}>{column.accessor(rowDataObject)}</MuiTableCell>;
};

const CardHeaderContent = ({ headerContent, title, search }) => {
  const DYNAMIC_CARD_HEADER_CONTENT_FULL =
    headerContent || search ? (
      <FlexBox alignCenter gap={"10px"}>
        {headerContent}
      </FlexBox>
    ) : null;

  if (isNil(title) && isNil(headerContent) && isNil(search)) {
    return null;
  }
  return (
    <>
      <FlexBox sx={styles.cardHeaderContent} justifyBetween alignCenter className="table-primary-header">
        <ScreenSubTitle sx={{ fontSize: "1rem" }} h2>
          {title}
        </ScreenSubTitle>

        {DYNAMIC_CARD_HEADER_CONTENT_FULL}
      </FlexBox>
    </>
  );
};

export default MuiReactTable;
