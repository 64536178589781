export const tooltipStyles = {
  fontSize: ".7rem",
  color: "#ffffff",
};

export const tableCellIconButtonStyles = {
  styles: {
    width: "18px",
    height: "24px",
    borderRadius: "4px",
    padding: "2px 0",
    fontSize: ".7rem",
  },
  button: {
    editing: {
      background: "#1c456a14",
      padding: "4px",
      // margin: "0 4px",
    },
  },
  icon: {
    fontSize: "1rem",
  },
  iconEditing: {
    color: "var(--blue)",
    fontSize: ".9rem !important",
    marginLeft: "-3px",
  },
};
