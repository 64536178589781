import {
  DeleteOutline,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  UploadFileOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Stack, Table, TableBody, Typography } from "@mui/material";
import { isArray, isNil } from "lodash";
import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Field, useForm, useFormState } from "react-final-form";
import { v4 as uuidv4 } from "uuid";
import { arrayProtector } from "../../../helpers/arrayProtector";
import { parsePhoneNumber } from "../../../helpers/parse/parsePhoneNumber";
import { VALIDATOR_REQUIRE } from "../../../helpers/validators/inputValidators";
import FlexBox from "../../shared/FlexBox";
import FormField from "../components/shared/FormField/FormField";

/**
 * Example:
 *  <FinalFormTextField
        label="Requested Loan amount"
        name={`${fieldNames.requestedLoanAmount}`}
        dollar
    />
 */

const FinalFormFileUpload = ({
  name,
  label,
  validate,
  fullWidth,
  value,
  required,
  dollar,
  disabled,
  percent,
  variant,
  helperText,
  type,
  placeholder,
  onChange,
  color,
  focused,
  phone,
  format,
  parse,
  number,
  count,
  ...rest
}) => {
  console.log("helperText", helperText);
  const validators = validate ? validate : required ? VALIDATOR_REQUIRE : undefined;
  const parseType = phone ? parsePhoneNumber : number ? (v) => Number(v) : undefined;

  return (
    <FormField fullWidth={fullWidth ? fullWidth : true}>
      <Field name={name} format={format} validate={validators} parse={parse ? parse : parseType}>
        {({ input, meta }) => <DropzoneInput helperText={helperText} {...input} {...rest} />}
      </Field>
      {helperText ? (
        <Typography
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            fontWeight: 400,
            lineHeight: 1.66,
            marginTop: "3px",
            fontSize: ".75rem",
            opacity: 0.95,
          }}
        >
          {helperText}
        </Typography>
      ) : null}
    </FormField>
  );
};

const DropzoneInput = ({ required, input, label, uploadLabel, dropZoneProps, maxFiles, helperText, ...props }) => {
  const { values } = useFormState();
  const { change } = useForm();

  const handleOnUpload = (mappedFiles) => {
    const currentDocumentList = isNil(values?.documents) ? [] : values?.documents;
    let spreadState = [...currentDocumentList, ...mappedFiles];

    change(`documents`, spreadState);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: maxFiles ? false : true,
    maxFiles: maxFiles || 0,
    onDrop: (acceptedFiles) => {
      console.log("---UPLOAD START", acceptedFiles);

      const mappedFiles = acceptedFiles.map((file, i) => {
        let fileObjectInit = {
          file: Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
          id: uuidv4(),
          docGroup: props.docGroup,
          docType: props.docType,
        };
        return fileObjectInit;
      });

      handleOnUpload(mappedFiles);
    },
  });

  console.log("helperText", props);

  return (
    <>
      <div id="file-upload">
        <div className="file-upload-inner-content">
          <div {...getRootProps({ className: "file-upload-box" })}>
            <input {...getInputProps()} />
            {uploadIcon}
            <span className="file-upload__title">
              {uploadLabel ? uploadLabel : "Drag 'n' drop or click to add files"}
            </span>
          </div>
        </div>
      </div>

      <UploadedFiles docGroup={props.docGroup} docType={props.docType} />
      {/* {uploadedFiles.length >= 1 && <UploadedFiles  files={uploadedFiles} />} */}
    </>
  );
};

const UploadedFiles = ({ docGroup, docType }) => {
  const { values } = useFormState();
  const { change } = useForm();

  const uploadedFiles = useMemo(
    () => arrayProtector(values?.documents).filter((d) => d?.docGroup === docGroup && d?.docType === docType),
    [values?.documents]
  );

  const [isOpen, setIsOpen] = useState(false);

  // const filesArray = useMemo(() => uploadedFiles, [uploadedFiles]);

  const handleRemoveFile = (id) => {
    console.log("remove", id);
    let files_spread = [...values?.documents];
    console.log("files_spread", files_spread);
    const filteredFiles = files_spread.filter((file) => file.id !== id);
    console.log("filteredFiles", filteredFiles);
    change("documents", filteredFiles);
  };

  if (!isArray(uploadedFiles) || uploadedFiles.length < 1) {
    return null;
  }

  return (
    <Box
      sx={{
        background: "#ffffff",
        width: "100%",
        position: "relative",
        // zIndex: 1111 - index,
        border: "2px solid var(--borderGray)",
        borderTop: 0,
      }}
    >
      <Stack
        id="header-bar"
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"8px 10px"}
      >
        <FlexBox sx={{ position: "relative", width: "100%" }} justifyBetween alignCenter>
          <FlexBox alignCenter>
            <UploadFileOutlined sx={{ color: "gray", fontSize: ".9rem" }} />
            <Typography
              sx={{ fontWeight: 600, color: "gray", fontSize: ".7rem", lineHeight: ".9rem", marginLeft: "5px" }}
            >
              {uploadedFiles?.length} Files
            </Typography>
          </FlexBox>
          <IconButton
            sx={{ color: "var(--primary)", position: "absolute", right: "0px", padding: "2px" }}
            onClick={() => setIsOpen((s) => !s)}
          >
            {isOpen ? (
              <KeyboardArrowUpRounded sx={{ fontSize: "1rem !important", margin: "0 !important" }} />
            ) : (
              <KeyboardArrowDownRounded sx={{ fontSize: "1rem !important", margin: "0 !important" }} />
            )}
          </IconButton>
        </FlexBox>
      </Stack>
      {isOpen ? (
        <Box
          sx={{
            position: "absolute",
            left: "0px",
            top: "100%",
            zIndex: 1111,
            maxHeight: "160px",
            overflowY: "auto",
            background: "#ffffff",
            width: "100%",
            boxShadow: "var(--bs2)",
            border: "2px solid var(--borderGray)",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          }}
        >
          <Table size="small" aria-label="a dense table">
            <TableBody>
              {uploadedFiles.map((file, index) => {
                return (
                  <FlexBox justifyBetween sx={{ borderBottom: "var(--border)", padding: "4px 8px" }} alignCenter>
                    <Typography sx={{ fontSize: ".7rem" }}>{file.file.path}</Typography>
                    <IconButton sx={{ padding: "2px" }} onClick={() => handleRemoveFile(file.id)}>
                      <DeleteOutline sx={{ color: "var(--primary)", fontSize: ".9rem !important" }} />
                    </IconButton>
                  </FlexBox>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      ) : null}
    </Box>
  );
};
const uploadIcon = (
  <svg id="upload-icon" viewBox="0 0 24 24">
    <path d="M11 15h2V9h3l-4-5-4 5h3z"></path>
    <path d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"></path>
  </svg>
);

export default React.memo(FinalFormFileUpload);
