import React from "react";
import { useSelector } from "react-redux";
import EditableDrilldownTabCard from "../components/EditableDrilldownTabCard";

import { Box, Stack, Typography } from "@mui/material";
import { isEqual, isNil } from "lodash";
import { Field, useFormState } from "react-final-form";
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Text } from "recharts";
import DrilldownCardTabSection from "../../../../../components/Drilldown/DrilldownCardTabSection";
import FinalFormSelectField from "../../../../../components/form/Fields/FinalFormSelectField";
import FinalFormTextField from "../../../../../components/form/Fields/FinalFormTextField";
import { DatePickerTextFieldComponent } from "../../../../../components/form/components/inputs/finalFormComponents/DatePickerTextFieldComponent";
import FlexBox from "../../../../../components/shared/FlexBox";
import MuiGrid from "../../../../../components/shared/MuiGrid";
import GridItem from "../../../../../components/shared/MuiGrid/GridItem";
import {
  loanExitStrategy,
  loanPurposeOptions,
  productTypeOptions,
} from "../../../../../forms/_formQuestions/selectOptions";
import { fieldNames } from "../../../../../global/forms/fieldNames";
import { formatAsDollarString } from "../../../../../helpers/formatAsDollarString";
import { getFinalFormTotalLoanAmount } from "../../../../../helpers/getFinalFormTotalLoanAmount";

const ScenarioDrilldownTabLoanTerms = (props) => {
  // const [display, setDisplay] = React.useState(true);
  const formData = useSelector((state) => state.formData);
  const { values } = useFormState();
  const display = isEqual(values, formData) ? true : false;

  const data = [
    { name: "Initial Loan Amount", value: values?.initialLoanAmount },
    // { name: "Rehab Budget", value: 50000 },
    {
      name: "Rehab Budget",
      value: isNil(values?.constructionRenovationBudget) ? 0 : values?.constructionRenovationBudget,
    },
  ];
  const COLORS = ["#0088FE", "#00C49F"];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name, index, ...props }) => {
    console.log("props", props);
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <Text
        // x={x}fAppraisals
        // y={y}
        //  dy={8}
        // textAnchor="middle"
        x={x}
        y={y - 50}
        className="recharts-text recharts-label"
        // textAnchor="middle"
        // dominantBaseline="central"
        // alignmentBaseline="central"
        // fill="#0088FE"
        fontSize="11"
        fontWeight="600"
        fill={COLORS[index]}
        {...props}
      >
        {formatAsDollarString(props.value)}
      </Text>
    );
    // return (
    //   <g>
    //     <Text
    //       // x={x}
    //       // y={y}
    //       x={x}
    //       y={y + 50}
    //       //  dy={8}
    //       // textAnchor="middle"
    //       fill={COLORS[index]}
    //       {...props}
    //     >
    //       {name}
    //     </Text>
    //     <Text
    //       // x={x}
    //       // y={y}
    //       //  dy={8}
    //       // textAnchor="middle"
    //       x={x}
    //       y={y - 50}
    //       className="recharts-text recharts-label"
    //       // textAnchor="middle"
    //       // dominantBaseline="central"
    //       // alignmentBaseline="central"
    //       // fill="#0088FE"
    //       fontSize="11"
    //       fontWeight="600"
    //       fill={COLORS[index]}
    //       {...props}
    //     >
    //       {formatAsDollarString(props.value)}
    //     </Text>
    //   </g>
    // );
    return (
      <>
        <Text
          // x={x}
          // y={y}
          //  dy={8}
          // textAnchor="middle"
          fill={COLORS[index]}
          {...props}
        >
          {name}
          {props.value}
        </Text>
        {/* <Text
          // x={x}
          // y={y}
          //  dy={8}
          // textAnchor="middle"
          fill={COLORS[index]}
          {...props}
        >
          {name}
        </Text> */}
      </>
    );
    // return (
    //   <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
    //     {`${(percent * 100).toFixed(0)}%`}
    //     {/* {`${name}`} */}
    //   </text>
    // );
    // return (
    //   <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
    //     {`${(percent * 100).toFixed(0)}%`}
    //   </text>
    // );
  };

  return (
    <EditableDrilldownTabCard sx={{ height: "auto" }} title={"Loan Terms"}>
      <MuiGrid spacing={4}>
        <GridItem mobile size={7}>
          <Stack spacing={2}>
            <DrilldownCardTabSection title={"Loan Details"}>
              <MuiGrid>
                <GridItem mobile size={6}>
                  <FinalFormSelectField
                    label="Product Type"
                    name={`${fieldNames.loanProductType}`}
                    items={productTypeOptions}
                  />
                </GridItem>
                <GridItem mobile size={6}>
                  <FinalFormSelectField
                    label="Loan Purpose"
                    name={`${fieldNames.loanPurpose}`}
                    items={loanPurposeOptions}
                  />
                </GridItem>
                <GridItem mobile size={6}>
                  <FinalFormSelectField label="Loan Exit Strategy" name={`loanExitStrategy`} items={loanExitStrategy} />
                </GridItem>
                <GridItem mobile size={6}>
                  <Field name={`loanClosingDate`} label="Loan Closing Date" component={DatePickerTextFieldComponent} />
                </GridItem>
              </MuiGrid>
            </DrilldownCardTabSection>
            <DrilldownCardTabSection title={"Amounts"}>
              <MuiGrid>
                <GridItem size={6}>
                  <FinalFormTextField label="Initial Loan amount" name={`${fieldNames.initialLoanAmount}`} dollar />
                </GridItem>
                <GridItem mobile size={6}>
                  <FinalFormTextField
                    label="Rehab Budget"
                    // onChange={handleOnRehabAmoutChange}
                    name={`constructionRenovationBudget`}
                    dollar
                  />
                </GridItem>
              </MuiGrid>
            </DrilldownCardTabSection>
          </Stack>
        </GridItem>
        <GridItem mobile size={5}>
          <Box sx={{ width: "100%", height: "240px" }}>
            <ResponsiveContainer>
              <PieChart
              // width={800}
              // height={400}
              // onMouseEnter={this.onPieEnter}
              >
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  // labelLine={false}
                  // activeShape={renderActiveShape}
                  // label
                  label={renderCustomizedLabel}
                  // label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  // data={data}
                  innerRadius={60}
                  // outerRadius={100}
                  // label={renderCustomizedLabel}
                  // fill="#8884d8"

                  paddingAngle={5}
                  // dataKey="value"
                >
                  <Label
                    content={
                      <CustomLabel labelText="Total Loan Amout" value={getFinalFormTotalLoanAmount(values, true)} />
                    }
                    position="center"
                  />
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Legend content={<CustomizedLegend />} />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </GridItem>
      </MuiGrid>
    </EditableDrilldownTabCard>
  );
};

const CustomizedLegend = (props) => {
  const { payload } = props;
  return (
    <FlexBox justifyContent={"space-evenly"} sx={{ width: "100%" }}>
      {payload.map((entry, index) => (
        <FlexBox sx={{ gap: "10px" }} alignCenter>
          <Box
            sx={{
              backgroundColor: entry.payload.fill,
              width: "10px",
              height: "10px",
            }}
          />
          <Typography className="BulletLabelText">{entry.value}</Typography>
        </FlexBox>
      ))}
    </FlexBox>
  );
};

const Bullet = ({ backgroundColor, size }) => {
  return (
    <div
      className="CirecleBullet"
      style={{
        backgroundColor,
        width: size,
        height: size,
      }}
    ></div>
  );
};
const CustomLabel = ({ viewBox, labelText, value }) => {
  const { cx, cy } = viewBox;
  return (
    <g>
      <text
        x={cx}
        y={cy - 10}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fontSize="11"
      >
        {labelText}
      </text>
      <text
        x={cx}
        y={cy + 10}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill="#0088FE"
        fontSize="16"
        fontWeight="600"
      >
        {value}
      </text>
    </g>
  );
};
export default ScenarioDrilldownTabLoanTerms;
