import {
  AspectRatioOutlined,
  FeedbackOutlined,
  FileDownloadOutlined,
  MoreVertRounded,
  SaveRounded,
  ThumbDownOutlined,
  ThumbUpOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, LinearProgress, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MuiEditableTableCell from "../../../../../../../components/shared/MuiEditableTable/MuiEditableTableCell";
import MuiButtonDropdown from "../../../../../../../components/shared/MuiButtonDropdown";
import { tableCellIconButtonStyles } from "../components/LoanUploadedFiles/LoanUploadedFilesTableRow/styles";
import { updateFormData } from "../../../../../../../redux/actions/formDataActions";
import { useFilesHook } from "../../../../../../../hooks/useFilesHook";
import { checkFileStorageNameType } from "../../../../../../../helpers/checkFileStorageNameType";
import { checkFilenameType } from "../../../../../../../helpers/checkFilenameType";
import MuiDialogModal from "../../../../../../../components/shared/MuiDialogModal";
import { useState } from "react";
import MuiDialog from "../../../../../../../components/shared/MuiDialog";
import { useBorrowerHook } from "../../../../../../../hooks/useBorrowerHook";

// const ScenarioPendingFilesTableCellActions = ({ isEditing, setIsEditing, fileData, row }) => {
//   return (
//     <>
//       <MuiEditableTableCell sx={{ width: "40px" }}>

//         <Box sx={{ display: "flex", justifyContent: "center" }}>
//           <MoreFileActionButtons fileData={fileData} row={row} />
//         </Box>
//       </MuiEditableTableCell>
//     </>
//   );
// };

/** COMPONENTS **/

const ScenarioPendingFilesTableCellActions = ({ fileData, row }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (passedData) => {
    setAnchorEl(null);
  };

  return (
    <MuiButtonDropdown
      icon={<MoreVertRounded sx={tableCellIconButtonStyles.icon} />}
      id={id}
      sx={tableCellIconButtonStyles.styles}
      open={open}
      anchorEl={anchorEl}
      handleClick={handleDropdownClick}
      handleClose={handleDropdownClose}
    >
      <FileActionButtonPreview fileData={fileData} onClose={handleDropdownClose} />
      <FileActionButtonDownload fileData={fileData} onClose={handleDropdownClose} />

      <Divider />
      <FileActionButtonMarkApproved fileData={fileData} onClose={handleDropdownClose} />

      {/* <FileActionButtonReject fileData={fileData} row={row} onClose={handleDropdownClose} />
      <FileActionButtonRejectWithComment fileData={fileData} row={row} onClose={handleDropdownClose} /> */}
    </MuiButtonDropdown>
  );
};

//---------------------

const FileActionButtonPreview = ({ fileData, onClose }) => {
  const formData = useSelector((state) => state.formData);

  const { getIsLoading, previewScenarioFile } = useBorrowerHook();

  const handlePreviewFile = async () => {
    const params = {
      fileStorageName: fileData?.file_storage_name,
      // fileStorageName: checkFileStorageNameType(fileData),
      scenarioId: formData._id,
      onSuccessFn: onClose,
    };

    previewScenarioFile(params);
  };
  return (
    <DropdownMenuItem
      isLoading={getIsLoading}
      onClick={handlePreviewFile}
      icon={<AspectRatioOutlined sx={{ maxHeight: "1rem" }} fontSize="small" />}
      label="Preview"
    />
  );
};

const FileActionButtonDownload = ({ fileData, onClose }) => {
  const formData = useSelector((state) => state.formData);
  const { getIsLoading, previewScenarioFile } = useBorrowerHook();

  const handleDownloadFile = async () => {
    const params = {
      fileStorageName: checkFileStorageNameType(fileData),
      scenarioId: formData._id,
      onSuccessFn: onClose,
      downloadName: checkFilenameType(fileData),
    };

    previewScenarioFile(params);
  };
  return (
    <DropdownMenuItem
      isLoading={getIsLoading}
      onClick={handleDownloadFile}
      icon={<FileDownloadOutlined sx={{ maxHeight: "1rem" }} fontSize="small" />}
      label="Download"
    />
  );
};

const FileActionButtonMarkApproved = ({ fileData, onClose, row }) => {
  const { postIsLoading, updateScenarioFile, approveScenarioFileForUnderwriting } = useBorrowerHook();

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.formData);
  const handleMarkFinal = () => {
    // let uploadState = {
    //   approvedForUnderwriting: true,
    // };

    // const params = {
    //   name: "Mark Approved",
    //   scenarioId: formData?._id,
    //   fileId: fileData?._id,
    //   payload: uploadState,
    //   onSuccessFn: (passedData) => {
    //     console.log("passedData", passedData);
    //     dispatch(updateFormData({ key: "dealDocuments", value: passedData.documents }));
    //     onClose();
    //   },
    // };

    // updateScenarioFile(params);
    const params = {
      scenarioId: formData?._id,
      fileId: fileData?._id,
      onSuccessFn: (passedData) => {
        console.log("passedData", passedData);
        dispatch(updateFormData({ key: "dealDocuments", value: passedData.updatedFile }));
        onClose();
      },
    };

    approveScenarioFileForUnderwriting(params);
  };

  return (
    <DropdownMenuItem
      onClick={handleMarkFinal}
      isLoading={postIsLoading} //postIsLoading
      icon={<ThumbUpOutlined sx={{ maxHeight: "1rem" }} fontSize="small" />}
      label="Approve File"
    />
  );
};

const FileActionButtonReject = ({ fileData, onClose, row }) => {
  const { filesPostIsLoading, updateFile } = useFilesHook();

  const formData = useSelector((state) => state.formData);

  const handleMakeVisible = () => {
    let uploadState = {
      _id: fileData._id,
      isHidden: false,
    };

    const params = {
      name: "Make Visible Update",
      payload: uploadState,
      dealId: formData?._id,
      ironId: formData?.ironId,
      onSuccessFn: onClose,
    };

    updateFile(params);
  };

  return (
    <DropdownMenuItem
      onClick={handleMakeVisible}
      isLoading={filesPostIsLoading}
      // disabled={row?.isHidden === true ? false : true}
      icon={<ThumbDownOutlined sx={{ maxHeight: "1rem" }} fontSize="small" />}
      label="Reject File"
    />
  );
};

const FileActionButtonRejectWithComment = ({ fileData, onClose, row }) => {
  const [show, setShow] = useState(false);
  const { filesPostIsLoading, updateFile } = useFilesHook();

  const formData = useSelector((state) => state.formData);
  const handleOpenModal = () => {
    setShow(true);
    // onClose();
  };
  const handleCloseModal = () => {
    setShow(false);
    onClose();
  };
  const handleSubmitRejectedFileMessage = () => {
    let uploadState = {
      _id: fileData._id,
      isHidden: true,
      isFinal: false,
    };

    const getFileParams = {
      name: "Make Hidden Update",
      payload: uploadState,
      dealId: formData?._id,
      ironId: formData?.ironId,
      onSuccessFn: onClose,
      loadingName: "hidden",
    };

    updateFile(getFileParams);
  };

  return (
    <>
      <MuiDialog
        title={"Reject File With Message"}
        show={show}
        isSuccess={false}
        // isSuccess={isSuccess}
        maxWidth={"xs"}
        onSaveLabel={"Confirm"}
        isLoading={filesPostIsLoading}
        // paperWidth={"60%"}
        onClose={handleCloseModal}
        onSubmit={handleSubmitRejectedFileMessage}
      >
        Please give the reason for why you are rejecting this file.
      </MuiDialog>
      <DropdownMenuItem
        onClick={handleOpenModal}
        isLoading={filesPostIsLoading}
        disabled={row?.isHidden === true ? true : false}
        icon={<FeedbackOutlined sx={{ maxHeight: "1rem" }} fontSize="small" />}
        label="Reject w/ Comment"
      />
    </>
  );
};

const DropdownMenuItem = ({ onClick, isLoading, label, icon, disabled }) => {
  const text = isLoading ? <LinearProgress /> : label;
  return (
    <MenuItem disabled={disabled} onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </MenuItem>
  );
};

export default ScenarioPendingFilesTableCellActions;
