// export const removeDuplicates = (array, property) => {
//   const seen = new Set();
//   const newArray = [];
//   for (const value of array) {
//     if (!seen.has(value[property])) {
//       newArray.push(value);
//       seen.add(value[property]);
//     }
//   }
//   return newArray;
// };

import { isNil, toLower } from "lodash";
import { getObjectValueFromStringPath } from "./getObjectValueFromStringPath";

export const removeDuplicates = (array, name) => {
  // const property = name;
  if (isNil(array)) {
    return [];
  }
  const seen = new Set();
  const newArray = [];
  for (const arrayObject of array) {
    // console.log("arrayObject", arrayObject);
    const property = getObjectValueFromStringPath(arrayObject, name);
    const arrayObjectValue = toLower(getObjectValueFromStringPath(arrayObject, name));
    if (!seen.has(arrayObjectValue)) {
      newArray.push(arrayObject);
      seen.add(arrayObjectValue);
    }
  }
  return newArray;
};
