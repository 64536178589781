import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardScreen from "../../../../components/layout/DashboardScreen";
import MuiReactTableWithHeader from "../../../../components/shared/MuiReactTableWithHeader";
import BrokerCreateScenarioFormModal from "../../../../forms/BrokerCreateScenario/BrokerCreateScenarioFormModal";
import { useBrokerDrilldownHook } from "../../../../hooks/useBrokerDrilldownHook";
import { loanScenariosTableColumns } from "../../../../shared/table_columns/loanScenariosTableColumns";
import { loanScenariosTableColumnsMobile } from "../../../../shared/table_columns/loanScenariosTableColumnsMobile";
import MuiCheckbox from "../../../../components/shared/MuiCheckbox";
import LoanScenariosTable from "../BrokerDashboardScreen/widgets/LoanScenariosTable";

const BrokerLoanScenariosScreen = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showDuplicates, setShowDuplicates] = useState(false);
  const { getIsLoading, handleGetAndSetBrokerScenarios } = useBrokerDrilldownHook();
  const loanScenarios = useSelector((state) => state.loanScenarios);
  const isMobile = useSelector((state) => state.globalData?.isMobile);
  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/scenarios/${id}`);
  };

  const handleOnDuplicateChange = (data) => {
    setShowDuplicates(data.target.checked);
  };

  const columns = React.useMemo(() => loanScenariosTableColumns(handleClick), []);
  const cols_mobile = React.useMemo(() => loanScenariosTableColumnsMobile(handleClick), []);
  const tableData = React.useMemo(
    () => (showDuplicates ? loanScenarios : loanScenarios.filter((s) => s.isDuplicate !== true)),
    [loanScenarios, showDuplicates]
  );

  useEffect(() => {
    handleGetAndSetBrokerScenarios();
  }, []);
  return (
    <DashboardScreen>
      {/* <MuiReactTableWithHeader
        title={"Submitted Loan Scenarios"}
        emptyTitle={"There are currently no scenarios"}
        noBorder
        search
        columns={isMobile ? cols_mobile : columns}
        data={tableData}
        headerContent={<MuiCheckbox onChange={handleOnDuplicateChange} label={"Show Duplicates"} />}
        isLoading={getIsLoading}
        // headerContent={
        //   <IconButton
        //     onClick={() => setShowModal(true)}
        //     sx={{
        //       margin: "0 !important",
        //       padding: "4px",
        //     }}
        //   >
        //     <AddRounded sx={{ fontSize: "1rem !important" }} />
        //   </IconButton>
        // }
      /> */}
      <LoanScenariosTable />
    </DashboardScreen>
  );
};

export default BrokerLoanScenariosScreen;
