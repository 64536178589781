import React, { useEffect } from "react";
import TabSwitcher from "../../../../components/shared/TabSwitcher";

import { Box, Button, Divider, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import ScreenBody from "../../../../components/layout/Screen/ScreenBody";
// import "./BorrowerLoanDrilldown.css";
import { isEmpty, isNil } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import DrilldownOverviewBox from "../../../../components/Drilldown/DrilldownOverviewBox";
import DrilldownOverviewItem from "../../../../components/Drilldown/DrilldownOverviewItem";
import DrilldownTitleButton from "../../../../components/Drilldown/DrilldownTitleButton";
import ReactFinalForm from "../../../../components/form/ReactFinalForm";
import DashboardScreen from "../../../../components/layout/DashboardScreen";
import FullPageLoader from "../../../../components/shared/FullPageLoader";
import { useBrokerDrilldownHook } from "../../../../hooks/useBrokerDrilldownHook";
import ScenarioDrilldownTabDetails from "./tabs/ScenarioDrilldownTabDetails";

import {
  ChevronLeft,
  ChevronRight,
  CloseRounded,
  PersonOutlined,
  SaveOutlined,
  SettingsRounded,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useFormState } from "react-final-form";
import FinalFormSelectField from "../../../../components/form/Fields/FinalFormSelectField";
import FinalFormToggle from "../../../../components/form/Fields/FinalFormToggle";
import DisplayDataFieldItem from "../../../../components/shared/DisplayDataFieldItem";
import FlexBox from "../../../../components/shared/FlexBox";
import MuiDialog from "../../../../components/shared/MuiDialog";
import { scenarioStatusOptions } from "../../../../forms/_formQuestions/selectOptions";
import { arrayProtector } from "../../../../helpers/arrayProtector";
import { formatAsDollarString } from "../../../../helpers/formatAsDollarString";
import { useBorrowerHook } from "../../../../hooks/useBorrowerHook";
import { clearFormData, setFormData } from "../../../../redux/actions/formDataActions";
import DrawerSection from "../UsersScreen/DrawerSection";
import ScenarioDrilldownTabBorrowers from "./tabs/ScenarioDrilldownTabBorrowers";
import ScenarioDrilldownTabComments from "./tabs/ScenarioDrilldownTabComments/ScenarioDrilldownTabComments";
import ScenarioDrilldownTabFiles from "./tabs/ScenarioDrilldownTabFiles/ScenarioDrilldownTabFiles";
import ScenarioDrilldownTabLoanTerms from "./tabs/ScenarioDrilldownTabLoanTerms";
import ScenarioDrilldownTabProperties from "./tabs/ScenarioDrilldownTabProperties";

const scenarioDrilldownTabs = [
  {
    label: "Scenario Terms & Details",
    to: "scenario-details",
  },

  {
    label: "Borrowers",
    to: "borrowers",
  },
  {
    label: "Properties",
    to: "properties",
  },
  // {
  //   label: "Sizer",
  //   to: "sizer",
  // },

  {
    label: "Files",
    to: "files",
  },
  {
    label: "Comments",
    to: "comments",
  },
];

const BrokerLoanScenarioDrilldownScreen = (props) => {
  const [isInvalidScenario, setIsInvalidScenario] = useState(undefined);
  const loanScenarios = useSelector((state) => state.loanScenarios);
  const formData = useSelector((state) => state.formData);
  const dispatch = useDispatch();

  const { id } = useParams();
  const navigate = useNavigate();
  const { isInternalEmployee, handleFilterScenario, handleGetAndSetBrokerScenarios } = useBrokerDrilldownHook();
  const isInUnderwriting = false;
  const isLoading = isNil(isInvalidScenario) && (isNil(formData) || isEmpty(formData));
  const showDrilldownData = isEmpty(formData) ? false : true;

  // const isInternalEmployee = userEmail.includes("@ironfund.com") || userEmail.includes("@flatironrealtycapital.com");

  useEffect(() => {
    if (arrayProtector(loanScenarios).length === 0) {
      handleGetAndSetBrokerScenarios(id, () => setIsInvalidScenario(true));
    } else {
      handleFilterScenario(id, () => setIsInvalidScenario(true));
    }
    return () => dispatch(clearFormData());
  }, []);

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (!showDrilldownData) {
    return (
      <DashboardScreen customScreenBody collapsed={false}>
        <FlexBox sx={{ padding: "50px 0", flexDirection: "column" }} center>
          <Typography variant="h2">Scenario Not Found</Typography>
          <Typography variant="body1">Sorry, we could not find a scenarios associated with this ID.</Typography>
          <Button
            onClick={() => navigate("/scenarios")}
            sx={{ marginTop: "20px" }}
            variant="outlined"
            startIcon={<ChevronLeft />}
          >
            Return to all loan scenarios
          </Button>
        </FlexBox>
      </DashboardScreen>
    );
  }

  if (showDrilldownData) {
    return (
      <DashboardScreen customScreenBody collapsed={false}>
        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, height: "100%" }}>
          <ReactFinalForm
            hideButton
            initialValues={formData}
            // example
            // formSpy
          >
            <HeaderTopRow />

            <ScreenBody>
              <Box
                sx={{
                  paddingTop: "15px",
                  minHeight: "550px",
                  height: "100%",
                  paddingBottom: "50px",
                  form: {
                    height: "100%",
                    width: "100%",
                  },
                }}
                spacing={2}
              >
                <FlexBox alignCenter justifyBetween sx={{ width: "100%" }}>
                  <TabSwitcher noBorder link tabs={scenarioDrilldownTabs} />
                  {isInternalEmployee && !isInUnderwriting ? (
                    <FlexBox gap={"10px"} alignCenter>
                      <LoanStatusField />
                      {/* <LoanStatusField /> */}
                      {/* <FinalFormSelectField
                        variant={"standard"}
                        label={"Status"}
                        name={`scenarioStatus`}
                        items={scenarioStatusOptions}
                      /> */}
                      <MoveToUnderwritingButton />
                    </FlexBox>
                  ) : null}
                </FlexBox>

                <Routes>
                  <Route path="/*" index element={<ScenarioDrilldownTabDetails />} />
                  <Route path="/scenario-details" index element={<ScenarioDrilldownTabDetails />} />
                  <Route path="/loan-terms" index element={<ScenarioDrilldownTabLoanTerms />} />
                  <Route path="/borrowers" index element={<ScenarioDrilldownTabBorrowers />} />
                  <Route path="/properties" index element={<ScenarioDrilldownTabProperties />} />
                  {/* <Route path="/sizer" element={<ScenarioDrilldownTabSizer />} /> */}
                  <Route path="/files" element={<ScenarioDrilldownTabFiles />} />
                  <Route path="/comments" element={<ScenarioDrilldownTabComments />} />
                </Routes>
              </Box>
            </ScreenBody>
          </ReactFinalForm>
        </Box>
      </DashboardScreen>
    );
  }
};

const LoanSettingsButton = ({ onClick }) => {
  const [show, setShow] = useState(false);
  const formData = useSelector((state) => state.formData);

  return (
    <>
      <IconButton
        onClick={() => setShow(true)}
        // size="small"
        variant="outlined"
        color="primary"
        // sx={{ padding: "6px", border: "1px solid var(--primary)", borderRadius: "4px" }}
      >
        <SettingsRounded sx={{ fontSize: "1rem !important" }} />
      </IconButton>

      <Drawer
        anchor={"right"}
        open={show}
        onClose={() => setShow(false)}
        sx={{
          ".MuiPaper-root": {
            display: "flex",
            width: "100%",
            maxWidth: "520px",
            padding: "20px 15px",
          },
        }}
      >
        <ReactFinalForm
          hideButton
          initialValues={formData}
          //
          // formSpy
        >
          <LoanSettingsDrawerForm setShow={setShow} />
        </ReactFinalForm>
      </Drawer>
    </>
  );
};

const LoanSettingsDrawerForm = ({ setShow }) => {
  const { postIsLoading, updateBrokerScenario, uploadScenarioFiles } = useBorrowerHook();
  const formData = useSelector((state) => state.formData);
  const { values } = useFormState();
  const dispatch = useDispatch();

  const handleOnScenarioUpdate = () => {
    const onRequestSuccess = (passedData) => {
      console.log("onrequest success", passedData);
      dispatch(setFormData(passedData));
      setShow(false);
    };

    const params = {
      payload: values,
      id: formData?._id,
      onSuccessFn: onRequestSuccess,
    };

    updateBrokerScenario(params);
  };
  return (
    <Stack>
      <IconButton
        onClick={() => setShow(false)}
        color="primary"
        sx={{ position: "absolute", top: "5px", right: "5px" }}
      >
        <CloseRounded />
      </IconButton>
      <Typography variant="h3" pb={2} fontWeight={700}>
        Loan Settings
      </Typography>

      <Divider />
      <DrawerSection
        mt={3}
        title={"Scenario Details"}
        titleIcon={<PersonOutlined sx={{ fontSize: "1rem !important", opacity: 0.75, marginRight: "5px" }} />}
      >
        <Stack spacing={1} mt={1}>
          <DisplayDataFieldItem
            row
            dot
            sx={{ justifyContent: "space-between" }}
            label={"Scenario Display Name"}
            value={formData?.loanSubjectName}
          />
          <Divider />
          <DisplayDataFieldItem
            row
            dot
            sx={{ justifyContent: "space-between" }}
            label={"Marked as Duplicate Loan"}
            value={<FinalFormToggle name={"isDuplicate"} />}
          />
        </Stack>
      </DrawerSection>
      <FlexBox
        sx={{
          top: "auto",
          bottom: 0,
          left: 0,
          position: "absolute",
          width: "100%",
          zIndex: 111111,
        }}
      >
        <LoadingButton
          // type="submit"
          onClick={handleOnScenarioUpdate}
          loading={postIsLoading}
          sx={{ flexGrow: 1, borderRadius: 0 }}
          variant="contained"
          size="large"
          endIcon={<SaveOutlined />}
        >
          Save Changes
        </LoadingButton>
      </FlexBox>
    </Stack>
  );
};

const MoveToUnderwritingButton = ({ onClick }) => {
  const [show, setShow] = useState(false);

  const formData = useSelector((state) => state.formData);
  const { postIsLoading, moveScenarioToUnderwriting } = useBorrowerHook();
  const handleOnMoveToUnderwritingClick = () => {
    const onSuccess = () => {
      setShow(false);
    };
    const params = {
      scenarioId: formData?._id,
      payload: formData,
      onSuccessFn: onSuccess,
    };

    moveScenarioToUnderwriting(params);
  };
  return (
    <>
      {/* <FinalFormSelectField label="Status" name={`scenarioStatus`} items={scenarioStatusOptions} /> */}
      <Button
        // sx={{ whiteSpace: "nowrap" }}
        onClick={() => setShow(true)}
        // size="small"
        endIcon={<ChevronRight sx={{ marginRight: "-4px" }} />}
        variant="contained"
      >
        Move To Underwriting Portal
      </Button>

      <MuiDialog
        title={"Move Scenario To Underwriting"}
        show={show}
        // isSuccess={isSuccess}
        maxWidth={"xs"}
        onSaveLabel={"Confirm"}
        isLoading={postIsLoading}
        onClose={() => setShow(false)}
        onSubmit={handleOnMoveToUnderwritingClick}
      >
        Are you sure you want to move this Scenario to underwriting?
      </MuiDialog>
    </>
  );
};

const LoanStatusField = () => {
  const { values } = useFormState();
  const { isInternalEmployee } = useBrokerDrilldownHook();
  return (
    <FlexBox
      sx={{
        ".form-field": {
          width: "max-content",
          minWidth: "120px",
          ".MuiFormLabel-root": {
            transform: "translate(5px , 5px)",
            fontSize: ".75rem",
          },
          ".MuiSelect-standard": {
            // transform: "translate(5px , 5px)",
            // fontSize: ".75rem",
            fontSize: ".75rem",
            padding: "6px 25px 6px 5px",
            // padding: "7px 5px",
          },
          ".MuiInputBase-root": {
            marginTop: 0,
            minHeight: "34px",
          },
        },
      }}
      gap={"8px"}
      alignCenter
    >
      <Typography sx={{ fontSize: ".75rem", fontWeight: 600 }}>Loan Status: </Typography>
      <FinalFormSelectField
        variant={"standard"}
        label={isNil(values?.scenarioStatus) ? "Status" : undefined}
        name={`scenarioStatus`}
        disabled={!isInternalEmployee}
        items={scenarioStatusOptions}
      />
    </FlexBox>
  );
};

const HeaderTopRow = () => {
  const formData = useSelector((state) => state.formData);
  const isMobile = useSelector((state) => state.globalData?.isMobile);
  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate("/scenarios");
  };
  return (
    <Box
      id="header"
      sx={{
        background: "#ffffff",
        boxShadow: "var(--bs)",
        zIndex: 11,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent={isMobile ? "flex-start" : "space-between"}
        alignItems={isMobile ? "flex-start" : "center"}
        sx={{
          width: "100%",
          // paddingLeft: "10px ",
          border: "4px solid #ffffff",
          flexWrap: "wrap",
          ".drilldown-overview-item.row": {
            justifyContent: "flex-start",
            span: {
              whiteSpace: "nowrap",
            },
          },
        }}
      >
        {/* <FinalFormSelectField name={`scenarioStatus`} label="Status" items={scenarioStatusOptions} /> */}
        <DrilldownTitleButton
          labelSx={{ fontWeight: 500, fontSize: "1rem" }}
          iconSx={{ marginRight: "-2px", width: "18px", height: "18px" }}
          onClick={handleBackButton}
        >
          {formData?.loanSubjectName}
        </DrilldownTitleButton>

        <FlexBox alignCenter gap={"5px"}>
          <DrilldownOverviewBox
            sx={{
              // flexGrow: 0,

              gap: isMobile ? 0 : "20px",
              flexWrap: "wrap",
              flexGrow: isMobile ? 1 : 0,
              // flexDirection: isMobile ? "column" : "row",
              flexBasis: isMobile ? "100%" : "auto",
              width: isMobile ? "100%" : "auto",
            }}
          >
            <DrilldownOverviewItem
              row
              label="Initial Loan Amount:"
              value={formatAsDollarString(formData.initialLoanAmount)}
            />

            <DrilldownOverviewItem row label="Loan Product:" value={formData.loanProductType} />

            <DrilldownOverviewItem row label="Loan Purpose:" value={formData.loanPurpose} />

            <DrilldownOverviewItem row label="Assigned To:" value={formData?.loanSalesperson?.fullName} />

            {/* <MuiGrid spacing={4}>
            <GridItem xs={12} sm={6} md={3} lg={3} xl={3}>
              <DrilldownOverviewItem
                row
                label="Initial Loan Amount:"
                value={formatAsDollarString(formData.initialLoanAmount)}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3} lg={3} xl={3}>
              <DrilldownOverviewItem row label="Loan Product:" value={formData.loanProductType} />
            </GridItem>
            <GridItem xs={12} sm={6} md={3} lg={3} xl={3}>
              <DrilldownOverviewItem row label="Loan Purpose:" value={formData.loanPurpose} />
            </GridItem>
            <GridItem xs={12} sm={6} md={3} lg={3} xl={3}>
              <DrilldownOverviewItem row label="Assigned To:" value={formData?.loanSalesperson?.fullName} />
            </GridItem>
          </MuiGrid> */}

            {/* <DrilldownOverviewItem row label="Closing Date:" value={""} /> */}
          </DrilldownOverviewBox>
          <LoanSettingsButton />
        </FlexBox>
      </Stack>
    </Box>
  );
};

export default BrokerLoanScenarioDrilldownScreen;
