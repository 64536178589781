import { AppBar, Box, Container } from "@mui/material";
import Hamburger from "hamburger-react";
import { isNil } from "lodash";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import PortalScreenTitle from "../../../components/layout/Screen/PortalScreenTitle";
import UserProfileButton from "../../../components/navigation/UserProfileButton";
import FlexBox from "../../../components/shared/FlexBox";
import IronFundLogo from "../../../components/shared/IronFundLogo";
import { stringProtector } from "../../../helpers/stringProtector";
// import "./BrokerDashboardHeader.css";

const BrokerDashboardHeader = ({ isMobile, isOpen, setIsOpen }) => {
  const location = useLocation();
  const locationName = location.pathname;
  const titleName =
    isNil(locationName) || locationName === "/" || locationName === "/home" || locationName === "/login"
      ? "Dashboard"
      : stringProtector(locationName).split("/")[1];
  const pageTitle = titleName.replace("/", "").replace("-", " ");

  return (
    <>
      {isMobile ? (
        <FlexBox justifyBetween alignCenter sx={{ paddingLeft: "5px", background: "#ffffff" }}>
          <Link to={"/"}>
            <IronFundLogo building width={"105px"} color={"#ffffff"} />
          </Link>
          {isMobile ? <Hamburger color="rgba(0, 0, 0, 0.54)" toggled={isOpen} toggle={setIsOpen} size={22} /> : null}
        </FlexBox>
      ) : null}
      <AppBar sx={{ padding: "8px 0", boxShadow: "none" }} position="static">
        <Container maxWidth={false}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "6px",
                alignItems: "center",
              }}
            >
              <PortalScreenTitle sx={{ fontSize: isMobile ? "1.2rem" : "1.6rem" }}>{pageTitle}</PortalScreenTitle>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {isMobile ? null : <UserProfileButton />}
              {/* {isMobile ? <Hamburger toggled={isOpen} toggle={setIsOpen} size={28} /> : <UserProfileButton />} */}
              {/* <NotificationsButton /> */}
            </Box>
          </Box>
        </Container>
      </AppBar>
      {/* <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Button
              sx={{ padding: "0px", fontWeight: "500" }}
              color="inherit"
              onClick={() => navigate("/loan-applications")}
            >
              <ScreenSubTitle>My Loan Applications</ScreenSubTitle>
            </Button>

            <Button sx={{ padding: "0px" }} variant="text" color="inherit">
              <ScreenSubTitle sx={{ fontWeight: 700 }}>
                Loan App - 1234
              </ScreenSubTitle>
            </Button>
          </Breadcrumbs> */}
    </>
  );
};

export default BrokerDashboardHeader;
