import React from "react";

import FormWizard from "../../components/form/FormWizard/FormWizard";
import { formStateWithQueryParams } from "../../helpers/formStateWithQueryParams";
import { useFormsHook } from "../../hooks/useFormsHook";
import { borrowerLoanApplicationFormSteps } from "./formSteps";

const BorrowerLoanApplicationFormWizard = ({ onSuccess }) => {
  const { isLoading, submitLoanApplication } = useFormsHook();

  const handleFormSubmit = async (formState) => {
    const params = {
      payload: formStateWithQueryParams(formState),
      onSuccessFn: () => onSuccess(),
      name: "Loan Application",
    };
    // onSuccess();
    await submitLoanApplication(params);
  };

  return (
    <FormWizard
      id="loan-application"
      muiStepper
      alt={false}
      isLoading={isLoading}
      formSteps={borrowerLoanApplicationFormSteps}
      onSubmit={handleFormSubmit}
      submitButtonText={"Submit Loan Application"}
      // clickable
      // formSpy
    />
  );
};

export default BorrowerLoanApplicationFormWizard;
