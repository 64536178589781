import { useState } from "react";
import CardGraphicButton from "../../../../../components/shared/CardGraphicButton";
import TickerWidgetStatus from "../../../../../components/shared/Widgets/TickerWidget/TickerWidgetStatus";
import { ReactComponent as Icon } from "../assets/loanCreditAuthGraphicDark.svg";
import { useStytchUser } from "@stytch/react";
import CopyToClipboardModal from "../../../../../components/shared/CopyToClipboardModal";

export const ShareCreditAuthorizationWidget = () => {
  const { user } = useStytchUser();
  const initUrl = `https://ironlinc.ironfund.com/credit-authorization?brokerId=${user?.trusted_metadata?.mongoid}&email=${user?.trusted_metadata?.emailAddress}`;
  // const initUrl = `https://ironlinc.getflatiron.com/credit-authorization?brokerId=${user?.trusted_metadata?.mongoid}&email=${user?.trusted_metadata?.emailAddress}`;

  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(initUrl);

  return (
    <>
      <CardGraphicButton
        title="Share Credit Authorization"
        description="Create a sharable link for credit authorizations."
        onClick={() => setOpen(true)}
        icon={<Icon />}
      >
        <TickerWidgetStatus value="Not Submitted" />
      </CardGraphicButton>
      <CopyToClipboardModal
        open={open}
        description={"Have your borrower fill out a credit authorization by sending them this link."}
        onClose={() => setOpen(false)}
        title={"Shareable Credit Authorization"}
        url={url}
      />
    </>
  );
};
