import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";

/**
  <TooltipIconButton title={"Example"} icon={<ChevronRightRounded />} onClick={exampleFunction} />
 */

const TooltipIconButton = ({ title, onClick, icon, sx, size }) => {
  return (
    <Tooltip title={title} arrow>
      <Box
        sx={{
          ".tooltip-button .MuiSvgIcon-root": {
            fontSize: size ? size : "1rem ",
          },
          ...sx,
        }}
      >
        <ButtonItem onClick={onClick} icon={icon} />
      </Box>
    </Tooltip>
  );
};

const ButtonItem = React.forwardRef(function ButtonItem({ onClick, icon }, ref) {
  //  Spread the props to the underlying DOM element.

  return (
    <IconButton className="tooltip-button" size="small" onClick={onClick} ref={ref}>
      {icon}
    </IconButton>
  );
});

export default TooltipIconButton;
