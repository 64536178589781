import { isArray, isNil, isString } from "lodash";
import { parseISOToDate } from "../../helpers/parseISOToDate";
import { convertStringToDate } from "../../helpers/convertStringToDate";
import { convertStringToTime } from "../../helpers/convertStringToTime";
import { useState } from "react";
import { Popover, Typography } from "@mui/material";
import IronFundUserLastSignInPopover from "../IronFundUserLastSignInPopover";

export const assignedUsersTableColumns = [
  {
    Header: "Name",
    accessor: (d) => `${d?.firstName} ${d?.lastName}`,
  },
  {
    Header: "Iron Fund Contact",
    accessor: (d) => d?.assignedSalesperson?.fullName,
  },
  {
    Header: "Email",
    accessor: "emailAddress",
  },
  // {
  //   Header: "Phone Number",
  //   accessor: "phoneNumber",
  // },
  // {-moz-hidden-unscrollable
  //   Header: "Company",
  //   accessor: "company",
  // },
  {
    Header: "Role",
    accessor: "companyRole",
  },
  {
    Header: "Scenarios",
    accessor: (d) => (isNil(d?.scenarioCount) ? 0 : d?.scenarioCount),
  },
  {
    Header: "Last Sign In",
    accessor: (d) => <IronFundUserLastSignInPopover user={d} />,
  },
  {
    Header: "Created",
    accessor: (d) => (isNil(d?.accountCreatedDate) ? "-" : parseISOToDate(d?.accountCreatedDate)),
  },
  {
    Header: "Source",
    accessor: (d) => (isNil(d?.referralSource) ? "Unknown" : getReferralSource(d)),
  },
];

const getReferralSource = (user) => {
  if (isNil(user)) {
    return "Unknown";
  }

  const source = user?.referralSource;
  const isBrokerReferral = source === "Broker referral";
  const isOther = source === "Other";

  if (isBrokerReferral) {
    return user?.referralSourceBrokerName;
  }
  if (isOther) {
    return user?.referralSourceOtherName;
  }

  return source;
};
