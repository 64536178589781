import { Box } from "@mui/material";
import { isNil } from "lodash";
import React from "react";
import CalendarDatePicker from "./components/CalendarDatePicker";
import CalendarInviteTitleText from "./components/CalendarInviteTitleText";
import ProfileImage from "./components/ProfileImage";
import { useMemo } from "react";
import { isMobile } from "react-device-detect";
import { useFormState } from "react-final-form";
import KazProfileImg from "../../../../assets/images/kaz-profile-hd.JPG";

const CalendarInviteAside = ({ data, dates }) => {
  const { values } = useFormState();
  const isKaz = data?.internalUserName === "Daniel Kaziev";
  const kazUrl = KazProfileImg;

  const name = useMemo(() => data?.internalUserName, [data]);
  const profileSrc = useMemo(() => (isKaz ? kazUrl : data?.profileSrc), [data]);
  const titleName = isNil(name) ? "us" : name;
  const title = `Meet with ${titleName}`;
  const hideCalendar = isMobile && !isNil(values?.meetingDateTime) ? true : false;
  return (
    <Box
      sx={{
        width: "100%",
        flexBasis: "60px",
        flexGrow: 1,
        padding: "30px 15px",
        // padding: "30px 10px 0",
        flexDirection: "column",
        justifyContent: "center",
        borderRight: "var(--border)",
        flexShrink: 0,
        // flexShrink: 0,
        background: "var(--blue)",
      }}
    >
      <ProfileImage
        name={titleName}
        src={profileSrc}
        // src="https://avatars.hubspot.net/24ad1c8fb83f84dec63745551151b63a-170"
      />
      <CalendarInviteTitleText
        variant={"h2"}
        sx={{
          color: "#ffffff",
          marginBottom: "20px",
          textAlign: "center",
          //
          // whiteSpace: "nowrap",
        }}
      >
        {title}
      </CalendarInviteTitleText>
      <CalendarDatePicker dates={dates} />
      {/* {hideCalendar ? null : <CalendarDatePicker dates={dates} />} */}
    </Box>
  );
};

export default CalendarInviteAside;
