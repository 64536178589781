export const extractDomainFromUrl = (url) => {
  // Remove "https://" or "http://"
  url = url.replace(/^https?:\/\//, "");

  // Remove "www." if present
  url = url.replace(/^www\./, "");

  // Extract domain name
  const domain = url.split("/")[0];

  return domain;
};
