import { DownloadRounded, InsertDriveFileOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { omit } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FlexBox from "../../../../../components/shared/FlexBox";
import MuiReactTable from "../../../../../components/shared/MuiReactTable";
import TabSwitcher from "../../../../../components/shared/TabSwitcher";
import { scenarioStatusOptions } from "../../../../../forms/_formQuestions/selectOptions";
import { downloadObjectAsExcel } from "../../../../../helpers/downloadObjectAsExcel";
import { formatTabSwitcherTabTitle } from "../../../../../helpers/formatTabSwitcherTabTitle";
import { getTodaysDate } from "../../../../../helpers/getTodaysDate";
import { useBrokerDrilldownHook } from "../../../../../hooks/useBrokerDrilldownHook";
import { loanScenariosTableColumns } from "../../../../../shared/table_columns/loanScenariosTableColumns";
import { loanScenariosTableColumnsMobile } from "../../../../../shared/table_columns/loanScenariosTableColumnsMobile";
import { useMemo } from "react";
const thStyles = { position: "relative", fontWeight: "600", fontSize: ".7rem", color: "rgba(0, 0, 0, 0.87)" };
const sortNameTypes = {
  uploadDate: "uploadDate",
  fileName: "file_display_name",
  docGroup: "docGroup",
  docType: "docType",
};

const LoanScenariosTable = ({ scenarios }) => {
  const loanScenarios = useSelector((state) => state.loanScenarios);
  const useableScenarioTableData = useMemo(() => (scenarios ? scenarios : loanScenarios), [scenarios, loanScenarios]);

  const [tab, setTab] = useState(0);
  const [tableData, setTableData] = useState(scenarios ? scenarios : []);

  const isMobile = useSelector((state) => state.globalData?.isMobile);
  const { isInternalEmployee, getIsLoading, handleGetAndSetBrokerScenarios } = useBrokerDrilldownHook();
  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/scenarios/${id}`);
  };

  const data = React.useMemo(() => tableData, [tableData]);
  const columns = React.useMemo(() => loanScenariosTableColumns(handleClick), []);
  const cols_mobile = React.useMemo(() => loanScenariosTableColumnsMobile(handleClick), []);

  const getFilteredScenariosByStatusName = (filterName) => {
    let filteredList = useableScenarioTableData.filter((scenario) => scenario?.scenarioStatus === filterName);
    return filteredList;
  };

  const handleFilterAndSetTableData = (tabNum) => {
    const filterName = scenarioStatusOptions[tabNum];
    const filteredScenarios = getFilteredScenariosByStatusName(filterName);
    setTableData(filteredScenarios);
  };

  useEffect(() => {
    handleFilterAndSetTableData(tab);
  }, [useableScenarioTableData]);

  const handleOnTabSwitch = (tab) => {
    setTab(tab);
    handleFilterAndSetTableData(tab);
  };

  const getExcelTableData = () => {
    const scenariosWithoutDuplicates = useableScenarioTableData?.filter(
      (sc) => sc.isDuplicate !== true && sc.scenarioStatus !== "Duplicate"
    );
    const cleanedList = scenariosWithoutDuplicates.map((scenario) => {
      const cleanedValues = omit(scenario, [
        "_id",
        "userId",
        "submitted_est",
        "submitted_utc",
        "submitted_est",
        "submitted_est",
      ]);
      return cleanedValues;
    });

    console.log("New Array", cleanedList);
    return cleanedList;
    // return newArray;
  };
  return (
    // <MuiReactTableWithHeader
    <MuiReactTable
      id="submitted-scenarios-table"
      title={`Loan Scenarios`}
      emptyTitle={"There are currently no scenarios"}
      noBorder
      widget
      headerContent={
        isInternalEmployee ? (
          <Tooltip arrow title="Download Excel">
            <IconButton
              onClick={() =>
                downloadObjectAsExcel(getExcelTableData(), `Iron Fund Scenario Report - ${getTodaysDate()}`)
              }
              // onClick={() => getExcelTableData()}
            >
              <FlexBox center sx={{ position: "relative", width: "1rem", height: "1rem" }}>
                <InsertDriveFileOutlined
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "1.4rem !important",
                    color: "#1f7244",
                  }}
                />
                <DownloadRounded
                  sx={{
                    position: "absolute",
                    top: "90%",
                    left: "80%",
                    borderRadius: "50px",
                    transform: "translate(-50%, -50%)",
                    fontSize: ".8rem !important",
                    color: "#1f7244",
                    background: "#f8f9fa",
                  }}
                />
              </FlexBox>
            </IconButton>
          </Tooltip>
        ) : null
      }
      // search
      secondaryRow={
        <TabSwitcher
          value={tab}
          noBorder
          sub
          sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)", background: "#18406308" }}
          setValue={handleOnTabSwitch}
          tabs={[
            formatTabSwitcherTabTitle(`New`, getFilteredScenariosByStatusName("New").length),
            formatTabSwitcherTabTitle(
              `Proceeding to Underwriting`,
              getFilteredScenariosByStatusName("Proceeding to Underwriting").length
            ),
            formatTabSwitcherTabTitle(`Appraisal In`, getFilteredScenariosByStatusName("Appraisal In").length),
            formatTabSwitcherTabTitle("In Underwriting", getFilteredScenariosByStatusName("In Underwriting").length),
            formatTabSwitcherTabTitle("Final Exceptions", getFilteredScenariosByStatusName("Final Exceptions").length),
            formatTabSwitcherTabTitle("Closed", getFilteredScenariosByStatusName("Closed").length),
            formatTabSwitcherTabTitle("Inactive", getFilteredScenariosByStatusName("Inactive").length),
            formatTabSwitcherTabTitle("Duplicate", getFilteredScenariosByStatusName("Duplicate").length),
          ]}
        />
      }
      // headerContent={<MuiCheckbox onChange={handleOnDuplicateChange} label={"Show Duplicates"} />}
      columns={isMobile ? cols_mobile : columns}
      data={data}
      isLoading={getIsLoading}
    />
  );
};

export default LoanScenariosTable;
