import { Download } from "@mui/icons-material";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { filter, isNil } from "lodash";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import MuiCustomTableCell from "../../../../../../../components/shared/MuiCustomTable/MuiCustomTableCell";
import MuiCustomTableRow from "../../../../../../../components/shared/MuiCustomTable/MuiCustomTableRow";
import MuiEditableTable from "../../../../../../../components/shared/MuiEditableTable/MuiEditableTable";
import MuiEmptyTableRow from "../../../../../../../components/shared/MuiEditableTable/MuiEmptyTableRow";
import { getDocumentGroupName } from "../../../../../../../helpers/getDocumentGroupName";
import { isTruthy } from "../../../../../../../helpers/isTruthy";
import { parseISOToDate } from "../../../../../../../helpers/parseISOToDate";
import { useBorrowerHook } from "../../../../../../../hooks/useBorrowerHook";
import MuiCustomTableCellEditableScenarioFilename from "./MuiCustomTableCellEditableScenarioFilename";

const ScenarioApprovedFilesTable = ({ title, isApproved, emptyMessage }) => {
  const columnsInit = ["Filename", "Doc Group", "Upload Date"];
  const columnsPending = [...columnsInit, "Actions"];
  const { getIsLoading, downloadScenarioFilesAsZip } = useBorrowerHook();
  const formData = useSelector((state) => state.formData);
  const isMobile = useSelector((state) => state.globalData?.isMobile);

  const files = formData?.dealDocuments;
  const tableFiles = useMemo(() => filter(files, (f) => isTruthy(f.approvedForUnderwriting)), [files]);

  const handleOnZipDownload = () => {
    const params = {
      scenarioId: formData?._id,
      params: ["approvedForUnderwriting=True"],
      downloadName: "Scenario Approved Files",
    };

    downloadScenarioFilesAsZip(params);
  };

  return (
    <MuiEditableTable
      maxWidth={isMobile ? "320px" : "unset"}
      tableSx={{
        th: {
          whiteSpace: "nowrap",
        },
      }}
      tableHeaderContent={
        <Tooltip arrow title={"Download Files As Zip"}>
          <IconButton
            onClick={handleOnZipDownload}
            sx={{
              position: "absolute",
              right: "4px",
              top: "2px",
              padding: "5px",
              // marginRight: "5px",
              color: "var(--blue)",
            }}
          >
            {getIsLoading ? <CircularProgress size={14} /> : <Download sx={{ fontSize: "1rem !important" }} />}
          </IconButton>
        </Tooltip>
      }
      title={title}
      columns={isApproved ? columnsInit : columnsPending}
    >
      {tableFiles?.map((document, index) => (
        <DocumentRow key={document?._id} document={document} />
      ))}
      <MuiEmptyTableRow files={tableFiles} title="There are no approved files" />
    </MuiEditableTable>
  );
};

const DocumentRow = ({ document }) => {
  return (
    <MuiCustomTableRow>
      <MuiCustomTableCellEditableScenarioFilename fileDisplayName={document?.file_display_name} document={document} />
      {/* <MuiCustomTableCell>
        {isNil(document?.docGroup) ? "Unknown" : getDocumentGroupName(document?.docGroup)}
      </MuiCustomTableCell> */}
      <MuiCustomTableCell>{parseISOToDate(document?.uploadDate)}</MuiCustomTableCell>
    </MuiCustomTableRow>
  );
};

export default ScenarioApprovedFilesTable;
