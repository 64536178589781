import { EmailOutlined, PhoneOutlined } from "@mui/icons-material";
import React from "react";
import { Field } from "react-final-form";
import { TextFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import AddressAutofillField from "../../../components/form/components/inputs/generic/AddressAutofillField/AddressAutofillField";
import DobField from "../../../components/form/components/inputs/generic/DobField";
import FormGroup from "../../../components/form/components/shared/FormGroup/FormGroup";
import MuiGrid from "../../../components/shared/MuiGrid";
import GridItem from "../../../components/shared/MuiGrid/GridItem";
import { parsePhoneNumber } from "../../../helpers/parse/parsePhoneNumber";
import { VALIDATOR_EMAIL, VALIDATOR_REQUIRE } from "../../../helpers/validators/inputValidators";
import FinalFormTextField from "../../../components/form/Fields/FinalFormTextField";

const BorrowerCreditAuthBorrowerInfo = (props) => {
  return (
    <>
      <FormGroup noMargin fieldLabel="Contact Information">
        <MuiGrid>
          <GridItem size={6}>
            <FinalFormTextField validate={VALIDATOR_REQUIRE} name={"firstName"} label="First Name" />
          </GridItem>
          <GridItem size={6}>
            <FinalFormTextField validate={VALIDATOR_REQUIRE} name={"lastName"} label="Last Name" />
          </GridItem>

          <GridItem size={12}>
            <Field
              validate={VALIDATOR_EMAIL}
              name={"emailAddress"}
              label={`Email Address`}
              icon={<EmailOutlined />}
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={12}>
            <FinalFormTextField
              validate={VALIDATOR_REQUIRE}
              name={"phoneNumber"}
              icon={<PhoneOutlined />}
              parse={parsePhoneNumber}
              label={`Phone Number`}
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>

      <AddressAutofillField isRequired name={"primaryAddress"} label="Your Primary Address" />
      <DobField />
    </>
  );
};

export default BorrowerCreditAuthBorrowerInfo;
