import { Navigate, Route, Routes } from "react-router";

import BorrowerCreditAuthScreen from "../views/Unauthenticated/screens/BorrowerCreditAuthScreen";
import BorrowerLoanApplicationScreen from "../views/Unauthenticated/screens/BorrowerLoanApplicationScreen";
import PortalWorkspace from "./PortalWorkspace";
import AuctionComScreen from "../views/Unauthenticated/screens/AuctionComScreen";
import LogoutScreen from "../views/BrokerView/screens/LogoutScreen/LogoutScreen";
import UnsubscribeScreen from "../views/Unauthenticated/screens/UnsubscribeScreen/UnsubscribeScreen";
import BrokerSignUpScreen from "../views/Unauthenticated/screens/BrokerSignUpScreen/BrokerSignUpScreen";
import CalendarInviteScreen from "../views/Unauthenticated/screens/CalendarInviteScreen/CalendarInviteScreen";
const entryPath = "/";

const AuthenticatedView = (props) => {
  return (
    <Routes>
      <Route path="/*" element={<PortalWorkspace />} />
      <Route path="/loan-application" element={<BorrowerLoanApplicationScreen />} />
      <Route path="/auction-preapproval" element={<AuctionComScreen />} />
      <Route path={"/unsubscribe"} element={<UnsubscribeScreen />} />
      <Route path={"/sign-up"} element={<BrokerSignUpScreen />} />
      <Route path="/credit-authorization" element={<BorrowerCreditAuthScreen />} />
      <Route path="/meeting/*" element={<CalendarInviteScreen />} />
      <Route path="/logout" element={<LogoutScreen />} />
      <Route path="*" element={<Navigate to={entryPath} replace />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
export default AuthenticatedView;

// export const authenticatedRoutes = (
//   <Routes>
//     <Route path="/*" element={<PortalWorkspace />} />
//     <Route path="/loan-application" element={<BorrowerLoanApplicationScreen />} />
//     <Route path="/auction-preapproval" element={<AuctionComScreen />} />
//     <Route path="/credit-authorization" element={<BorrowerCreditAuthScreen />} />
//     <Route path="/logout" element={<LogoutScreen />} />

//     <Route path="*" element={<Navigate to={entryPath} replace />} />
//     <Route path="*" element={<Navigate to="/" replace />} />
//   </Routes>
// );
