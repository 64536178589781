import {
  AccountCircleOutlined,
  AssessmentOutlined,
  FeedOutlined,
  GroupsOutlined,
  HomeOutlined,
  LogoutOutlined,
  RuleOutlined,
  StorefrontOutlined,
} from "@mui/icons-material";
import { Box, Divider, List, ListItem } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import NavLink from "../../../components/navigation/NavLink";
import { useBrokerDrilldownHook } from "../../../hooks/useBrokerDrilldownHook";
import { useReduxHook } from "../../../redux/helpers/useReduxHook";

const BrokerVerticalNavigationLinks = ({ isCollapsed }) => {
  const { handleLogoutUser } = useReduxHook();
  const { isInternalEmployee } = useBrokerDrilldownHook();
  const borrowerRoutesProfile = [
    {
      label: "Dashboard",
      path: "/dashboard",
      icon: <HomeOutlined />,
    },
    {
      label: "Loan Scenarios",
      path: "/scenarios",
      icon: <AssessmentOutlined />,
    },
    {
      label: "Deals In Underwriting",
      path: "/deals-in-underwriting",
      icon: <RuleOutlined />,
    },
  ];

  const internalRoutes = [
    {
      label: "Companies",
      path: "/companies",
      icon: <StorefrontOutlined />,
    },

    {
      label: "Users",
      path: "/users",
      icon: <GroupsOutlined />,
    },
  ];
  const borrowerRoutesSubmissions = [
    // {
    //   label: "Broker Package",
    //   path: "/broker-package",
    //   icon: <InventoryRounded />,
    // },
    {
      label: "Underwriting Material",
      path: "/underwriting-material",
      icon: <FeedOutlined />,
    },
    // {
    //   label: "File Manager",
    //   path: "/file-manager",
    //   icon: <FileUploadOutlined />,
    // },
    {
      label: "My Profile",
      path: "/profile",
      icon: <AccountCircleOutlined />,
    },
    // {
    //   label: "Settings",
    //   path: "/settings",
    //   icon: <SettingsOutlined />,
    // },
    // {
    //   label: "Help",
    //   path: "/help",
    //   icon: <HelpOutline />,
    // },
    {
      label: "Logout",
      path: "/logout-user",
      onClick: () => handleLogoutUser(),
      icon: <LogoutOutlined />,
    },
  ];

  const bottomRoutes = isInternalEmployee
    ? [...internalRoutes, ...borrowerRoutesSubmissions]
    : borrowerRoutesSubmissions;

  const listItemStyles = {
    "&.collapsed": {
      ".MuiButtonBase-root": {
        justifyContent: "center",
        flexDirection: "column",
        ".MuiTypography-root": {
          fontSize: ".55rem !important",
          textAlign: "center",
          // fontWeight: 500,
        },
      },
      ".MuiListItemIcon-root": {
        display: "flex",
        justifyContent: "center",
      },
    },
  };

  return (
    <Box
      component={"nav"}
      id="borrower-navbar"
      height="100%"
      sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
    >
      <Stack direction={"column"} justifyContent="space-between" sx={{ flexGrow: 1 }}>
        <LinkListGroup title={"Submissions"}>
          {borrowerRoutesProfile.map((link, index) => (
            <ListItem className={isCollapsed ? "collapsed" : ""} key={link.label} disablePadding sx={listItemStyles}>
              <NavLink hideText={isCollapsed} icon={link.icon} id="dashboard-home" to={link.path} label={link.label} />
            </ListItem>
          ))}
        </LinkListGroup>
        <LinkListGroup title={"Profile"}>
          <Divider />
          {bottomRoutes.map((link, index) => (
            <ListItem className={isCollapsed ? "collapsed" : ""} key={link.label} sx={listItemStyles} disablePadding>
              <NavLink
                hideText={isCollapsed}
                icon={link.icon}
                id="dashboard-home"
                to={link.path}
                label={link.label}
                onClick={link?.onClick}
              />
            </ListItem>
          ))}
        </LinkListGroup>
      </Stack>
    </Box>
  );
};

const LinkListGroup = ({ title, children }) => {
  return (
    <Box
      sx={{
        ".MuiListItemIcon-root": {
          minWidth: "32px",
        },
        ".nav-link-item": {
          ".MuiButtonBase-root": {
            paddingLeft: "14px",
          },

          ".MuiTypography-root": {
            color: "#00000099",
            // color: "#000000de",
            // color: "rgba(0, 0, 0, 0.54)",
            fontSize: ".75rem !important",
            fontWeight: "600",
          },
          ".MuiSvgIcon-root": {
            height: ".9em",
          },
          "&:hover": {
            background: "#76a2cc24 !important",
          },
          "&.active": {
            color: "var(--blue) !important",
            // color: "var(--blue) !important",
            background: "var(--accent)",
            borderRadius: 0,
            // background: "#76a2cc36",
            ".MuiTypography-root": {
              color: "var(--blue)",
              fontSize: ".75rem !important",
              fontWeight: "600",
            },
            ".MuiSvgIcon-root": {
              fill: "var(--blue)",
            },
          },
        },
      }}
    >
      <List
        sx={{
          color: "var(--black)",
          padding: 0,
        }}
      >
        {children}
      </List>
    </Box>
  );
};

export default BrokerVerticalNavigationLinks;
