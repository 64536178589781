import { SearchRounded } from "@mui/icons-material";
import { Box, InputAdornment, Table, TableBody, TableHead, TextField, Typography } from "@mui/material";
import { isNil } from "lodash";
import React, { useState } from "react";
import {
  useAsyncDebounce,
  useExpanded,
  useFilters,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { arrayProtector } from "../../helpers/arrayProtector";
import ScreenSubTitle from "../layout/Screen/ScreenSubTitle";
import FlexBox from "./FlexBox";
import MuiEditableTableCell from "./MuiEditableTable/MuiEditableTableCell";
import MuiEditableTableRow from "./MuiEditableTable/MuiEditableTableRow";
import MuiEmptyTableRow from "./MuiEditableTable/MuiEmptyTableRow";
import MuiLoadingTableRow from "./MuiEditableTable/MuiLoadingTableRow";
import WidgetCard from "./Widgets/WidgetCard";

export const MuiReactTableWithHeader = ({ columns, data, isLoading, id, hideSubHeader, ...props }) => {
  // Use the state and functions returned from useTable to build your UI
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.

      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 50 },
      filterTypes,

      disableMultiSort: true,
    },
    useGlobalFilter,
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const DYNAMIC_CARD_HEADER_CONTENT_FULL =
    props.headerContent || props.search ? (
      <FlexBox alignCenter gap={"10px"}>
        {props.headerContent}
        {props.search ? (
          <TableSearch
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            rows={rows}
          />
        ) : null}
      </FlexBox>
    ) : null;

  const CARD_HEADER_CONTENT = (
    <FlexBox
      sx={{
        minHeight: "58px",
        padding: "6px 10px",
        background: "#18406308",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
      }}
      justifyBetween
      alignCenter
      className="table-primary-header"
    >
      <ScreenSubTitle sx={{ fontSize: "1rem" }} h2>
        {props.title}
      </ScreenSubTitle>

      {DYNAMIC_CARD_HEADER_CONTENT_FULL}
    </FlexBox>
  );
  return (
    <WidgetCard
      id={id}
      sx={{
        position: "relative",
        background: "#ffffff",
        border: "var(--border)",
        display: "flex",
        padding: 0,
        width: "100%",
        flexDirection: "column",
        ...props.cardSx,
      }}
    >
      {props.title || props.headerContent || props.search ? CARD_HEADER_CONTENT : null}
      {/* {props.title || props.headerContent || props.search ? cardHeaderContent : null} */}
      {props.secondaryRow ? props.secondaryRow : null}
      <Box className="table-outer-wrapper">
        <Table
          size="small"
          sx={{ ...props.tableStyles }}
          id={props.id}
          className={`file-document-table  `}
          {...getTableProps()}
        >
          {!props.hideHeader ? (
            <TableHead className="cell-titles">
              {hideSubHeader
                ? null
                : headerGroups.map((headerGroup, index) => (
                    <MuiEditableTableRow key={index + 1} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        // console.log("column", column);
                        return (
                          <MuiEditableTableCell th key={column?.id} {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </MuiEditableTableCell>
                        );
                      })}
                    </MuiEditableTableRow>
                  ))}
            </TableHead>
          ) : null}
          {/* Table Body */}

          {isLoading ? <MuiLoadingTableRow /> : null}
          {data?.length < 1 && !isLoading ? (
            <TableBody>
              <MuiEmptyTableRow title={props.emptyTitle} />
            </TableBody>
          ) : null}
          {data?.length >= 1 ? (
            <TableBody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <MuiEditableTableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <MuiEditableTableCell
                          noBorder={arrayProtector(rows).length >= rowIndex + 1 ? true : false}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </MuiEditableTableCell>
                      );
                    })}
                  </MuiEditableTableRow>
                );
              })}
            </TableBody>
          ) : null}
        </Table>
      </Box>
    </WidgetCard>
  );
};

const TableSearch = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, rows, visibleRows }) => {
  const TWO_HUNDRED_MS = 200;
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, TWO_HUNDRED_MS);
  return (
    <Box
      className="global-search-wrapper"
      sx={{
        display: "flex",
        gap: "8px",
        alignItems: "center",
        ".MuiOutlinedInput-root": {
          fontWeight: 700,
          borderRadius: "8px",
          // borderRadius: "50px",
          border: " 1px solid #cdced3 !important",
          padding: "0px 14px !important",
          background: "#ffffff !important",

          svg: {
            width: "1rem",
            height: "1rem",
            opacity: 0.8,
          },
        },
        fieldset: {
          display: "none",
        },
        "#global-search": {
          padding: "8px 0 !important",
        },
      }}
    >
      <TextField
        id="global-search"
        fullWidth
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRounded />
            </InputAdornment>
          ),
        }}
      />

      {rows.length < count && (
        <Box
          sx={{
            fontSize: "0.7rem",
            textAlign: "center",
            // padding: "10px",
            // display: "block",
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            opacity: 0.8,
          }}
        >
          <Typography fontSize={".65rem"}>Showing</Typography>
          <Typography fontSize={".7rem"} fontWeight={600}>
            {rows.length} of {count}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default MuiReactTableWithHeader;
