import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useMemo } from "react";
import { useForm } from "react-final-form";
// import "./input.css";
import { useSelector } from "react-redux";

import { arrayProtector } from "../../../../../helpers/arrayProtector";
import FormField from "../../shared/FormField/FormField";
import { isNil } from "lodash";

export const EmployeeSelectFieldComponent = (props) => {
  const employees = useSelector((state) => state.globalData.employees);
  const selectOptions = useMemo(() => arrayProtector(employees), [employees]);

  const {
    input: { name, value, ...restInput },
    meta,
    label,
    items,
    FormControlProps,
    noMargin,
    isRequired,
    helperText,
    id,
    onChange,
    onSelectChange,
    multiple,
    ...rest
  } = props;
  // console.log("fieldState", props, input);
  const { change } = useForm();

  const handleDefaultInputChange = (e) => {
    let val = e.target.value;
    let filteredEmployee = employees?.filter((em) => em.fullName === val);

    let employeeObject = filteredEmployee[0];

    if (onChange) {
      onChange(name, employeeObject);
    }

    if (onSelectChange) {
      onSelectChange(e);
    }

    change(name, employeeObject);
  };

  return (
    <>
      <FormField
        className={`${props.display && "display-field"} ${
          (meta.active || meta.dirty || (meta.valid && value !== "")) && "active"
        }`}
        {...props}
      >
        {/* <RenderCount /> */}
        {/* {props.label && <FormLabel>{props.label}</FormLabel>} */}
        <FormControl fullWidth variant="filled">
          {!props.display && (
            <InputLabel
              // sx={{ fontSize: ".8rem", fontWeight: 600, opacity: ".6" }}
              size="small"
              htmlFor={name}
            >
              {label}
              {isRequired && "*"}
            </InputLabel>
          )}

          <Select
            {...rest}
            name={name}
            // labelId={name}
            id={id}
            error={meta.error && meta.touched}
            multiple={multiple ? true : false}
            // error={true}
            size="small"
            label={label}
            disabled={props.disabled}
            defaultValue=""
            value={value.fullName === undefined ? value : value.fullName}
            variant="filled"
            inputProps={restInput}
            onChange={handleDefaultInputChange}
          >
            <MenuItem disabled value="">
              <em>{isNil(selectOptions) ? "Fetching sales people" : "Select salesperson"}</em>
            </MenuItem>
            {selectOptions !== undefined &&
              selectOptions.map((i) => {
                return (
                  <MenuItem key={i.fullName} value={i.fullName}>
                    {i.fullName}
                  </MenuItem>
                );
              })}
          </Select>

          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {meta.error && meta.touched && <span className="error">{meta.error}</span>}
        </FormControl>
      </FormField>
    </>
  );
};
// export default TextboxFormComponent;
