import React from "react";

import LandingPage from "../../../components/LandingPage/LandingPage";
import BackgroundShape from "../../../components/shared/BackgroundShape";
import SuccessContent from "../../../components/shared/SuccessContent";
import BorrowerLoanApplicationFormWizard from "../../../forms/BorrowerLoanApplication/BorrowerLoanApplicationFormWizard";

const BorrowerLoanApplicationScreen = (props) => {
  const [showError, setShowError] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const title = isSuccess ? "Thanks for your submission!" : "Loan Application";
  const description = isSuccess
    ? "We will process your application and reach out to you shortly."
    : "Please fill out the following fields to submit an application for a loan.";
  return (
    <>
      <BackgroundShape />

      <LandingPage
        id="loan-application"
        cardStyle={{ paddingTop: "20px" }}
        className={isSuccess && "success"}
        title={title}
        description={description}
      >
        {isSuccess ? <SuccessContent /> : <BorrowerLoanApplicationFormWizard onSuccess={() => setIsSuccess(true)} />}
      </LandingPage>
    </>
  );
  // const [isSuccess, setIsSuccess] = React.useState(false);

  // const successContent = (
  //   <div className="form-success-content">
  //     <SvgWrapper width="80px" center className="success-icon-wrapper">
  //       <AnimatedCheckmark />
  //     </SvgWrapper>
  //   </div>
  // );
  // return (
  //   <>
  //     <BackgroundShape />

  //     <LandingPage
  //       id="loan-application"
  //       cardStyle={{ paddingTop: "20px" }}
  //       className={isSuccess && "success"}
  //       title={
  //         isSuccess
  //           ? "Thanks for your submission!"
  //           : "Borrower Loan Application"
  //       }
  //       description={
  //         isSuccess
  //           ? "We will process your application reach out to you shortly."
  //           : "Please fill out the following fields to submit a Flatiron Loan Application."
  //       }
  //     >
  //       {isSuccess ? (
  //         successContent
  //       ) : (
  //         <BorrowerLoanApplicationFormWizard
  //           onFail={() => setShowError(true)}
  //           onComplete={() => setIsSuccess(true)}
  //         />
  //       )}
  //     </LandingPage>
  //   </>
  // );
};

export default BorrowerLoanApplicationScreen;
