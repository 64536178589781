import { SendOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Stack, Switch, Typography } from "@mui/material";
import { isNil } from "lodash";
import React, { useState } from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { useSelector } from "react-redux";
import FinalFormDraftJsHtmlField from "../../../../../components/form/Fields/FinalFormDraftJsHtmlField";
import FinalFormTextField from "../../../../../components/form/Fields/FinalFormTextField";
import { EmployeeSelectFieldComponent } from "../../../../../components/form/components/inputs/finalFormComponents/EmployeeSelectFieldComponent";
import { TextFieldComponent } from "../../../../../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import FormGroup from "../../../../../components/form/components/shared/FormGroup/FormGroup";
import FlexBox from "../../../../../components/shared/FlexBox";
import MuiGrid from "../../../../../components/shared/MuiGrid";
import GridItem from "../../../../../components/shared/MuiGrid/GridItem";
import MuiSelectField from "../../../../../components/shared/MuiSelectField";
import SuccessContent from "../../../../../components/shared/SuccessContent";
import { VALIDATOR_EMAIL, VALIDATOR_REQUIRE } from "../../../../../helpers/validators/inputValidators";
import { useAxiosHook } from "../../../../../hooks/useAxiosHook";
import { useStytchUser } from "@stytch/react";

const InviteUserEmailContent = ({ isLoading, url }) => {
  const { axiosPostIsLoading, axiosPostRequest } = useAxiosHook();
  const [show, setShow] = useState(false);
  const { values } = useFormState();
  const { change } = useForm();

  const handleOnSubmit = async (state) => {
    const payloadData = {
      loanSalesperson: values?.loanSalesperson,
      recipient: values?.recipient,
      subject: values?.subject,
      body: isNil(values?.signature) ? values.body : (values.body += values?.signature),
    };

    const onSuccessFn = () => {
      change({});
      setShow(true);
    };

    const params = {
      name: "Create Borrower",
      path: "ironfund_portal_outbound_email",
      payload: payloadData,
      onSuccessFn: onSuccessFn,
    };
    // console.log("🚀 ~ file: InviteUserEmailContent.js:53 ~ handleOnSubmit ~ params:", params);
    await axiosPostRequest(params);
  };

  const isValid = isNil(values?.subject) || isNil(values?.loanSalesperson) || isNil(values?.recipient);

  if (show) {
    return (
      <Stack alignItems={"center"}>
        <SuccessContent sx={{ paddingBottom: "15px !important", paddingTop: "50px !important" }} />
        <Typography variant="h4" fontSize="1rem">
          Email Sent Successfully
        </Typography>
        <Button
          sx={{ marginTop: "45px" }}
          size="small"
          onClick={() => setShow(false)}
          variant="outlined"
          endIcon={<SendOutlined />}
        >
          Send another email
        </Button>
      </Stack>
    );
  }

  return (
    <Stack>
      <RecipientInformation />
      <SubjectLine />
      <EmailBodyContent url={url} />
      {!isNil(values?.loanSalesperson) ? <EmailSignatureContnent url={url} /> : null}
      <LoadingButton
        sx={{ marginTop: "30px" }}
        size="large"
        disabled={isValid}
        loading={axiosPostIsLoading}
        // loading={isLoading}
        // type="submit"
        onClick={handleOnSubmit}
        variant="contained"
        endIcon={<SendOutlined />}
      >
        Send Email
      </LoadingButton>
    </Stack>
  );
};

const RecipientInformation = () => {
  return (
    <FormGroup label="Recipient Information">
      <MuiGrid spacing={1}>
        <GridItem mobile size={6}>
          <Field
            validate={VALIDATOR_REQUIRE}
            label="Email From"
            name={`loanSalesperson`}
            component={EmployeeSelectFieldComponent}
          />
        </GridItem>
        <GridItem size={6}>
          <Field validate={VALIDATOR_EMAIL} name={"recipient"} label={`Email To`} component={TextFieldComponent} />
        </GridItem>
      </MuiGrid>
    </FormGroup>
  );
};

const SubjectLine = () => {
  return (
    <FormGroup label="Email Subject Line">
      <MuiGrid spacing={1}>
        <GridItem size={12}>
          <FinalFormTextField required name={"subject"} label={`Subject`} />
        </GridItem>
      </MuiGrid>
    </FormGroup>
  );
};

const EmailBodyContent = ({ url }) => {
  const emailBodyOptions = ["Invite Broker Template", "Custom Message"];

  const { change } = useForm();
  const [template, setTemplate] = useState(emailBodyOptions[0]);
  const templates = [
    `
    <div>Hello, </div><br />
    <div>We would like to invite you to join Iron Fund. Please click the link below and complete the form to get your account set up.</div><br />
    <div>
    <a href="${url}" target="_blank" color="#235685" style="text-decoration: underline; color: rgb(0, 0, 0); font-size: 12px;">
    ${url}</a></div>
    <br />
    `,
    `
    <div>Hello, </div><br />
    <div>We would like to invite you to join Iron Fund. Please click the link below and complete the form to get your account set up.</div><br />
    <div>
    <a href="${url}" target="_blank" color="#235685" style="text-decoration: underline; color: rgb(0, 0, 0); font-size: 12px;">
    ${url}</a></div>
    <br />
    `,
  ];

  // const handleAddTemplate = () => {
  //   change("body", templates[0]);
  // };
  const handleOnEmailBodyChange = (t) => {
    const value = t.target.value;
    if (value === emailBodyOptions[0]) {
      change("body", templates[0]);
    }
    if (value === emailBodyOptions[1]) {
      change("body", "");
    }

    setTemplate(value);
    // change("body", templates[0]);
  };

  return (
    <FormGroup label="Email Body">
      <FlexBox
        gap="10px"
        sx={{
          position: "absolute",
          top: "5px",
          right: 0,
          padding: "1px 3px",
          fontWeight: 500,
          fontSize: ".7rem",
          zIndex: 1,
        }}
      ></FlexBox>
      <MuiGrid spacing={1}>
        <GridItem size={12}>
          <MuiSelectField
            label={"Content Type"}
            value={template}
            onChange={handleOnEmailBodyChange}
            items={emailBodyOptions}
          />
        </GridItem>

        {template === emailBodyOptions[1] ? (
          <GridItem size={12}>
            <FinalFormDraftJsHtmlField html name={"body"} />{" "}
          </GridItem>
        ) : null}
      </MuiGrid>
    </FormGroup>
  );
};

const EmailSignatureContnent = ({ url }) => {
  //State
  const { user } = useStytchUser();

  const currentUserEmail = user?.trusted_metadata?.emailAddress;

  const { values } = useFormState();
  const { change } = useForm();
  const [checked, setChecked] = React.useState(false);
  const profile = useSelector((state) => state.globalData?.profile);
  const userProfilePhoneNumber = profile?.phoneNumber;

  //Functions
  const generateSignatureHtmlString = ({ name, email, phoneNumber }) => {
    const salesperson = values?.loanSalesperson;
    const salespersonPhoneNumber = isNil(userProfilePhoneNumber)
      ? values?.signaturePhoneNumber
      : userProfilePhoneNumber;

    const sigValues = {
      signatureName: isNil(name) ? salesperson?.fullName : name,
      signatureEmail: isNil(email) ? salesperson?.ironfund_email : email,
      signaturePhoneNumber: isNil(phoneNumber) ? salespersonPhoneNumber : phoneNumber,
    };

    const signature_role_salesDevRep = `Sales Development Representative`;
    const signature_role_vp = `Vice President - Strategy &amp; Business Development`;

    const signatureRole =
      currentUserEmail === "dk@flatironrealtycapital.com" ||
      currentUserEmail === "dk@ironfund.com" ||
      currentUserEmail === "lm@flatironrealtycapital.com" ||
      currentUserEmail === "lm@ironfund.com" ||
      currentUserEmail === "ek@flatironrealtycapital.com" ||
      currentUserEmail === "ek@ironfund.com"
        ? signature_role_vp
        : signature_role_salesDevRep;

    const signatureString = `
    <div>Sincerly, </div>
    <br />
      <h2 style="margin-bottom:  5px; font-size: 16px; font-family: Verdana;"><strong>${
        sigValues?.signatureName
      }</strong></h2>
      <div style="font-family: Verdana !important;">${signatureRole}</div>
      
      <figure style="margin: 10px 0 0 0 !important;"><img src="https://frc.flatironrealtycapital.com/hubfs/IRON%20FUND%20LOGO%20SHADOW.png" width="163" height="38" /></figure><br />
      <hr />
      <br />
      <table cellpadding="0" cellspacing="0"  style="width: 100%; vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Verdana;">
        <thead>
          <tr>
            <th style="width: 30px;"></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
            ${
              isNil(sigValues?.signaturePhoneNumber) || sigValues?.signaturePhoneNumber === ""
                ? ""
                : `  <tr style="height: 24px;">
                <td>
                  <img src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/phone-icon-2x.png" color="#57585E" alt="mobilePhone" width="13" style="display: block; background-color: rgb(87, 88, 94);">
                </td>           <td>
            <a href="tel:${sigValues?.signaturePhoneNumber}" color="#000000" style="font-family: Verdana; text-decoration: none; color: rgb(0, 0, 0); font-size: 12px;">
            ${sigValues?.signaturePhoneNumber}
            </a>
            </td>
            </tr>`
            }
      
          <tr style="height: 24px;">
            <td>
              <img src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/email-icon-2x.png" color="#57585E" alt="email" width="13" style="display: block; background-color: rgb(87, 88, 94);">
            </td>
            <td>
              <a href="mailto:${
                sigValues?.signatureEmail
              }" color="#000000" style="font-family: Verdana; text-decoration: none; color: rgb(0, 0, 0); font-size: 12px;">
                ${sigValues?.signatureEmail}
                </a>
            </td>
          </tr>
          <tr style="height: 24px;">
            <td>
              <img src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/link-icon-2x.png" color="#57585E" alt="websiteLink" width="13" style="display: block; background-color: rgb(87, 88, 94);">
            </td>
            <td>
              <a href="https://www.ironfund.com/" target="_blank" color="#000000" style="font-family: Verdana; text-decoration: none; color: rgb(0, 0, 0); font-size: 12px;">
                https://www.ironfund.com/
                </a>
            </td>
          </tr>
          <tr style="height: 24px;">
            <td>
              <img src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/address-icon-2x.png" color="#57585E" alt="location" width="13" style="display: block; background-color: rgb(87, 88, 94);">
            </td>
            <td>
              <span color="#000000" style="font-family: Verdana !important;text-decoration: none; color: rgb(0, 0, 0); font-size: 12px;">
                New York, NY || Great Neck, NY
              </span>
            </td>
          </tr>
        </tbody>
      </table>
  `;
    return signatureString;
  };

  const handleUpdateSignature = (val) => {
    const value = val.target.value;
    const updated = generateSignatureHtmlString({ phoneNumber: value });
    change("signature", updated);
  };

  const handleAddSignature = (val) => {
    const signature = generateSignatureHtmlString({});
    change("signature", signature);
  };

  const handleRemoveSignature = () => {
    change("signature", undefined);
  };

  const handleOnSignatureToggleChange = (event) => {
    console.log("event", event.target.checked);
    if (event.target.checked) {
      handleAddSignature();
    } else {
      handleRemoveSignature();
    }
    setChecked(event.target.checked);
  };

  return (
    <FormGroup label="Include Email Signature">
      <FlexBox
        gap="10px"
        sx={{
          position: "absolute",
          top: "-6px",
          right: "-10px",
          padding: "1px 3px",
          fontWeight: 500,
          fontSize: ".7rem",
          zIndex: 1,
        }}
      >
        <Switch
          checked={checked}
          onChange={handleOnSignatureToggleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </FlexBox>
      <MuiGrid spacing={1}>
        <GridItem size={12}>
          {isNil(userProfilePhoneNumber) && checked ? (
            <FinalFormTextField
              phone
              onChange={handleUpdateSignature}
              name={"signaturePhoneNumber"}
              label={"Signature Phone Number"}
            />
          ) : null}
        </GridItem>
      </MuiGrid>
    </FormGroup>
  );
};

export default InviteUserEmailContent;
