import { useSelector } from "react-redux";
import CardGraphicButton from "../../../../../components/shared/CardGraphicButton";
import TickerWidgetStatus from "../../../../../components/shared/Widgets/TickerWidget/TickerWidgetStatus";

import { arrayProtector } from "../../../../../helpers/arrayProtector";
import { ReactComponent as Icon } from "../assets/loanScenarioGraphicDark.svg";
import { useState } from "react";
import BrokerCreateScenarioFormModal from "../../../../../forms/BrokerCreateScenario/BrokerCreateScenarioFormModal";

export const CreateLoanScenariosWidget = ({ onClick }) => {
  const [show, setShow] = useState(false);
  const loanScenarios = useSelector((state) => state.loanScenarios);

  const numberOfScenarios = arrayProtector(loanScenarios).length;
  const scenariosAreEmpty = numberOfScenarios.length === 0 ? true : false;
  return (
    <>
      <CardGraphicButton
        title="Create Loan Scenario"
        id="create-scenario-widget"
        description="Analyze the value of a deal."
        // statusLabel={scenariosAreEmpty ? undefined : "Active Scenarios:"}
        // statusValue={scenariosAreEmpty ? undefined : numberOfScenarios}
        onClick={() => setShow(true)}
        icon={<Icon />}
      >
        <TickerWidgetStatus value="Not Submitted" />
      </CardGraphicButton>
      <BrokerCreateScenarioFormModal show={show} onCancel={() => setShow(false)} />
    </>
  );
};
