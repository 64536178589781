import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { Box, Button, Popover } from "@mui/material";
import { isString } from "lodash";
import React, { useMemo, useState } from "react";
import FlexBox from "../shared/FlexBox";

const PopoverDropdown = ({ value, onChange, options, variant, sx }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectChange = (type) => {
    onChange(type);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "sort-popover" : undefined;

  const buttonStyles = useMemo(() => {
    let styles = { minWidth: "unset" };

    if (variant === "outlined") {
      const outlinedStyles = { boxShadow: "0px 0px 1px 1px #21517d24" };
      styles = { ...styles, ...outlinedStyles };
    }
    return styles;
  }, []);

  const rootStyles = useMemo(
    () => ({
      ".MuiButtonBase-root": {
        fontSize: ".7rem",

        color: "#374151",
        fontWeight: 500,

        ".MuiSvgIcon-root": {
          color: "#9ca3af",
          marginLeft: "-6px",
        },
        ...sx,
      },
    }),
    [sx]
  );

  const paperStyles = useMemo(() => {
    return {
      ".MuiButtonBase-root": {
        fontSize: ".7rem",
        padding: "6px 10px",
        fontWeight: 400,
        justifyContent: "flex-start",
        textAlign: "left",
        ".MuiSvgIcon-root": {
          color: "#9ca3af",
        },
        "&.dropdown-btn": {
          borderLeft: "2px solid #ffffff",
          borderRight: "2px solid #ffffff",
        },
        "&.active": {
          "&.dropdown-btn": {
            background: "#dfdfdf4a",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
            borderLeft: "2px solid var(--secondary)",
          },
        },
      },
    };
  }, []);

  const anchorOrigin = useMemo(() => {
    return {
      vertical: "top",
      horizontal: "left",
    };
  }, []);

  const transformOrigin = useMemo(() => {
    return {
      vertical: "top",
      horizontal: "left",
    };
  }, []);

  return (
    <Box sx={rootStyles}>
      <Button
        fullWidth
        variant={variant}
        className="active"
        size="small"
        sx={buttonStyles}
        color="inherit"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownRounded sx={{ opacity: 0.85 }} />}
      >
        {isString(value) ? value : options?.find((o) => o?.value === value)?.label}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        // sx={styles}
        slotProps={{
          paper: {
            sx: {
              ...paperStyles,
            },
          },
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <FlexBox column sx={{ padding: "4px" }}>
          {options?.map((opt) => {
            const optionLabel = isString(opt) ? opt : opt?.label;
            const optionValue = isString(opt) ? opt : opt?.value;
            return (
              <Button
                className={`dropdown-btn ${value === optionValue ? "active" : ""}`}
                fullWidth
                size="small"
                color="inherit"
                onClick={() => onSelectChange(optionValue)}
              >
                {optionLabel}
              </Button>
            );
          })}
        </FlexBox>
      </Popover>
    </Box>
  );
};

export default PopoverDropdown;
