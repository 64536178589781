import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { get, isArray } from "lodash";
import React from "react";
import { useMemo } from "react";
import { Field } from "react-final-form";

import { arrayProtector } from "../../../helpers/arrayProtector";
import RenderCount from "../components/RenderCount/RenderCount";
import FormField from "../components/shared/FormField/FormField";
import { VALIDATOR_REQUIRE } from "../../../helpers/validators/inputValidators";
import DisplayDataFieldItem from "../../shared/DisplayDataFieldItem";
import { useSelector } from "react-redux";

/**
 * Example:
    <FinalFormSelectField
        name={"state"}
        label="State"
        items={options_stateInitials}
    />
 */

const FinalFormSelectField = ({
  name,
  label,
  validate,
  fullWidth,
  required,
  helperText,
  items,
  placeholder,
  count,
  multiple,
  disabled,
  variant,
  onChange,
}) => {
  let itemsList = useMemo(() => arrayProtector(items), [items]);
  const validators = validate ? validate : required ? VALIDATOR_REQUIRE : undefined;

  return (
    <FormField fullWidth={fullWidth ? fullWidth : true}>
      <Field name={name} validate={validators}>
        {({ input, meta }) => (
          <FormControl fullWidth={fullWidth ? fullWidth : true} variant="filled">
            {label && (
              <InputLabel size="small" htmlFor={name}>
                {label}
                {required && "*"}
              </InputLabel>
            )}
            {count ? <RenderCount /> : null}
            {/* <RenderCount /> */}

            <Select
              error={meta.error && meta.touched}
              size="small"
              label={label}
              multiple={multiple ? true : false}
              disabled={disabled}
              variant={variant ? variant : "filled"}
              // onChange={input.onChange}
              {...input}
            >
              <MenuItem disabled value="">
                <em>{placeholder}</em>
              </MenuItem>
              {!isArray(items)
                ? null
                : itemsList.map((i) => {
                    return typeof i === "string" ? (
                      <MenuItem key={i} value={i}>
                        {i}
                      </MenuItem>
                    ) : (
                      <MenuItem key={i.name} value={i.name}>
                        {i.label}
                      </MenuItem>
                    );
                  })}
            </Select>

            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {meta.error && meta.touched && <span className="error">{meta.error}</span>}
          </FormControl>
        )}
      </Field>
    </FormField>
  );
};

export default React.memo(FinalFormSelectField);
