import { HelpOutlineRounded } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { isNil } from "lodash";
import React from "react";
import { useState } from "react";
import Tour from "reactour";

const BrokerDashboardScreenTutorial = (props) => {
  const [tourIsActive, setTourIsActive] = useState(false);
  const steps = [
    {
      selector: "welcome",

      content: () => (
        <TutorialStepItem title={"Welcome to Iron Fund"}>
          Let us walk you through some basic features like creating a scenario and uploading files.
        </TutorialStepItem>
      ),
    },
    {
      selector: "#submitted-scenarios-table",
      content: () => (
        <TutorialStepItem title={"Viewing your Scenarios"}>
          All of your previously submitted scenarios can be found here.
        </TutorialStepItem>
      ),
    },
    {
      selector: "#create-scenario-widget",
      // content: ' To get started with submitting a scenario, click here "Create Loan Scenario" widget .' ,
      content: () => (
        <TutorialStepItem title={"Submitting a Scenario"}>
          To get started with submitting a scenario, click here "Create Loan Scenario" widget .
        </TutorialStepItem>
      ),
      // action: () => setModalIsActive(true),
    },
    // {
    //   selector: "#submitted-scenarios-table",
    //   content: () => (
    //     <TutorialStepItem title={"Viewing your Scenarios"}>
    //       All of your previously submitted scenarios can be found here.
    //     </TutorialStepItem>
    //   ),
    // },
  ];
  return (
    <>
      <IconButton onClick={() => setTourIsActive(true)}>
        <HelpOutlineRounded />
      </IconButton>
      <Tour steps={steps} isOpen={tourIsActive} onRequestClose={() => setTourIsActive(false)} />
    </>
  );
};

const TutorialStepItem = ({ title, description, children }) => {
  return (
    <Stack>
      <Typography variant="h3" fontWeight={700} fontSize={".9rem"} paddingBottom={"5px"}>
        {title}
      </Typography>
      <Typography variant="body1">{isNil(children) ? description : children}</Typography>
    </Stack>
  );
};

export default BrokerDashboardScreenTutorial;
