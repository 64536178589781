import React from "react";
import { isNil } from "lodash";
import { useStytchUser } from "@stytch/react";
import { useFormState } from "react-final-form";

import { capitalizeFirstLetter } from "../../../../../helpers/capitalizeFirstLetter";
import { parseISOToDateTime } from "../../../../../helpers/parseISOToDateTime";

import FlexBox from "../../../../../components/shared/FlexBox";
import FinalFormTextField from "../../../../../components/form/Fields/FinalFormTextField";
import ProfileScreenCard from "../components/ProfileScreenCard";
import ProfileScreenCardRow from "../components/ProfileScreenCardRow";
import FinalFormToggle from "../../../../../components/form/Fields/FinalFormToggle";
import { getObjectValueFromStringPath } from "../../../../../helpers/getObjectValueFromStringPath";

const NotificationsCard = () => {
  const { user } = useStytchUser();
  const stytchProfile = user?.trusted_metadata;
  const { values } = useFormState();

  return (
    <ProfileScreenCard title="Notifications & Alerts">
      <ProfileScreenCardRow
        // border
        label={"Email Notifications"}
        name={"emailNotifications"}
        input={
          <FinalFormToggle
            phone
            name={"emailNotifications"}
            label={getObjectValueFromStringPath(values, "emailNotifications") ? "Enabled" : "Disabled"}
          />
        }
      />
    </ProfileScreenCard>
  );
};

export default NotificationsCard;
