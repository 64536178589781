import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import FlexBox from "../../../../components/shared/FlexBox";

const DrawerSection = ({ title, mt, children, buttonIcon, titleIcon, onClick, tooltipTitle }) => {
  return (
    <Box position={"relative"} mt={mt ? mt : 5}>
      {onClick ? (
        <Tooltip title={tooltipTitle} arrow>
          <IconButton
            onClick={onClick}
            sx={{ zIndex: 11111, position: "absolute", padding: "5px", top: "-5px", right: "0px" }}
          >
            {buttonIcon}
          </IconButton>
        </Tooltip>
      ) : null}
      <FlexBox>
        {titleIcon}
        <Stack width={"100%"}>
          <Typography variant="h4" fontWeight={500} sx={{ opacity: 0.75 }}>
            {title}
          </Typography>
          <Stack mt={0.5} spacing={0}>
            {children}
          </Stack>
        </Stack>
      </FlexBox>
    </Box>
  );
};

export default DrawerSection;
