import { CircularProgress } from "@mui/material";
import React from "react";
import FlexBox from "../FlexBox";
import "./Loader.css";

const Loader = ({ size, sx }) => {
  return (
    <FlexBox center sx={{ flexGrow: 1, padding: "20px", width: "100%", ...sx }}>
      <CircularProgress size={size ? size : 24} />
    </FlexBox>
  );
};

export default Loader;
