export const setScenarios = (state) => {
  return {
    type: "SET_SCENARIOS",
    payload: state,
  };
};

export const updateScenario = ({ key, value }) => {
  return {
    type: "UPDATE_SCENARIOS",
    payload: { key, value },
  };
};
export const addScenario = (scenario) => {
  return {
    type: "ADD_SCENARIOS",
    payload: scenario,
  };
};
export const removeScenario = ({ key, value }) => {
  return {
    type: "REMOVE_SCENARIOS",
    payload: { key, value },
  };
};

export const clearScenarios = () => {
  return {
    type: "CLEAR_SCENARIOS",
  };
};
