import { AddRounded, ChevronRightRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardScreen from "../../../../components/layout/DashboardScreen";
import DisplayTableWithHeader from "../../../../components/shared/DisplayTableWithHeader";
import MuiReactTableWithHeader from "../../../../components/shared/MuiReactTableWithHeader";
import BrokerCreateScenarioFormModal from "../../../../forms/BrokerCreateScenario/BrokerCreateScenarioFormModal";
import { formFormatDollar } from "../../../../helpers/parse/formFormatDollar";
import { parseISOToDate } from "../../../../helpers/parseISOToDate";
import { useBrokerDrilldownHook } from "../../../../hooks/useBrokerDrilldownHook";

const BrokerDealsInUnderwritingScreen = (props) => {
  const [showModal, setShowModal] = useState(false);
  const loanScenarios = useSelector((state) => state.loanScenarios);
  const navigate = useNavigate();
  const { getIsLoading, handleGetAndSetBrokerScenarios } = useBrokerDrilldownHook();
  const handleClick = (id) => {
    navigate(`/scenarios/${id}`);
  };
  const cols_mobile = [
    {
      Header: "Name",
      accessor: "loanSubjectName",
    },
    {
      Header: "Loan Product Type",
      accessor: "loanProductType",
    },
    {
      Header: "Loan Purpose",
      accessor: "loanPurpose",
    },

    {
      Header: " ",
      accessor: (d) => (
        <IconButton
          onClick={() => handleClick(d?._id)}
          color="primary"
          sx={{ background: "#39679112", marginLeft: "10px" }}
        >
          <ChevronRightRounded />
        </IconButton>
      ),
    },
  ];
  const cols = [
    {
      Header: "Name",
      accessor: "loanSubjectName",
    },
    {
      Header: "Loan Product Type",
      accessor: "loanProductType",
    },
    {
      Header: "Loan Purpose",
      accessor: "loanPurpose",
    },
    {
      Header: "Initial Loan Amount",
      accessor: (d) => `$${formFormatDollar(d.initialLoanAmount)}`,
    },
    {
      Header: "Created Date",
      accessor: (d) => parseISOToDate(d?.submitted_est),
    },
    {
      Header: "Salesperson",
      accessor: (d) => d?.loanSalesperson?.fullName,
    },

    {
      Header: "Actions",
      accessor: (d) => (
        <IconButton
          onClick={() => handleClick(d?._id)}
          color="primary"
          sx={{ background: "#39679112", marginLeft: "10px" }}
        >
          <ChevronRightRounded />
        </IconButton>
      ),
    },
  ];

  return (
    <DashboardScreen>
      <MuiReactTableWithHeader
        title={"Current Deals In Underwriting"}
        emptyTitle={"There are currently no deals In underwriting"}
        columns={cols_mobile}
        data={[]}
        isLoading={getIsLoading}
      />
    </DashboardScreen>
  );
};

export default BrokerDealsInUnderwritingScreen;
