import React from "react";

import { CircularProgress, InputBase } from "@mui/material";
import { isNil } from "lodash";
import { CloseRounded, SearchRounded } from "@mui/icons-material";
import FlexBox from "../shared/FlexBox";

const SearchTextField = ({ quickFilterText, setQuickFilterText, variant, placeholder, loading, sx, width, large }) => {
  const variantStyleMapValue = variant ? variant : "default";
  const variantStyleMap = {
    default: {
      background: "#f3f3f3",
    },
    white: {
      background: "#ffffff",
      boxShadow: "0px 1px 2px 1px #21517d1a",
    },
  };

  const styles = {
    borderRadius: "8px",
    width: width ? width : "100%",
    ...variantStyleMap[variantStyleMapValue],

    ".MuiInputBase-root": {
      backgroundColor: "transparent !important",
      width: "100%",
    },
    input: {
      width: "100%",
      border: "none",
      overflow: "hidden",
      position: "relative",
      textOverflow: "ellipsis",
      whiteSpace: " nowrap",
      padding: large ? "10px !important" : "8px 10px !important",
      "&::placeholder": {
        color: "#0000008a !important",
        opacity: 1,
      },
    },
    ...sx,
  };
  return (
    <FlexBox alignCenter sx={styles}>
      <InputBase
        size="small"
        placeholder={placeholder ? placeholder : "Search in table..."}
        inputProps={{ "aria-label": "search for value" }}
        value={quickFilterText}
        onChange={(e) => setQuickFilterText(e?.target?.value)}
        sx={{
          fontSize: ".7rem",
          border: "none",
          lineHeight: "unset",
          margin: 0,
          background: "inherit",
          input: {
            padding: "4px 6px",
          },
        }}
      />

      {isNil(quickFilterText) || quickFilterText === "" ? (
        <SearchRounded sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "1rem", marginRight: "4px" }} />
      ) : (
        <>
          {loading ? <CircularProgress size={14} sx={{ marginRight: "4px" }} /> : null}
          <CloseRounded
            role="button"
            onClick={() => setQuickFilterText("")}
            sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "1rem", marginRight: "4px", cursor: "pointer" }}
          />
        </>
      )}
    </FlexBox>
  );
};

export default SearchTextField;
