import { CircularProgress, IconButton } from "@mui/material";
import React from "react";

const LoadingIconButton = ({ sx, isLoading, icon, onClick, color, type }) => {
  return (
    <IconButton type={type} color={color} className="edit-button" sx={sx} onClick={onClick}>
      {isLoading ? <CircularProgress size={12} /> : icon}
    </IconButton>
  );
};

export default LoadingIconButton;
